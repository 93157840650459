import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import { useHistory } from "react-router";
import { ORDER_BATCHING } from "../../constants-inventorysmart/routesConstants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../inventorysmart-utility";
import {
  ORDER_BATCHING_FILTERS_HEADER,
  ERROR_MESSAGE,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import globalStyles from "Styles/globalStyles";
import { addSnack } from "actions/snackbarActions";
import classNames from "classnames";
import Filters from "commonComponents/filters/filterGroup";
import { cloneDeep, isEmpty } from "lodash";
import LoadingOverlay from "Utils/Loader/loader";
import { Button, Grid, Paper } from "@mui/material";
import ViewCurrentAllocationsTables from "./components/ViewCurrentAllocationsTables";
import CustomAccordion from "commonComponents/Custom-Accordian";
import {
  setInventorysmartOrderBatchingFilterLoader,
  setSelectedFilters,
  setIsFiltersValid,
  setInventorysmartOrderBatchingFilterElements,
  setInventorysmartOrderBatchingFilterDependency,
  resetOrderBatchingStoreState,
  getOrderBatchingMetrics,
  setInventorysmartOrderBatchingMetricsLoader,
  setInventorysmartOrderBatchingMetrics,
} from "modules/inventorysmart/services-inventorysmart/Order-Batching/order-batching-services";
import OrderBatchingMetrics from "./components/OrderBatchingMetrics";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import { generateIcon } from "Utils/icon-color-generator";

const OrderBatching = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const classes = useStyles();

  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const updateFilters = async (selected, current) => {
    await getFiltersOptions(selected, current);
    props.setInventorysmartOrderBatchingFilterDependency(selected);
  };

  const applyFilters = (filterElements, filterDependency) => {
    const payload = filtersPayload(
      filterElements || props.inventorysmartOrderBatchingFilterElements,
      filterDependency || props.inventorysmartOrderBatchingFilterDependency,
      true
    );

    props.setIsFiltersValid(payload.isValid);
    props.setSelectedFilters(payload.reqBody);
    setOpenModal(false);
  };

  const resetSelectedFilterValues = async () => {
    props.setInventorysmartOrderBatchingFilterDependency([]);
    applyFilters(filterData, []);
    setOpenModal(false);
  };

  const getFiltersOptions = async (selected, current) => {
    try {
      props.setInventorysmartOrderBatchingFilterLoader(true);
      const selectedFilters =
        props.inventorysmartCreateAllocationFilterDependency?.length > 0
          ? cloneDeep(props.inventorysmartCreateAllocationFilterDependency)
          : selected;
      const response = await fetchFilterOptions(
        filters || [],
        selectedFilters,
        current
      );

      let filterElements = cloneDeep(response);
      setFilterData(response);
      props.setInventorysmartOrderBatchingFilterElements(filterElements);
    } catch (error) {
      displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      props.setInventorysmartOrderBatchingFilterLoader(false);
    }
  };

  const fetchOrderBatchingMetrics = async () => {
    try {
      props.setInventorysmartOrderBatchingMetricsLoader(true);
      let body = {
        filters: props.selectedFilters,
      };
      let response = await props.getOrderBatchingMetrics(body);
      if (response.data.status) {
        const metricsWithIcon = response.data.data.map((item) => {
          let icon = generateIcon();
          return { ...item, icon };
        });
        props.setInventorysmartOrderBatchingMetrics(metricsWithIcon);
        props.setInventorysmartOrderBatchingMetricsLoader(false);
      } else {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setInventorysmartOrderBatchingMetricsLoader(false);
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setInventorysmartOrderBatchingMetricsLoader(false);
      return [];
    }
  };

  const resetOrderBatching = () => {
    setFilterData([]);
    setFilters([]);
    props.resetOrderBatchingStoreState();
  };

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        props.setInventorysmartOrderBatchingFilterLoader(true);
        const response = await fetchFilterConfig(
          "inventorysmart_order_triaging"
        );
        setFilters(response);
      } catch (error) {
        props.setInventorysmartOrderBatchingFilterLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    fetchFilters();

    return () => {
      resetOrderBatching();
    };
  }, []);

  useEffect(() => {
    !isEmpty(props.selectedFilters) && fetchOrderBatchingMetrics();
  }, [props.selectedFilters]);

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  return (
    <>
      <HeaderBreadCrumbs
        options={[
          {
            label: "View Current Allocations",
            id: 1,
            action: () => {
              history.push(ORDER_BATCHING);
            },
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {props.inventorysmartOrderBatchingFilterDependency.length > 0 && (
          <FilterChips
            filterConfig={props.inventorysmartOrderBatchingFilterDependency}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay
            loader={props.inventorysmartOrderBatchingFilterLoader}
          >
            <Paper elevation={3} className={globalClasses.paperWrapper}>
              <div className={classes.filterBoardHeader}>
                <h3 className={classes.createDetailsTitle}>
                  {ORDER_BATCHING_FILTERS_HEADER}
                </h3>
              </div>
              <Filters
                filters={filterData}
                update={updateFilters}
                onFilter={applyFilters}
                onReset={resetSelectedFilterValues}
                filterElevation={3}
                showBorderedWrapper={false}
                screen="inventory-smart-dashboard"
                inititalSelection={
                  props.inventorysmartOrderBatchingFilterDependency
                }
                doNotUpdateDefaultValue
              />
            </Paper>
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>
      {props.isFiltersValid && (
        <div
          className={classNames(
            globalClasses.filterWrapper,
            globalClasses.marginVertical1rem
          )}
        >
          <CustomAccordion label="KPIs">
            <OrderBatchingMetrics />
          </CustomAccordion>
        </div>
      )}
      {props.isFiltersValid && (
        <div className={globalClasses.filterWrapper}>
          <CustomAccordion label="Current Allocations">
            <ViewCurrentAllocationsTables
              fetchOrderBatchingMetrics={fetchOrderBatchingMetrics}
            />
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedFilters:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .selectedFilters,
    inventorysmartOrderBatchingFilterLoader:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingFilterLoader,
    isFiltersValid:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .isFiltersValid,
    inventorysmartOrderBatchingFilterElements:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingFilterElements,
    inventorysmartOrderBatchingFilterDependency:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingFilterDependency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventorysmartOrderBatchingFilterLoader: (payload) =>
    dispatch(setInventorysmartOrderBatchingFilterLoader(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setIsFiltersValid: (payload) => dispatch(setIsFiltersValid(payload)),
  setInventorysmartOrderBatchingFilterElements: (payload) =>
    dispatch(setInventorysmartOrderBatchingFilterElements(payload)),
  setInventorysmartOrderBatchingFilterDependency: (payload) =>
    dispatch(setInventorysmartOrderBatchingFilterDependency(payload)),
  setInventorysmartOrderBatchingMetricsLoader: (payload) =>
    dispatch(setInventorysmartOrderBatchingMetricsLoader(payload)),
  setInventorysmartOrderBatchingMetrics: (payload) =>
    dispatch(setInventorysmartOrderBatchingMetrics(payload)),
  getOrderBatchingMetrics: (payload) =>
    dispatch(getOrderBatchingMetrics(payload)),
  resetOrderBatchingStoreState: (payload) =>
    dispatch(resetOrderBatchingStoreState(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderBatching);
