import Table from "../../../Utils/reactTable";
import Link from "@mui/material/Link";
import { connect } from "react-redux";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  updateFilterConfig,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
  getFilterConfigById,
  setModuleConfigData,
  getFilteredFields,
  setFilteredFields,
  setMandatoryFieldsInScreen,
} from "../../../actions/filterAction";
import { addSnack } from "../../../actions/snackbarActions";
import { getColumns } from "../../../actions/tableColumnActions";
import moment from "moment";
import Tabs from "commonComponents/tabs";
import {
  flattenSelectedFiltersData,
  formatTabsArray,
  checkForEmptyDimensionFilters,
} from "./common-functions";
const useStyles = makeStyles({
  moduleTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  dialogActions: {
    background: "#F9F9F9",
    marginRight: "2%",
  },
});
const EditModal = (props) => {
  const classes = useStyles();
  const [updateFilterConfigLoader, setupdateFilterConfigLoader] =
    useState(false);
  const [editFilterDimensions, seteditFilterDimensions] = useState([]);
  const [updateConfigData, setupdateConfigData] = useState({}); //State variable for edit config API response details
  const update = async () => {
    if (
      checkForEmptyDimensionFilters(
        props.selectedModuleConfigData,
        editFilterDimensions
      )
    ) {
      props.addSnack({
        message: "Please add atleast one filter for the chosen dimension(s)",
        options: {
          variant: "error",
        },
      });
      return;
    }
    const elements = flattenSelectedFiltersData(props.selectedModuleConfigData);
    const configJSON = {
      name: props.configObj.name,
      screens: updateConfigData.screens,
      elements: elements,
    };
    try {
      setupdateFilterConfigLoader(true);
      await props.updateFilterConfig(
        props.id,
        props.configObj.fc_code,
        configJSON
      );
      props.addSnack({
        message: "Configuration updated successfully",
        options: {
          variant: "success",
        },
      });
      const body = {
        filters: {
          search: [],
          sort: [],
          range: [],
        },
      };
      props.getFilterConfigsTableData(body, props.page - 1);
      setupdateFilterConfigLoader(false);
      props.handleClose();
    } catch (error) {
      setupdateFilterConfigLoader(false);
      props.addSnack({
        message: "Failed to update",
        options: {
          variant: "error",
        },
      });
    }
  };

  useEffect(() => {
    const fetchConfigInfo = async () => {
      setupdateFilterConfigLoader(true);
      const res = await props.getFilterConfigById(props.configObj.fc_code);
      const configObj = res.data.data;
      const dimensions = configObj?.dimensions;
      const screens = props.configObj.screens;
      setupdateConfigData(configObj); //after fetching the information from the API related to the config
      //set the object into the state variable
      const fieldsres = await props.getFilteredFields(dimensions, screens);
      props.setFilteredFields(fieldsres.data.data.filters);
      props.setMandatoryFieldsInScreen(fieldsres.data.data.compulsory);
      dimensions.forEach((dimension) => {
        const eachDimensionFilters = configObj?.elements.filter(
          (element) => element.dimension === dimension
        );
        props.setModuleConfigData(eachDimensionFilters, dimension);
      });
      seteditFilterDimensions(
        configObj?.dimensions.map((dimension) => {
          return {
            label: dimension,
            value: dimension,
          };
        })
      );
      setupdateFilterConfigLoader(false);
    };
    //Once the modal is opened fetch the config details
    fetchConfigInfo();
  }, []);
  return (
    <Dialog
      id="editFilterDialog"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth="md"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        props.handleClose();
      }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      {updateFilterConfigLoader && <LinearProgress />}
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        <Typography classes={{ root: classes.moduleTitle }}>
          {props.configObj.name}
        </Typography>
        <Tabs tabsData={formatTabsArray(editFilterDimensions, props.id)} />
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <>
          {" "}
          <Button
            disabled={updateFilterConfigLoader}
            color="primary"
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            variant="contained"
            onClick={update}
            disabled={updateFilterConfigLoader}
          >
            Update Filters
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};
const ConfigurationsTable = (props) => {
  const [selectedConfig, setselectedConfig] = useState("");
  const [open, setOpen] = useState(false);
  const [filterConfigsTableColumns, setfilterConfigsTableColumns] = useState(
    []
  );

  const handleClose = () => {
    props.resetFilterConfigSelections();
    setOpen(false);
  };

  const manualCallBack = (body, pageIndex, pageSize) => {
    const manualbody = {
      filters: body,
    };
    props.getFilterConfigsTableData(manualbody, pageIndex, pageSize);
  };

  useEffect(() => {
    //fetch the configs from the API
    const body = {
      filters: {
        search: [],
        sort: [],
        range: [],
      },
    };
    props.getFilterConfigsTableData(body);
    const fetchFilterConfigTableColumns = async () => {
      let cols = await getColumns("table_name=filter_configurations")();
      cols = cols.map((col) => {
        if (col.column_name === "dimensions") {
          col.Cell = (row) => {
            return (
              <>
                {row.value.map((dimension) => {
                  return (
                    <Chip
                      size="medium"
                      color="primary"
                      style={{ padding: 10, fontSize: 12 }}
                      label={dimension}
                    />
                  );
                })}
              </>
            );
          };
        }
        if (col.column_name === "applicable_modules") {
          col.accessor = "screens";
          col.Cell = (row) => {
            return <>{row.value.toString()}</>;
          };
        }
        if (col.column_name === "created_on") {
          col.accessor = "created_at";
          col.Cell = (row) => {
            return <>{moment(row.value).format("MMM Do YY, hh:mm a")}</>;
          };
        }
        if (col.column_name === "name") {
          col.Cell = (row) => {
            return (
              <Link
                component="button"
                variant="body2"
                onClick={() => {
                  setselectedConfig(row.row.original);
                  setOpen(true);
                }}
              >
                {row.value}
              </Link>
            );
          };
        }
        return col;
      });
      setfilterConfigsTableColumns(cols);
    };
    fetchFilterConfigTableColumns();
  }, []);
  return (
    <>
      {filterConfigsTableColumns.length > 0 && (
        <Table
          style={{ height: "60vh" }}
          tableId="filter-config-data"
          rowdata={props.configs.data || []}
          columns={filterConfigsTableColumns}
          tableName={"filter_configurations"}
          fieldChooser={false}
          showPagination={true}
          totalCount={props.configs.totalCount || 0}
          paginatorCallback={(_page) => null}
          manualFilters={true}
          manualSortBy={true}
          manualCallBack={manualCallBack}
          hidePageSelection={true}
        />
      )}
      {open && (
        <EditModal
          open={open}
          configObj={selectedConfig}
          createdConfigs={props.configs.data}
          page={props.configs.page}
          handleClose={handleClose}
          updateFilterConfig={props.updateFilterConfig}
          resetFilterConfigSelections={props.resetFilterConfigSelections}
          id={props.id}
          addSnack={props.addSnack}
          selectedModuleConfigData={props.selectedModuleConfigData}
          getFilterConfigsTableData={props.getFilterConfigsTableData}
          getFilterConfigById={props.getFilterConfigById}
          setModuleConfigData={props.setModuleConfigData}
          getFilteredFields={props.getFilteredFields}
          setFilteredFields={props.setFilteredFields}
          setMandatoryFieldsInScreen={props.setMandatoryFieldsInScreen}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    createdConfigs: state.filterElementsReducer.createdConfigs,
    configs: state.filterElementsReducer.configs,
    selectedModuleConfigData:
      state.filterElementsReducer.selectedModuleConfigData,
  };
};
const mapActionsToProps = {
  updateFilterConfig,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
  addSnack,
  getFilterConfigById,
  setModuleConfigData,
  getFilteredFields,
  setFilteredFields,
  setMandatoryFieldsInScreen,
};
export default connect(mapStateToProps, mapActionsToProps)(ConfigurationsTable);
