import { addSnack } from "actions/snackbarActions";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import {
  defaultTableData,
  DESC_ORDER,
  ERROR_MESSAGE,
  MIN_ARTICLE_SELECTION_MESSAGE,
  tableConfigurationMetaData,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getArticleInventoryTableConfiguration,
  getArticleInventoryTableData,
  setArticleInventoryTableData,
  setArticleInventoryLoader,
  setArticleInventoryTableConfigLoader,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/store-inventory-services";
import {
  setCreateAllocationArticles,
  setInventorysmartCreateAllocationFilterDependency,
  setSelectedFilters,
} from "modules/inventorysmart/services-inventorysmart/Create-Allocation/create-allocation-services";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";
import globalStyles from "Styles/globalStyles";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import StoreArticleInventoryPopup from "./StoreArticleInventoryPopup";
import { Button, Grid } from "@mui/material";
import { CREATE_ALLOCATION } from "modules/inventorysmart/constants-inventorysmart/routesConstants";
import { sortByNumber } from "../../inventorysmart-utility";

const StyleInventoryDetailsTable = (props) => {
  const globalClasses = globalStyles();
  const classes = useStyles();

  const history = useHistory();

  const [articleInventoryData, setArticleInventoryData] = useState([]);
  const [
    articleInventoryTableColumns,
    setArticleInventoryTableColumns,
  ] = useState([]);
  const [selectedArticleIds, setSelectedArticleIds] = useState([]);
  const [
    openStoreArticleInventoryDialog,
    setOpenStoreArticleInventoryDialog,
  ] = useState(false);
  const [article, setArticle] = useState(null);
  const [metric, setMetric] = useState(null);

  const toggleStoreInventoryPopup = (data, columnName) => {
    const selectedArticle = data?.article;
    setArticle(selectedArticle);
    setMetric(columnName);
    openStoreArticleInventoryPopUp();
  };

  const storeCountActionMap = {
    stock_out: toggleStoreInventoryPopup,
    stockout: toggleStoreInventoryPopup,
    shortfall: toggleStoreInventoryPopup,
    normal: toggleStoreInventoryPopup,
    excess: toggleStoreInventoryPopup,
  };

  const fetchArticleInventoryData = async () => {
    try {
      props.setArticleInventoryLoader(true);
      let body = {
        filters: props.selectedFilters?.filter(
          (filterItem) => filterItem?.values?.length > 0
        ),
        meta: {
          ...tableConfigurationMetaData.meta,
        },
      };
      let response = await props.getArticleInventoryTableData(body);
      if (response.data.status) {
        const sortedData = sortByNumber(
          response.data.data,
          DESC_ORDER,
          "lw_qty"
        );
        setArticleInventoryData(sortedData);
        props.setArticleInventoryTableData(response.data);
        props.setArticleInventoryLoader(false);
        return { ...response.data, totalCount: response?.data?.total };
      } else {
        props.setArticleInventoryLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    } catch (err) {
      props.setArticleInventoryLoader(false);
      displaySnackMessages(ERROR_MESSAGE, "error");
      return defaultTableData;
    }
  };

  const redirectToCreateAllocation = async () => {
    props.setInventorysmartCreateAllocationFilterDependency(
      props.inventorysmartDashboardFilterDependency
    );
    props.setSelectedFilters(props.selectedFilters);
    props.setCreateAllocationArticles(selectedArticleIds);
    history.push(`${CREATE_ALLOCATION}?step=0&type=details`);
  };

  useEffect(() => {
    const fetchColumnData = async () => {
      props.setArticleInventoryTableConfigLoader(true);
      let columns = await props.getArticleInventoryTableConfiguration();
      props.setArticleInventoryTableConfigLoader(false);
      let formattedColumns = agGridColumnFormatter(
        columns?.data?.data,
        null,
        storeCountActionMap
      );
      setArticleInventoryTableColumns(formattedColumns);
    };
    fetchColumnData();
  }, []);

  useEffect(() => {
    if (!isEmpty(selectedArticleIds)) {
      //TODO Add Create Allocation Logic
    }
  }, [selectedArticleIds]);

  useEffect(() => {
    !isEmpty(props.selectedFilters) && fetchArticleInventoryData();
  }, [props.selectedFilters]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    let articleIds = selections.map((item) => item.article);
    setSelectedArticleIds(articleIds);
  };

  const openStoreArticleInventoryPopUp = () => {
    setOpenStoreArticleInventoryDialog(true);
  };

  const closeStoreArticleInventoryPopUp = () => {
    setOpenStoreArticleInventoryDialog(false);
  };

  const handleCreateAllocation = () => {
    if (selectedArticleIds?.length === 0) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else {
      redirectToCreateAllocation();
    }
  };

  return (
    <>
      <Loader
        loader={
          props.articleInventoryLoader ||
          props.articleInventoryTableConfigLoader
        }
      >
        <AgGridComponent
          columns={articleInventoryTableColumns}
          rowdata={articleInventoryData}
          selectAllHeaderComponent={props.isCreateAllocationAllowed}
          onSelectionChanged={onSelectionChanged}
          rowSelection="multiple"
          uniqueRowId={"ph_code"}
        />
        {props.isCreateAllocationAllowed && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={globalClasses.marginAround}
          >
            <Button
              variant="contained"
              color="primary"
              id="productSetAllBtn"
              className={classes.button}
              onClick={() => handleCreateAllocation()}
            >
              Create Allocation
            </Button>
          </Grid>
        )}
      </Loader>
      <StoreArticleInventoryPopup
        active={openStoreArticleInventoryDialog}
        openModal={openStoreArticleInventoryPopUp}
        closeModal={closeStoreArticleInventoryPopUp}
        filters={props.selectedFilters}
        article={article}
        metric={metric}
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    articleInventoryLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .articleInventoryLoader,
    articleInventoryTableConfigLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .articleInventoryTableConfigLoader,
    articleInventoryTableData:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .articleInventoryTableData,
    selectedFilters:
      store.inventorysmartReducer.inventorySmartDashboardService
        .selectedFilters,
    inventorysmartDashboardFilterDependency:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDashboardFilterDependency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getArticleInventoryTableConfiguration: (payload) =>
    dispatch(getArticleInventoryTableConfiguration(payload)),
  getArticleInventoryTableData: (payload) =>
    dispatch(getArticleInventoryTableData(payload)),
  setArticleInventoryLoader: (payload) =>
    dispatch(setArticleInventoryLoader(payload)),
  setArticleInventoryTableConfigLoader: (payload) =>
    dispatch(setArticleInventoryTableConfigLoader(payload)),
  setArticleInventoryTableData: (payload) =>
    dispatch(setArticleInventoryTableData(payload)),
  setCreateAllocationArticles: (payload) =>
    dispatch(setCreateAllocationArticles(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setInventorysmartCreateAllocationFilterDependency: (payload) =>
    dispatch(setInventorysmartCreateAllocationFilterDependency(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StyleInventoryDetailsTable);
