import axiosInstance from "../Utils/axios";
import { GET_COLUMNS, SET_ALL_DATA } from "../config/api";
import { SAVE_TABLE_STATE } from "./types";
import columnFormatter from "../Utils/reactTable/components/column-formatter";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

export const getColumns =
  (queryParams, levelsJson = {}, formatSetAllLabel = false) =>
  async () => {
    const { data } = await axiosInstance({
      url: `${GET_COLUMNS}?${queryParams}`,
      method: "GET",
    });
    return columnFormatter(data.data, levelsJson, formatSetAllLabel);
  };

// ag grid formatter
export const getColumnsAg =
  (queryParams, levelsJson = {}, actions = {}, formatSetAllLabel = false) =>
  async () => {
    const { data } = await axiosInstance({
      url: `${GET_COLUMNS}?${queryParams}`,
      method: "GET",
    });
    return agGridColumnFormatter(
      data.data,
      levelsJson,
      actions,
      formatSetAllLabel
    );
  };

export const setColumns = (queryParams, dataObj) => async () => {
  return axiosInstance({
    url: `${GET_COLUMNS}/?tableName=${queryParams}`,
    method: "PUT",
    data: dataObj,
  });
};
export const setAllData = (dataObj) => async () => {
  return axiosInstance({
    url: SET_ALL_DATA,
    method: "POST",
    data: dataObj,
  });
};

export const setTableState = (payload) => (dispatch) => {
  dispatch({
    type: SAVE_TABLE_STATE,
    payload: payload,
  });
};

export const setColumnsConfiguration = (dataObj) => async () => {
  return axiosInstance({
    url: `${GET_COLUMNS}/user-preference`,
    method: "POST",
    data: dataObj,
  });
};
