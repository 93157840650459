import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Layout from "commonComponents/layout";
import HomePage from "./HomePage.jsx";
import "commonComponents/layout/layout.css";
import { setActiveUserApp } from "actions/sideBarActions";
import { appName } from "config/constants/index.js";

const Home = (props) => {
  const sideBarOptions = [
    {
      link: "/" + window.location.pathname.split("/")[1] + "/home",
      title: "Home",
      icon: React.createElement(HomeIcon),
      order: 1,
    },
  ];
  const viewRender = (value)=>{
    return (
      <>
        <HomePage {...value} />
      </>
    )
  }
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + "/home",
      component: viewRender([]),
      title: "Home",
    },
  ];


  useEffect(() => {
    props.setActiveUserApp(appName.WORKFLOW_INPUT_CENTER);
  }, []);

  return (
    <Layout
      routes={routes}
      sideBarOptions={sideBarOptions}
      app={appName.WORKFLOW_INPUT_CENTER}
    />
  );
};

const mapStateToProps = (state) => ({
  clientName: state.authReducer.clientName,
});

const mapActionsToProps = { setActiveUserApp };

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Home));
