import ReviewClusters from "../components/reviewClusters";
import GradingDetails from "../components/gradingDetails";
import ReviewGrading from "../components/reviewGrading";
import PerformanceMetrices from "../components/performanceMetrices";
import GradingPercentile from "../components/gradingPercentile";

const GRADING_STEPS = {
  clusterSteps: [
    {
      screenCode: "grade-details",
      screen: <GradingDetails />,
      label: "Grade Details",
    },
    {
      screenCode: "performance-matrices",
      screen: <PerformanceMetrices />,
      label: "Performance Matrices",
    },
    {
      screenCode: "review-clusters",
      screen: <ReviewClusters />,
      label: "Review Clusters",
    },
    {
      screenCode: "review-grading",
      screen: <ReviewGrading />,
      label: "Review Grading",
    },
  ],
  percentile: [
    {
      screenCode: "grade-details",
      screen: <GradingDetails />,
      label: "Grade Details",
    },
    {
      screenCode: "grade-percentile",
      screen: <GradingPercentile />,
      label: "Grade Percentile",
    },
    {
      screenCode: "performance-matrices",
      screen: <PerformanceMetrices />,
      label: "Performance Matrices",
    },
    {
      screenCode: "review-grading",
      screen: <ReviewGrading />,
      label: "Review Grading",
    },
  ],
};

export const GRADE_SETTINGS_INPUT = [
  {
    accessor: "settings",
    label: "",
    attribute_type: "create_plan",
    column_name: "settings",
    field_type: "checkBoxGroup",
    filter_type: "non-cascaded",
    options: [
      {
        label: "Automatically grade new stores",
        value: "auto",
        isDisabled: false,
      },
      {
        label: "Automatically grade all stores for new product hierarchies",
        value: "product_auto",
        isDisabled: false,
      },
      {
        label: "Auto refresh grades with updated data",
        value: "refresh_updated",
        isDisabled: false,
      },
    ],
    dimension: "create_plan",
  },
];

export const STATIC_DATE_INPUT = [
  {
    label: "Time Period",
    column_name: "time_period",
    type: "dateRange",
    display_type: "dateRange",
    isMandatory: true,
  }
]

export default GRADING_STEPS;
