import React, { useEffect, useState } from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { useAsyncDebounce } from "react-table";
import SearchIcon from "@mui/icons-material/Search";

export default function DefaultColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter },
}) {
  const Index = filterValue.findIndex((filter) => {
    return filter.type === "search";
  });
  let searchValue = "";
  if (Index !== -1) {
    searchValue = filterValue[Index].value;
  }

  const [value, setValue] = useState(searchValue);
  const [filterEdit, setFilterChanged] = useState(false);
  const modifyColFilterArray = (newValue, filterValues, index) => {
    let colfilterArr = [...filterValues];
    if (newValue === "") {
      colfilterArr = colfilterArr.filter((filter) => {
        return filter.type !== "search";
      });
      return colfilterArr;
    } else {
      if (index === -1) {
        colfilterArr.push({
          type: "search",
          value: newValue,
        });
        return colfilterArr;
      } else {
        colfilterArr[index].value = newValue;
        return colfilterArr;
      }
    }
  };

  useEffect(() => {
    if (filterEdit && filterValue.length === 0) {
      setValue("");
      setFilterChanged(false);
    }
  }, [filterValue]);

  const onValueChange = useAsyncDebounce((val) => {
    setFilter(modifyColFilterArray(val, filterValue, Index));
    setFilterChanged(true);
  }, 500);
  const onChange = (e) => {
    setValue(e.target.value);
    onValueChange(e.target.value);
  };
  return (
    <OutlinedInput
      className="search-input"
      id="column-search"
      placeholder="Search"
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
      value={value}
      onChange={onChange}
    />
  );
}
