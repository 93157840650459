import React, { useState } from "react";
import { OutlinedInput, InputAdornment } from "@mui/material";
import { useAsyncDebounce } from "react-table";
import SearchIcon from "@mui/icons-material/Search";

export default function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <span>
      <OutlinedInput
        variant="outlined"
        placeholder="Search"
        value={value || ""}
        className={"search-bar"}
        inputProps={{ style: { padding: "5px" } }}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
    </span>
  );
}
