import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import { uniqBy } from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  BLANK_LIST,
  ERROR_MESSAGE,
  NO_DATA_FOUND,
  POP_UP_TYPE,
  PRODUCT_RULE_POP_UP_TITLE,
  UPDATED_MESSAGE,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";

import { addSnack } from "actions/snackbarActions";

import {
  getProductRulePOPUPTableData,
  getRuleHeaderConfiguration,
  saveProductRulePOPUPTableData,
  setProductRulePopUpLoader,
  setSavePayloadForPopUp,
  setSelectedStoreGroupDataPopUp,
} from "modules/inventorysmart/services-inventorysmart/Product-Profile/product-rule-services";

const useStyles = makeStyles((theme) => ({
  labelPopUp: {
    marginRight: "1.25rem",
    fontWeight: 500,
  },
  dialogPaper: {
    minHeight: "30vh",
    maxHeight: "60vh",
  },
}));
const ProductRulePopUp = ({
  active,
  _openModal,
  closeModal,
  _filters,
  popUpColumnData,
  popUpROWData,
  ...props
}) => {
  const classes = useStyles();
  const [storeGroupRowData, setStoreGroupRowData] = useState([]);
  const [
    storeGroupDefaultSelectedRowData,
    setStoreGroupDefaultSelectedRowData,
  ] = useState([]);
  const [productDCMappingDefault, setProductDCMappingDefault] = useState([]);
  const [dcAndProductColumn, setDcAndProductColumn] = useState([]);
  const [productDCMappingRowData, setProductDCMappingRowData] = useState([]);
  const [agGridInstance, setSetGridInstance] = useState({});
  const [popUpType, setPopUpType] = useState("");

  const [columnDefs, setColumnDefs] = useState([]);

  const loadTableInstance = (params) => {
    setSetGridInstance(params);
  };
  const autoGroupColumnDef = {
    headerName: "Store Group",
    minWidth: 200,
    width: 350,
  };

  const fetchStoreMappingData = async () => {
    try {
      props.setProductRulePopUpLoader(true);
      let bodyPayload = {};
      if (popUpColumnData?.accessor === "dc_mapped") {
        bodyPayload = {
          selection_type: "dc",
          ph_code: popUpROWData?.ph_code,
          channel: popUpROWData?.channel,
          available: popUpROWData?.dc_available || [],
          default: popUpROWData?.default_dcs || [],
        };
      } else if (popUpColumnData?.accessor === "product_profile_name") {
        // payload to be changed once api is up
        bodyPayload = {
          selection_type: "product_profile",
          ph_code: popUpROWData?.ph_code,
          channel: popUpROWData?.channel,
          available: popUpROWData?.product_profile_available || 0,
          default:
            popUpROWData?.default_product_profile == null
              ? []
              : [popUpROWData?.default_product_profile],
        };
      }

      let response = await props.getProductRulePOPUPTableData(bodyPayload);
      if (response?.data?.available.length > 0) {
        let selectedRows = response.data.available
          .filter((row) => {
            let isItemPresent = response.data.selected.indexOf(
              row.dc_code || row.pp_code
            );
            if (isItemPresent > -1) {
              return row.dc_code || row.pp_code;
            }
          })
          .map((modifiedRow) => modifiedRow.dc_code || modifiedRow.pp_code);

        let selectedRowsWithCode = uniqBy(selectedRows, function (e) {
          return e;
        });

        setProductDCMappingRowData(response?.data?.available);
        setProductDCMappingDefault(selectedRowsWithCode);
        props.setProductRulePopUpLoader(false);
      } else {
        displaySnackMessages(NO_DATA_FOUND, "success");
        setProductDCMappingRowData([]);
        props.setProductRulePopUpLoader(false);
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
    }
  };

  const getSGGroupingTableData = async () => {
    try {
      props.setProductRulePopUpLoader(true);
      let bodyPayload = {};
      if (popUpColumnData?.accessor === "store_group_mapped_display") {
        setPopUpType(POP_UP_TYPE.store);
        bodyPayload = {
          ph_code: popUpROWData?.ph_code,
          channel: popUpROWData?.channel,
          selection_type: "store",
          available: popUpROWData?.store_group_available || [],
          default:
            popUpROWData?.default_store_groups == null
              ? []
              : popUpROWData?.default_store_groups,
        };
      }
      let response = await props.getProductRulePOPUPTableData(bodyPayload);

      if (response.data.available.length === 0) {
        displaySnackMessages(NO_DATA_FOUND, "success");
        props.setProductRulePopUpLoader(false);
        setStoreGroupRowData([]);
      }
      let selectedRows = response.data.available
        .filter((row) => {
          let isItemPresent = response.data.selected.indexOf(row.sg_code);
          if (isItemPresent > -1) {
            return row.name;
          }
        })
        .map((modifiedRow) => modifiedRow.name);

      let selectedRowsWithName = uniqBy(selectedRows, function (e) {
        return e;
      });
      response.data.available.forEach((row) => {
        let isItemPresent = response.data.selected.indexOf(row.sg_code);
        if (isItemPresent > -1) {
          row.selection = true;
        } else {
          row.selection = false;
        }
      });
      const finalTableData = response.data.available.sort(
        (a, b) => Number(b.selection) - Number(a.selection)
      );
      setStoreGroupRowData(finalTableData);
      setStoreGroupDefaultSelectedRowData(selectedRowsWithName);

      props.setProductRulePopUpLoader(false);
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setProductRulePopUpLoader(false);

      return [];
    }
  };

  const saveHandlerPopUp = async () => {
    props.setProductRulePopUpLoader(true);

    let selectedRowData;
    let selectedRowsForLocalUpdate;
    if (popUpType === POP_UP_TYPE.store) {
      selectedRowData = agGridInstance.api.getSelectedNodes().map((row) => {
        return row?.allLeafChildren[0].data.sg_code;
      });
      selectedRowsForLocalUpdate = [];
      agGridInstance.api.getSelectedNodes().forEach((masterRow) => {
        masterRow.allLeafChildren.forEach((childRow) => {
          selectedRowsForLocalUpdate.push(childRow?.data);
        });
      });
    } else {
      selectedRowsForLocalUpdate = agGridInstance.api
        .getSelectedNodes()
        .map((row) => {
          return row.data;
        });

      selectedRowData = selectedRowsForLocalUpdate.map((selectedItems) => {
        return selectedItems?.dc_code || selectedItems?.pp_code;
      });
    }

    let updatePayload = {
      selection_type: popUpType,
      ph_code: popUpROWData?.ph_code,
      channel: popUpROWData?.channel,
      selectedRowData: [...selectedRowsForLocalUpdate],
      selected_values:
        popUpType === POP_UP_TYPE.store || popUpType === POP_UP_TYPE.dc
          ? selectedRowData
          : selectedRowData[0],
    };

    try {
      let saveResponse = await props.saveProductRulePOPUPTableData(
        updatePayload
      );
      if (!saveResponse.message) {
        throw ERROR_MESSAGE;
      }
      props.setProductRulePopUpLoader(true);
      props.setSavePayloadForPopUp(updatePayload);
      displaySnackMessages(UPDATED_MESSAGE, "success");
      // fetch table data to show the updated store group/stores mapped count
      if (popUpType === POP_UP_TYPE.store) {
        props.setProductRuleTableLoader(true);
        props.agGridInstance.api?.refreshServerSideStore({ purge: true });
        props.manualCallBack();
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
    }
    setStoreGroupRowData([]);
    setProductDCMappingRowData([]);
    setTimeout(() => {
      closeModal();
    }, 0);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  useEffect(() => {
    const fetchColumnData = async () => {
      let columns = [];

      if (popUpColumnData?.accessor === "store_group_mapped_display") {
        columns = await getRuleHeaderConfiguration(
          "inventorysmart_pr_store_groups"
        )();
        // fetch row data
        getSGGroupingTableData();

        //set type of popup
        setPopUpType(POP_UP_TYPE.store);
        columns.forEach((column) => {
          if (column?.column_name == "name") {
            column["cellRenderer"] = "agGroupCellRenderer";
            column.rowGroup = true;
          }
        });

        //set column data
        setColumnDefs(columns);
        return;
        /* return from function as grouping table has different logic for displaying table
            Set -> Column definition & Popup Type
         */
      } else if (popUpColumnData?.accessor === "dc_mapped") {
        columns = await getRuleHeaderConfiguration(
          "inventorysmart_pr_dc_mapping"
        )();
        columns.map((column) => (column["width"] = "350"));
        setPopUpType(POP_UP_TYPE.dc);
        fetchStoreMappingData();
      } else if (popUpColumnData?.accessor === "product_profile_name") {
        setPopUpType(POP_UP_TYPE.product_profile);
        columns = await await getRuleHeaderConfiguration(
          "inventorysmart_pr_product_profile_mapping"
        )();
        fetchStoreMappingData();
      }
      let formattedColumns = agGridColumnFormatter(columns);
      setDcAndProductColumn(formattedColumns);
    };

    if (active) {
      fetchColumnData();
    }

    return () => {
      setProductDCMappingRowData([]);
      setStoreGroupRowData([]);
    };
  }, [active]);

  const gridSelectionType = () => {
    if (popUpType === POP_UP_TYPE.store || popUpType === POP_UP_TYPE.dc) {
      return "multiple";
    }
    return "single";
  };

  const gridUniqueKey = () => {
    if (popUpType === POP_UP_TYPE.store || popUpType === POP_UP_TYPE.dc) {
      return "dc_code";
    }
    return "pp_code";
  };

  return active ? (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      id="productRulePopup"
      aria-labelledby="product-rule-dialog"
      open={active}
      maxWidth="lg"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        closeModal();
      }}
    >
      <DialogTitle id="product-rule-dialog">
        {PRODUCT_RULE_POP_UP_TITLE[popUpColumnData?.accessor]}
      </DialogTitle>
      <DialogContent dividers style={{ height: "350px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: "1rem" }}
        >
          <Grid>
            <label className={classes.labelPopUp}>
              {props.articleHeading} Number:
            </label>{" "}
            <label>{popUpROWData?.article || "-"}</label>
          </Grid>
          {props.articleHeading !== "SKU" && (
            <Grid>
              <label className={classes.labelPopUp}>Style Color:</label>{" "}
              <label>
                {popUpROWData?.human_readable_color ||
                  popUpROWData?.style ||
                  "-"}
              </label>
            </Grid>
          )}
          <Grid>
            <label className={classes.labelPopUp}>
              {props.articleHeading} Description:
            </label>{" "}
            <label>{popUpROWData?.style_description || "-"}</label>
          </Grid>
        </Grid>
        {/* Grid for row grouped store grouping table */}
        {popUpType === POP_UP_TYPE.store && (
          <>
            <Loader loader={props.productRulePopUpLoader} minHeight={"350px"}>
              {columnDefs?.length > 0 && storeGroupRowData?.length > 0 && (
                <>
                  <AgGridComponent
                    selectAllHeaderComponent={true}
                    columns={columnDefs}
                    rowdata={storeGroupRowData}
                    rowSelection="multiple"
                    loadTableInstance={loadTableInstance} // to make use of available grid api's
                    hideChildSelection={true}
                    sizeColumnsToFitFlag={true}
                    autoGroupColumnDef={autoGroupColumnDef}
                    selectedRows={storeGroupDefaultSelectedRowData || []}
                  />
                </>
              )}
            </Loader>
          </>
        )}

        {/* Grid for DC and product profiling table */}
        {(popUpType === POP_UP_TYPE.dc ||
          popUpType === POP_UP_TYPE.product_profile) && (
          <Loader loader={props.productRulePopUpLoader} minHeight={"350px"}>
            {dcAndProductColumn?.length > 0 && productDCMappingRowData && (
              <>
                <AgGridComponent
                  selectAllHeaderComponent={true}
                  columns={dcAndProductColumn}
                  rowdata={productDCMappingRowData}
                  uniqueRowId={gridUniqueKey()}
                  rowSelection={gridSelectionType()}
                  loadTableInstance={loadTableInstance} // to make use of available grid api's
                  selectEachRow={false}
                  sizeColumnsToFitFlag={true}
                  selectedRows={productDCMappingDefault || []}
                />
              </>
            )}
          </Loader>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeModal}
          id="productRulePopCancelBtn"
          color="primary"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={saveHandlerPopUp}
          id="productRulePopCancelBtn"
          color="primary"
          variant="contained"
          disabled={props.productRulePopUpLoader}
        >
          Update and Save
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

const mapStateToProps = (store) => {
  return {
    productRulePopUpLoader:
      store.inventorysmartReducer.productRuleService.productRulePopUpLoader,
    articleHeading:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig.dynamicLabels.article,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setProductRulePopUpLoader: (payload) =>
    dispatch(setProductRulePopUpLoader(payload)),
  getProductRulePOPUPTableData: (body) =>
    dispatch(getProductRulePOPUPTableData(body)),
  setSelectedStoreGroupDataPopUp: (body) =>
    dispatch(setSelectedStoreGroupDataPopUp(body)),
  saveProductRulePOPUPTableData: (body) =>
    dispatch(saveProductRulePOPUPTableData(body)),
  setSavePayloadForPopUp: (body) => dispatch(setSavePayloadForPopUp(body)),

  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductRulePopUp);
