import React, { useState, useEffect } from "react";
import {
  formatMomentDate,
  formatStringDate,
} from "modules/inventorysmart/pages-inventorysmart/Create-Allocation/helperFunctions";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import colours from "Styles/colours";
import "./style.scss";
import { isEmpty } from "lodash";

const NormalCalendarFiscalMapping = (props) => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const isBeforeDay = (initialDate, newDate) => {
    if (!moment.isMoment(initialDate) || !moment.isMoment(newDate))
      return false;

    const initialDateYear = initialDate.year();
    const initialDateMonth = initialDate.month();

    const newDateYear = newDate.year();
    const newDateMonth = newDate.month();

    const isSameYear = initialDateYear === newDateYear;
    const isSameMonth = initialDateMonth === newDateMonth;

    if (isSameYear && isSameMonth) return initialDate.date() < newDate.date();
    if (isSameYear) return initialDateMonth < newDateMonth;
    return initialDateYear < newDateYear;
  };

  const isInclusivelyAfterDay = (initialDate, newDate) => {
    if (!moment.isMoment(initialDate) || !moment.isMoment(newDate))
      return false;
    return !isBeforeDay(initialDate, newDate);
  };

  /** To update initial value on component mount we check if these conditions satisfy to update the date values */
  const canUpdateDate = () => {
    return (
      props.selectedDate?.fiscalInfoStartDate &&
      props.selectedDate?.fiscalInfoEndDate &&
      !startDate &&
      !endDate
    );
  };

  const setDateChange = (startDate, endDate) => {
    let fiscalInfoStartDate = null;
    let fiscalInfoEndDate = null;

    if (!startDate || !endDate) {
      return props.onDateChange({
        fiscalInfoStartDate,
        fiscalInfoEndDate,
      });
    }

    let formattedEndDate = formatMomentDate(endDate);

    let endDateStartWeek = moment(formattedEndDate)?.startOf("week");
    props?.fiscalCalendarData?.forEach((fiscalDate) => {
      if (
        formatStringDate(fiscalDate?.calendar_week_start_date, true) ===
        formatMomentDate(startDate)
      ) {
        fiscalInfoStartDate = fiscalDate;
      }
      if (
        formatStringDate(fiscalDate?.calendar_week_start_date, true) ===
        formatMomentDate(endDateStartWeek)
      ) {
        fiscalInfoEndDate = fiscalDate;
      }
    });

    props.onDateChange({
      fiscalInfoStartDate,
      fiscalInfoEndDate,
    });
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);

    if (!props.setValueOnBlur) {
      setDateChange(startDate, endDate);
    }
  };

  const handleFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput === "endDate" ? "startDate" : focusedInput);
  };

  useEffect(() => {
    if (!isEmpty(props.selectedDate) && canUpdateDate()) {
      setStartDate(
        moment(
          props.selectedDate?.fiscalInfoStartDate?.calendar_week_start_date
        )
      );
      setEndDate(
        moment(
          props.selectedDate?.fiscalInfoEndDate?.calendar_week_start_date
        ).endOf("week")
      );
    }
  }, [props.selectedDate]);

  useEffect(() => {
    if (endDate && !focusedInput && props.setValueOnBlur) {
      setDateChange(startDate, endDate);
    }
  }, [focusedInput]);

  const renderDayContents = (momentInstance, modifiers) => {
    let currentWeek = null;
    if (modifiers.has("first-day-of-week")) {
      currentWeek = props?.fiscalCalendarData?.find((fiscalDate) => {
        return (
          formatStringDate(fiscalDate?.calendar_week_start_date, true) ===
          formatMomentDate(momentInstance)
        );
      });
    }

    return (
      <>
        <span className="CalendarDayNumber">{momentInstance.format("D")}</span>
        {/* Add week numbers, adds one button each row */}
        {currentWeek && modifiers.has("first-day-of-week") && (
          <span className="CalendarDayWeekNumber">
            {String(currentWeek?.fiscal_year_week)?.slice(-2) || null}
          </span>
        )}
      </>
    );
  };

  return (
    <div className={props.displayRow && "date-range-wrapper-row"}>
      <p
        className={
          props.displayRow ? "date-range-label-row" : "date-range-label"
        }
      >
        Date Range{" "}
        {props.isMandatory && <span style={{ color: colours.jaffa }}>*</span>}
      </p>

      <DateRangePicker
        readOnly
        isOutsideRange={(day) => {
          return null;
          // TO DO - handle disable past weeks
          // props.disablePastWeeks ? !isInclusivelyAfterDay(day, moment()) : null
        }}
        startDate={startDate}
        endDate={endDate}
        keepOpenOnDateSelect
        endDateId="endDate"
        focusedInput={focusedInput}
        onDatesChange={handleDateChange}
        onFocusChange={handleFocusChange}
        startDateId="startDate"
        showClearDates
        startDateOffset={(day) => {
          let days = day.diff(startDate, "days");
          if (startDate && days > 0 && !props.maxOneWeekSelection) {
            return startDate.startOf("week");
          }
          return day.startOf("week");
        }}
        endDateOffset={(day) => {
          return day.endOf("week");
        }}
        renderDayContents={(...renderDayContentsProps) =>
          renderDayContents(...renderDayContentsProps)
        }
      />
    </div>
  );
};

export default NormalCalendarFiscalMapping;
