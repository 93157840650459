export const orderByFn = (arr, funcs, dirs) => {
  return [...arr].sort((rowA, rowB) => {
    for (let i = 0; i < funcs.length; i += 1) {
      if (rowA.depth > 0 && rowB.depth > 0) {
        return rowA.index - rowB.index;
      }
      const sortFn = funcs[i];
      const desc = dirs[i] === false || dirs[i] === "desc";
      const sortInt = sortFn(rowA, rowB);
      if (sortInt !== 0) {
        return desc ? -sortInt : sortInt;
      }
    }
    return dirs[0] ? rowA.index - rowB.index : rowB.index - rowA.index;
  });
};

export const getBody = (filters, sortArr) => {
  let body = {
    search: [],
    range: [],
    sort: [],
  };
  for (const filter of filters) {
    if (filter.value.length !== 0) {
      for (const filterType of filter.value) {
        const type = filterType.type;
        const value = filterType.value;
        if (type === "range") {
          let rangeBody = {
            column: filter.id,
          };
          const minNaNCheck = isNaN(value[0]);
          const maxNaNCheck = isNaN(value[1]);
          if (!minNaNCheck) {
            rangeBody = {
              ...rangeBody,
              min_val: value[0],
            };
          }
          if (!maxNaNCheck) {
            rangeBody = {
              ...rangeBody,
              min_val: value[0],
            };
          }
          body.range.push(rangeBody);
        }
        if (type === "search") {
          let searchBody = {
            column: filter.id,
            pattern: value,
          };
          body.search.push(searchBody);
        }
      }
    }
  }
  if (sortArr.length > 0) {
    for (const col of sortArr) {
      const sortBody = {
        column: col.id,
        order: col.desc ? "desc" : "asc",
      };
      body.sort.push(sortBody);
    }
  }
  return body;
};
export const textFn = (rows, ids, filterValue) => {
  const Index = filterValue.findIndex((filter) => {
    return filter.type === "search";
  });
  let searchValue = "";
  if (Index !== -1) {
    searchValue = filterValue[Index].value;
  }
  rows = rows.filter((row) => {
    return ids.some((id) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .includes(String(searchValue).toLowerCase())
        : true;
    });
  });
  return rows;
};
