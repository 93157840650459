import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CalendarToday, FactCheck } from "@mui/icons-material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import RuleIcon from "@mui/icons-material/Rule";
import LineStyleIcon from "@mui/icons-material/LineStyle";
import Layout from "commonComponents/layout";
import {
  DASHBOARD,
  PLANOGRAM,
  FLOORPLANS,
  RULES,
  FIXTUREMANAGEMENT,
} from "../constants-spacesmart/routesContants";
import Dashboard from "../pages-spacesmart/react-demo/dashboard";
import Planogram from "../pages-spacesmart/react-demo/planogram";
import FloorPlan from "../pages-spacesmart/react-demo/floorplans";
import Rules from "../pages-spacesmart/react-demo/rules";
import FixtureManagement from "../pages-spacesmart/react-demo/fixturemanagement";
import "commonComponents/layout/layout.css";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(CalendarToday),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + FLOORPLANS,
    title: "Floor Plans",
    icon: React.createElement(DisplaySettingsIcon),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PLANOGRAM,
    title: "Planogram",
    icon: React.createElement(FactCheck),
    order: 3,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + RULES,
    title: "Rules",
    icon: React.createElement(RuleIcon),
    order: 4,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + FIXTUREMANAGEMENT,
    title: "Fixture Management",
    icon: React.createElement(LineStyleIcon),
    order: 5,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
      component: Dashboard,
      title: "Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + FLOORPLANS,
      component: FloorPlan,
      title: "Floor Plan",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PLANOGRAM,
      component: Planogram,
      title: "Planogram",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + RULES,
      component: Rules,
      title: "Rules",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + FIXTUREMANAGEMENT,
      component: FixtureManagement,
      title: "Fixture Management",
    },
  ];
  return (
    <Layout
      routes={routes}
      sideBarOptions={sideBarOptions}
      app={"spacesmart"}
    />
  );
};

const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
