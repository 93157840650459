import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import { Inventory2Outlined } from "@mui/icons-material";
import {
  ADA_DASHBOARD,
  ADA_REPORTS,
  ADA_CONFIGURATION,
} from "../constants-ada/routesContants";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import Dashboard from "../pages-ada/react-demo/dashboard";
import Reports from "../pages-ada/react-demo/reports";
import Configuration from "../pages-ada/react-demo/configuration";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(Inventory2Outlined),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_REPORTS,
    title: "Reports",
    icon: React.createElement(AutoAwesomeMosaicIcon),
    order: 2,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ADA_CONFIGURATION,
    title: "Configuration",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 3,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_DASHBOARD,
      component: Dashboard,
      title: "Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_REPORTS,
      component: Reports,
      title: "Reports",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ADA_CONFIGURATION,
      component: Configuration,
      title: "Configuration",
    },
  ];
  return <Layout routes={routes} sideBarOptions={sideBarOptions} app="ada" />;
};

export default Routes;
