import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  GET_APS_ST,
  UPDATE_L3_APS_ST,
  UPDATE_CLUSTER_APS_ST,
  GET_DEPTH_CHOICE,
  UPDATE_DEPTH_CHOICE,
  OPTIMIZE_APS_ST,
  OPTIMIZE_DEPTH_CHOICE,
} from "../../../constants/apiConstants";

export const depthChoiceService = createSlice({
  name: "depthChoiceService",
  initialState: {
    apsStData: [],
    loader_2_2: false,
    depthChoiceData: [],
    depthChoiceGraphData: {},
  },
  reducers: {
    setApsStData: (state, action) => {
      state.apsStData = action.payload;
    },
    set2_2_Loader: (state, action) => {
      state.loader_2_2 = action.payload;
    },
    setDepthChoiceData: (state, action) => {
      state.depthChoiceData = action.payload;
    },
    setDepthChoiceGraphData: (state, action) => {
      state.depthChoiceGraphData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setApsStData,
  set2_2_Loader,
  setDepthChoiceData,
  setDepthChoiceGraphData,
} = depthChoiceService.actions;
export const optimizeApsSt = (postBody) => (dispatch) => {
  return axiosInstance({
    url: OPTIMIZE_APS_ST,
    method: "POST",
    data: postBody,
  });
};
export const getApsStData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: GET_APS_ST,
    method: "POST",
    data: postBody,
  });
};
export const updateL3ApsStData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: UPDATE_L3_APS_ST,
    method: "PUT",
    data: postBody,
  });
};
export const updateClusterApsStData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: UPDATE_CLUSTER_APS_ST,
    method: "PUT",
    data: postBody,
  });
};
export const optimizeDepthChoice = (postBody) => (dispatch) => {
  return axiosInstance({
    url: OPTIMIZE_DEPTH_CHOICE,
    method: "POST",
    data: postBody,
  });
};
export const getDepthChoiceData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: GET_DEPTH_CHOICE,
    method: "POST",
    data: postBody,
  });
};
export const updateDepthChoiceData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: UPDATE_DEPTH_CHOICE,
    method: "PUT",
    data: postBody,
  });
};

export default depthChoiceService.reducer;
