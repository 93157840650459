import globalStyles from "../../../../../Styles/globalStyles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../../inventorysmart-utility";
import { ERROR_MESSAGE } from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import ProductRuleTableWrapper from "./ProductRuleTableWrapper";
import {
  resetProductRuleStoreState,
  setApplicationObjectList,
  setInventorysmartRulesFilterDependency,
  setProductRuleFilterLoader,
  setProductRuleTableLoader,
  setSelectedFilters,
  setShowGridContainer,
} from "modules/inventorysmart/services-inventorysmart/Product-Profile/product-rule-services";
import { addSnack } from "actions/snackbarActions";
import { getApplicationMaster } from "actions/tenantConfigActions";
import CustomAccordion from "commonComponents/Custom-Accordian";
import Filters from "commonComponents/filters/filterGroup";
import LoadingOverlay from "Utils/Loader/loader";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import { Button, Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import { cloneDeep } from "lodash";

const ProductRuleDashboard = (props) => {
  const globalClasses = globalStyles();

  const type = new URLSearchParams(window.location.search).get("type");

  const isRedirectedFromDifferentPage =
    type && props?.inventorysmartRulesFilterDependency?.length > 0;

  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterDependency, setFilterDependency] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const updateFilters = async (selected, current) => {
    await getFiltersOptions(selected, current);
    setFilterDependency(selected);
  };

  const applyFilters = (filterElements, dependency) => {
    setProductRuleTableLoader(true);
    const payload = filtersPayload(
      filterElements || filterData,
      dependency || filterDependency,
      true
    );

    props.setShowGridContainer(payload.isValid);
    props.setSelectedFilters(payload.reqBody);
    setProductRuleTableLoader(false);
    setOpenModal(false);
  };

  const resetSelectedFilterValues = async () => {
    setFilterDependency([]);
    applyFilters(filterData, []);
    setOpenModal(false);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const getFiltersOptions = async (selected, current) => {
    try {
      props.setProductRuleFilterLoader(true);

      const selectedFilters = isRedirectedFromDifferentPage
        ? cloneDeep(props.inventorysmartRulesFilterDependency)
        : selected;

      const response = await fetchFilterOptions(
        filters || [],
        selectedFilters,
        current,
        null,
        props.inventorysmartScreenConfig.roleBasedAccess,
        props.screenName
      );

      if (isRedirectedFromDifferentPage) {
        applyFilters(response, selectedFilters);
      }

      setFilterData(response);
    } catch (error) {
      displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      props.setProductRuleFilterLoader(false);
    }
  };

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        props.setProductRuleFilterLoader(true);
        const response = await fetchFilterConfig(
          "inventorysmart_product_rules"
        );
        setFilters(response);
      } catch (error) {
        props.setProductRuleFilterLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    fetchFilters();
    props.setActiveScreenName("inventorysmart_product_rules");
  }, []);
  //fetch application on load
  useEffect(() => {
    if (props.applicationObjectList !== null) return;

    // fetch only if not master list is available in store
    const fetchApplicationMasterList = async () => {
      let getApplicationMasterList = await props.getApplicationMaster();
      let applicationObjectList = getApplicationMasterList.data.data.filter(
        (masterList) => {
          if (
            masterList.name?.toLowerCase() === "InventorySmart".toLowerCase()
          ) {
            return masterList;
          }
        }
      );
      props.setApplicationObjectList(applicationObjectList);
    };
    fetchApplicationMasterList();
  }, []);

  useEffect(() => {
    return () => {
      props.setShowGridContainer(false);
      props.resetProductRuleStoreState();
    };
  }, []);

  return (
    <>
      <div className={globalClasses.marginVertical1rem}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {(filterDependency.length > 0 ||
          props.inventorysmartRulesFilterDependency.length > 0) && (
          <FilterChips
            filterConfig={
              props.inventorysmartRulesFilterDependency.length > 0
                ? props.inventorysmartRulesFilterDependency
                : filterDependency
            }
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay
            loader={props.inventoryProductFilterLoader}
            minHeight={200}
          >
            <Filters
              filters={filterData}
              update={updateFilters}
              onFilter={applyFilters}
              onReset={resetSelectedFilterValues}
              filterElevation={3}
              showBorderedWrapper={false}
              screen="inventory-smart-product-rule-dashboard"
              disabledDropdown={isRedirectedFromDifferentPage}
              inititalSelection={props.inventorysmartRulesFilterDependency}
              doNotUpdateDefaultValue
            />
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>

      {props.showGridContainer && (
        <div className={globalClasses.marginVertical1rem}>
          <CustomAccordion label="Details table">
            <ProductRuleTableWrapper
              isRedirectedFromDifferentPage={isRedirectedFromDifferentPage}
              module={props.module}
            />
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    inventoryProductFilterLoader:
      store.inventorysmartReducer.productRuleService
        .inventoryProductFilterLoader,
    showGridContainer:
      store.inventorysmartReducer.productRuleService.showGridContainer,
    applicationObjectList:
      store.inventorysmartReducer.productRuleService.applicationObjectList,
    selectedRulesArticles:
      store.inventorysmartReducer.productRuleService.selectedRulesArticles,
    inventorysmartRulesFilterDependency:
      store.inventorysmartReducer.productRuleService
        .inventorysmartRulesFilterDependency,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (snack) => dispatch(addSnack(snack)),
  setProductRuleFilterLoader: (payload) =>
    dispatch(setProductRuleFilterLoader(payload)),
  setProductRuleTableLoader: (payload) =>
    dispatch(setProductRuleTableLoader(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setShowGridContainer: (payload) => dispatch(setShowGridContainer(payload)),
  getApplicationMaster: (body) => dispatch(getApplicationMaster(body)),
  setApplicationObjectList: (body) => dispatch(setApplicationObjectList(body)),
  setActiveScreenName: (data) => dispatch(setActiveScreenName(data)),
  setInventorysmartRulesFilterDependency: (payload) =>
    dispatch(setInventorysmartRulesFilterDependency(payload)),
  resetProductRuleStoreState: (payload) =>
    dispatch(resetProductRuleStoreState(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductRuleDashboard);
