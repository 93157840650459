import { Home } from "@mui/icons-material";
import SelectContainer from "commonComponents/filters/SelectContainer";
import CalendarDropDown from "../pages-ada/Dashboard/exclusion/calendar-dropdown";
import ProductTable from "../pages-ada/Dashboard/exclusion/product-table";
import {
  fetchChannels,
  fetchGroupData,
  getFiscalCalendar,
  getFiscalMonth,
  getFiscalWeeks,
} from "../services-ada/ada-dashboard/ada-dashboard-services";
import { ADA_DASHBOARD } from "./routesContants";

export const HOME_BREAD_CRUMB = {
  label: "AdaVisual",
  route: ADA_DASHBOARD,
  icon: <Home />,
};

export const BREAD_CRUMB_TITLES = {
  0: [HOME_BREAD_CRUMB],
};

export const COMPARE_WITH_CONSTANTS = [
  { value: "Historic Actuals", label: "Historic Actuals", disabled: false },
  { value: "Plan", label: "Plan", disabled: true },
  { value: "Similar styles", label: "Similar styles", disabled: true },
];

export const TIMELINE_CONSTANTS = [
  { value: "W", label: "Week" },
  { value: "M", label: "Month" },
  { value: "Q", label: "Quarter" },
];

export const FILTER_DIMENSIONS = [
  {
    dimension: null,
    showExclusion: false,
    heading: "Channel",
    staticFilters: [
      {
        label: "Channel",
        api: () => fetchChannels(),
        type: "dropdown",
        reducerKey: "channel",
        isClearable: true,
      },
      {
        label: null,
        type: "dateRange",
        reducerKey: "fiscalDates",
        isMandatory: true,
      },
      {
        label: "Aggregation level",
        api: null,
        type: "switchGroup",
        reducerKey: "switchTimeLine",
        initialOptions: TIMELINE_CONSTANTS,
        defaultValue: TIMELINE_CONSTANTS[0],
      },
    ],
    staticExclusion: [
      {
        label: "Week Calendar",
        api: (apiPayload) => getFiscalWeeks(apiPayload),
        type: "dropdown",
        component: CalendarDropDown,
        levelLabel: "",
        reducerKey: "exclusionFiscalWeek",
      },
      {
        label: "Month Calendar",
        api: (apiPayload) => getFiscalMonth(apiPayload),
        type: "dropdown",
        levelLabel: null,
        component: CalendarDropDown,
        reducerKey: "exclusionFiscalMonth",
      },
      // {
      //   label: null,
      //   api: () => getFiscalCalendar(),
      //   type: "dateRange",
      //   reducerKey: "fiscalDates",
      // },
    ],
  },
  {
    dimension: "product",
    heading: "Products",
    showExclusion: false,

    staticFilters: [
      // {
      //   label: "Product Group",
      //   api: () => fetchGroupData("/product"),
      //   type: "dropdown",
      //   reducerKey: "productGroup",
      // },
    ],
    staticExclusion: [
      {
        label: "Product Group",
        levelLabel: "Product Group",
        type: "dropdown",
        component: SelectContainer,

        reducerKey: "exclusionProductGroup",
        filterReducerKey: "productGroup",
      },
      // {
      //   label: "",
      //   levelLabel: "Product",
      //   type: "table",
      //   reducerKey: "fiscalDates",
      //   filterReducerKey: "productGroup",
      // },

      {
        label: "Product",
        api: (apiPayload) => getFiscalWeeks(apiPayload),
        type: "table",
        levelLabel: "Product",
        component: ProductTable,
        reducerKey: "exclusionProduct",
      },
    ],
  },
  {
    dimension: "store",
    heading: "Stores",
    showExclusion: false,
    staticFilters: [
      // {
      //   label: "Store Group",
      //   api: () => fetchGroupData("/store"),
      //   type: "dropdown",
      //   reducerKey: "storeGroup",
      // },
    ],
    staticExclusion: [
      {
        label: "Store Group",
        levelLabel: "Store Group",
        type: "dropdown",
        component: SelectContainer,

        reducerKey: "exclusionProductGroup",
        filterReducerKey: "productGroup",
      },
      // {
      //   label: "",
      //   levelLabel: "Product",
      //   type: "table",
      //   reducerKey: "fiscalDates",
      //   filterReducerKey: "productGroup",
      // },
    ],
  },
  {
    dimension: null,
    heading: "Compare with",
    showExclusion: false,
    isMandatory: true,
    staticFilters: [
      {
        label: null,
        api: null,
        type: "compareWith",
        reducerKey: "compareWith",
      },
    ],
  },
];

export const calendarData = {
  signet: {
    "2022": {
      "1": {
        fy: "2022",
        fw: 48,
      },
      "2": {
        fy: "2022",
        fw: 49,
      },
      "3": {
        fy: "2022",
        fw: 50,
      },
      "4": {
        fy: "2022",
        fw: 51,
      },
      "5": {
        fy: "2023",
        fw: 52,
      },
      "6": {
        fy: "2023",
        fw: 1,
      },
      "7": {
        fy: "2023",
        fw: 2,
      },
      "8": {
        fy: "2023",
        fw: 3,
      },
      "9": {
        fy: "2023",
        fw: 4,
      },
      "10": {
        fy: "2023",
        fw: 5,
      },
      "11": {
        fy: "2023",
        fw: 6,
      },
      "12": {
        fy: "2023",
        fw: 7,
      },
      "13": {
        fy: "2023",
        fw: 8,
      },
      "14": {
        fy: "2023",
        fw: 9,
      },
      "15": {
        fy: "2023",
        fw: 10,
      },
      "16": {
        fy: "2023",
        fw: 11,
      },
      "17": {
        fy: "2023",
        fw: 12,
      },
      "18": {
        fy: "2023",
        fw: 13,
      },
      "19": {
        fy: "2023",
        fw: 14,
      },
      "20": {
        fy: "2023",
        fw: 15,
      },
      "21": {
        fy: "2023",
        fw: 16,
      },
      "22": {
        fy: "2023",
        fw: 17,
      },
      "23": {
        fy: "2023",
        fw: 18,
      },
      "24": {
        fy: "2023",
        fw: 19,
      },
      "25": {
        fy: "2023",
        fw: 20,
      },
      "26": {
        fy: "2023",
        fw: 21,
      },
      "27": {
        fy: "2023",
        fw: 22,
      },
      "28": {
        fy: "2023",
        fw: 23,
      },
      "29": {
        fy: "2023",
        fw: 24,
      },
      "30": {
        fy: "2023",
        fw: 25,
      },
      "31": {
        fy: "2023",
        fw: 26,
      },
      "32": {
        fy: "2023",
        fw: 27,
      },
      "33": {
        fy: "2023",
        fw: 28,
      },
      "34": {
        fy: "2023",
        fw: 29,
      },
      "35": {
        fy: "2023",
        fw: 30,
      },
      "36": {
        fy: "2023",
        fw: 31,
      },
      "37": {
        fy: "2023",
        fw: 32,
      },
      "38": {
        fy: "2023",
        fw: 33,
      },
      "39": {
        fy: "2023",
        fw: 34,
      },
      "40": {
        fy: "2023",
        fw: 35,
      },
      "41": {
        fy: "2023",
        fw: 36,
      },
      "42": {
        fy: "2023",
        fw: 37,
      },
      "43": {
        fy: "2023",
        fw: 38,
      },
      "44": {
        fy: "2023",
        fw: 39,
      },
      "45": {
        fy: "2023",
        fw: 40,
      },
      "46": {
        fy: "2023",
        fw: 41,
      },
      "47": {
        fy: "2023",
        fw: 42,
      },
      "48": {
        fy: "2023",
        fw: 43,
      },
      "49": {
        fy: "2023",
        fw: 44,
      },
      "50": {
        fy: "2023",
        fw: 45,
      },
      "51": {
        fy: "2023",
        fw: 46,
      },
      "52": {
        fy: "2023",
        fw: 47,
      },
    },
    "2023": {
      "1": {
        fy: "2023",
        fw: 48,
      },
      "2": {
        fy: "2023",
        fw: 49,
      },
      "3": {
        fy: "2023",
        fw: 50,
      },
      "4": {
        fy: "2023",
        fw: 51,
      },
      "5": {
        fy: "2024",
        fw: 52,
      },
      "6": {
        fy: "2024",
        fw: 1,
      },
      "7": {
        fy: "2024",
        fw: 2,
      },
      "8": {
        fy: "2024",
        fw: 3,
      },
      "9": {
        fy: "2024",
        fw: 4,
      },
      "10": {
        fy: "2024",
        fw: 5,
      },
      "11": {
        fy: "2024",
        fw: 6,
      },
      "12": {
        fy: "2024",
        fw: 7,
      },
      "13": {
        fy: "2024",
        fw: 8,
      },
      "14": {
        fy: "2024",
        fw: 9,
      },
      "15": {
        fy: "2024",
        fw: 10,
      },
      "16": {
        fy: "2024",
        fw: 11,
      },
      "17": {
        fy: "2024",
        fw: 12,
      },
      "18": {
        fy: "2024",
        fw: 13,
      },
      "19": {
        fy: "2024",
        fw: 14,
      },
      "20": {
        fy: "2024",
        fw: 15,
      },
      "21": {
        fy: "2024",
        fw: 16,
      },
      "22": {
        fy: "2024",
        fw: 17,
      },
      "23": {
        fy: "2024",
        fw: 18,
      },
      "24": {
        fy: "2024",
        fw: 19,
      },
      "25": {
        fy: "2024",
        fw: 20,
      },
      "26": {
        fy: "2024",
        fw: 21,
      },
      "27": {
        fy: "2024",
        fw: 22,
      },
      "28": {
        fy: "2024",
        fw: 23,
      },
      "29": {
        fy: "2024",
        fw: 24,
      },
      "30": {
        fy: "2024",
        fw: 25,
      },
      "31": {
        fy: "2024",
        fw: 26,
      },
      "32": {
        fy: "2024",
        fw: 27,
      },
      "33": {
        fy: "2024",
        fw: 28,
      },
      "34": {
        fy: "2024",
        fw: 29,
      },
      "35": {
        fy: "2024",
        fw: 30,
      },
      "36": {
        fy: "2024",
        fw: 31,
      },
      "37": {
        fy: "2024",
        fw: 32,
      },
      "38": {
        fy: "2024",
        fw: 33,
      },
      "39": {
        fy: "2024",
        fw: 34,
      },
      "40": {
        fy: "2024",
        fw: 35,
      },
      "41": {
        fy: "2024",
        fw: 36,
      },
      "42": {
        fy: "2024",
        fw: 37,
      },
      "43": {
        fy: "2024",
        fw: 38,
      },
      "44": {
        fy: "2024",
        fw: 39,
      },
      "45": {
        fy: "2024",
        fw: 40,
      },
      "46": {
        fy: "2024",
        fw: 41,
      },
      "47": {
        fy: "2024",
        fw: 42,
      },
      "48": {
        fy: "2024",
        fw: 43,
      },
      "49": {
        fy: "2024",
        fw: 44,
      },
      "50": {
        fy: "2024",
        fw: 45,
      },
      "51": {
        fy: "2024",
        fw: 46,
      },
      "52": {
        fy: "2024",
        fw: 47,
      },
    },
    "2024": {
      "1": {
        fy: "2024",
        fw: 49,
      },
      "2": {
        fy: "2024",
        fw: 50,
      },
      "3": {
        fy: "2024",
        fw: 51,
      },
      "4": {
        fy: "2024",
        fw: 52,
      },
      "53": {
        fy: "2024",
        fw: 53,
      },
    },
  },
  vb: {
    "2022": {
      "1": {
        fy: "2021",
        fw: 48,
      },
      "2": {
        fy: "2021",
        fw: 49,
      },
      "3": {
        fy: "2021",
        fw: 50,
      },
      "4": {
        fy: "2021",
        fw: 51,
      },
      "5": {
        fy: "2022",
        fw: 52,
      },
      "6": {
        fy: "2022",
        fw: 1,
      },
      "7": {
        fy: "2022",
        fw: 2,
      },
      "8": {
        fy: "2022",
        fw: 3,
      },
      "9": {
        fy: "2022",
        fw: 4,
      },
      "10": {
        fy: "2022",
        fw: 5,
      },
      "11": {
        fy: "2022",
        fw: 6,
      },
      "12": {
        fy: "2022",
        fw: 7,
      },
      "13": {
        fy: "2022",
        fw: 8,
      },
      "14": {
        fy: "2022",
        fw: 9,
      },
      "15": {
        fy: "2022",
        fw: 10,
      },
      "16": {
        fy: "2022",
        fw: 11,
      },
      "17": {
        fy: "2022",
        fw: 12,
      },
      "18": {
        fy: "2022",
        fw: 13,
      },
      "19": {
        fy: "2022",
        fw: 14,
      },
      "20": {
        fy: "2022",
        fw: 15,
      },
      "21": {
        fy: "2022",
        fw: 16,
      },
      "22": {
        fy: "2022",
        fw: 17,
      },
      "23": {
        fy: "2022",
        fw: 18,
      },
      "24": {
        fy: "2022",
        fw: 19,
      },
      "25": {
        fy: "2022",
        fw: 20,
      },
      "26": {
        fy: "2022",
        fw: 21,
      },
      "27": {
        fy: "2022",
        fw: 22,
      },
      "28": {
        fy: "2022",
        fw: 23,
      },
      "29": {
        fy: "2022",
        fw: 24,
      },
      "30": {
        fy: "2022",
        fw: 25,
      },
      "31": {
        fy: "2022",
        fw: 26,
      },
      "32": {
        fy: "2022",
        fw: 27,
      },
      "33": {
        fy: "2022",
        fw: 28,
      },
      "34": {
        fy: "2022",
        fw: 29,
      },
      "35": {
        fy: "2022",
        fw: 30,
      },
      "36": {
        fy: "2022",
        fw: 31,
      },
      "37": {
        fy: "2022",
        fw: 32,
      },
      "38": {
        fy: "2022",
        fw: 33,
      },
      "39": {
        fy: "2022",
        fw: 34,
      },
      "40": {
        fy: "2022",
        fw: 35,
      },
      "41": {
        fy: "2022",
        fw: 36,
      },
      "42": {
        fy: "2022",
        fw: 37,
      },
      "43": {
        fy: "2022",
        fw: 38,
      },
      "44": {
        fy: "2022",
        fw: 39,
      },
      "45": {
        fy: "2022",
        fw: 40,
      },
      "46": {
        fy: "2022",
        fw: 41,
      },
      "47": {
        fy: "2022",
        fw: 42,
      },
      "48": {
        fy: "2022",
        fw: 43,
      },
      "49": {
        fy: "2022",
        fw: 44,
      },
      "50": {
        fy: "2022",
        fw: 45,
      },
      "51": {
        fy: "2022",
        fw: 46,
      },
      "52": {
        fy: "2022",
        fw: 47,
      },
    },
    "2023": {
      "1": {
        fy: "2022",
        fw: 48,
      },
      "2": {
        fy: "2022",
        fw: 49,
      },
      "3": {
        fy: "2022",
        fw: 50,
      },
      "4": {
        fy: "2022",
        fw: 51,
      },
      "5": {
        fy: "2023",
        fw: 52,
      },
      "6": {
        fy: "2023",
        fw: 1,
      },
      "7": {
        fy: "2023",
        fw: 2,
      },
      "8": {
        fy: "2023",
        fw: 3,
      },
      "9": {
        fy: "2023",
        fw: 4,
      },
      "10": {
        fy: "2023",
        fw: 5,
      },
      "11": {
        fy: "2023",
        fw: 6,
      },
      "12": {
        fy: "2023",
        fw: 7,
      },
      "13": {
        fy: "2023",
        fw: 8,
      },
      "14": {
        fy: "2023",
        fw: 9,
      },
      "15": {
        fy: "2023",
        fw: 10,
      },
      "16": {
        fy: "2023",
        fw: 11,
      },
      "17": {
        fy: "2023",
        fw: 12,
      },
      "18": {
        fy: "2023",
        fw: 13,
      },
      "19": {
        fy: "2023",
        fw: 14,
      },
      "20": {
        fy: "2023",
        fw: 15,
      },
      "21": {
        fy: "2023",
        fw: 16,
      },
      "22": {
        fy: "2023",
        fw: 17,
      },
      "23": {
        fy: "2023",
        fw: 18,
      },
      "24": {
        fy: "2023",
        fw: 19,
      },
      "25": {
        fy: "2023",
        fw: 20,
      },
      "26": {
        fy: "2023",
        fw: 21,
      },
      "27": {
        fy: "2023",
        fw: 22,
      },
      "28": {
        fy: "2023",
        fw: 23,
      },
      "29": {
        fy: "2023",
        fw: 24,
      },
      "30": {
        fy: "2023",
        fw: 25,
      },
      "31": {
        fy: "2023",
        fw: 26,
      },
      "32": {
        fy: "2023",
        fw: 27,
      },
      "33": {
        fy: "2023",
        fw: 28,
      },
      "34": {
        fy: "2023",
        fw: 29,
      },
      "35": {
        fy: "2023",
        fw: 30,
      },
      "36": {
        fy: "2023",
        fw: 31,
      },
      "37": {
        fy: "2023",
        fw: 32,
      },
      "38": {
        fy: "2023",
        fw: 33,
      },
      "39": {
        fy: "2023",
        fw: 34,
      },
      "40": {
        fy: "2023",
        fw: 35,
      },
      "41": {
        fy: "2023",
        fw: 36,
      },
      "42": {
        fy: "2023",
        fw: 37,
      },
      "43": {
        fy: "2023",
        fw: 38,
      },
      "44": {
        fy: "2023",
        fw: 39,
      },
      "45": {
        fy: "2023",
        fw: 40,
      },
      "46": {
        fy: "2023",
        fw: 41,
      },
      "47": {
        fy: "2023",
        fw: 42,
      },
      "48": {
        fy: "2023",
        fw: 43,
      },
      "49": {
        fy: "2023",
        fw: 44,
      },
      "50": {
        fy: "2023",
        fw: 45,
      },
      "51": {
        fy: "2023",
        fw: 46,
      },
      "52": {
        fy: "2023",
        fw: 47,
      },
    },
  },
};

export const NUMBER_OF_EXTRA_YEARS_IN_DATE_PICKER = 5;
