//api constants

// Dashboard API URLs
export const ADA_DASHBOARD_TABLE_DATA = "/";
export const ADA_DASHBOARD_CHART_DATA = "/ada-visual/graph/forecast";

export const ADA_DASHBOARD_FILTER_CONFIG = "ada-visual";
export const ADA_DASHBOARD_FISCAL_WEEKS = "/ada-visual/timeline/date-range";
export const ADA_DASHBOARD_COMPARE_FISCAL_WEEKS =
  "/ada-visual/timeline/comparison-data";
export const ADA_DASHBOARD_GET_FISCAL_CALENDAR =
  "/ada-visual/timeline/cal-to-fiscal";
export const ADA_DASHBOARD_GET_FISCAL_WEEKS_MONTH =
  "/ada-visual/timeline/fsweeks-fsmonths";

export const ADA_DASHBOARD_GET_DRIVER_FORECAST_DATA =
  "/ada-visual/table/drivers-forecast";

export const ADA_DASHBOARD_COLUMNS = "/ada-visual/table/table-columns";
export const ADA_DASHBOARD_GET_FORECAST_MULTIPLIER_DATA =
  "/ada-visual/table/forecast-multiplier";

export const ADA_DASHBOARD_AGGREGATION_LEVEL_DATA =
  "/ada-visual/table/aggregation-level-forecast";

export const ADA_SAVE_DETAILED_FORECAST =
  "ada-visual/table/update-detail-level-forecast";
export const ADA_SAVE_DRIVER_FORECAST =
  "ada-visual/table/update-drivers-forecast";
export const ADA_SAVE_MULTIPLIER =
  "ada-visual/table/update-forecast-multiplier";
export const ADA_HISTORIC_YEARS = "ada-visual/timeline/last-years-dropdown";
