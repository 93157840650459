import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Form from "Utils/form";
import Table from "Utils/reactTable";
import Loader from "Utils/Loader/loader";
import { common } from "modules/react-demo/constants/stringContants";
import { emailIdValidation } from "./emailValidator";
import { USER_FORM_FIELDS } from "./constants";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import globalStyles from "Styles/globalStyles";

const useStyles = makeStyles(() => ({
  form: {
    alignItems: "flex-end",
    display: "flex",
    width: "50%",
    marginBottom: "3rem",
  },
  tableAlignment: {
    textAlign: "center",
    width: "60%",
    margin: "auto",
  },
}));

const AddUser = (props) => {
  // using common and individual styles of the component
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [addUserTableColumns, setAddUserTableColumns] = useState([]);
  const [userFormData, setUserFormData] = useState({});
  const [addUserTableData, setAddUserTableData] = useState([]);
  const [userFormFields, setUserFormFields] = useState([]);
  const [loadAddUserColumns, setLoadAddUserColumns] = useState(false);
  const [closeAddUserModalConfirm, setCloseAddUserModalConfirm] = useState(
    false
  );

  useEffect(() => {
    setLoadAddUserColumns(true);
    setUserFormFields(USER_FORM_FIELDS);
    const fetchColumnData = async () => {
      let cols = await props.getColumns("table_name=add_new_user");
      cols = cols.map((item) => {
        item.disableSortBy = item.is_editable;
        return item;
      });
      setAddUserTableColumns(cols);
      setLoadAddUserColumns(false);
    };
    fetchColumnData();
  }, []);

  const deleteUserRecord = (index) => {
    let addUserTableDataCopy = [...addUserTableData];
    addUserTableDataCopy.splice(index, 1);
    setAddUserTableData([...addUserTableDataCopy]);
  };

  const closeAddNewUserModal = () => {
    props.handleClose();
  };

  const handleUserDetailsOnChange = (updateFormValue) => {
    setUserFormData(updateFormValue);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const saveNewUsersCreated = () => {
    if (addUserTableData.length !== 0) {
      let userTableDetails = addUserTableData.map((user) => {
        return {
          email: user.email.toLowerCase(),
          user_name: user.user_name[0].toUpperCase() + user.user_name.slice(1),
        };
      });
      props.onApply(userTableDetails);
    } else {
      displaySnackMessages("Cannot submit empty data", "error");
    }
  };

  const formItemValueAfterValidation = (item, value) => {
    return {
      ...item,
      error: value,
    };
  };

  const validateFormFields = () => {
    let formFieldsList = userFormFields.map((item) => {
      if (item.accessor === "user_name") {
        let regexp = /^[a-zA-Z ]+$/;
        if (!regexp.test(userFormData["user_name"])) {
          return formItemValueAfterValidation(item, true);
        } else {
          return formItemValueAfterValidation(item, false);
        }
      } else {
        let result = emailIdValidation(userFormData["email"]);
        if (!result) {
          return formItemValueAfterValidation(item, true);
        } else {
          return formItemValueAfterValidation(item, false);
        }
      }
    });
    setUserFormFields(formFieldsList);
    return formFieldsList.some((obj) => obj.error);
  };

  const addNewUserDetailsToTable = () => {
    let requiredFieldsError = false;
    let duplicates = false;
    let formFieldCheck = false;

    //  check for empty values
    [...userFormFields].forEach((item) => {
      // remove whitespaces or avoid white space entries
      userFormData[item.accessor] = userFormData[item.accessor]?.trim();
      if (item.required && !userFormData[item.accessor]) {
        requiredFieldsError = true;
      }
    });

    if (requiredFieldsError) {
      displaySnackMessages(
        "Please Enter the data in all the required fields",
        "error"
      );
    } else {
      formFieldCheck = validateFormFields();
    }
    // To check duplicate email id entries
    addUserTableData.forEach((row) => {
      if (row.email === userFormData.email) {
        duplicates = true;
        displaySnackMessages("Entered email id already exists", "warning");
      }
    });

    if (!requiredFieldsError && !duplicates && !formFieldCheck) {
      let tableData = [...addUserTableData];
      tableData = [...tableData, userFormData];
      setAddUserTableData(tableData);
      setUserFormData({});
    }
  };

  return (
    <>
      <ConfirmPrompt
        showModal={true}
        title="Add User to Platform"
        message=""
        ariaLabeledBy="addUser-dialog"
        primaryBtnText="Save"
        secondaryBtnText="Cancel"
        showCloseIcon={true}
        size="lg"
        setConfirm={setCloseAddUserModalConfirm}
        confirmCallback={(val) => {
          if (val) {
            saveNewUsersCreated();
          }
          else {
            addUserTableData.length > 0
              ? setCloseAddUserModalConfirm(true)
              : closeAddNewUserModal();
          }
        }}
      >
        <div className={globalClasses.marginBottom}>
          <div className={classes.form}>
            <Form
              layout={"vertical"}
              maxFieldsInRow={2}
              handleChange={handleUserDetailsOnChange}
              fields={userFormFields}
              updateDefaultValue={false}
              defaultValues={userFormData}
            ></Form>
            <div className={globalClasses.marginLeft1rem}>
              <Button
                variant="contained"
                onClick={addNewUserDetailsToTable}
                id="createProductApplyBtn"
                color="primary"
                disabled={loadAddUserColumns}
              >
                Add
              </Button>
            </div>
          </div>
          <Loader loader={loadAddUserColumns}>
            <div className={classes.tableAlignment}>
              <Table
                columns={addUserTableColumns}
                rowdata={addUserTableData}
                callDeleteApi={(tableInfo) =>
                  deleteUserRecord(tableInfo.row.index)
                }
              />
            </div>
          </Loader>
        </div>
      </ConfirmPrompt>
      <ConfirmPrompt
        showModal={closeAddUserModalConfirm}
        title="Close Add User"
        message="Your changes will be discarded if you proceed. Are you sure you want to close?"
        ariaLabeledBy="close-addUser-confirmation"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setCloseAddUserModalConfirm}
        confirmCallback={(val) => {
          if (val) {
            closeAddNewUserModal();
          }
        }}
      />
    </>
  );
};

export default AddUser;
