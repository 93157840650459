import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Typography,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";
import Form from "Utils/form";

import globalStyles from "Styles/globalStyles";
import makeStyles from "@mui/styles/makeStyles";
import { addSnack } from "actions/snackbarActions";
import {
  getAlertsActionTableConfiguration,
  setAlertsActionPopupConfigLoader,
} from "modules/inventorysmart/services-inventorysmart/StoreInventoryAlerts/alerts-actions-service";
import {
  setSelectedFilters,
  setConstraintsArticles,
  setInventorysmartConstraintsFilterDependency,
} from "modules/inventorysmart/services-inventorysmart/Constraints/constraints-services";
import {
  setCreateAllocationArticles,
  setInventorysmartCreateAllocationFilterDependency,
  setSelectedFilters as setCreateAllocationFilters,
} from "modules/inventorysmart/services-inventorysmart/Create-Allocation/create-allocation-services";
import {
  setInventorysmartRulesFilterDependency,
  setSelectedRulesArticles,
  setSelectedFilters as setSelectedRuleFilters,
} from "modules/inventorysmart/services-inventorysmart/Product-Profile/product-rule-services";

import {
  setProductMappingFilterDependency,
  setSelectedFilters as setSelectedProductMappingFilters,
  setSelectedProductMappingArticles,
} from "pages/product-mapping/services-product-mapping/productMappingService";

import {
  ALLOCATION_IN_EACHES_MESSAGE,
  KITS_ALLOCATION_ALERT_CONFIG_NAME,
  MIN_ARTICLE_SELECTION_MESSAGE,
  SHOW_HISTORICALMAPPING,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  ADA_VISUAL,
  CONFIGURATION,
  CONSTRAINTS,
  CREATE_ALLOCATION,
} from "modules/inventorysmart/constants-inventorysmart/routesConstants";
import { cloneDeep, isEmpty } from "lodash";
import moment from "moment";
import { setInventoryAdaPayload } from "modules/ada/services-ada/ada-dashboard/ada-dashboard-services";

import { useStyles as invStyles } from "../../styles-inventorysmart";
import {
  UPDATE_MODAL_STOCK,
  ERROR_MESSAGE,
} from "../../../constants-inventorysmart/stringConstants";

import {
  fetchStoreCodesForAlert,
  updateStoreInventoryModelStockValues,
} from "../../../services-inventorysmart/StoreInventoryAlerts/store-inventory-alerts-service";
import {
  fetchProductCode,
  fetchProductCodes,
} from "../../inventorysmart-utility";
import StoreArticleInventoryPopup from "../../Decision-Dashboard/components/StoreArticleInventoryPopup";

const useStyles = makeStyles((theme) => ({
  moduleTitle: {
    ...theme.typography.h3,
  },
  dialogContentBody: {
    borderTop: "none",
  },
  paperFullWidth: {
    overflowY: "visible",
    minWidth: "80%",
  },
  dialogRoot: {
    "& .MuiDialog-paperWidthSm": {
      width: "35rem !important",
      borderRadius: "0.6rem",
    },
  },
}));

const AlertsActionPopup = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const invClasses = invStyles();

  const [alertsPopupTableColumns, setAlertsPopupTableColumns] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [showSetAllModal, setShowSetAllModal] = useState(false);
  const [modelStockData, setModelStockData] = useState("");
  const [alertsPopupTableData, setAlertsPopupTableData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [article, setArticle] = useState(null);
  const [metric, setMetric] = useState(null);
  const [
    openStoreArticleInventoryDialog,
    setOpenStoreArticleInventoryDialog,
  ] = useState(false);

  // used to store the rows whose updated model stock value is edited via onChange(single row edit) or by onSelection(multiple Row edit)
  const editedRowRef = useRef([]);
  const agGridUserInstance = useRef(null);

  const toggleStoreInventoryPopup = (data, columnName) => {
    const selectedArticle = data?.article || data.sku || data.product_code;
    setArticle(selectedArticle);
    setMetric(columnName);
    openStoreArticleInventoryPopUp();
  };

  const storeCountActionMap = {
    stock_out: toggleStoreInventoryPopup,
    stockout: toggleStoreInventoryPopup,
    shortfall: toggleStoreInventoryPopup,
    normal: toggleStoreInventoryPopup,
    excess: toggleStoreInventoryPopup,
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const openStoreArticleInventoryPopUp = () => {
    setOpenStoreArticleInventoryDialog(true);
  };

  const closeStoreArticleInventoryPopUp = () => {
    setOpenStoreArticleInventoryDialog(false);
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    setSelectedArticles([...selections]);
    if (!editedRowRef.current.length) {
      editedRowRef.current = selections;
    } else {
      let updatedRows = [...editedRowRef.current, ...selections];
      editedRowRef.current = updatedRows;
    }
  };

  const checkIfAllocationInEaches = () => {
    let allocationInEaches = false;

    /**
     * Checking if all the articles from a pack are selected or not
     */
    const articlesByPackName = {};
    const selectedArticlesByPackName = {};

    alertsPopupTableData.forEach((article) => {
      if (!articlesByPackName[article.name]) {
        articlesByPackName[article.name] = [article];
      } else {
        articlesByPackName[article.name].push(article);
      }
    });

    selectedArticles.forEach((article) => {
      if (!selectedArticlesByPackName[article.name]) {
        selectedArticlesByPackName[article.name] = [article];
      } else {
        selectedArticlesByPackName[article.name].push(article);
      }
    });

    const selectedArticlePacks = Object.keys(selectedArticlesByPackName);
    for (let i = 0; i < selectedArticlePacks.length; i++) {
      const packName = selectedArticlePacks[i];
      if (
        selectedArticlesByPackName[packName].length !==
        articlesByPackName[packName].length
      ) {
        allocationInEaches = true;
        break;
      }
    }

    return allocationInEaches;
  };

  const redirectToCreateAllocation = async () => {
    let allocationInEaches = false;

    if (props.tableName === KITS_ALLOCATION_ALERT_CONFIG_NAME) {
      allocationInEaches = checkIfAllocationInEaches();
    }

    const selectedArticleIds = fetchProductCodes(selectedArticles);

    const delay = allocationInEaches ? 1000 : 0;

    if (allocationInEaches) {
      displaySnackMessages(ALLOCATION_IN_EACHES_MESSAGE, "info");
    }

    localStorage.setItem(
      "selectedFiltersDependency",
      JSON.stringify(props.inventorysmartDashboardFilterDependency)
    );
    localStorage.setItem(
      "selectedArticles",
      JSON.stringify(selectedArticleIds)
    );
    localStorage.setItem(
      "po_code",
      JSON.stringify(selectedArticles[0]?.po_code || null)
    );

    /**
     * Wait for 1 second if any toast message is to be displayed to the user and then redirect
     */
    setTimeout(() => {
      window.open(
        `${CREATE_ALLOCATION}?step=0&type=alerts`,
        "_blank",
        "noopener,noreferrer"
      );
    }, delay);
  };

  const redirectToConstraints = async () => {
    localStorage.setItem(
      "selectedFiltersDependency",
      JSON.stringify(props.inventorysmartDashboardFilterDependency)
    );
    localStorage.setItem("selectedArticles", JSON.stringify(selectedArticles));

    if (props.tableName === "constrained_inventory") {
      localStorage.setItem("storeCodes", JSON.stringify(storeData));
    }
    window.open(`${CONSTRAINTS}?type=alerts`, "_blank", "noopener,noreferrer");
  };

  const redirectToProductRules = async () => {
    const selectedArticleIds = fetchProductCodes(selectedArticles);
    // filtering as channel filter is not present on product mapping
    let dependency = props.inventorysmartDashboardFilterDependency;

    localStorage.setItem(
      "selectedFiltersDependency",
      JSON.stringify(dependency)
    );
    localStorage.setItem(
      "selectedArticles",
      JSON.stringify(selectedArticleIds)
    );
    window.open(
      `${CONFIGURATION}?type=alerts`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const redirectToADA = async () => {
    const selectedArticleIds = fetchProductCodes(selectedArticles);

    const storeCodes = [];
    selectedArticles.forEach((article) => {
      article?.store_data?.forEach((store) =>
        storeCodes.push(store.store_code)
      );
    });
    const adaPayload = {
      isRedirectedFromInventory: true,
      payload: {
        product_code: selectedArticleIds,
        store_code: storeCodes,
      },
      selectedHistoricValue: SHOW_HISTORICALMAPPING[props?.name] || 0,
      timeline: {
        startDate: moment().format("YYYY/MM/DD"),
        endDate: moment().add(8, "weeks").format("YYYY/MM/DD"), //setting the default timeline as 8 weeks from the current date (temporary implementation)
      },
    };
    localStorage.setItem("adaPayload", JSON.stringify(adaPayload));
    window.open(`${ADA_VISUAL}?type=alerts`, "_blank", "noopener,noreferrer");
  };

  const handleCreateAllocation = () => {
    if (selectedArticles?.length === 0) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else {
      redirectToCreateAllocation();
    }
  };

  const handleRedirectionToConstraints = () => {
    if (selectedArticles?.length === 0) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else {
      redirectToConstraints();
    }
  };

  const handleAutoAllocationFinalize = () => {
    if (selectedArticles?.length === 0 && props?.isSelectionEnabled) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else if (
      selectedArticles?.length !== props?.data?.data?.length &&
      props?.isSelectionEnabled
    ) {
      displaySnackMessages("Select all articles", "warning");
    } else {
      window.open(
        `${CREATE_ALLOCATION}?step=1&allocation_code=${props?.data?.extra?.plan_code}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  const handleRedirectionToADA = () => {
    if (selectedArticles?.length === 0) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else {
      redirectToADA();
    }
  };

  const handleRedirectionToProductRules = () => {
    if (selectedArticles?.length === 0) {
      displaySnackMessages(MIN_ARTICLE_SELECTION_MESSAGE, "error");
    } else {
      redirectToProductRules();
    }
  };

  useEffect(() => {
    const fetchColumnConfig = async () => {
      props.setAlertsActionPopupConfigLoader(true);

      let columns;

      if (props.tableConfigName) {
        const payload = {
          tableConfigName: props.tableConfigName,
        };

        columns = await props.getAlertsActionTableConfiguration(payload);
      } else if (props.tableConfig) {
        columns = {
          data: {
            data: cloneDeep(props.tableConfig),
          },
        };
      }

      if (props.tableName === "constrained_inventory") {
        const channelFilter = props.selectedFilters.filter(
          (filter) => filter.attribute_name === "channel"
        );

        const payload = {
          article: props?.data?.extra?.article,
          channel: channelFilter[0].values[0],
        };

        let storeDataResponse = await props.fetchStoreCodesForAlert(payload);
        setStoreData([...storeDataResponse.data.data]);
      }

      const modifiedColumns = columns?.data?.data?.map((column) => {
        if (!props.canEdit) {
          if (column?.sub_headers?.length > 0) {
            column.sub_headers = column.sub_headers.map((subHeader) => {
              if (subHeader.is_editable && subHeader.type !== "link") {
                subHeader.is_editable = false;
              }
              return subHeader;
            });
          } else {
            if (column.is_editable && column.type !== "link") {
              column.is_editable = false;
            }
          }
        }

        return column;
      });

      let formattedColumns = agGridColumnFormatter(
        modifiedColumns,
        null,
        storeCountActionMap
      );
      setAlertsPopupTableColumns(formattedColumns);
      props.setAlertsActionPopupConfigLoader(false);
    };

    if (props.active) {
      fetchColumnConfig();
    }
  }, [props.active]);

  useEffect(() => {
    return () => {
      setAlertsPopupTableColumns([]);
      setAlertsPopupTableData([]);
      setSelectedArticles([]);
    };
  }, []);

  useEffect(() => {
    if (props?.data) {
      let tableData = props?.data?.data.map((item, index) => {
        item.index = index;
        item.is_resolved = item.is_resolved || props.isResolved;
        return item;
      });

      /**
       * Sorting for packs allocation
       */
      if (props.tableName === KITS_ALLOCATION_ALERT_CONFIG_NAME) {
        tableData.sort((a, b) => a.name.localeCompare(b.name || "-"));
      }

      // clone deep the data and store once the api changes are made so that on closing the values reset to prev values
      if (
        props.tableConfigName === "auto_allocation_level_2" &&
        props?.isSelectionEnabled
      ) {
        let selectedData = tableData.map((item) => {
          return {
            ...item,
            is_selected: true,
          };
        });
        setAlertsPopupTableData(selectedData);
      } else {
        setAlertsPopupTableData(tableData);
      }
    }
  }, [props.data]);

  const handleChange = (formdata) => {
    setModelStockData(formdata);
  };

  const loadUserTableInstance = (params) => {
    agGridUserInstance.current = params;
  };

  const onApply = () => {
    if (isEmpty(modelStockData.model_stock)) {
      displaySnackMessages("Enter a value", "warning");
    } else {
      let updatedRows = alertsPopupTableData.map((item) => {
        if (
          selectedArticles.some((obj) => obj.store_code === item.store_code)
        ) {
          item.model_stock = modelStockData.model_stock;
          return item;
        } else return item;
      });
      setAlertsPopupTableData(updatedRows);
      agGridUserInstance.current?.api?.redrawRows();
      setShowSetAllModal(false);
      setModelStockData("");
    }
  };

  const saveUpdatedModelStockValue = async () => {
    // to remove repetitive records
    let uniqueArr = [
      ...new Set(editedRowRef.current.map((o) => JSON.stringify(o))),
    ].map((s) => JSON.parse(s));

    if (!uniqueArr.length) {
      displaySnackMessages("No Changes to save", "warning");
    } else {
      props.setAlertsActionPopupConfigLoader(true);
      try {
        let body = {
          data: {
            values: uniqueArr.map((row) => {
              const value = {};

              props?.updateKeys?.forEach((key) => {
                value[key] = row[key];
              });

              return value;
            }),
          },
          url: props?.updateURL,
        };
        await props.updateStoreInventoryModelStockValues(body);
        props.setAlertsActionPopupConfigLoader(false);
        displaySnackMessages("Updated successfully", "success");
        setAlertsPopupTableData([]);
        editedRowRef.current = [];
        let updatedDataSum = updateModelStockSum();
        props.updateAggModelStock(updatedDataSum);
        props.closeModal();
      } catch (e) {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setAlertsActionPopupConfigLoader(false);
      }
    }
  };

  const closeSetAllModal = () => {
    setShowSetAllModal(false);
    setModelStockData("");
    setAlertsPopupTableData([]);
    editedRowRef.current = [];
  };

  const onCellValueChanged = (params) => {
    if (!editedRowRef.current.length) {
      editedRowRef.current = [params.data];
    } else {
      let data = [...editedRowRef.current, params.data];
      editedRowRef.current = data;
    }
  };

  // this function is used to update the parent table with the sum of the updated model stock values of all rows
  const updateModelStockSum = () => {
    let aggModelStock = 0;
    alertsPopupTableData.forEach((row) => {
      aggModelStock += parseInt(row.model_stock);
    });
    return {
      sum: aggModelStock,
      index: props?.data?.extra?.index,
      skuId: fetchProductCode(alertsPopupTableData[0]),
    };
  };

  const closeReviewAlerts = () => {
    // warning to be removed later
    if (
      editedRowRef.current.length > 0 &&
      props.tableConfigName === "model_stock_level_2"
    )
      displaySnackMessages("Changes made are not saved", "warning");
    props.closeModal();
  };

  const renderSetAllPopUp = () => {
    return (
      <Dialog
        onClose={closeSetAllModal}
        className={classes.dialogRoot}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth={true}
        disableEscapeKeyDown={true}
        disableBackdropClick={false}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              Set All
            </Typography>
            <IconButton
              aria-label="close"
              onClick={closeSetAllModal}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className={classes.contentBody}>
            <Form
              maxFieldsInRow={1}
              layout={"horizontal"}
              handleChange={handleChange}
              fields={UPDATE_MODAL_STOCK}
              updateDefaultValue={false}
              defaultValues={modelStockData}
            ></Form>
          </div>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.footer,
          }}
        >
          <Button variant="contained" onClick={onApply} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return props.active ? (
    <>
      <Dialog
        id="alertsActionDialog"
        aria-labelledby="alerts-action-dialog"
        open={props.active}
        maxWidth="lg"
        fullWidth={true}
        disableEscapeKeyDown={true}
        onClose={(_event, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          props.closeModal();
        }}
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
      >
        <DialogContent
          dividers
          classes={{
            root: classnames(
              classes.dialogContentRoot,
              globalClasses.flexRow,
              globalClasses.layoutAlignBetweenCenter
            ),
          }}
        >
          <Typography classes={{ root: classes.moduleTitle }}>
            Review Recommendation
          </Typography>
          <IconButton color="primary" onClick={closeReviewAlerts} size="large">
            <CloseIcon fontSize="medium" />
          </IconButton>
        </DialogContent>
        <DialogContent
          dividers
          classes={{
            root: classes.dialogContentBody,
          }}
        >
          <Loader
            loader={props.alertsActionPopupConfigLoader}
            minHeight={"260px"}
          >
            <AgGridComponent
              columns={alertsPopupTableColumns}
              rowdata={alertsPopupTableData}
              selectAllHeaderComponent={
                ((props.redirection === "Create Allocation" &&
                  props.canCreate) ||
                  props.canEdit) &&
                ((props.redirection && props?.isSelectionEnabled) ||
                  props.tableConfigName === "model_stock_level_2")
              }
              onSelectionChanged={onSelectionChanged}
              uniqueRowId={"index"}
              downloadAsExcel={true}
              loadTableInstance={loadUserTableInstance}
              onCellValueChanged={onCellValueChanged}
              getRowStyle={(params) => {
                if (params.data.is_resolved) {
                  return { background: "rgb(57 255 20 / 20%)" };
                }
              }}
            />
            {props.tableConfigName === "model_stock_level_2" &&
              props.canUpdate && (
                <div className={invClasses.buttonGroupWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedArticles.length < 2}
                    className={invClasses.button}
                    onClick={() => setShowSetAllModal(true)}
                  >
                    Set All
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={invClasses.button}
                    onClick={saveUpdatedModelStockValue}
                  >
                    Save
                  </Button>
                </div>
              )}
            {showSetAllModal && renderSetAllPopUp()}
            {props?.data.data?.length > 0 &&
              (props.redirection === "Create Allocation" && props.canCreate ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={globalClasses.marginAround}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    id="productSetAllBtn"
                    className={classes.button}
                    onClick={() => handleCreateAllocation()}
                  >
                    Create Allocation
                  </Button>
                </Grid>
              ) : props.redirection === "Constraints" && props.canEdit ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={globalClasses.marginAround}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    id="productSetAllBtn"
                    className={classes.button}
                    onClick={() => handleRedirectionToConstraints()}
                  >
                    Go To Constraints
                  </Button>
                </Grid>
              ) : props.redirection === "Finalize" && props.canEdit ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={globalClasses.marginAround}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    id="productSetAllBtn"
                    className={classes.button}
                    onClick={() => handleAutoAllocationFinalize()}
                  >
                    Review
                  </Button>
                </Grid>
              ) : props.redirection === "ADA" && props.canEdit ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={globalClasses.marginAround}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    id="productSetAllBtn"
                    className={classes.button}
                    onClick={() => handleRedirectionToADA()}
                  >
                    Go To ADA Dashboard
                  </Button>
                </Grid>
              ) : (
                props.redirection === "Configuration" &&
                props.canEdit && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={globalClasses.marginAround}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      id="productSetAllBtn"
                      className={classes.button}
                      onClick={() => handleRedirectionToProductRules()}
                    >
                      Go To Configuration
                    </Button>
                  </Grid>
                )
              ))}
          </Loader>
        </DialogContent>
      </Dialog>
      <StoreArticleInventoryPopup
        active={openStoreArticleInventoryDialog}
        openModal={openStoreArticleInventoryPopUp}
        closeModal={closeStoreArticleInventoryPopUp}
        filters={props.selectedFilters}
        article={article}
        metric={metric}
      />
    </>
  ) : null;
};

const mapStateToProps = (store) => {
  return {
    alertsActionPopupConfigLoader:
      store.inventorysmartReducer.inventorySmartAlertsActionService
        .alertsActionPopupConfigLoader,
    selectedFilters:
      store.inventorysmartReducer.inventorySmartDashboardService
        .selectedFilters,
    inventorysmartDashboardFilterDependency:
      store.inventorysmartReducer.inventorySmartDashboardService
        .inventorysmartDashboardFilterDependency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAlertsActionTableConfiguration: (payload) =>
    dispatch(getAlertsActionTableConfiguration(payload)),
  setAlertsActionPopupConfigLoader: (payload) =>
    dispatch(setAlertsActionPopupConfigLoader(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setSelectedRuleFilters: (payload) =>
    dispatch(setSelectedRuleFilters(payload)),
  setCreateAllocationFilters: (payload) =>
    dispatch(setCreateAllocationFilters(payload)),
  setConstraintsArticles: (payload) =>
    dispatch(setConstraintsArticles(payload)),
  setCreateAllocationArticles: (payload) =>
    dispatch(setCreateAllocationArticles(payload)),
  setSelectedRulesArticles: (payload) =>
    dispatch(setSelectedRulesArticles(payload)),
  setInventorysmartConstraintsFilterDependency: (payload) =>
    dispatch(setInventorysmartConstraintsFilterDependency(payload)),
  setInventorysmartCreateAllocationFilterDependency: (payload) =>
    dispatch(setInventorysmartCreateAllocationFilterDependency(payload)),
  setInventorysmartRulesFilterDependency: (payload) =>
    dispatch(setInventorysmartRulesFilterDependency(payload)),
  setInventoryAdaPayload: (payload) =>
    dispatch(setInventoryAdaPayload(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
  setSelectedProductMappingFilters: (payload) =>
    dispatch(setSelectedProductMappingFilters(payload)),
  setProductMappingFilterDependency: (payload) =>
    dispatch(setProductMappingFilterDependency(payload)),
  setSelectedProductMappingArticles: (payload) =>
    dispatch(setSelectedProductMappingArticles(payload)),
  updateStoreInventoryModelStockValues: (payload) =>
    dispatch(updateStoreInventoryModelStockValues(payload)),
  fetchStoreCodesForAlert: (payload) =>
    dispatch(fetchStoreCodesForAlert(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsActionPopup);
