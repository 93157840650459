import PageRouteTitles from "../PageRouteTitles";
import { useHistory } from "react-router-dom";
import { useReducer, useEffect } from "react";
import {
  getDefinitions,
  setDefinitions,
  ToggleLoader,
  fetchGroupDefinitionById,
  setMappedDefns,
  fetchIndividualGroup,
  getGroupInfo,
  updateGrp,
  setDefnMapTablecols,
} from "pages/product-grouping/product-grouping-service";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  LinearProgress,
  Paper,
} from "@mui/material";
import CellRenderer from "../../../../Utils/reactTable/components/cellRenderer";
import Table from "../../../../Utils/reactTable";
import { getColumns } from "../../../../actions/tableColumnActions";
import { addSnack } from "../../../../actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const initialState = {
  columns: [],
  defns: [],
  open: false,
  grpObj: {},
  isLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_COLUMNS":
      return {
        ...state,
        columns: action.payload,
      };
    case "SET_GRP_OBJECT":
      return {
        ...state,
        grpObj: action.payload,
      };
    case "SET_DEFINITIONS":
      return {
        ...state,
        defns: [...action.payload],
      };
    case "SET_MAPPED_DEFINITIONS":
      return {
        ...state,
        mappedDefns: action.payload,
      };
    case "SET_LOADER":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "TOGGLE_MODAL_STATE":
      return {
        ...state,
        open: action.status,
      };
    default:
      return state;
  }
};
const GroupDefMapper = (props) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const routeOptions = [
    {
      id: "product_grping_scr",
      label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
      action: () => history.push("/product-grouping"),
    },
    {
      id: "create_new_product_grp",
      label: "Group Definition Mapping",
      action: () => null,
    },
  ];

  const deleteData = (data, index) => {
    props.setMappedDefns(data.filter((item, idx) => idx !== index));
  };
  useEffect(() => {
    const fetchData = async () => {
      props.ToggleLoader(true);
      const body = {
        search: [],
        sort: [],
        range: [],
        filters: [],
        include_mapped_definition: true,
      };
      const res = await props.getDefinitions(body, 100);
      const grpdId = props.match.params.group_id;
      const grpObj = await props.getGroupInfo(grpdId);
      dispatch({
        type: "SET_GRP_OBJECT",
        payload: grpObj.data.data[0],
      });
      const options = res.data.data.map((row) => {
        return {
          ...row,
          value: row.pgd_code,
        };
      });

      dispatch({
        type: "SET_DEFINITIONS",
        payload: options.map((option) => {
          return {
            ...option,
            value: option.pgd_code,
          };
        }),
      });
      try {
        if (props.tableCols.length === 0) {
          let cols = await props.getColumns("table_name=group_definition");
          cols = cols.map((col) => {
            if (col.accessor === "name") {
              col.pagination = true;
              col.options = [...options];
              col.fetchOptions = (page) => fetchNewOptions(page);
              col.Cell = (cellProps, extraProps) => {
                if (cellProps.row.original.isSaved) {
                  return cellProps.row.original.defn_name;
                } else {
                  col.type = "list";
                  col.is_editable = true;
                  return (
                    <div style={{ width: "80%" }}>
                      <CellRenderer
                        cellData={cellProps}
                        column={col}
                        extraProps={extraProps}
                      ></CellRenderer>
                    </div>
                  );
                }
              };
            }
            return col;
          });
          cols = [
            ...cols,
            {
              Header: "Action",
              Cell: (tableInfo) => {
                return (
                  <>
                    <IconButton
                      disabled={tableInfo.row.original.name === ""}
                      onClick={() =>
                        history.push({
                          pathname: `/product-grouping/group-definition-mapping/${props.match.params.group_id}/edit-definitions/${tableInfo.row.original.name}`,
                        })
                      }
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        deleteData(tableInfo.data, tableInfo.row.index)
                      }
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                );
              },
            },
          ];
          props.setDefnMapTablecols(cols);
        }
        if (props.mappedDefns.length === 0) {
          const defnbody = {
            search: [],
            sort: [],
            range: [],
          };
          const resdefn = await props.fetchIndividualGroup(
            props.match.params.group_id,
            defnbody
          );
          props.setMappedDefns(
            resdefn.data.data.map((defn) => {
              return {
                ...defn,
                name: defn.pgd_code,
                defn_name: defn.name,
                pseudo_code: defn.pseudo_code,
                isSaved: true,
              };
            })
          );
        }
        props.ToggleLoader(false);
      } catch (error) {
        //Error Handling
        props.ToggleLoader(false);
      }
    };
    fetchData();
  }, []);

  const fetchNewOptions = async (page) => {
    try {
      const res = await props.getDefinitions(page);
      let newOptions = [...state.defns, ...res.data.data];
      dispatch({
        type: "SET_DEFINITIONS",
        payload: newOptions,
      });
    } catch (error) {}
  };

  const updateMyData = async (
    rowIndex,
    columnId,
    value,
    initialValue,
    column,
    wholeData,
    row,
    id,
    actions
  ) => {
    if (value === "") {
      return;
    }
    const defnObj = state.defns.filter((defn) => {
      return defn.value === value;
    })[0];
    const updatedData = props.mappedDefns.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          [columnId]: value,
          defn_name: defnObj.name,
          pseudo_code: defnObj.pseudo_code,
        };
      }
      return row;
    });
    props.setMappedDefns(updatedData);
  };
  const addDefinition = () => {
    const updatedDefns = [
      ...props.mappedDefns,
      { name: "", isSaved: false, pseudo_code: "" },
    ];
    props.setMappedDefns(updatedDefns);
  };
  const saveDefns = () => {
    const updatedDefns = props.mappedDefns.map((defn) => {
      if (!defn.isSaved && defn.name !== "") {
        return {
          ...defn,
          isSaved: true,
        };
      }
      return defn;
    });
    props.setMappedDefns(updatedDefns);
  };

  const openModal = () => {
    dispatch({
      type: "TOGGLE_MODAL_STATE",
      status: true,
    });
  };

  const closeModal = () => {
    dispatch({
      type: "TOGGLE_MODAL_STATE",
      status: false,
    });
  };
  const onUpdate = async () => {
    const group = state.grpObj;
    const saveddefns = props.mappedDefns.filter((defn) => defn.isSaved);
    const defnIds = saveddefns.map((defn) => defn.name);
    const body = {
      name: group.name,
      group_type: "manual",
      definitions: defnIds,
      exclude_product_ids: [],
      added_product_ids: [],
      removed_product_ids: [],
      product_group_ids: [],
    };
    try {
      dispatch({
        type: "SET_LOADER",
        payload: true,
      });
      await props.updateGrp(group.pg_code, body);
      props.addSnack({
        message: "Updated successfully",
        options: {
          variant: "success",
          onClose: () => history.push("/product-grouping"),
        },
      });
      closeModal();
      props.setMappedDefns([]);
      dispatch({
        type: "SET_LOADER",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADER",
        payload: false,
      });
      props.addSnack({
        message: "Update failed",
        options: {
          variant: "error",
        },
      });
    }
  };
  const goBack = () => {
    props.setMappedDefns([]);
    history.push("/product-grouping");
  };
  const globalClasses = globalStyles();
  return (
    <>
      <LoadingOverlay loader={props.isLoading} spinner>
        <PageRouteTitles options={routeOptions} />
        <Dialog
          id="productGrpingDfnMapCnfmDialog"
          open={state.open}
          maxWidth="xs"
        >
          {state.isLoading && <LinearProgress />}
          <DialogContent>
            Confirm the changes made to mapping definitions
          </DialogContent>
          <DialogActions>
            <Button
              id="productGrpingDfnMapCnfmDialogCnclBtn"
              variant="outlined"
              color="primary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              id="productGrpingDfnMapCnfmDialogCnfmBtn"
              variant="contained"
              color="primary"
              onClick={onUpdate}
            >
              {"Confirm & Update"}
            </Button>
          </DialogActions>
        </Dialog>
        <Paper className={globalClasses.paper}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 15,
            }}
          >
            <Typography>
              Product Group Name: <span>{state.grpObj.name}</span>
            </Typography>
            <Button
              id="productGrpingDfnMapCrtDfnBtn"
              color="primary"
              variant="contained"
              onClick={() =>
                history.push({
                  pathname: `/product-grouping/group-definition-mapping/${props.match.params.group_id}/create-definition`,
                })
              }
            >
              Create New Definition
            </Button>
          </div>
          <Typography>Mapped Definitions</Typography>
          {props.tableCols.length !== 0 && (
            <Table
              tableId="productGrpingDfnMapTable"
              id="productGrpingDfnMapTable"
              columns={props.tableCols}
              updateMyData={updateMyData}
              rowdata={props.mappedDefns}
              fetchNewOptions={fetchNewOptions}
            />
          )}
          <div style={{ float: "right" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={addDefinition}
              id="productGrpingDfnMapAddDfnBtn"
            >
              + Add Definition
            </Button>
          </div>
        </Paper>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={saveDefns}
            id="productGrpingDfnMapSaveDfnBtn"
          >
            Save
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={openModal}
            id="productGrpingDfnMapUpdDfnBtn"
          >
            Update
          </Button>
          <Button
            id="productGrpingDfnMapCnclDfnBtn"
            color="primary"
            variant="contained"
            onClick={goBack}
          >
            Cancel
          </Button>
        </div>
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    definitions: state.productGroupReducer.definitions,
    isLoading: state.productGroupReducer.isLoading,
    mappedDefns: state.productGroupReducer.mappedDefns,
    tableCols: state.productGroupReducer.defnMapTableCols,
  };
};
const mapActionsToProps = {
  getColumns,
  getDefinitions,
  setDefinitions,
  ToggleLoader,
  fetchGroupDefinitionById,
  setMappedDefns,
  addSnack,
  fetchIndividualGroup,
  getGroupInfo,
  updateGrp,
  setDefnMapTablecols,
};
export default connect(mapStateToProps, mapActionsToProps)(GroupDefMapper);
