import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
import FilterGroup from "commonComponents/filters/filterGroup";
import globalStyles from "Styles/globalStyles";
import { USER_ROLE_FILTERS } from "../constants";
import { isEmpty } from "lodash";
import {
  getCrossDimensionFiltersData,
  getFiltersValues,
} from "actions/filterAction";

const FilterHeader = (props) => {
  const [filterConfig, setFilterConfig] = useState([]);
  const [selectedDependency, setSelectedDependency] = useState([]);
  const [
    selectedHierarchyDependency,
    setSelectedHierarchyDependency,
  ] = useState([]);
  const [initialDependencyValue, setInitialDependencyValue] = useState([]);
  const globalClasses = globalStyles();

  useEffect(() => {
    const getInitialData = async () => {
      props.setUserRoleMgmtLoader(true);
      try {
        let response = await props.getFilterConfiguration();
        props.setFilterConfiguration(response.data.data);
      } catch (err) {
        props.setUserRoleMgmtLoader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };
    getInitialData();
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      props.setUserRoleMgmtLoader(true);
      if (props.filtersList.length !== 0) {
        let newFilterList = [...USER_ROLE_FILTERS, ...props.filtersList];
        const filterElements = newFilterList.map(async (key) => {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: [],
          };
          try {
            const filterConfigOptions =
              key.dimension === "user"
                ? await props.getUserFilterAttributes(body)
                : await getFiltersValues(key.dimension, body)();

            const filterConfigOptionsAttribute =
              key.dimension === "user"
                ? filterConfigOptions.data.data
                : filterConfigOptions.data.data.attribute;

            return {
              ...key,
              filter_keyword: key.column_name,
              initialData: filterConfigOptionsAttribute
                .map((item) => {
                  if (item.attribute !== null) {
                    return {
                      value: item.attribute,
                      label: item.attribute,
                      id: item.attribute,
                    };
                  }
                })
                .filter((obj) => obj),
            };
          } catch (err) {
            props.setUserRoleMgmtLoader(false);
            displaySnackMessages("Something went wrong", "error");
          }
        });
        let data = await Promise.all(filterElements);
        setFilterConfig(data);
      }
    };
    getInitialData();
  }, [props.filtersList]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const updateFilterValuesOnChange = async (dependency, filter) => {
    props.setUserRoleMgmtLoader(true);
    setInitialDependencyValue(dependency);
    if (
      dependency.some(
        (item) => item.filter_id === "application_name" && !isEmpty(item.values)
      )
    ) {
      filterConfig[1].is_disabled = false;
      setFilterConfig(filterConfig);
    } else {
      let initialDependencyOfRoles = dependency?.filter(
        (item) => item.filter_id !== "role_name"
      );
      let indexOfRole = dependency?.findIndex(
        (item) => item.filter_id === "role_name"
      );
      if (indexOfRole !== -1) dependency.splice(indexOfRole, 1);
      filterConfig[1].is_disabled = true;
      setFilterConfig(filterConfig);
      setInitialDependencyValue(initialDependencyOfRoles);
    }
    let userMgmtDependency = dependency?.filter(
      (item) =>
        item.filter_id === "role_name" || item.filter_id === "application_name"
    );
    let hierarchyDependency = dependency?.filter(
      (item) =>
        item.filter_id === "l0_name" ||
        item.filter_id === "l1_name" ||
        item.filter_id === "l2_name" ||
        item.filter_id === "channel"
    );
    if (!isEmpty(userMgmtDependency)) {
      let selectionValueDependency = updateUserRoleMgmtFilterValues(
        userMgmtDependency
      );
      setUserMgmtFilter(filter, selectionValueDependency);
    } else {
      let selectionValueDependency = updateUserRoleMgmtFilterValues([]);
      setUserMgmtFilter(filter, selectionValueDependency);
    }
    if (!isEmpty(hierarchyDependency)) {
      let selectionValueDependency = updateUserRoleMgmtFilterValues(
        hierarchyDependency
      );
      setHierarchyFilter(filter, selectionValueDependency);
    } else {
      let selectionValueDependency = updateUserRoleMgmtFilterValues([]);
      setHierarchyFilter(filter, selectionValueDependency);
    }
  };

  const updateUserRoleMgmtFilterValues = (dependencies) => {
    return dependencies.length > 0
      ? dependencies.map((opt) => {
          return {
            attribute_name: opt.filter_id,
            operator: "in",
            values: Array.isArray(opt.values)
              ? opt.values.map((option) => option.value)
              : opt.values,
            filter_type: opt.filter_type,
            dimension: opt.dimension
          };
        })
      : [];
  };

  const setUserMgmtFilter = async (filter, selectionValueDependency) => {
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...filterConfig];
        const filterElements = initialFilterElements.map(async (key) => {
          if (
            key.type === "cascaded" &&
            (key.column_name === "application_name" ||
              key.column_name === "role_name")
          ) {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency,
            };
            const options = await props.getUserFilterAttributes(body);
            key.initialData = options.data.data
              .map((opt) => {
                if (opt.attribute !== null) {
                  return {
                    value: opt.attribute,
                    label: opt.attribute,
                    id: opt.attribute,
                  };
                }
              })
              .filter((obj) => obj);
          }
          return key;
        });
        await Promise.all(filterElements);
        setFilterConfig(initialFilterElements);
        setSelectedDependency(selectionValueDependency);
      }
    } catch (err) {
      props.setUserRoleMgmtLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const setHierarchyFilter = async (filter, selectionValueDependency) => {
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...filterConfig];
        const filterElements = initialFilterElements.map(async (key) => {
          if (
            key.type === "cascaded" &&
            (key.column_name === "l0_name" ||
              key.column_name === "l1_name" ||
              key.column_name === "l2_name" ||
              key.column_name === "channel")
          ) {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency,
              dimension: key.dimension,
            };
            const options = await getCrossDimensionFiltersData(body)();
            key.initialData = options.data.data.attribute.map((opt) => {
              return {
                value: opt.attribute,
                label: opt.attribute,
                id: opt.attribute,
              };
            });
          }
          return key;
        });
        await Promise.all(filterElements);
        setFilterConfig(initialFilterElements);
        setSelectedHierarchyDependency(selectionValueDependency);
        props.setUserRoleMgmtLoader(false);
      }
    } catch (err) {
      props.setUserRoleMgmtLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const fetchValuesBasedOnFilterApplied = async (
    _hideloader,
    initialDependency
  ) => {
    props.setUserRoleMgmtLoader(true);
    let dependency = initialDependency
      ? initialDependency
      : [...selectedDependency, ...selectedHierarchyDependency];
    try {
      let body = {
        meta: {},
        filters: dependency,
      };
      let response = await props.getRolesMappedToUserData(body);
      props.setRolesMappedToUserData(response.data.data);
    } catch (err) {
      props.setUserRoleMgmtLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const resetFilterValuesSelected = async () => {
    setFilterConfig([]);
    props.setUserRoleMgmtLoader(true);
    let filterResponse = await props.getFilterConfiguration();
    props.setFilterConfiguration(filterResponse.data.data);
    const postBody = {
      meta: {
        search: [],
      },
      filters: [],
    };
    let response = await props.getRolesMappedToUserData(postBody);
    props.setRolesMappedToUserData(response.data.data);
  };

  return (
    <Container
      maxWidth={false}
      className={globalClasses.marginBottom}
      style={{ flex: 1 }}
    >
      <FilterGroup
        filters={filterConfig}
        onFilter={fetchValuesBasedOnFilterApplied}
        screen={"store status"}
        update={updateFilterValuesOnChange}
        onReset={resetFilterValuesSelected}
        inititalSelection={initialDependencyValue}
      />
    </Container>
  );
};

export default FilterHeader;
