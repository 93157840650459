import { createSlice } from "@reduxjs/toolkit";
import {
  DASHBOARD_FILTER_CONFIG,
  VIEWPLANS_TABLE_CONFIG,
  VIEWPLANS_TABLE_DATA,
  DELETE_PLANS,
  PRODUCT_RULE_DASHBOARD_FILTER_CONFIG,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";
import axiosInstance from "../../../../Utils/axios/index";

export const inventorySmartDashboardService = createSlice({
  name: "inventorySmartDashboardService",
  initialState: {
    inventorysmartFilterLoader: false,
    inventorysmartDeletePlanLoader: false,
    inventorysmartDatesLoader: false,
    inventorysmartDashboardFilterElements: [],
    inventorysmartDashboardFilterDependency: [],
    selectedFilters: [],
    selectedDates: {
      fiscalInfoStartDate: null,
      fiscalInfoEndDate: null,
    },
    isFiltersValid: false,
    viewPlanLoader: false,
    viewPlanTableConfigLoader: false,
    viewPlansTableConfig: [],
    viewPlansTableData: [],
  },
  reducers: {
    setInventorysmartFilterLoader: (state, action) => {
      state.inventorysmartFilterLoader = action.payload;
    },
    setInventorysmartDeletePlanLoader: (state, action) => {
      state.inventorysmartDeletePlanLoader = action.payload;
    },
    setInventorysmartDatesLoader: (state, action) => {
      state.inventorysmartDatesLoader = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setInventorysmartDashboardFilterElements: (state, action) => {
      state.inventorysmartDashboardFilterElements = action.payload;
    },
    setInventorysmartDashboardFilterDependency: (state, action) => {
      state.inventorysmartDashboardFilterDependency = action.payload;
    },
    setIsFiltersValid: (state, action) => {
      state.isFiltersValid = action.payload;
    },
    setViewPlanTableLoader: (state, action) => {
      state.viewPlanLoader = action.payload;
    },
    setViewPlanTableConfigLoader: (state, action) => {
      state.viewPlanTableConfigLoader = action.payload;
    },
    setViewPlanTableConfiguration: (state, action) => {
      state.viewPlansTableConfig = action.payload;
    },
    setViewPlanTableData: (state, action) => {
      state.viewPlansTableData = action.payload;
    },
    setSelectedDates: (state, action) => {
      state.selectedDates = action.payload;
    },
    resetDashboardStore: (state, _action) => {
      state.inventorysmartFilterLoader = false;
      state.inventorysmartDeletePlanLoader = false;
      state.inventorysmartDatesLoader = false;
      state.inventorysmartDashboardFilterElements = [];
      state.inventorysmartDashboardFilterDependency = [];
      state.selectedFilters = [];
      state.selectedDates = {
        fiscalInfoStartDate: null,
        fiscalInfoEndDate: null,
      };
      state.isFiltersValid = false;
      state.viewPlanLoader = false;
      state.viewPlanTableConfigLoader = false;
      state.viewPlansTableConfig = [];
      state.viewPlansTableData = [];
    },
  },
});

export const {
  setInventorysmartFilterLoader,
  setInventorysmartDeletePlanLoader,
  setInventorysmartDatesLoader,
  setSelectedFilters,
  setInventorysmartDashboardFilterElements,
  setInventorysmartDashboardFilterDependency,
  setIsFiltersValid,
  setViewPlanTableLoader,
  setViewPlanTableConfigLoader,
  setViewPlanTableConfiguration,
  setViewPlanTableData,
  setSelectedDates,
  resetDashboardStore,
} = inventorySmartDashboardService.actions;

export const getFilterConfiguration = (filterType) => () => {
  let dynamicURL = DASHBOARD_FILTER_CONFIG;
  if (filterType === "ProductRule") {
    dynamicURL = PRODUCT_RULE_DASHBOARD_FILTER_CONFIG;
  }
  return axiosInstance({
    url: dynamicURL,
    method: "GET",
  });
};

export const getViewPlanTableConfiguration = () => () => {
  return axiosInstance({
    url: VIEWPLANS_TABLE_CONFIG,
    method: "GET",
  });
};

export const getViewPlanTableData = (postBody) => () => {
  return axiosInstance({
    url: VIEWPLANS_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const deletePlans = (postBody) => () => {
  return axiosInstance({
    url: DELETE_PLANS,
    method: "POST",
    data: postBody,
  });
};

export default inventorySmartDashboardService.reducer;
