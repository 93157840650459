import GroupType from "./groupType";
import PageRouteTitles from "./PageRouteTitles";
import { useHistory, Prompt } from "react-router-dom";
import { Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import FormLabel from "@mui/material/FormLabel";
import GroupTypeFilters from "./grpTypeFilters";
import FilteredStores from "./storeFilters";
import LoadingOverlay from "../../../Utils/Loader/loader";
import { connect } from "react-redux";
import { storeGrouping } from "config/routes";
import { DASHBOARD } from "modules/react-demo/constants/routesConstants";
import globalStyles from "Styles/globalStyles";
import { useRef } from "react";

const useStyles = makeStyles((theme) => ({
  groupTypeContainer: {
    margin: theme.typography.pxToRem(20),
    padding: 18,
  },
  grpTypeLabel: {
    marginRight: 20,
  },
  grpFiltersContainer: {
    padding: 18,
  },
}));

const CreateStoreGroup = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const prevScr = props.location.state?.prevScr;
  const globalClasses = globalStyles();
  const storeFiltersDependencyRef = useRef([]);
  const productFiltersDependencyRef = useRef([]);
  const storeTableRef = useRef(null);
  const storeGroupTableRef = useRef(null);
  const storeGrpTypeOptions = [
    {
      label: "Manual",
      value: "manual",
    },
    {
      label: "Custom",
      value: "custom",
    },
  ];
  let routeOptions = prevScr
    ? [
        {
          id: "assort_smart_dashboard",
          label: "AssortSmart",
          action: () => history.push(DASHBOARD),
        },
        {
          id: "assort_smart_intelligent_clustering",
          label: "Intelligent Clustering",
          action: () => history.push(prevScr),
          icon: null,
        },

        {
          id: "create_new_store_grp",
          label: "Create New Group",
          action: () => null,
        },
      ]
    : [
        {
          id: "store_grping_scr",
          label: "Store Grouping",
          action: () => history.push(storeGrouping.home),
        },
        {
          id: "create_new_store_grp",
          label: "Create New Group",
          action: () => null,
        },
      ];

  if (prevScr === "/inventory-smart/store-eligibility-grouping") {
    routeOptions = [
      {
        id: "store_grping_scr",
        label: "Store Grouping",
        action: () => history.push(prevScr),
      },
      {
        id: "create_new_store_grp",
        label: "Create New Group",
        action: () => null,
      },
    ];
  }

  const getPromptStatus = (loc) => {
    const message = `Are you sure you want to go to ${loc.pathname}?`;
    if (
      (loc.pathname === storeGrouping.home ||
        !loc.pathname.includes("store-grouping")) &&
      (props.selectedStores.length !== 0 || props.selectedGrps.length !== 0)
    ) {
      return message;
    }
    return true;
  };

  return (
    <>
      <Prompt
        message={(location) => {
          return getPromptStatus(location);
        }}
      />
      <PageRouteTitles id="storeGrpingCrtBrdCrmbs" options={routeOptions} />
      <Container maxWidth={false}>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter}`}
        >
          <FormLabel
            classes={{ root: classes.grpTypeLabel }}
            component="legend"
          >
            GroupType
          </FormLabel>
          <GroupType
            id="storeGrpingGrpTypeComp"
            type="store"
            options={storeGrpTypeOptions}
          />
        </div>
        <GroupTypeFilters
          ref={{
            storeFiltersRef: storeFiltersDependencyRef,
            productFiltersRef: productFiltersDependencyRef,
            storeTableRef: storeTableRef,
            storeGroupTableRef: storeGroupTableRef,
          }}
          {...props}
          id="storeGrpingGrpTypeFiltersComp"
        />
        <LoadingOverlay loader={props.isLoading} spinner>
          <FilteredStores
            ref={{
              storeFiltersRef: storeFiltersDependencyRef,
              productFiltersRef: productFiltersDependencyRef,
              storeTableRef: storeTableRef,
              storeGroupTableRef: storeGroupTableRef,
            }}
            prevScr={prevScr}
            id="storeGrpingFilteredStoresComp"
          />
        </LoadingOverlay>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading:
      state.storeGroupReducer.isLoading ||
      state.storeGroupReducer.customStoreGroupLoader,
    selectedStores: state.storeGroupReducer.selectedstores,
    selectedGrps: state.storeGroupReducer.manualselectedGroups,
  };
};
export default connect(mapStateToProps, null)(CreateStoreGroup);
