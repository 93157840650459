import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { cloneDeep, isEmpty } from "lodash";

import Filters from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import Loader from "Utils/Loader/loader";

import ExcessInventoryGraphComponent from "./excess-inventory-graph";
import {
  setExcessInventoryScreenLoader,
  setExcessInventoryGraphData,
  setExcessInventoryFilterConfiguration,
  getExcessInventoryFiscalWeekGraph,
  clearExcessInventoryStates,
  setExcessInventoryTableLoader,
} from "../../../services-inventorysmart/Allocation-Reports/excess-inventory-service";
import {
  ERROR_MESSAGE,
  tableConfigurationMetaData,
} from "../../../constants-inventorysmart/stringConstants";
import {
  fetchFilterConfig,
  updateCrossFiltersData,
} from "../../inventorysmart-utility";
import ExcessInventoryTableView from "./excess-inventory-table-view";
import { Button, Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import FilterChips from "commonComponents/filters/filterChips";

const ExcessInventoryComponent = (props) => {
  const [excessInventoryFilterConfig, setExcessInventoryFilterConfig] =
    useState([]);
  const [excessInventoryFilterDependency, setExcessInventoryFilterDependency] =
    useState([]);
  const [
    excessInventoryFilterDependencyChips,
    setExcessInventoryFilterDependencyChips,
  ] = useState([]);
  const [showExcessInventoryDetails, setShowExcessInventoryDetails] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [renderTable, setRenderTable] = useState({});

  const globalClasses = globalStyles();

  useEffect(() => {
    const getInitialFilterConfiguration = async () => {
      try {
        props.setExcessInventoryScreenLoader(true);
        let response = await fetchFilterConfig("inventorysmart_reportings");
        props.setExcessInventoryFilterConfiguration(response);
        props.setExcessInventoryScreenLoader(false);
      } catch (e) {
        props.setExcessInventoryScreenLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    getInitialFilterConfiguration();
    return props.clearExcessInventoryStates();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.excessInventoryFilterConfiguration)) {
      props.setExcessInventoryScreenLoader(true);
      const getFilterValues = async () => {
        try {
          let initialFilterElements = cloneDeep(
            props.excessInventoryFilterConfiguration
          ).sort((a, b) => (a.dimension > b.dimension ? -1 : 1));
          let onUpdateFilters = await updateCrossFiltersData(
            initialFilterElements,
            [],
            "fetchOptions"
          );
          setExcessInventoryFilterConfig(onUpdateFilters);
          props.setExcessInventoryScreenLoader(false);
        } catch (err) {
          displaySnackMessages(ERROR_MESSAGE, "error");
          props.setExcessInventoryScreenLoader(false);
        }
      };
      getFilterValues();
    }
  }, [props.excessInventoryFilterConfiguration]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const updateExcessInventoryFilters = async (dependency, filter) => {
    props.setExcessInventoryScreenLoader(true);
    /*
      dependency is a list that consists of selected filter configuration
      that is used for creating request body to fetch the corresponding filter's drop down values
    */
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              // passing boolean values instead of strings TRUE and FAlSE when active drop down is selected, else pass the default value for other filters
              // as it is a multiSelect filter the values are in the array format
              values: Array.isArray(opt.values)
                ? opt.values.map((option) =>
                    option.value === "TRUE"
                      ? true
                      : option.value === "FALSE"
                      ? false
                      : option.value
                  )
                : opt.values,
              filter_type: opt.filter_type,
              dimension: opt.dimension,
            };
          })
        : [];
    setExcessInventoryFilterDependencyChips(dependency);
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...excessInventoryFilterConfig];
        let onUpdateFilters = await updateCrossFiltersData(
          initialFilterElements,
          selectionValueDependency,
          "updateOptions"
        );
        setExcessInventoryFilterConfig(onUpdateFilters);
      }
      setExcessInventoryFilterDependency(selectionValueDependency);
      props.setExcessInventoryScreenLoader(false);
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setExcessInventoryScreenLoader(false);
      setExcessInventoryFilterDependencyChips([]);
    }
  };

  const fetchValuesBasedOnFilterApplied = async () => {
    props.setExcessInventoryScreenLoader(true);
    try {
      let graphReqBody = {
        meta: tableConfigurationMetaData.meta,
        filters: excessInventoryFilterDependency,
      };
      let response = await props.getExcessInventoryFiscalWeekGraph(
        graphReqBody
      );
      props.setExcessInventoryGraphData(response.data?.data);
      response.data?.status && setShowExcessInventoryDetails(true);
      setRenderTable(graphReqBody);
      props.setExcessInventoryScreenLoader(false);
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setExcessInventoryScreenLoader(false);
    }
    setOpenModal(false);
    // to avoid rendering graph on initial go
    props.setExcessInventoryTableLoader(true);
  };

  const resetSelectedFilterValues = () => {
    props.setExcessInventoryTableLoader(true);
    setExcessInventoryFilterDependency([]);
    setExcessInventoryFilterDependencyChips([]);
    setRenderTable({});
    setShowExcessInventoryDetails(false);
    setOpenModal(false);
  };

  return (
    <>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {excessInventoryFilterDependencyChips.length > 0 && (
          <FilterChips
            filterConfig={excessInventoryFilterDependencyChips}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <Loader loader={props.excessInventoryScreenLoader}>
            <Filters
              filters={excessInventoryFilterConfig}
              update={updateExcessInventoryFilters}
              doNotUpdateDefaultValue={true}
              onFilter={fetchValuesBasedOnFilterApplied}
              onReset={resetSelectedFilterValues}
            />
          </Loader>
        </CustomAccordion>
      </FilterModal>
      {showExcessInventoryDetails && (
        <Loader loader={props.excessInventoryTableLoader}>
          <div className={globalClasses.filterWrapper}>
            <ExcessInventoryGraphComponent
              excessInventoryGraphData={props.excessInventoryFiscalWeekGraph}
            />
            <ExcessInventoryTableView
              displaySnack={displaySnackMessages}
              renderTable={renderTable}
              weeksAvailable={props.excessInventoryFiscalWeekGraph}
            />
          </div>
        </Loader>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    excessInventoryScreenLoader:
      inventorysmartReducer.inventorySmartExcessInventoryService
        .excessInventoryScreenLoader,
    excessInventoryTableLoader:
      inventorysmartReducer.inventorySmartExcessInventoryService
        .excessInventoryTableLoader,
    excessInventoryFilterConfiguration:
      inventorysmartReducer.inventorySmartExcessInventoryService
        .excessInventoryFilterConfiguration,
    excessInventoryFiscalWeekGraph:
      inventorysmartReducer.inventorySmartExcessInventoryService
        .excessInventoryFiscalWeekGraph,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setExcessInventoryScreenLoader: (body) =>
      dispatch(setExcessInventoryScreenLoader(body)),
    setExcessInventoryTableLoader: (body) =>
      dispatch(setExcessInventoryTableLoader(body)),
    setExcessInventoryFilterConfiguration: (body) =>
      dispatch(setExcessInventoryFilterConfiguration(body)),
    setExcessInventoryGraphData: (body) =>
      dispatch(setExcessInventoryGraphData(body)),
    getExcessInventoryFiscalWeekGraph: (body) =>
      dispatch(getExcessInventoryFiscalWeekGraph(body)),
    clearExcessInventoryStates: (body) =>
      dispatch(clearExcessInventoryStates(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExcessInventoryComponent);
