export const DASHBOARD_FILTER_CONFIG =
  "core/filter-configuration/screen/InventoryDashboard";
export const VIEWPLANS_TABLE_CONFIG =
  "core/table-fields?table_name=inventorysmart_plans_table";
export const STORE_INVENTORY_TABLE_CONFIG =
  "core/table-fields?table_name=inventorysmart_details_store_table";
export const STORE_INVENTORY_ALERTS_TABLE_CONFIG =
  "core/table-fields?table_name=store_inventory_forecast";
export const ALERTS_ACTION_TABLE_CONFIG = "core/table-fields?table_name=";
export const ARTICLE_INVENTORY_TABLE_CONFIG =
  "core/table-fields?table_name=inventorysmart_details_table";
export const VIEWPLANS_TABLE_DATA = "inventory-smart/plan/search";

// Create Allocation
export const CREATE_PLANS_FILTER_CONFIG =
  "core/filter-configuration/screen/Allocation";
export const GET_ALLOCATE = "inventory-smart/plan/get-allocation-strategy";
export const GET_COLUMN =
  "core/table-fields?table_name=inventorysmart_strategy_table";
export const GET_APS_WOS = "inventory-smart/plan/get-aps-wos";
export const GET_STOREGROUP_STORE_MAP =
  "inventory-smart/plan/get-storegroup-store-map";
export const GET_SETALL_DCS = "inventory-smart/plan/get-setall-defaults";

export const GET_STORES = "inventory-smart/simulation/strategy-constraints";
export const GET_DCS = "inventory-smart/simulation/dc-level-metrics";
export const CREATE_ALLOCATION_API =
  "inventory-smart/simulation/create-allocation";
export const SAVE_DRAFT = "inventory-smart/plan/draft";
export const UPDATE_RES_QTY = "inventory-smart/plan/update-reserved-quantity";

export const STORE_INVENTORY_TABLE_DATA =
  "inventory-smart/dashboard/getStoreInventory";
export const STORE_INVENTORY_ALERTS_TABLE_DATA =
  "inventory-smart/dashboard/alerts";
export const FORECAST_ALERTS_TABLE_DATA =
  "inventory-smart/dashboard/alerts/forecast";
export const ARTICLE_INVENTORY_TABLE_DATA =
  "inventory-smart/dashboard/getArticleInventory";
export const DELETE_PLANS = "inventory-smart/plan/delete";
export const INVENTORY_DASHBOARD_KPI_DATA = "/core/kpi-data";
export const INVENTORY_DASHBOARD_FORECAST_KPI_DATA =
  "inventory-smart/dashboard/kpi/forecast";
export const INVENTORY_DASHBOARD_STORE_INVENTORY_KPI_DATA =
  "inventory-smart/dashboard/kpi/store-inventory";
export const INVENTORY_DASHBOARD_ALERTS_REVIEW =
  "inventory-smart/dashboard/alerts/view";
export const FETCH_INVENTORY_DASHBOARD_ALERTS_STORE_CODES =
  "inventory-smart/dashboard/alerts/constraints";

// PRODUCT PROFILE SCREEN API'S
export const FETCH_CROSS_FILTERS_DATA = "core/cross-filter";
export const GET_IA_RECOMMENDED_TABLE_DATA =
  "inventory-smart/product-profile/ia";
export const GET_USER_CREATED_TABLE_DATA =
  "inventory-smart/product-profile/user";
export const GET_STORE_SIZE_CONTRIBUTION_DATA =
  "inventory-smart/product-profile/contribution/store-size";
export const GET_STYLE_COLOR_DESCRIPTION_DATA =
  "inventory-smart/product-profile/contribution/style-color";
export const DELETE_USER_PRODUCT_PROFILE = "inventory-smart/product-profile";
// CREATE PRODUCT PROFILE SCREEN API'S
export const GET_PRODUCTS_TO_SELECT =
  "inventory-smart/product-profile/style-color";
export const GET_PRODUCTS_STORE_SIZE_PENETRATION =
  "inventory-smart/product-profile/store-size";
export const SAVE_NEW_PRODUCT_PROFILE = "inventory-smart/product-profile";

// Product Rules
export const PRODUCT_RULE_DASHBOARD_FILTER_CONFIG =
  "core/filter-configuration/screen/inventorysmart_product_rules";

export const PRODUCT_RULE_DASHBOARD_TABLE_FILTER_CONFIG = "core/table-fields";

export const PRODUCT_RULE_DASHBOARD_TABLE_ROW_DATA =
  "inventory-smart/product-rule/search";

export const PRODUCT_RULE_POP_UP_TABLE_ROW_DATA =
  "/inventory-smart/product-rule/mapping-details";

export const SAVE_PRODUCT_RULE_POP_UP_TABLE_ROW_DATA =
  "inventory-smart/product-rule/update-mapping-selection";

// Constraints

export const GET_STORE_LEVEL_TABLE_DATA =
  "/inventory-smart/constraints/store-search";
export const POST_STORE_LEVEL_TABLE_DATA =
  "/inventory-smart/constraints/single-update";
export const GET_STORE_GRADE_LEVEL_TABLE_DATA =
  "/inventory-smart/constraints/store-grade-search";
export const GET_STORE_GROUP_LEVEL_TABLE_DATA =
  "/inventory-smart/constraints/store-group-search";
export const GET_STORE_MODAL_TABLE_DATA =
  "/inventory-smart/constraints/mapping-details";
export const SET_ALL_TABLE_DATA =
  "/inventory-smart/constraints/filter-store-update";
export const GET_STORE_GROUP_AGGREGATE =
  "/inventory-smart/constraints/store-group-aggregate";
export const GET_STORE_GRADE_AGGREGATE =
  "/inventory-smart/constraints/store-grade-aggregate";

// Finalize
export const GET_PRODUCT_VIEW_SUMMARY =
  "/inventory-smart/finalize/finalize-product-view-summary";
export const GET_STORE_VIEW_SUMMARY =
  "/inventory-smart/finalize/finalize-store-view-summary";
export const GET_PRODUCT_VIEWS =
  "/inventory-smart/finalize/finalize-product-view-data";
export const GET_STORE_VIEW =
  "/inventory-smart/finalize/finalize-store-view-data";
export const GET_PRODUCT_STORE_VIEW =
  "/inventory-smart/finalize/finalize-product-store-view-data";
export const GET_DRAFTS = "/inventory-smart/plan/draft";
export const CHANGE_PLAN_STATUS = "/inventory-smart/plan/change-status";
export const SAVE_ALLOCATION = "/inventory-smart/finalize/edit/save-allocation";
export const FINALIZE_API = "/inventory-smart/finalize/finalize";
export const GET_PACKAGE_DETAILS =
  "/inventory-smart/finalize/edit/get-package-details";
export const GET_PACKAGE_DETAILS_FOR_BULK_EDIT =
  "/inventory-smart/finalize/edit/get-package-details-bulk";
export const UPDATE_ALLOCATED_UNITS =
  "/inventory-smart/finalize/edit/update-allocated-units";
export const BULK_UPDATE_ALLOCATED_UNITS =
  "inventory-smart/finalize/edit/update-allocated-units-multi";

// Order Triaging

export const GET_ORDER_BATCHING_TABLE_CONFIG =
  "core/table-fields?table_name=inventorysmart_order_triaging";
export const GET_ORDER_BATCHING_TABLE_DATA = "inventory-smart/order/search";
export const GET_ORDER_BATCHING_METRICS = "inventory-smart/order/metrics";
export const UPDATE_ORDER_BATCHING_DATA = "inventory-smart/order/update";
export const FINALIZE_BATCHING_DATA = "inventory-smart/order/finalise";

//Past Allocation
export const GET_PAST_ALLOCATION_TABLE_CONFIG =
  "core/table-fields?table_name=inventorysmart_past_plans_table";
export const GET_PAST_ALLOCATION_TABLE_DATA =
  "inventory-smart/plan/view-past-plans";

// REPORT SCREEN APIs
export const GET_LOST_SALES_GRAPHDATA =
  "inventory-smart/reporting/lost-sales-fiscal-week-graph";
export const GET_LOST_SALES_TABLE_DETAILS =
  "inventory-smart/reporting/lost-sales-fiscal-week-list";

export const GET_EXCESS_INVENTORY_GRAPHDATA =
  "inventory-smart/reporting/excess-inv-fiscal-week-graph";
export const GET_EXCESS_INVENTORY_TABLE_DETAILS =
  "inventory-smart/reporting/excess-invt-fiscal-week-list";

export const GET_DAILY_ALLOCATION_TABLE_DETAILS =
  "inventory-smart/reporting/daily-allocation-article";

export const GET_DAILY_ALLOCATION_ARTICLE_TO_STORE_VIEW =
  "inventory-smart/reporting/daily-allocation-article-to-store";

export const GET_DAILY_ALLOCATION_TABLE_DETAILS_STORE =
  "inventory-smart/reporting/daily-allocation-store";
export const GET_DAILY_ALLOCATION_STORE_TO_ARTICLE_VIEW =
  "inventory-smart/reporting/daily-allocation-store-to-article";

export const GET_STORE_STOCK_DRILL_DOWN_LIST =
  "inventory-smart/reporting/store-stock-drill-down-list";

export const GET_STORE_STOCK_DRILL_DOWN_SIZE_DETAILS =
  "inventory-smart/reporting/store-stock-drill-down-size-details";

export const GET_ALLOCATION_DEEP_DIVE_TABLE_DETAILS =
  "inventory-smart/reporting/allocation-deep-dive";

export const GET_FORECASTED_UNITS_TABLE_LIST =
  "inventory-smart/reporting/forecast-under-over-units";

export const GET_ADDITIONAL_REPORTS_DATA =
  "inventory-smart/reporting/rolling-forecast";

export const FETCH_FISCAL_WEEK_DROPDOWN_VALUE =
  "inventory-smart/reporting/rolling-forecast-available-week-list";

export const GET_FUTURE_RECEIPTS_TABLE_DATA =
  "inventory-smart/reporting/future-receipt-store-list";

export const GET_FUTURE_RECEIPTS_SUB_ROWS_DATA =
  "inventory-smart/reporting/future-receipt-store-aggregate-list";

export const SAVE_FUTURE_RECEIPTS_TARGET_WOS =
  "inventory-smart/reporting/update-future-receipt-target-wos";

export const GET_MODEL_STOCK_DEEP_DIVE_POP_UP_TABLE_CONFIG =
  "core/table-fields?table_name=model_stock_deep_dive_level_1";
export const GET_MODEL_STOCK_DEEP_DIVE_TABLE_DATA =
  "inventory-smart/reporting/model-stock-deep-dive";

export const GET_FORECAST_REPORTS_TABLE_CONFIG =
  "core/table-fields?table_name=forecast_report";
export const GET_FORECAST_REPORTS_TABLE_DATA =
  "inventory-smart/reporting/forecast-report";
export const GET_IN_STOCK_TABLE_DATA = "inventory-smart/reporting/in-stock";
export const GET_EXCESS_INV_REPORT_TABLE_DATA =
  "inventory-smart/reporting/excess-invt-fiscal-week-list";
