import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Drawer from "@mui/material/Drawer";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { Typography } from "@mui/material";
import { getApplicationMaster } from "actions/tenantConfigActions";
import {
  createComment,
  deleteComment,
  editComment,
  fetchComment,
  getScreenMaster,
} from "actions/commentActions";
import { groupBy } from "lodash";
import { addSnack } from "actions/snackbarActions";
import LoadingOverlay from "Utils/Loader/loader";
import { common } from "modules/react-demo/constants/stringContants";
import { Close } from "@mui/icons-material";
import globalStyles from "Styles/globalStyles";
import CommentingMainSection from "./CommentingMainSection";
import TextInputFieldSection from "./TextInputFieldSection";
import CommentingReplySection from "./CommentingReplySection";
import { setUserManagementList } from "actions/userAccessActions";
import { getUserDetails } from "pages/tenant-config/access-user-management/services/TenantManagement/User-Management/user-management-service";
import { getFormattedApplicationName } from "Utils/functions/utils";
import { filterView } from "modules/react-demo/utils/utilityFunctions";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: theme.customVariables.commentDrawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.common.white,
    transition: "width 300ms ease-out",
  },
  drawerOpen: {
    width: 300,
  },
  drawerClose: {
    width: theme.customVariables.commentDrawerWidth,
  },
  commentIcon: {
    padding: "0.6rem",
    cursor: "pointer",
  },
  wrapper: {
    padding: "0 0.6rem",
  },
  commentInputDiv: {
    padding: "0.6rem 0",
    marginBottom: "1rem",
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  editTextInput: {
    "& .MuiTextField-root": {
      width: "100%",
    },
  },
  actionBtn: {
    textAlign: "right",
    marginTop: "0.6rem",
  },
  commentRow: {
    listStyle: "none",
    padding: "0.6rem 0.3rem",
    "&:hover": {
      backgroundColor: theme.palette.colours.silderDisabledRail,
    },
  },
  commentList: {
    height: "100%",
    overflow: "hidden auto",
    position: "relative",
    "& ul": {
      padding: 0,
    },
  },
  commentHeading: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.common.black,
    padding: "1rem 0.75rem",
    position: "sticky",
    top: "0",
    zIndex: "1",
  },
  headerWrap: {
    display: "flex",
    "& .MuiButton-root": {
      padding: "0",
      minWidth: "0",
    },
  },
  userWrap: {
    display: "flex",
    flexDirection: "column",
    padding: "0 0.75rem",
    width: "100%",
  },
  userName: {
    ...theme.typography.body2,
    fontWeight: theme.typography.fontWeightBold,
    display: "flex",
  },
  timeStamp: {
    color: theme.palette.textColours.slateGrayLight,
    ...theme.typography.body1,
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.primary,
    padding: "0.6rem",
    borderRadius: theme.shape.borderRadius,
    "& .MuiFormControl-root": {
      padding: "0 0.6rem",
    },
    "& .MuiOutlinedInput-root": {
      width: "14rem",
    },
  },
  editTextDiv: {
    ...theme.typography.body1,
    marginTop: "0.25rem",
    padding: "0.75rem",
    whiteSpace: "initial",
  },
  replyWrapper: {
    "& li": {
      listStyle: "none",
    },
  },
  downArrowIcon: {
    transform: "rotate(90deg)",
    transition: "transform 225ms linear",
    marginRight: "0.6rem",
  },
  rightArrowIcon: {
    transform: "rotate(0deg)",
    transition: "transform 225ms linear",
  },
  textInputField: {
    whiteSpace: "initial",
    overflowX: "hidden",
    height: "6.25rem",
    border: `0.06rem solid ${theme.palette.colours.disabledBorder}`,
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.75rem",
    padding: "0.93rem",
    lineHeight: "1.25rem",
  },
  highlight: {
    display: "inline",
    border: "0.06rem solid transparent",
    background: theme.palette.colours.accordionBorder,
    color: theme.palette.textColours.codGray,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    padding: "0 0.3em 0.12rem 0.23em",
    lineHeight: 1.714,
    fontSize: "1em",
    fontWeight: "normal",
    wordBreak: "break-word",
  },
}));

const CommentBar = (props) => {
  const [commentLoader, setCommentLoader] = useState(true);
  const [isActive, setisActive] = useState(false);
  const [comment, setComment] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [applicationCode, setApplicationCode] = useState(null);
  const [screenCode, setScreenCode] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isCommentEditable, setCommentEditable] = useState(false);
  const [editCommentTextInput, setEditCommentTextInput] = useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [openChilCommentdDropDown, setOpenChildCommentDropDown] = useState(
    null
  );
  const [userRoleDetails, setUserRoleDetails] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showReplySection, setShowReplySection] = useState(false);
  const [showReplyTextInput, setShowReplyTextInput] = useState(false);
  const [childComment, setChildComment] = useState(null);
  const [deleteCommentData, setDeleteCommentData] = useState(null);
  const [usersMentioned, setUserMentioned] = useState([]);
  const [planStepOptions, setPlanStepOptions] = useState([]);
  const [selectedPlanStep, setSelectedPlanStep] = useState(null);

  const classes = useStyles();
  const globalClasses = globalStyles();

  const postBody = {
    meta: {
      search: [],
      range: [],
      sort: [],
    },
  };
  const fetchUserManagementList = async () => {
    //let response = await props.getUserDetails(postBody);
    let response = {
      total: 1,
      page: 1,
      count: 1,
      status: true,
      data: [
        {
          user_name: "Demo User",
          user_code: 252,
          email: "demo@impactanalytics.co",
        },
      ],
      message: "Successful",
      previous: null,
      next: null,
    };
    if (response?.data?.status) {
      await props.setUserManagementList(response.data.data);
    }
  };

  useEffect(() => {
    fetchUserManagementList();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      // Fetching application Name from url
      let applicationURL = window.location.pathname.split("/")?.[1];
      let applicationName = getFormattedApplicationName(applicationURL);
      // compare and find code for application name in the url
      let getApplicationMasterList = await props.getApplicationMaster();
      getApplicationMasterList?.data?.data.forEach(async (app) => {
        if (app.name === applicationName) {
          setApplicationCode(app.application_code);
        }
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (applicationCode) {
      fetchScreenCode();
    }
  }, [applicationCode, props.activeScreenName]);

  useEffect(() => {
    if (applicationCode && screenCode) {
      fetchCommentListData(screenCode);
    }
  }, [screenCode, props.planDetails?.data?.plan_code]);

  /**
   * Retrieve data of screen master
   */

  const fetchScreenCode = async () => {
    let payload = {
      application: [applicationCode],
    };
    //let getUserRole = await props.getScreenMaster(payload);
    let getUserRole = {
      total: null,
      page: null,
      count: null,
      status: true,
      data: [
        { screen_code: 2, screen_name: "Filter Configurations" },
        { screen_code: 4, screen_name: "Product Mapping" },
        { screen_code: 5, screen_name: "Product Status" },
        { screen_code: 6, screen_name: "Store Mapping" },
        { screen_code: 7, screen_name: "Store Status" },
        { screen_code: 8, screen_name: "Product Group" },
        { screen_code: 9, screen_name: "Store Group" },
        { screen_code: 10, screen_name: "Product Unit Definition" },
        { screen_code: 11, screen_name: "Inventory" },
        { screen_code: 12, screen_name: "DC Status" },
        { screen_code: 13, screen_name: "DC Mapping" },
        { screen_code: 17, screen_name: "Dc Store" },
        { screen_code: 18, screen_name: "DC FC" },
        { screen_code: 20, screen_name: "DC to Product" },
        { screen_code: 21, screen_name: "DC/FC  to Store Mapping" },
        { screen_code: 22, screen_name: "DC Status" },
        { screen_code: 23, screen_name: "Product to ASN" },
        { screen_code: 24, screen_name: "Product to DC/FC Mapping" },
        { screen_code: 25, screen_name: "Product to PO" },
        { screen_code: 26, screen_name: "Product to Store Mapping" },
        { screen_code: 27, screen_name: "Store to DC/FC Mapping" },
        { screen_code: 28, screen_name: "Store to Product Mapping" },
        { screen_code: 42, screen_name: "FC status" },
        { screen_code: 44, screen_name: "VendorConfigurations" },
        { screen_code: 74, screen_name: "Grading create" },
        { screen_code: 100, screen_name: "Grade Basic Details" },
        { screen_code: 101, screen_name: "Grade levels" },
        { screen_code: 102, screen_name: "Grade Metrics" },
        { screen_code: 4001, screen_name: "Store Mapping Modify Screen" },
      ],
      message: "Successful",
      previous: null,
      next: null,
    };
    if (getUserRole?.data?.status) {
      setUserRoleDetails(getUserRole.data.data);
      let options = [{ label: "All", value: "all", id: "all" }];
      getUserRole.data.data.forEach((obj) => {
        if (obj.screen_name === props.activeScreenName) {
          setScreenCode(obj.screen_code);
          setSelectedPlanStep({
            label: obj.screen_name,
            value: obj.screen_code,
            id: obj.screen_code,
          });
        }
        options.push({
          label: obj.screen_name,
          value: obj.screen_code,
          id: obj.screen_code,
        });
      });
      setPlanStepOptions(options);
    }
  };

  /**
   * Fetches all the comments based on screen and application code
   */

  const fetchCommentListData = async (code) => {
    setCommentLoader(true);
    let planCode = props.planDetails?.data?.plan_code;
    let payload = {
        body: [
          {
            attribute_name: "application_code",
            attribute_value: applicationCode,
            operator: "eq",
          },
          {
            attribute_name: "screen_code",
            attribute_value: code,
            operator: "eq",
          },
          {
            attribute_name: "search_context->>'plan_code'",
            attribute_value: planCode,
            operator: "eq",
          },
        ],
      },
      formattedGetCommentPayload = payload;

    if (!planCode) {
      formattedGetCommentPayload = {
        body: payload.body.filter((item) => {
          return item.attribute_name !== "search_context->>'plan_code'";
        }),
      };
    }

    if (!code) {
      formattedGetCommentPayload = {
        body: payload.body.filter((item) => {
          return item.attribute_name !== "screen_code";
        }),
      };
    }

    let commentDataResponse = await props.fetchComment(
      formattedGetCommentPayload
    );
    if (commentDataResponse?.status) {
      let parentComments = commentDataResponse?.data?.data.filter(
        (obj) => !obj.parent_code
      );
      // Grouping child comments with the parent
      parentComments.forEach((parentData, index) => {
        let repliesArray = [];
        commentDataResponse?.data?.data.forEach((childData) => {
          if (parentData.note_code === childData.parent_code) {
            repliesArray.push(childData);
            parentComments[index].repliesArray = repliesArray;
          }
        });
      });
      commentDataResponse.data.data = parentComments;
      // Group by screen codes
      let tempGroupedData = groupBy(
        commentDataResponse.data.data,
        "screen_code"
      );

      // Setting screen name
      userRoleDetails.forEach((obj) => {
        if (tempGroupedData[obj.screen_code]) {
          tempGroupedData[obj.screen_name] = tempGroupedData[obj.screen_code];
          delete tempGroupedData[obj.screen_code];
        }
      });
      commentDataResponse.data.data = tempGroupedData;
      // Closing reply section after success
      setShowReplySection(false);
      setShowReplyTextInput(false);
      setCommentList(commentDataResponse.data);
    }
    setCommentLoader(false);
  };

  const toggleSideBarExpansionHandler = () => {
    setisActive(!isActive);
  };

  const handleOpenDropdown = (event, type) => {
    type === "child"
      ? setOpenChildCommentDropDown(event.currentTarget)
      : setOpenCommentDropdown(event.currentTarget);
  };

  const handleCloseDropdown = (type) => {
    type === "child"
      ? setOpenChildCommentDropDown(null)
      : setOpenCommentDropdown(null);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  /**
   * Calls action to add comment
   */
  const saveComment = async (type) => {
    setCommentLoader(true);
    let planCode = props.planDetails?.data?.plan_code;
    let payload = {
      application_code: applicationCode,
      screen_code: screenCode,
      html_msg: document.getElementById(`${type}TextBoxField`).innerText,
      search_context: planCode
        ? [
            {
              key: "plan_code",
              value: planCode,
            },
          ]
        : [],
    };
    if (type === "reply") {
      payload = { ...payload, parent_code: selectedComment.note_code };
    }
    if (usersMentioned?.length) {
      payload = getUserMentionedPayload(payload);
    }

    let addedCommentResponse = await props.createComment(payload);
    if (addedCommentResponse?.data?.status) {
      fetchCommentListData(screenCode);
      displaySnackMessages(addedCommentResponse.data.message, "success");
      closeComment(type);
      setUserMentioned([]);
    } else {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const getUserMentionedPayload = (payload) => {
    let userMentionedCodes = [];
    usersMentioned.forEach((user) => {
      if (payload.html_msg.includes(user.user_name)) {
        userMentionedCodes.push(user.user_code);
      }
      payload = {
        ...payload,
        html_msg: payload.html_msg.replace(
          `@${user.user_name}`,
          `<mark class=${classes.highlight} contenteditable="false">@${user.user_name}</mark>`
        ),
      };
    });
    payload = {
      ...payload,
      users_mentioned: userMentionedCodes,
      redirect_url: window.location.href,
    };
    return payload;
  };

  const closeComment = (type) => {
    setComment(null);
    document.getElementById(`${type}TextBoxField`).innerText = "";
  };

  /**
   * Calls action to delete comment
   * @param {string} data - selected comment to be deleted
   */

  const deleteComment = async () => {
    setCommentLoader(true);
    let deleteResponse = await props.deleteComment(deleteCommentData.note_code);
    if (deleteResponse?.data?.status) {
      setShowDeleteDialog(false);
      setDeleteCommentData(null);
      fetchCommentListData(screenCode);
      displaySnackMessages(deleteResponse.data.message, "success");
    } else {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  /**
   * Calls action to edit comment
   * @param {string} data - selected comment for edit
   */

  const editCommentFn = async (data) => {
    setCommentLoader(true);
    let payload = {
      note_id: data.note_code,
      html_msg: document.getElementById("editTextBoxField").innerHTML,
    };
    if (usersMentioned?.length) {
      payload = getUserMentionedPayload(payload);
    }

    let editResponse = await props.editComment(payload);
    if (editResponse?.data?.status) {
      fetchCommentListData(screenCode);
      displaySnackMessages(editResponse.data.message, "success");
      setCommentEditable(false);
      setUserMentioned([]);
    } else {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const onChangeDropOption = (options) => {
    setSelectedPlanStep(options);
    options.value !== "all"
      ? fetchCommentListData(options.value)
      : fetchCommentListData();
  };

  return props.activeScreenName ? (
    <div className={globalClasses.flexRow}>
      <Drawer
        variant="permanent"
        anchor="right"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isActive,
          [classes.drawerClose]: !isActive,
        })}
        classes={{
          paper: clsx(
            {
              [classes.drawerOpen]: isActive,
              [classes.drawerClose]: !isActive,
            },
            classes.drawer
          ),
        }}
      >
        <div
          className={classes.commentIcon}
          onClick={() => toggleSideBarExpansionHandler()}
        >
          {isActive ? <Close /> : <ChatBubbleIcon color="primary" />}
        </div>
        {isActive && (
          <div className={classes.wrapper}>
            <div className={classes.commentInputDiv}>
              {/* onclick making text field row as 5 */}
              <TextInputFieldSection
                type={"create"}
                comment={comment}
                setComment={setComment}
                saveComment={() => saveComment("create")}
                closeComment={() => closeComment("create")}
                classes={classes}
                setUserMentioned={setUserMentioned}
                usersMentioned={usersMentioned}
              />
            </div>
            <LoadingOverlay loader={commentLoader} spinner>
              <div>
                {filterView(
                  "Screen Name",
                  "screen_name",
                  planStepOptions,
                  onChangeDropOption,
                  selectedPlanStep
                )}
              </div>
              <div className={classes.commentList}>
                {commentList?.data && Object.keys(commentList.data)?.length ? (
                  Object.keys(commentList.data).map((key) => {
                    return (
                      <section>
                        <h3 className={classes.commentHeading}>{key}</h3>
                        <ul>
                          {commentList.data[key].map((commentData) => {
                            return (
                              <li className={classes.commentRow}>
                                <div className={classes.contentWrapper}>
                                  <CommentingMainSection
                                    commentData={commentData}
                                    handleOpenDropdown={handleOpenDropdown}
                                    setSelectedComment={setSelectedComment}
                                    isCommentEditable={
                                      isCommentEditable &&
                                      commentData.note_code ===
                                        selectedComment?.note_code
                                    }
                                    openCommentDropdown={openCommentDropdown}
                                    handleCloseDropdown={handleCloseDropdown}
                                    userData={props.userData}
                                    selectedComment={selectedComment}
                                    setCommentEditable={setCommentEditable}
                                    setEditCommentTextInput={
                                      setEditCommentTextInput
                                    }
                                    setShowDeleteDialog={setShowDeleteDialog}
                                    setDeleteCommentData={setDeleteCommentData}
                                    type="parent"
                                    classes={classes}
                                    editCommentFn={editCommentFn}
                                    editCommentTextInput={editCommentTextInput}
                                    setUserMentioned={setUserMentioned}
                                    usersMentioned={usersMentioned}
                                  />
                                  <CommentingReplySection
                                    commentData={commentData}
                                    classes={classes}
                                    showReplySection={showReplySection}
                                    setShowReplySection={setShowReplySection}
                                    selectedComment={selectedComment}
                                    handleOpenDropdown={handleOpenDropdown}
                                    openChilCommentdDropDown={
                                      openChilCommentdDropDown
                                    }
                                    handleCloseDropdown={handleCloseDropdown}
                                    setChildComment={setChildComment}
                                    setShowReplyTextInput={
                                      setShowReplyTextInput
                                    }
                                    editCommentFn={editCommentFn}
                                    setEditCommentTextInput={
                                      setEditCommentTextInput
                                    }
                                    setShowDeleteDialog={setShowDeleteDialog}
                                    saveComment={saveComment}
                                    childComment={childComment}
                                    showReplyTextInput={showReplyTextInput}
                                    setDeleteCommentData={setDeleteCommentData}
                                    editCommentTextInput={editCommentTextInput}
                                    userData={props.userData}
                                    setSelectedComment={setSelectedComment}
                                    setUserMentioned={setUserMentioned}
                                    usersMentioned={usersMentioned}
                                  />
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </section>
                    );
                  })
                ) : (
                  <Typography
                    align="center"
                    component="p"
                    variant="body1"
                    mt={5}
                  >
                    No Data
                  </Typography>
                )}
              </div>
            </LoadingOverlay>
          </div>
        )}
      </Drawer>
      <ConfirmPrompt
        showModal={showDeleteDialog}
        message="Are you sure you want to delete comment?"
        title="Confirm Delete"
        ariaLabeledBy="delete-confirmation-dialog"
        primaryBtnText={common.__ConfirmBtnText}
        secondaryBtnText={common.__RejectBtnText}
        showCloseIcon={true}
        setConfirm={setShowDeleteDialog}
        confirmCallback={(val) => {
          if (val) {
            deleteComment();
          }
        }}
      />
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    userData: state.authReducer.user,
    activeScreenName:
      state.assortsmartReducer.commonAssortReducer.activeScreenName,
    planDetails: state.assortsmartReducer.planDashboardReducer.planDetails,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getApplicationMaster: (payload) => dispatch(getApplicationMaster(payload)),
  createComment: (payload) => dispatch(createComment(payload)),
  fetchComment: (payload) => dispatch(fetchComment(payload)),
  deleteComment: (payload) => dispatch(deleteComment(payload)),
  editComment: (payload) => dispatch(editComment(payload)),
  getScreenMaster: (payload) => dispatch(getScreenMaster(payload)),
  getUserDetails: (payload) => dispatch(getUserDetails(payload)),
  setUserManagementList: (payload) => dispatch(setUserManagementList(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentBar);
