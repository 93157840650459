import axiosInstance from "../Utils/axios";
import { GET_HOME_APPLICATION, GET_TENANT_NAME } from "config/api";
import { SET_USER_APPS } from "./types";

export const homePageActions = () => async (dispatch) => {
  // let data = await axiosInstance({
  //   url: `${GET_HOME_APPLICATION}`,
  //   method: "GET",
  // });
  // const userApps = data.data.data.map((e) => e.application);
  // dispatch({
  //   type: SET_USER_APPS,
  //   payload: userApps,
  // });
};

/**
 * @func
 * @desc Fetch Client Name
 * @returns {Object}
 */
export const getClientName = () => async () => {
  // return axiosInstance({
  //   url: `${GET_TENANT_NAME}`,
  //   method: "GET",
  // });
};
