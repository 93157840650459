import Definition from "./Definition";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  fetchGroupDefinitionById,
  ToggleLoader,
} from "pages/product-grouping/product-grouping-service";
import { connect } from "react-redux";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const EditMapDefinition = (props) => {
  const history = useHistory();
  const group_id = props.match.params.group_id;
  const prevScr = `/product-grouping/group-definition-mapping/${group_id}`;
  const [definitionObj, setdefinitionObj] = useState({});
  const routeOptions = [
    {
      id: "product_grouping_home_scr",
      label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
      action: () => history.push("/product-grouping"),
    },
    {
      id: "product_grouping_definitions_scr",
      label: "Group Definition Mapping",
      action: () => {
        history.push({
          pathname: prevScr,
        });
      },
    },
    {
      id: "product_group_defn_mapping_edit_definition",
      label: "edit definition",
      action: () => null,
    },
  ];
  useEffect(() => {
    const fetchDefinitionData = async () => {
      try {
        props.ToggleLoader(true);
        const res = await props.fetchGroupDefinitionById(
          props.match.params.def_id
        );
        setdefinitionObj(res.data.data);
        props.ToggleLoader(false);
      } catch (error) {
        //error handling
      }
    };
    fetchDefinitionData();
  }, []);
  return (
    <LoadingOverlay loader={props.isLoading} spinner>
      <Definition
        id="productGrpingDfnMapEdit"
        type="edit"
        definition={definitionObj}
        routeOptions={routeOptions}
        prevScr={prevScr}
      />
    </LoadingOverlay>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.productGroupReducer.isLoading,
  };
};
const mapActionsToProps = {
  fetchGroupDefinitionById,
  ToggleLoader,
};
export default connect(mapStateToProps, mapActionsToProps)(EditMapDefinition);
