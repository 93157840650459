import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import DialpadIcon from "@mui/icons-material/Dialpad";
import CategoryIcon from "@mui/icons-material/Category";
import { BsPlusLg, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { Inventory2Outlined } from "@mui/icons-material";
import WebhookIcon from "@mui/icons-material/Webhook";
import GridViewIcon from "@mui/icons-material/GridView";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import CalendarToday from "@mui/icons-material/CalendarToday";
import TungstenOutlinedIcon from "@mui/icons-material/TungstenOutlined";
import {
  ASSORT_CLUSTER,
  DASHBOARD_HINDSIGHT,
  PRESEASON_DASHBOARD,
  STRATEGY,
  CONFIGURATIONS,
  ASSORT_IN_SEASON,
  STRATEGY_REGIONAL,
  PRESEASON_DASHBOARD_REGIONAL,
  IN_SEASON_REGIONAL,
  CONFIGURATIONS_REGIONAL,
  PRODUCT_ATTRIBUTES,
  LOCATION_ATTRIBUTES,
  PRODUCT_OVERVIEW,
  CONSUMER_DECISION_TREE,
  INSIGHTS,
  DEMAND_TRANSFERENCE,
  CLUSTERING,
  INPUTS,
  OPTIMISATIONS,
  MASTERS,
} from "../constants/ReactDemoRoutesConstants";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import DemoPage from "../pages/react-demo/demo-page";

export const sideBarOptions = [
  //AssortSmart modules
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD_HINDSIGHT,
    title: "Hindsight Dashboard",
    icon: React.createElement(WebhookIcon),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ASSORT_CLUSTER,
    title: "Cluster",
    icon: React.createElement(BubbleChartIcon),
    order: 2,
  },
  {
    isParent: true,
    title: `Global Assortment Planning`,
    icon: React.createElement(Inventory2Outlined),
    childList: [
      {
        link: "/" + window.location.pathname.split("/")[1] + STRATEGY,
        title: "Strategy",
        icon: React.createElement(TungstenOutlinedIcon),
        order: 3,
      },
      {
        link:
          "/" + window.location.pathname.split("/")[1] + PRESEASON_DASHBOARD,
        title: "Pre-Season Dashboard",
        icon: React.createElement(CalendarToday),
        order: 4,
      },
      {
        link: "/" + window.location.pathname.split("/")[1] + ASSORT_IN_SEASON,
        title: "In-Season",
        icon: React.createElement(GridViewIcon),
        order: 5,
      },
      {
        link: "/" + window.location.pathname.split("/")[1] + CONFIGURATIONS,
        title: "Configurations",
        icon: React.createElement(CategoryIcon),
        order: 6,
      },
    ],
  },
  {
    isParent: true,
    title: `Regional Assortment Planning`,
    icon: React.createElement(DialpadIcon),
    childList: [
      {
        link: "/" + window.location.pathname.split("/")[1] + STRATEGY_REGIONAL,
        title: "Strategy",
        icon: React.createElement(TungstenOutlinedIcon),
        order: 7,
      },
      {
        link:
          "/" +
          window.location.pathname.split("/")[1] +
          PRESEASON_DASHBOARD_REGIONAL,
        title: "Pre-Season Dashboard",
        icon: React.createElement(CalendarToday),
        order: 8,
      },
      {
        link: "/" + window.location.pathname.split("/")[1] + IN_SEASON_REGIONAL,
        title: "In-Season",
        icon: React.createElement(GridViewIcon),
        order: 9,
      },
      {
        link:
          "/" +
          window.location.pathname.split("/")[1] +
          CONFIGURATIONS_REGIONAL,
        title: "Configurations",
        icon: React.createElement(CategoryIcon),
        order: 10,
      },
    ],
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + STRATEGY,
    title: "Strategy",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 11,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRESEASON_DASHBOARD,
    title: "Pre-Season Dashboard",
    icon: React.createElement(CalendarToday),
    order: 12,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ASSORT_IN_SEASON,
    title: "In-Season",
    icon: React.createElement(GridViewIcon),
    order: 13,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CONFIGURATIONS,
    title: "Configurations",
    icon: React.createElement(CategoryIcon),
    order: 14,
  },
  {
    isParent: true,
    title: `Attribute Management`,
    icon: React.createElement(AutoAwesomeMosaicIcon),
    childList: [
      {
        link: "/" + window.location.pathname.split("/")[1] + PRODUCT_ATTRIBUTES,
        title: "Product Attribute Management",
        icon: React.createElement(BsReverseLayoutTextWindowReverse),
        order: 15,
      },
      {
        link:
          "/" + window.location.pathname.split("/")[1] + LOCATION_ATTRIBUTES,
        title: "Location Attribute Management",
        icon: React.createElement(BsPlusLg),
        order: 16,
      },
    ],
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRODUCT_OVERVIEW,
    title: "Product Overview",
    icon: React.createElement(GridViewIcon),
    order: 17,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CONSUMER_DECISION_TREE,
    title: "Consumer Decision Tree",
    icon: React.createElement(CategoryIcon),
    order: 18,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + INSIGHTS,
    title: "Insights",
    icon: React.createElement(Inventory2Outlined),
    order: 19,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + DEMAND_TRANSFERENCE,
    title: "Demand Transference",
    icon: React.createElement(MonetizationOnIcon),
    order: 20,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CLUSTERING,
    title: "Clustering",
    icon: React.createElement(BubbleChartIcon),
    order: 21,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + INPUTS,
    title: "Inputs",
    icon: React.createElement(BsPlusLg),
    order: 22,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + OPTIMISATIONS,
    title: "Optimisations",
    icon: React.createElement(DialpadIcon),
    order: 23,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + MASTERS,
    title: "Masters",
    icon: React.createElement(TungstenOutlinedIcon),
    order: 24,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD_HINDSIGHT,
      component: DemoPage,
      title: "Hindsight Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ASSORT_CLUSTER,
      component: DemoPage,
      title: "Cluster",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PRESEASON_DASHBOARD,
      component: DemoPage,
      title: "Pre-Season Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + STRATEGY,
      component: DemoPage,
      title: "Strategy",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ASSORT_IN_SEASON,
      component: DemoPage,
      title: "In-Season",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CONFIGURATIONS,
      component: DemoPage,
      title: "Configurations",
    },
    {
      path:
        "/" +
        window.location.pathname.split("/")[1] +
        PRESEASON_DASHBOARD_REGIONAL,
      component: DemoPage,
      title: "Pre-Season Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + STRATEGY_REGIONAL,
      component: DemoPage,
      title: "Strategy",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + IN_SEASON_REGIONAL,
      component: DemoPage,
      title: "In-Season",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + CONFIGURATIONS_REGIONAL,
      component: DemoPage,
      title: "Configurations",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PRODUCT_ATTRIBUTES,
      component: DemoPage,
      title: "Product Attribute Management",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + LOCATION_ATTRIBUTES,
      component: DemoPage,
      title: "Location Attribute Management",
    },
  ];

  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app="assortsmart" />
  );
};

export default Routes;
