export const DASHBOARD = "/ootb/decision-dashboard";
export const WHATIF = "/ootb-inventory-smart/what-if";
export const CREATE_ALLOCATION = "/ootb-inventory-smart/create-allocation";
export const FINALIZE_ALLOCATION = "/ootb-inventory-smart/finalize-allocation";
export const PRODUCT_PROFILE = "/ootb-inventory-smart/product-profile";
export const CREATE_PRODUCT_PROFILE =
  "/ootb-inventory-smart/create-product-profile";
export const GROUPING = "/ootb-inventory-smart/grouping";
export const CONFIGURATION = "/ootb-inventory-smart/configuration";
export const CONSTRAINTS = "/ootb-inventory-smart/constraints";
export const PRODUCT_CONFIGURATION =
  "/ootb-inventory-smart/product-configuration";
export const SETTINGS = "/ootb-inventory-smart/settings";
export const ALLOCATION_REPORT = "/ootb-inventory-smart/allocation-reports";
export const ORDER_BATCHING = "/ootb-inventory-smart/view-current-allocations";
export const VIEW_PAST_ALLOCATION =
  "/ootb-inventory-smart/view-past-allocations";
export const ORDER_MANAGEMENT = "/ootb-inventory-smart/order-management";
export const ADA_VISUAL = "/ootb-inventory-smart/ada-visuals";
export const ORDER_REPOSITORY = "/ootb-inventory-smart/order-repository";
export const ORDER_REPOSITORY_WHATIF =
  "/ootb-inventory-smart/order-repository-whatif";
export const CREATE_NEW_ORDER = "/ootb-inventory-smart/create-new-order";
export const MEIO = "/ootb-inventory-smart/meio";
