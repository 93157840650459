import Definition from "./Definition";
import { useHistory, Prompt } from "react-router-dom";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { useState } from "react";
import { getPromptStatus } from "./common-functions";
const CreateDefintion = (props) => {
  const [isEdited, setIsEdited] = useState(false);
  const history = useHistory();
  const getPrevScr = () => {
    if (history.location.pathname.includes("create-group")) {
      return "/product-grouping/create-group/group-definitions";
    } else if (history.location.pathname.includes("definition-mapping")) {
      const group_id = props.match.params.group_id;
      return `/product-grouping/group-definition-mapping/${group_id}`;
    } else if (history.location.pathname.includes("modify")) {
      const group_id = props.match.params.group_id;
      return `/product-grouping/modify/${group_id}/group-definitions`;
    } else {
      return "/product-grouping/group-definitions";
    }
  };
  const prevScr = getPrevScr();
  let routeOptions = [];
  if (prevScr.includes("create-group")) {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Create New Group",
        action: () => history.push("/product-grouping/create-group"),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "View Definitions",
        action: () =>
          history.push({
            pathname: "/product-grouping/create-group/group-definitions",
          }),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "create definition",
        action: () => null,
      },
    ];
  } else if (prevScr.includes("group-definition-mapping")) {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Group Definition Mapping",
        action: () => history.push(prevScr),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "create definition",
        action: () => null,
      },
    ];
  } else if (prevScr.includes("modify")) {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "View Group",
        action: () =>
          history.push(`/product-grouping/view/${props.match.params.group_id}`),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Modify Group",
        action: () =>
          history.push(
            `/product-grouping/modify/${props.match.params.group_id}`
          ),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "View Definitions",
        action: () =>
          history.push({
            pathname: prevScr,
          }),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "create definition",
        action: () => null,
      },
    ];
  } else {
    routeOptions = [
      {
        id: "product_grouping_home_scr",
        label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
        action: () => history.push("/product-grouping"),
      },
      {
        id: "product_grouping_definitions_scr",
        label: "Grouping Definitions",
        action: () => history.push("/product-grouping/group-definitions"),
      },
      {
        id: "product_grouping_definitions_create_definition",
        label: "create definition",
        action: () => null,
      },
    ];
  }

  return (
    <>
      <Prompt
        message={(location) => {
          const condition =
            !location.pathname.includes("create-definition") && isEdited;
          return getPromptStatus(condition, location);
        }}
      />
      <Definition
        id="productGrpngCrtDfnComp"
        prevScr={prevScr}
        type="create"
        routeOptions={routeOptions}
        setIsEdited={setIsEdited}
      />
    </>
  );
};

export default CreateDefintion;
