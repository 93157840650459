import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter, Link, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import {
  getDropdownValues,
  setHelpDesk,
} from "../../actions/tenantConfigActions";
import {
  setNotifications,
  setNotificationDND,
  setWebSocketClient,
} from "../../actions/notificationActions";
import AppBar from "@mui/material/AppBar";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LoopIcon from '@mui/icons-material/Loop';
import Notification from "../../pages/notifications/components/notifications-model";
import Popover from "@mui/material/Popover";
import { firebaseobj } from "../../commonComponents/auth/firebase";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { WEBSOCKET_URL } from "../../config/api";
import {
  Badge,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import globalStyles from "Styles/globalStyles";
import { setActiveUserApp } from "actions/sideBarActions";
import _ from "lodash";
import { height } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.common.white,
    border: "none",
    borderRadius: "0",
    boxShadow: "0px 1px 4px #00000029",
    padding: "0.75rem 1.5rem",
    position: "sticky",
    right: 0,
    zIndex: 800,
    top: 0,
    height: theme.customVariables.closedNavWidth,
  },
  navList: {
    margin: 0,
    gap: "0.375rem",
  },
  heading: {
    textDecoration: "none",
    color: theme.palette.textColours.codGray,
  },
  profile: {
    background: theme.palette.primary.dark,
    height: "3rem",
    marginRight: "1rem",
    width: "3rem",
  },
  userText: {
    alignSelf: "center",
    color: theme.palette.textColours.codGray,
    fontWeight: "normal",
  },
  headerIcons: {
    color: theme.palette.textColours.tiara,
  },
  notificationModel: {
    "& .MuiPopover-paper": {
      minWidth: "26rem",
      width: "30rem",
      minHeight: "30rem",
    },
  },
  notificationIcon: {
    marginRight: "0.5rem",
  },
}));
function Header(props) {
  const dispatch = useDispatch();
  const [unreadCount, setUnreadCount] = useState(0);
  const [dnd, setDnd] = useState(false);
  const { notificationData } = useSelector(
    (store) => store?.notificationReducer
  );
  const [notifications, setNotificationsData] = useState({});
  let userName = localStorage.getItem("name")
    ? localStorage.getItem("name").split("@")[0]
    : "User";
  const shortName = userName
    .split(".")
    .map((item) => item[0].toUpperCase())
    .join("");
  userName = userName
    .split(".")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [webSocketClient, setWsClient] = useState(null);
  const [isCalled, setIsCalled] = useState(false);
  const [open, setOpen] = useState(false);
  const [refreshOpen, setRefreshOpen] = useState(false)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const connectWebsocket = async () => {
    const user = firebaseobj.auth().currentUser;
    let token = await user.getIdToken();
    const client = null;

    new W3CWebSocket(`${WEBSOCKET_URL}&token=${token}`);

    client.onopen = () => {
      props.setWebSocketClient(client);
    };

    /**
     * @desc Callback function to handle data when recieved through WebScoket
     * @param {Object} message
     */
    client.onmessage = (message) => {
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer.data) {
        if (!dataFromServer.page) {
          props.setNotificationDND(dataFromServer.data.dnd);
        } else {
          // Update the latest recieved notifications
          let updateNotifications = {
            notifications: dataFromServer.data.notifications,
            unread_count: dataFromServer.data.unread_count,
            dnd: dataFromServer.data.dnd,
            total: dataFromServer.total,
          };
          props.setNotifications(updateNotifications);
          setNotificationsData(updateNotifications.notifications);
        }
      }

      if (dataFromServer.unread_count || dataFromServer.unread_count === 0) {
        setUnreadCount(dataFromServer.unread_count);
      }
    };
    client.onclose = () => {
      // convert date to a string in UTC timezone format:
      connectWebsocket();
    };
    //setWsClient(client);
  };

  // var createGuest = require("cross-domain-storage/guest");

  // var bazStorage = createGuest("https://sandbox.cluster.impactsmartsuite.com");

  const createNotification = (notification) => {
    // console.log("Create Notifiaction Called", notificationData, notification);
    // // localStorage.setItem("notification", JSON.stringify(notification));
    // if (Object.keys(notificationData).length > 0) {
    //   const info = _.uniqBy(
    //     notification["data"]["info"].concat(notificationData["info"]),
    //     "description"
    //   );
    //   const all = _.uniqBy(
    //     notification["data"]["all"].concat(notificationData["all"]),
    //     "description"
    //   );
    //   notification["data"]["info"] = info;
    //   notification["data"]["all"] = all;
    // }
    // let infoCount = notification?.data["info"]?.length || 0;
    // let actionCount = notification?.data["action"]?.length || 0;
    // let oldCount = notification?.data["old"]?.length || 0;
    // let allCount = infoCount + actionCount + oldCount;
    // console.log("New Notiiaction data", notification);
    if (window.location.pathname.includes("plan-smart")) {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: {
          results: {
            all: [
              {
                status: "unread",
                subject: "Updates",
                description:
                  "The IA Forecast is updated on 4/12/2023, 10:30am EST",
                action: {},
              },
            ],
            info: [
              {
                status: "unread",
                subject: "Updates",
                description:
                  "The IA Forecast is updated on 4/12/2023, 10:30am EST",
                action: {},
              },
            ],
            action: [],
          },
          count: 1,
          unreadCount: 1,
          dnd: false,
        },
      });
    } else {
      dispatch({
        type: "SET_NOTIFICATIONS",
        payload: {},
      });
    }
    // bazStorage.remove("plan-smart-ulta", function (error, removeValue) {
    //   console.log("key Removed", error, removeValue);
    // });
  };
  const getNoitificationData = () => {
    // bazStorage.get("plan-smart-ulta", function (error, value) {
    //   const notification = JSON.parse(value || "{}");
    //   // const localNotification = JSON.parse((localStorage.getItem("notification") ||  "{}" ))
    //   const localNotification =
    //     (Array.isArray(notificationData) ? {} : notificationData) || {};
    //   // console.log(notification,localNotification , _.isEqual(notification, localNotification))
    //   if (
    //     Object.keys(notification).length > 0 &&
    //     Object.keys(localNotification).length === 0
    //   ) {
    //     createNotification(notification);
    //   } else if (
    //     Object.keys(notification).length > 0 &&
    //     Object.keys(localNotification).length > 0 &&
    //     !_.isEqual(notification, localNotification)
    //   ) {
    //     createNotification(notification);
    //   }
    // });
  };

  useEffect(async () => {
    //connectWebsocket();
    const getHelpDesk = async () => {
      // let helpDeskResponse = await props.getDropdownValues(2, {
      //   attribute_name: "help_desk_url",
      // });
      let helpDeskResponse = [
        {
          name: "help_desk_url",
          attribute_value: {
            value:
              "https://impactanalytics.mojohelpdesk.com/login/create_request#/ticket-form/66734",
          },
        },
      ];
      props.setHelpDesk(helpDeskResponse?.attribute_value?.value);
    };
    getHelpDesk();
    createNotification();
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setUnreadCount(props.notificationUnreadCount);
  }, [props.notificationUnreadCount]);

  useEffect(() => {
    setDnd(props.dnd);
  }, [props.dnd]);
  useEffect(() => {
    setNotificationsData(props.notificationData);
  }, [props.notificationData]);

  const openSupportLinkInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const handleCloseNotifPopup = () => {
    setOpen(false);
  };

  const handleRefreshClick = (event) => {
    setRefreshOpen(true); 
  }

  const handleCloseRefreshPopup = () => {
    setRefreshOpen(false); 
  }
  return (
    <AppBar position="fixed" className={classes.header}>
      <Dialog
        open={refreshOpen}
        onClose={handleCloseRefreshPopup}
        aria-describedby="refresh-dialog-description"
        maxWidth="1000px"
      >
        <DialogTitle>
          <Typography variant="h3" sx={{textAlign: "center"}}>You are about to trigger the refresh process</Typography>
        </DialogTitle>
        <DialogContent sx={{marginTop: "20px", marginBottom: "40px"}}>
          <Typography variant="h5" sx={{textAlign: "center"}}>This would take the tool down for sometime</Typography>
          <Typography variant="h5" sx={{textAlign: "center"}}>Click on confirm to proceed</Typography>
        </DialogContent>
        <DialogActions sx={{display: "flex",alignItems: "center", justifyContent: "center"}}>
          <Button variant="contained" onClick={handleCloseRefreshPopup} >Confirm</Button>
          <Button onClick={handleCloseRefreshPopup}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleCloseNotifPopup}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="1000px"
      >
        <DialogTitle>{"IA Forecast - Key Driver Analysis"}</DialogTitle>
        <DialogContent>
          <img
            src={require("../../assets/ia_forecast_notif.png")}
            style={{ width: "700px", height: "600px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotifPopup}>Close</Button>
        </DialogActions>
      </Dialog>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter}`}
      >
        <Link to="/home" className={classes.heading}>
          <Typography component="h1" variant="h3">
            {props.title}
          </Typography>
        </Link>
        <ul
          className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter} ${classes.navList}`}
        >
         <li title="Refresh">
            <IconButton
              aria-label="refresh"
              className={classes.notificationIcon}
              onClick={handleRefreshClick}
              size="large"
            >
              <Badge badgeContent={dnd ? null : unreadCount} color="error">
                <LoopIcon className={classes.headerIcons} />
              </Badge>
            </IconButton>
          </li> 
          {
            <li title="Raise support ticket">
              <IconButton
                aria-label="raise support ticket"
                onClick={() => openSupportLinkInNewTab(props.helpDeskUrl)}
              >
                <HelpOutlineIcon className={classes.headerIcons} />
              </IconButton>
            </li>
          }
          <li title="Notification">
            <IconButton
              aria-label="notification"
              className={classes.notificationIcon}
              onClick={handleClick}
              size="large"
            >
              <Badge badgeContent={dnd ? null : unreadCount} color="error">
                <NotificationsIcon className={classes.headerIcons} />
              </Badge>
            </IconButton>
          </li>
          <li
            className={`${globalClasses.flexRow} ${globalClasses.layoutAlignCenter}}`}
          >
            <Avatar className={classes.profile} color="primary">
              {shortName}
            </Avatar>
            <Typography
              className={classes.userText}
              component="span"
              variant="h4"
              title={userName}
            >
              {" "}
              Hi, {userName}
            </Typography>
          </li>
          <Popover
            id="simple-menu"
            anchorEl={anchorEl}
            placement="bottom"
            className={classes.notificationModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Notification
              characterLimit={true}
              {...props}
              onClose={handleClose}
              setOpen={setOpen}
              websocket={webSocketClient}
            ></Notification>
          </Popover>
        </ul>
      </div>
    </AppBar>
  );
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notificationUnreadCount: state.notificationReducer.notificationUnreadCount,
  dnd: state.notificationReducer.dnd,
  notificationData: state.notificationReducer.notificationData,
  helpDeskUrl: state.tenantConfigReducer.helpDesk || "",
});

export default connect(mapStateToProps, {
  logoutUser,
  setNotificationDND,
  setNotifications,
  setWebSocketClient,
  getDropdownValues,
  setActiveUserApp,
  setHelpDesk,
})(withRouter(Header));
