import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../Utils/Loader/loader";
import "./ProductFilter.scss";
import moment from "moment";
import { Button, Typography, IconButton, Container } from "@mui/material";
import Switch from "../../Utils/switch";
import AddIcon from "@mui/icons-material/Add";
import makeStyles from "@mui/styles/makeStyles";
import CreateProductModal from "./components/create-product";
import ConfirmBox from "../../Utils/reactTable/components/confirmPopup";
import {
  getProductStatusData,
  getProductStatusStyleData,
  setStatusData,
  createNewProduct,
  setUpdateStatusData,
  getStatusData,
} from "../../actions/productStoreStatusActions";
import Modify from "./components/modify";
import { END_DATE } from "../../config/constants";
import { Prompt } from "react-router";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import SetAllMultiRow from "Utils/reactTable/components/setall-multirow-form";
import {
  uniq,
  isEmpty,
  capitalize,
  cloneDeep,
  isUndefined,
  isEqual,
} from "lodash";
import {
  dateValidationMessage,
  isDateRangeConflict,
} from "Utils/functions/helpers/validation-helpers";
import { getColumnsAg } from "../../actions/tableColumnActions";
import AgGridComponent from "Utils/agGrid";
import ConflictResolutionModal from "pages/storeStatus/components/conflict-resolution-modal";
import CellRenderers from "Utils/agGrid/cellRenderer";
import Delete from "@mui/icons-material/Delete";
import colours from "Styles/colours";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  formatAttribute,
  checkConflictInAttributeType,
  getUpdatedSetAllData,
  getListOptions,
} from "pages/storeStatus/utils";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { setDisabledDates } from "Utils/functions/utils";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import { setFilterConfiguration } from "actions/filterAction";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    padding: "0px",
  },
}));

function ProductsFilter(props) {
  const [filterData, setData] = useState([]);
  const [showloader, setloader] = useState(true);
  // Style Level
  const [styleColumns, setStyleColumns] = useState([]);
  const [selectedStyleRowsIDs, setSelectedStyleRowsIDs] = useState([]);
  // Product Level
  const [productColumns, setProductColumns] = useState([]);
  const [selectedProductRowsIDs, setSelectedProductRowsIDs] = useState([]);
  // Create Product
  const [createProduct, showCreateProduct] = useState(false);
  const [modifyProduct, showModifyProduct] = useState(false);
  // Modify Product
  const [defaultFilterDataSet, setDefaultDataSet] = useState([]);
  const [allColumnsName, setHeaders] = useState([]);
  const [checked, setChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [flag_edit, setFlag_edit] = useState(false);
  const [setAllData, updateSetAllData] = useState([]);
  const [setAll, toggleSetAll] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [showStyleLevelData, setShowStyleLevelData] = useState(true);
  const [showCreateProductBtn, setShowCreateProductBtn] = useState(true);
  // stores the action to be performed after unsaved changes modal confirm click
  const [onConfirmAction, setOnConfirmAction] = useState({ action: null });
  const classes = useStyles();
  const globalClasses = globalStyles();
  const onFilterDependency = useRef([]);
  const tableInstance = useRef({});

  const [resolutionType, setResolutionType] = useState("hard_reset");
  const [deleteActionObj, setDeleteActionObj] = useState({});
  const [editActionObj, setEditActionObj] = useState({});

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  // called when delete icon is clicked and delete object is set
  useEffect(() => {
    if (!isEmpty(deleteActionObj)) {
      updateSetAllDataHandler(deleteActionObj, "delete");
    }
  }, [deleteActionObj]);

  // called when edit icon is clicked and edit object is set
  useEffect(() => {
    if (!isEmpty(editActionObj)) {
      updateSetAllDataHandler(editActionObj, "edit");
    }
  }, [editActionObj]);

  const updateSetAllDataHandler = (inputActionObj, updateActionType) => {
    const { isEdited, updatedSetAllData } = getUpdatedSetAllData(
      inputActionObj,
      updateActionType,
      setAllData
    );

    if (isEdited) {
      // if attribute present then update in existing object
      updateSetAllData(updatedSetAllData);
    } else {
      //else add new attribute object
      updateSetAllData([...setAllData, inputActionObj]);
    }
  };
  useEffect(() => {
    const getInitialData = async () => {
      try {
        let headers = [];
        // fetch table columns
        let cols = await getColumnsAg("table_name=product_status")();
        let styleCols = await getColumnsAg("table_name=product_status_style")();
        const statusValues = await getStatusData("product");

        styleCols = styleCols.map((item) => {
          if (item.column_name === "status") {
            item.options = getListOptions(statusValues);
          }
          if (item.column_name === "type") {
            item.options = [
              { label: "Season", id: "season" },
              { label: "fashion", id: "fashion" },
            ];
          }
          return item;
        });
        let permissionCheckToDisable = canTakeActionOnModules(
          INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_STATUS,
          "edit"
        );
        cols = cols.map((item) => {
          if (item.column_name === "status") {
            item.showFilter = true;
            item.options = getListOptions(statusValues);
          }
          if (item.column_name === "type") {
            item.showFilter = true;
            item.options = [
              { label: "Season", id: "season", value: "season" },
              { label: "fashion", id: "fashion", value: "fashion" },
            ];
          }
          if (item.type === "datetime") {
            item.disablePast = false;
            item.shouldDisableDate = setDisabledDates;
          }
          if (item.column_name === "product_code") {
            item.cellRenderer = "agGroupCellRenderer";
          }
          headers.push(item.column_name);
          return item;
        });

        // adding custom renderer on parent group row to aggregate status and type
        cols = cols.map((item) => {
          if (item.column_name === "status") {
            item.disabled = !permissionCheckToDisable;
            item.cellRenderer = (params, extraProps) => {
              if (params.node.level === 0) {
                const valueSet = params.data.status_obj
                  ? uniq(
                      params.data.status_obj.map((value) =>
                        capitalize(value.status)
                      )
                    )
                  : [];
                return multiValueAggregate(valueSet);
              } else {
                return (
                  <CellRenderers
                    cellData={params}
                    column={item}
                    extraProps={extraProps}
                    actions={null}
                  ></CellRenderers>
                );
              }
            };
          } else if (item.type === "datetime") {
            item.disabled = !permissionCheckToDisable;
            item.cellRenderer = (params, extraProps) => {
              if (params.node.level !== 0) {
                return (
                  <CellRenderers
                    cellData={params}
                    column={item}
                    extraProps={extraProps}
                    actions={null}
                  ></CellRenderers>
                );
              } else return "";
            };
          }
          return item;
        });

        // pushing a column for delete action
        cols.push({
          headerName: "Delete",
          maxWidth: 95,
          cellRenderer: (params, extraProps) => {
            if (params.node.level !== 0) {
              return (
                <div>
                  <IconButton
                    variant="text"
                    color="primary"
                    className={classes.actionButton}
                    onClick={() => onDeleteClick(params)}
                    disabled={
                      !canTakeActionOnModules(
                        INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_STATUS,
                        "delete"
                      )
                    }
                    title="Delete"
                    size="large"
                  >
                    <Delete />
                  </IconButton>
                </div>
              );
            } else {
              return null;
            }
          },
          editable: false,
          colId: "action",
        });

        styleCols = styleCols.map((item) => {
          if (item.column_name === "status") {
            item.cellRenderer = (params) => {
              const value = params.data[item.column_name];
              return value ? value : "-";
            };
          }
          return item;
        });
        const data = props?.roleBasedAccess
          ? await fetchFilterFieldValues(
              "product status",
              [],
              true,
              props.screenName,
              1
            )
          : await fetchFilterFieldValues("product status", []);
        if (isEmpty(props.filterDashboardConfiguration)) {
          let filterConfigData = [
            {
              filterDashboardData: data,
              expectedFilterDimensions: ["product"],
              screenDimension: "product",
              isCrossDimensionFilter: false,
            },
          ];
          if (props?.roleBasedAccess) {
            filterConfigData[0] = {
              ...filterConfigData[0],
              is_urm_filter: true,
              screen_name: props.screenName,
              application_code: 1,
            };
          }
          const filterConfig = formattedFilterConfiguration(
            "productStatusFilterConfiguration",
            filterConfigData,
            "Product Status"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          onFilterDependency.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        setProductColumns(cols);
        setStyleColumns(styleCols);
        setHeaders(headers);
        setDefaultDataSet(data);
        setData(data);
        setloader(false);

        let showStyleLevelDataResp =
          await props.getTenantConfigApplicationLevel(3, {
            attribute_name: "core_show_style_level_info",
          });

        if (showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowStyleLevelData(
            showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]?.value
          );
        }

        let showCreateProductBtnResp =
          await props.getTenantConfigApplicationLevel(3, {
            attribute_name: "core_show_create_product_store_dc",
          });

        if (showCreateProductBtnResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowCreateProductBtn(
            showCreateProductBtnResp?.data?.data?.[0]?.["attribute_value"]
              ?.value
          );
        }
      } catch (error) {
        setloader(false);
      }
    };
    getInitialData();
  }, []);

  const multiValueAggregate = (value) => {
    value = value.filter((val) => val !== null);
    return value.length > 0 ? value.join(`${","}${" "}`) : "-";
  };

  const getCellRenderer = (params, item, extraProps) => {
    return (
      <CellRenderers
        cellData={params}
        column={item}
        extraProps={extraProps}
        actions={null}
      ></CellRenderers>
    );
  };

  const productStatusManualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    let body = {
      filters: onFilterDependency.current,
      meta: {
        ...manualbody,
        search: manualbody.search,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      headers: [],
      selection: {
        data: tableInstance?.current?.api?.checkConfiguration,
        unique_columns: ["product_code"],
      },
    };
    try {
      const response = await getProductStatusData("product", body)();
      response.data.data = formatProductStatusData(response.data.data);
      setloader(false);
      return {
        data: response?.data.data,
        totalCount: response?.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      setloader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const productStyleManualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    let body = {
      filters: onFilterDependency.current,
      meta: {
        ...manualbody,
        search: manualbody.search,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      headers: [],
      selection: {
        data: tableInstance?.current?.api?.checkConfiguration,
        unique_columns: ["style", "l3_name"],
      },
    };
    try {
      let response = await getProductStatusStyleData("product", body)();
      response.data.data = response.data.data.map((item) => {
        item.style_id = `${item.style}${","}${item.l3_name}`;
        return item;
      });
      setloader(false);
      return {
        data: response?.data.data,
        totalCount: response?.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      setloader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const formatProductStatusData = (productData) => {
    return productData.map((item) => {
      item.reference_product_codes = item.reference_product_codes[0];
      item.replacement_product_codes = item.replacement_product_codes[0];
      item.isDisabled = false;
      item.hasConflict = false;
      return item;
    });
  };

  const unsavedChangeCheck = (confirmAction) => {
    if (setAllData.length) {
      setOnConfirmAction({ action: confirmAction });
      showConfirmBox(true);
      throw Error("Unsaved changes");
    }
  };

  const onFilter = async (unsavedCheck) => {
    unsavedCheck && unsavedChangeCheck(() => onFilter(false));

    let body = {
      meta: {
        range: [],
        sort: [],
        search: [],
        limit: { limit: 10, page: 0 },
      },
    };
    tableInstance.current.api?.refreshServerSideStore({ purge: true });
    if (checked) {
      await productStyleManualCallBack(body.meta, 0);
    } else {
      await productStatusManualCallBack(body.meta, 0);
    }
  };

  const onStyleSwitch = async (event) => {
    const checkChecked = event.target.checked;
    unsavedChangeCheck(() => setChecked(checkChecked));
    setChecked(checkChecked);
  };

  const setAllChanges = async (formattedAttributes) => {
    let setAllBody = {
      attributes: formattedAttributes,
      codes: [],
    };

    setAllBody.codes = {
      filters: onFilterDependency.current,
      meta: {
        range: [],
        sort: [],
        search: [],
      },
      headers: [],
      selection: {
        data: tableInstance?.current?.api?.checkConfiguration,
        unique_columns: [],
      },
    };

    if (checked) {
      //style table
      setAllBody.codes.selection.unique_columns = ["style", "l3_name"];
    } else {
      //product table
      setAllBody.conflict_resolution = resolutionType;
      setAllBody.codes.selection.unique_columns = ["product_code"];
    }

    toggleSetAll(false);
    // patch api call for set all action
    await onConfirm(setAllBody, true);
  };

  const dateValidation = (startDate, endDate) => {
    const startdate = new Date(startDate);
    const enddate = new Date(endDate);
    const errMessage = dateValidationMessage(startdate, enddate);
    if (errMessage) {
      displaySnackMessages(errMessage, "error");
      throw Error("Date is not correct");
    }
  };

  const formatProductRow = (key, id, input) => {
    return {
      attribute_name: key,
      attribute_value: input[`${key}${id}`],
      start_time: input[`${key}${"_start_time"}${id}`],
      end_time:
        input[`${key}${"_end_time"}${id}`] &&
        input[`${key}${"_end_time"}${id}`] !== "Invalid date"
          ? input[`${key}${"_end_time"}${id}`]
          : END_DATE,
    };
  };

  /**
   * dynamically format multi row set-all data
   * data is formatted before apply action is called
   */
  const formatSetAllData = (input, rowCount) => {
    let setAllOutput = [];

    for (let i = 0; i <= rowCount; i++) {
      const str = "_" + i;
      if (input["status" + str]) {
        const statusRow = formatProductRow("status", str, input);
        dateValidation(statusRow.start_time, statusRow.end_time);
        setAllOutput.push(statusRow);
      }
      if (input["type" + str]) {
        const typeRow = formatProductRow("type", str, input);
        dateValidation(typeRow.start_time, typeRow.end_time);
        setAllOutput.push(typeRow);
      }
    }

    let statusDateRangeList = setAllOutput.filter(
      (item) => item.attribute_name === "status"
    );
    let typeDateRangeList = setAllOutput.filter(
      (item) => item.attribute_name === "type"
    );

    // checking if conflict exists in date ranges
    const statusHasConflict = isDateRangeConflict(
      statusDateRangeList,
      "YYYY-MM-DD",
      "[]"
    );
    const typeHasConflict = isDateRangeConflict(
      typeDateRangeList,
      "YYYY-MM-DD",
      "[]"
    );

    if (statusHasConflict || typeHasConflict) {
      displayConflictError();
    }

    return setAllOutput;
  };

  const saveRequest = () => {
    if (setAllData.length) {
      setShowModal(true);
    } else {
      displaySnackMessages("There is no change to save", "error");
    }
  };

  const displayConflictError = () => {
    displaySnackMessages("Please resolve overlapping date ranges", "error");
    throw Error("Date is overlapping");
  };

  // checks if conflict error is present in tabledata after edit/delete
  const isDateRangeConflictInData = () => {
    let hasConflictFlag = false;

    tableInstance.current.api.getRenderedNodes().forEach((item) => {
      if (item.data.hasConflict) {
        hasConflictFlag = true;
      }
    });

    if (hasConflictFlag) {
      displayConflictError();
    }
  };

  const onConfirm = async (payloadData, isSetAllAction) => {
    try {
      setloader(true);
      setShowModal(false);
      // set all patch api call
      isSetAllAction &&
        (await setStatusData(
          `${"product?level="}${checked ? "aggregate" : "product"}`,
          payloadData
        )());
      // edit/delete patch api call
      if (!isSetAllAction) {
        // check if conflicts present in data
        isDateRangeConflictInData();
        await setUpdateStatusData("product", { body: payloadData })();
      }

      setFlag_edit(false);
      tableInstance.current.api.deselectAll(true);
      updateSetAllData([]);
      setloader(false);
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
      const successMessage = `${
        checked ? "Style" : "Product"
      }${" "}${"updated successfully"}`;
      displaySnackMessages(successMessage, "success");
    } catch (err) {
      const errMsg = !isEmpty(err.response?.data.message)
        ? err.response.data.message
        : "Unsuccessfull at updating product attributes";
      displaySnackMessages(errMsg, "error");
      setloader(false);
    }
  };

  const handleConfirmBox = () => {
    tableInstance.current.api?.refreshServerSideStore({ purge: false });
    tableInstance.current.api.deselectAll(true);
    onConfirmAction.action?.();
    updateSetAllData([]);
    setFlag_edit(false);
    showConfirmBox(false);
    setOnConfirmAction({ action: null });
  };

  const createNewProducFunc = async (dataObj, newSetAllData) => {
    setloader(true);
    try {
      let body = [];
      let setAllBody = {
        attributes: [
          {
            attribute_name: "status",
            attribute_value: newSetAllData.status,
            start_time: moment(newSetAllData.start_time).format("YYYY-MM-DD"),
            end_time: moment(newSetAllData.end_time).format("YYYY-MM-DD"),
          },
          {
            attribute_name: "type",
            attribute_value: newSetAllData.type,
            start_time: moment(newSetAllData.start_time).format("YYYY-MM-DD"),
            end_time: moment(newSetAllData.end_time).format("YYYY-MM-DD"),
          },
        ],
        codes: [dataObj.product_code],
      };
      Object.keys(dataObj).forEach((key) => {
        if (key !== "product_code" && dataObj[key]) {
          let obj = {
            attribute_name: key,
            attribute_value: dataObj[key],
          };
          body.push(obj);
        }
      });
      let reqBody = {
        attributes: body,
        code: dataObj.product_code,
      };

      dateValidation(
        setAllBody.attributes[0].start_time,
        setAllBody.attributes[0].end_time
      );

      if (dataObj.product_code) {
        await createNewProduct("product", reqBody)();
        if (Object.keys(newSetAllData).length > 0)
          await setStatusData("product", setAllBody)();
        displaySnackMessages("Product created successfully", "success");
        onFilter();
        return true;
      } else {
        displaySnackMessages("Please enter Product Id", "error");
      }
      setloader(false);
    } catch (err) {
      displaySnackMessages(
        "Failed to create a product. Please try again with different product ID",
        "error"
      );
      setloader(false);
    }
  };

  const checkBoxDisplayHandler = (instance) => {
    return !(
      instance.row.depth > 0 ||
      (instance.row.original && instance.row.original.hideCheckBox)
    );
  };

  /**
   * set editable product and style fields and pass to set-all component
   */
  const editableFieldsList = React.useMemo(() => {
    const cloneProductColumns = cloneDeep(productColumns);
    let editableProductFields = cloneProductColumns.filter(
      (item) => !item.is_hidden && item.is_editable && !item.system_field
    );

    editableProductFields = editableProductFields.map((item) => {
      if (item.type === "datetime") {
        item.type = "DateTimeField";
        item.disablePast = true;
      }
      return item;
    });

    let setAllFieldsList = [
      {
        fields: [
          editableProductFields[0],
          editableProductFields[1],
          editableProductFields[2],
        ],
        addRowLabel: "Add Status",
        id: "status",
        rowCount: 0,
      },
    ];

    setAllFieldsList = setAllFieldsList.filter((item) => {
      let flag = true;
      item.fields.forEach((fieldValue) => {
        if (isUndefined(fieldValue)) flag = false;
      });

      return flag;
    });

    return setAllFieldsList;
  }, [productColumns, styleColumns]);

  const onClickModify = () => {
    const rowsSelected = checked
      ? selectedStyleRowsIDs.length
      : selectedProductRowsIDs.length;
    const levelType = checked ? "style" : "product";
    if ((checked && rowsSelected) === 1 || (!checked && rowsSelected) === 1) {
      unsavedChangeCheck(() => showModifyProduct(true));
      showModifyProduct(true);
    } else {
      // generate message based on rows selected and level type
      let message = "";
      if (rowsSelected === 0) {
        // no rows selected
        message = `${"Please select a"}${" "}${levelType}`;
      } else {
        // multiple rows selected
        message = `${"Please select a single"}${" "}${levelType}`;
      }
      displaySnackMessages(message, "error");
    }
  };

  const onProductSelectionChanged = (event) => {
    let selections = event.api.getSelectedRows().map((item) => {
      return {
        product_code: item.product_code,
      };
    });
    setSelectedProductRowsIDs(selections);
  };

  const onStyleSelectionChanged = (event) => {
    let selections = event.api.getSelectedRows().map((item) => {
      return {
        style: item.style,
        codes: item.products.map((product) => product.product_code),
      };
    });
    setSelectedStyleRowsIDs(selections);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  // function is called on delete action in table
  const onDeleteClick = (event) => {
    const attributeId = event.data.time_attr_id;
    const attributeRowNode = event.node.parent;
    const parentRowNode = event.node.parent;
    const attributeType = "status";

    // removing deleted status
    const updated_attribute_obj = parentRowNode.data[
      `${attributeType}${"_obj"}`
    ].filter((item) => item.time_attr_id != attributeId);

    // updating parent row sub rows data
    const updated_data = {
      ...parentRowNode.data,
      [`${attributeType}${"_obj"}`]: isEmpty(updated_attribute_obj)
        ? null
        : updated_attribute_obj,
    };

    parentRowNode.setData(updated_data);
    conflictResolutionCheck(parentRowNode);
    const setAllObject = formatAttribute(
      parentRowNode.id,
      event,
      attributeType,
      "delete"
    );
    setDeleteActionObj(setAllObject);
    setFlag_edit(true);
    // table actions
    attributeRowNode.setExpanded(false);
    tableInstance.current.api.flashCells({ rowNodes: [attributeRowNode] });
    if (updated_data[`${attributeType}${"_obj"}`])
      attributeRowNode.setExpanded(true);
  };

  // checks and manages conflict error in store status data
  const conflictResolutionCheck = (parentRowNode) => {
    const currentDateRangeConflict = parentRowNode.data.hasConflict;
    const statusObj = cloneDeep(parentRowNode.data.status_obj);
    const typeObj = cloneDeep(parentRowNode.data.type_obj);
    let statusDateRangeConflict = false;
    let typeDateRangeConflict = false;

    if (statusObj) {
      statusDateRangeConflict = checkConflictInAttributeType(
        statusObj,
        "status"
      );
    }
    if (typeObj) {
      typeDateRangeConflict = checkConflictInAttributeType(typeObj, "type");
    }

    if (statusObj || typeObj) {
      if (
        currentDateRangeConflict === false &&
        (statusDateRangeConflict || typeDateRangeConflict)
      ) {
        parentRowNode.setData({ ...parentRowNode.data, hasConflict: true });
        displaySnackMessages("Please resolve overlapping date ranges", "error");
      } else if (
        currentDateRangeConflict === true &&
        !statusDateRangeConflict &&
        !typeDateRangeConflict
      ) {
        parentRowNode.setData({ ...parentRowNode.data, hasConflict: false });
        displaySnackMessages(
          "Successfully resolved overlapping date ranges",
          "success"
        );
      }
    }
  };

  // function is called on edit action in table
  const onCellValueChanged = (params) => {
    const parentRowNode = params.node.parent;
    let isInputValueSame = false;

    if (moment.isMoment(params.newValue)) {
      isInputValueSame = moment(params.newValue).isSame(params.oldValue);
    } else {
      isInputValueSame = isEqual(params.oldValue, params.newValue);
    }

    if (!isInputValueSame) {
      const attributeType = "status";
      conflictResolutionCheck(parentRowNode);
      const setAllObject = formatAttribute(
        parentRowNode.id,
        params,
        attributeType,
        "edit"
      );
      setEditActionObj(setAllObject);
      // table actions
      tableInstance.current.api.refreshCells({
        force: true,
        suppressFlash: false,
        rowNodes: [parentRowNode],
      });
      setFlag_edit(true);
      tableInstance.current.api.flashCells({ rowNodes: [parentRowNode] });
    }
  };

  // row highlighting based on conflict present in status
  const getRowStyle = (params) => {
    if (params.node.level === 0 && params.node.data) {
      if (params.node.data.hasConflict) return { background: colours.wispPink };
    }

    return null;
  };

  const onFilterDashboardClick = (dependencyData) => {
    onFilterDependency.current = dependencyData;
    onFilter(true);
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      // inventorysmartModulesPermission, module  will be undefined in work flow input center flow
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    return (
      <CoreComponentScreen
        pageLabel={dynamicLabelsBasedOnTenant("product_status", "core")}
        showPageRoute={props.hideBreadCrumbs ? false : true}
        showPageHeader={true}
        routeOptions={[
          {
            label: "Product Status",
            id: 1,
            action: () => {
              props.history.push("/product-status");
            },
          },
        ]}
        // Filter dashboard props
        showFilterDashboard={true}
        filterConfigKey={"productStatusFilterConfiguration"}
        onApplyFilter={onFilterDashboardClick}
      >
        <Loader loader={showloader}>
          <div
            style={{ display: modifyProduct ? "none" : "" }}
            data-testid="filterContainer"
          >
            <Prompt when={flag_edit} message={""} />
            <ConfirmPrompt
              showModal={showModal}
              title="Confirm Changes"
              message="Are you sure to save all your changes?"
              ariaLabeledBy="save-changes-dialog"
              primaryBtnText="Update"
              secondaryBtnText="Close"
              showCloseIcon={true}
              setConfirm={setShowModal}
              confirmCallback={(val) => {
                if (val) {
                  onConfirm(setAllData, false);
                }
              }}
            />

            {confirmBox && (
              <ConfirmBox
                onClose={() => {
                  showConfirmBox(false);
                }}
                onConfirm={() => handleConfirmBox()}
              />
            )}
            {createProduct && (
              <CreateProductModal
                onApply={createNewProducFunc}
                toggleError={(errMsg) => {
                  displaySnackMessages(errMsg, "error");
                }}
                handleModalClose={() => showCreateProduct(false)}
                accessData={[]}
              ></CreateProductModal>
            )}

            {productColumns.length > 0 && (
              <div data-testid="resultContainer">
                <Container maxWidth={false}>
                  <div
                    className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
                  >
                    <Typography variant="h6" gutterBottom>
                      {checked
                        ? "Filtered Style"
                        : `Filtered ${dynamicLabelsBasedOnTenant(
                            "product",
                            "core"
                          )}`}
                    </Typography>
                    <div>
                      {dynamicLabelsBasedOnTenant("product", "core")}
                      &nbsp;level
                      {showStyleLevelData && (
                        <>
                          <Switch
                            className="switch"
                            id="productToggleBtn"
                            checked={checked}
                            onChange={onStyleSwitch}
                            // disabled={true}
                          ></Switch>
                          Style level
                        </>
                      )}
                    </div>
                    <div
                      className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.layoutAlignBetweenCenter}`}
                    >
                      {showCreateProductBtn && (
                        <Button
                          variant="contained"
                          color="primary"
                          id="createProductBtn"
                          onClick={() => showCreateProduct(true)}
                          disabled={false}
                        >
                          <AddIcon fontSize="small"></AddIcon>{" "}
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        id="productSetAllBtn"
                        onClick={async () => {
                          if (setAllData.length) {
                            showConfirmBox(true);
                          } else if (
                            (checked && selectedStyleRowsIDs.length > 0) ||
                            (!checked && selectedProductRowsIDs.length > 0)
                          ) {
                            toggleSetAll(true);
                          } else {
                            const errMsg = checked
                              ? "Please select atleast one style"
                              : "Please select atleast one product";
                            displaySnackMessages(errMsg, "error");
                          }
                        }}
                        disabled={
                          !canTakeActionOnModules(
                            INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_STATUS,
                            "edit"
                          )
                        }
                      >
                        Set All
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        id="productModifyBtn"
                        onClick={() => onClickModify()}
                        disabled={true}
                      >
                        Modify
                      </Button> */}
                    </div>
                  </div>
                  {setAll && (
                    <SetAllMultiRow
                      onApply={setAllChanges}
                      fieldList={editableFieldsList}
                      handleModalClose={() => toggleSetAll(false)}
                      formatMultiRowData={formatSetAllData}
                      additionalContainer={
                        !checked ? (
                          <ConflictResolutionModal
                            resolutionType={resolutionType}
                            setResolutionType={setResolutionType}
                          />
                        ) : null
                      }
                    />
                  )}
                  {checked && (
                    <AgGridComponent
                      columns={styleColumns}
                      selectAllHeaderComponent={true}
                      onSelectionChanged={onStyleSelectionChanged}
                      sizeColumnsToFitFlag
                      onGridChanged
                      manualCallBack={(body, pageIndex, params) =>
                        productStyleManualCallBack(body, pageIndex, params)
                      }
                      rowModelType="serverSide"
                      serverSideStoreType="partial"
                      cacheBlockSize={10}
                      uniqueRowId={"style_id"}
                      hideChildSelection={true}
                      loadTableInstance={setNewTableInstance}
                      showSetAll={false}
                      purgeClosedRowNodes={true}
                      suppressAggFuncInHeader={true}
                      rowSelection={"multiple"}
                      onRowSelected
                    />
                  )}
                  {!checked && (
                    <AgGridComponent
                      columns={productColumns}
                      selectAllHeaderComponent={true}
                      onSelectionChanged={onProductSelectionChanged}
                      sizeColumnsToFitFlag
                      onGridChanged
                      manualCallBack={(body, pageIndex, params) =>
                        productStatusManualCallBack(body, pageIndex, params)
                      }
                      rowModelType="serverSide"
                      serverSideStoreType="partial"
                      cacheBlockSize={10}
                      uniqueRowId={"product_code"}
                      hideChildSelection={true}
                      loadTableInstance={setNewTableInstance}
                      showSetAll={false}
                      purgeClosedRowNodes={true}
                      suppressAggFuncInHeader={true}
                      rowSelection={"multiple"}
                      suppressClickEdit={true}
                      onCellValueChanged={onCellValueChanged}
                      getRowStyle={getRowStyle}
                      groupDisplayType={"custom"}
                      treeData={true}
                      childKey={"status_obj"}
                      onRowSelected
                    />
                  )}
                </Container>
              </div>
            )}
            <div
              className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
            >
              <Button
                variant="contained"
                color="primary"
                id="productSaveBtn"
                onClick={() => {
                  saveRequest();
                }}
                disabled={
                  !canTakeActionOnModules(
                    INVENTORY_SUBMODULES_NAMES.INVENTORY_PRODUCT_STATUS,
                    "edit"
                  )
                }
              >
                Save
              </Button>
              <Button
                variant="outlined"
                color="primary"
                id="productCancelBtn"
                onClick={() => {
                  unsavedChangeCheck(null);
                  tableInstance?.api.deselectAll(true);
                  displaySnackMessages("There is no new change.", "error");
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Loader>
        {modifyProduct && (
          <div>
            <Modify
              defaultDataSet={defaultFilterDataSet}
              cols={checked ? styleColumns : productColumns}
              allColumnsName={allColumnsName}
              selectedProducts={
                checked ? selectedStyleRowsIDs[0] : selectedProductRowsIDs[0]
              }
              goBack={(showSuccess) => {
                if (showSuccess) {
                  displaySnackMessages(
                    "Product mapped successfully",
                    "success"
                  );
                }
                showModifyProduct(false);
                // manualCallBack(
                //   tableFilterBody,
                //   checked ? productCurrentPage : styleCurrentPage
                // );
              }}
              checkBoxDisplayHandler={checkBoxDisplayHandler}
            ></Modify>
          </div>
        )}
      </CoreComponentScreen>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapDispatchToProps = {
  addSnack,
  getTenantConfigApplicationLevel,
  setFilterConfiguration,
};
const mapStateToProps = (state) => {
  return {
    selectedFilters: state.filterReducer.selectedFilters["product status"],
    userAccessList:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.userAccessList,
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "productStatusFilterConfiguration"
      ],
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsFilter);
