import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { addSnack } from "actions/snackbarActions";
import { useHistory } from "react-router-dom";
import moment from "moment";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";

import {
  DIALOG_CANCEL_BTN_TEXT,
  DIALOG_FINALIZE_BTN_TEXT,
  ERROR_MESSAGE,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getOrderBatchingTableConfiguration,
  getOrderBatchingTableData,
  updateOrderBatchingData,
  setInventorysmartOrderBatchingTableConfigLoader,
  setInventorysmartOrderBatchingTableDataLoader,
  setInventorysmartUpdateOrderBatchingLoader,
  setOrderBatchingTableConfig,
  setOrderBatchingTableData,
  finalizeOrderBatchingData,
} from "modules/inventorysmart/services-inventorysmart/Order-Batching/order-batching-services";
import { cloneDeep, isEmpty } from "lodash";
import { Button, Grid } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import { CREATE_ALLOCATION } from "modules/inventorysmart/constants-inventorysmart/routesConstants";
import {
  setSelectedFilters,
  setInventorySmartFinalizeFilterDependency,
  setRedirectedFrom,
} from "modules/inventorysmart/services-inventorysmart/Finalize/product-view-services";
import OrderBatchingSetAllModal from "./OrderBatchingSetAllModal";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const ViewCurrentAllocationsTables = (props) => {
  const globalClasses = globalStyles();
  const classes = useStyles();
  const history = useHistory();

  const articleTableGridInstance = useRef(null);

  const [orderBatchingTableColumns, setOrderBatchingTableColumns] = useState(
    []
  );
  const [orderBatchingTableData, setOrderBatchingData] = useState([]);
  const [orderTypeOptions, setOrderTypeOptions] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [showFinalizePopup, setShowFinalizePopup] = useState(false);
  const [showSetAllModal, setShowSetAllModal] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const redirectToFinalizeScreen = (value) => {
    setSelectedFilters(props.selectedFilters);
    setRedirectedFrom("Order Batching");
    setInventorySmartFinalizeFilterDependency(
      props.inventorysmartOrderBatchingFilterDependency
    );
    history.push(
      `${CREATE_ALLOCATION}?step=1&allocation_code=${value.allocation_code}`
    );
  };

  const actionMap = {
    allocation_name: redirectToFinalizeScreen,
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerValueGetter: (params) => `${params.colDef.headerName}`,
      minWidth: 220,
      cellRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  useEffect(() => {
    const fetchColumnConfig = async () => {
      props.setInventorysmartOrderBatchingTableConfigLoader(true);
      let columns = await props.getOrderBatchingTableConfiguration();
      props.setInventorysmartOrderBatchingTableConfigLoader(false);

      const orderTypeColumn = columns?.data?.data.find(
        (column) => column.column_name === "order_type"
      );

      setOrderTypeOptions([...orderTypeColumn?.extra?.options]);

      let formattedColumns = agGridColumnFormatter(
        columns?.data?.data,
        null,
        actionMap
      );
      setOrderBatchingTableColumns(formattedColumns);
    };
    fetchColumnConfig();
  }, []);

  useEffect(() => {
    !isEmpty(props.selectedFilters) && fetchOrderBatchingTableData();
  }, [props.selectedFilters]);

  const updateOrderStatus = async (articles, status) => {
    try {
      props.setInventorysmartUpdateOrderBatchingLoader(true);

      let changedArticles = [];
      for (let i = 0; i < articles?.length; i++) {
        if (
          articles[i].order_type.value !==
            props.orderBatchingData.data[i].order_type.value ||
          articles[i].delivery_dt !==
            props.orderBatchingData.data[i].delivery_dt
        ) {
          changedArticles.push(articles[i]);
        }
      }
      const orderArticles = changedArticles?.map((item) => {
        return {
          article: item.article,
          store: item.store,
          allocation_code: item.allocation_code,
          delivery_dt: item?.delivery_dt
            ? moment(item.delivery_dt).format("MM/DD/YYYY")
            : null,
          order_type: item?.order_type?.value
            ? item.order_type.value
            : item.order_type,
        };
      });

      let body = {
        payload: [...orderArticles],
        status,
      };
      let response = await props.updateOrderBatchingData(body);
      if (response.data.status) {
        if (status === 3) {
          const allocationCodes = [];
          orderBatchingTableData.forEach((article) => {
            if (allocationCodes.indexOf(article.allocation_code) === -1) {
              allocationCodes.push(article.allocation_code);
            }
          });

          const payload = allocationCodes.map((code) => {
            return { allocation_code: code };
          });

          const finalizeResponse = await props.finalizeOrderBatchingData({
            payload,
          });

          displaySnackMessages("Allocation has been finalized", "success");
        } else {
          displaySnackMessages("Allocation data has been updated", "success");
        }
        props.setInventorysmartUpdateOrderBatchingLoader(false);
        fetchOrderBatchingTableData();
        props.fetchOrderBatchingMetrics();
      } else {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setInventorysmartUpdateOrderBatchingLoader(false);
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setInventorysmartUpdateOrderBatchingLoader(false);
    }
  };

  const handleSave = () => {
    updateOrderStatus(orderBatchingTableData, 2);
  };

  const handleFinalizingOrder = async () => {
    updateOrderStatus(orderBatchingTableData, 3);
  };

  const fetchOrderBatchingTableData = async () => {
    try {
      props.setInventorysmartOrderBatchingTableDataLoader(true);
      let body = {
        filters: props.selectedFilters,
      };
      let response = await props.getOrderBatchingTableData(body);
      if (response.data.status) {
        response.data.data = response.data.data.map((item, index) => {
          item.allocation_index = index;
          return item;
        });
        setOrderBatchingData(cloneDeep(response.data.data));
        props.setOrderBatchingTableData(response.data);
        props.setInventorysmartOrderBatchingTableDataLoader(false);
        return { data: response.data, totalCount: response.data.total };
      } else {
        displaySnackMessages(ERROR_MESSAGE, "error");
        props.setInventorysmartOrderBatchingTableDataLoader(false);
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setInventorysmartOrderBatchingTableDataLoader(false);
      return [];
    }
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();

    if (selections?.length > 0) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }

    setSelectedArticles([...selections]);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const loadTableInstance = (params) => {
    articleTableGridInstance.current = params;
  };

  return (
    <>
      <Loader
        loader={
          props.inventorysmartOrderBatchingTableConfigLoader ||
          props.inventorysmartOrderBatchingTableDataLoader ||
          props.inventorysmartUpdateOrderBatchingLoader
        }
        minHeight={"120px"}
      >
        {!props.inventorysmartOrderBatchingTableConfigLoader &&
          !props.inventorysmartOrderBatchingTableDataLoader &&
          !props.inventorysmartUpdateOrderBatchingLoader && (
            <AgGridComponent
              columns={orderBatchingTableColumns}
              rowdata={orderBatchingTableData}
              selectAllHeaderComponent={true}
              onSelectionChanged={onSelectionChanged}
              rowSelection="multiple"
              autoGroupColumnDef={autoGroupColumnDef}
              groupDisplayType={"multipleColumns"}
              uniqueRowId={"allocation_index"}
              loadTableInstance={loadTableInstance}
            />
          )}

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={globalClasses.marginAround}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!buttonEnabled || !orderBatchingTableData?.length}
            className={classes.button}
            onClick={() => setShowSetAllModal(true)}
          >
            Set All
          </Button>
          <Button
            variant="contained"
            color="primary"
            id="productSetAllBtn"
            disabled={!orderBatchingTableData?.length}
            className={classes.button}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={globalClasses.marginAround}
        >
          <Button
            variant="contained"
            color="primary"
            id="productSetAllBtn"
            disabled={!orderBatchingTableData?.length}
            className={classes.button}
            onClick={() => setShowFinalizePopup(true)}
          >
            Finalize For Order Generation
          </Button>
        </Grid>
      </Loader>
      <ConfirmPrompt
        showModal={showFinalizePopup}
        message="Are you sure you want to finalize these allocations?"
        title="Confirm Finalizing Allocations"
        ariaLabeledBy="finalize-allocations-dialog"
        primaryBtnText={DIALOG_FINALIZE_BTN_TEXT}
        secondaryBtnText={DIALOG_CANCEL_BTN_TEXT}
        showCloseIcon={true}
        setConfirm={setShowFinalizePopup}
        confirmCallback={(val) => {
          if (val) {
            handleFinalizingOrder();
          }
        }}
      />
      {showSetAllModal && (
        <OrderBatchingSetAllModal
          showSetAllModal={showSetAllModal}
          orderTypeOptions={orderTypeOptions}
          setShowSetAllModal={setShowSetAllModal}
          agGridInstance={articleTableGridInstance.current}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedFilters:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .selectedFilters,
    inventorysmartOrderBatchingTableConfigLoader:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingTableConfigLoader,
    inventorysmartOrderBatchingTableDataLoader:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingTableDataLoader,
    inventorysmartUpdateOrderBatchingLoader:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartUpdateOrderBatchingLoader,
    orderBatchingData:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .orderBatchingTableData,
    inventorysmartOrderBatchingFilterDependency:
      store.inventorysmartReducer.inventorySmartOrderBatchingService
        .inventorysmartOrderBatchingFilterDependency,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOrderBatchingTableConfiguration: (payload) =>
    dispatch(getOrderBatchingTableConfiguration(payload)),
  getOrderBatchingTableData: (payload) =>
    dispatch(getOrderBatchingTableData(payload)),
  updateOrderBatchingData: (payload) =>
    dispatch(updateOrderBatchingData(payload)),
  finalizeOrderBatchingData: (payload) =>
    dispatch(finalizeOrderBatchingData(payload)),
  setInventorysmartOrderBatchingTableConfigLoader: (payload) =>
    dispatch(setInventorysmartOrderBatchingTableConfigLoader(payload)),
  setInventorysmartOrderBatchingTableDataLoader: (payload) =>
    dispatch(setInventorysmartOrderBatchingTableDataLoader(payload)),
  setInventorysmartUpdateOrderBatchingLoader: (payload) =>
    dispatch(setInventorysmartUpdateOrderBatchingLoader(payload)),
  setOrderBatchingTableConfig: (payload) =>
    dispatch(setOrderBatchingTableConfig(payload)),
  setOrderBatchingTableData: (payload) =>
    dispatch(setOrderBatchingTableData(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setInventorySmartFinalizeFilterDependency: (payload) =>
    dispatch(setInventorySmartFinalizeFilterDependency(payload)),
  setRedirectedFrom: (payload) => dispatch(setRedirectedFrom(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCurrentAllocationsTables);
