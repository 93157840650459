import Highcharts from "highcharts";
import { GRAPH_MENU_LIST } from "./constants";

export const barLineChartOptions = (props) => {
  return {
    chart: {
      zoomType: "xy",
    },
    title: {
      text: props.chartTitle,
    },
    xAxis: [
      {
        categories: props.axisLegends.xaxis.categories,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: props.axisLegends.yaxis.primaryAxisLable,
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: props.axisLegends.yaxis.primaryAxisTitle,
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      },
      {
        // Secondary yaxis
        title: {
          text: props.axisLegends.yaxis.secondaryAxisTitle,
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        labels: {
          format: props.axisLegends.yaxis.secondaryAxisLable,
          style: {
            color: Highcharts.getOptions().colors[0],
          },
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false,
    },
    exporting: GRAPH_MENU_LIST,
    series: props.series,
  };
};
