import { useState } from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import Slider from "@mui/material/Slider";
import _ from "lodash";
import Popover from "@mui/material/Popover";
const useStyles = makeStyles({
  root: {
    width: 200,
    backgroundColor: "#F5F5F5",
  },
  actionArea: {
    "&:hover $focusHighlight": {
      opacity: 0,
    },
  },
  focusHighlight: {},
  Input: {
    width: 70,
    height: 0,
  },
});
const FilterIcon = ({ onClick, isRangeCleared = true }) => {
  return (
    <>
      <Badge variant="dot" color="primary" invisible={isRangeCleared}>
        <span>
          <i
            className="fa fa-filter fa-lg filter-icon"
            onClick={(event) => onClick(event)}
          ></i>
        </span>
      </Badge>
    </>
  );
};

const RangeSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -5,
    borderRadius: 0,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 6,
    borderRadius: 4,
  },
  rail: {
    height: 6,
    borderRadius: 4,
  },
})(Slider);
const RangeSliderComponent = ({
  minVal,
  maxVal,
  inputMinVal,
  inputMaxVal,
  onChange,
}) => {
  const value = [
    !inputMinVal || inputMinVal === "" ? minVal : inputMinVal,
    !inputMaxVal || inputMaxVal === "" ? maxVal : inputMaxVal,
  ];
  return (
    <RangeSlider
      min={minVal}
      max={maxVal}
      value={value}
      onChange={onChange}
      valueLabelDisplay="off"
      aria-labelledby="range-slider"
    />
  );
};
const CardComponent = ({ filterValue, setFilter, minVal, maxVal, onClose }) => {
  const classes = useStyles();
  const Index = filterValue.findIndex((filter) => {
    return filter.type === "range";
  });
  let rangeValues = ["", ""];
  if (Index !== -1) {
    rangeValues = filterValue[Index].value;
  }
  const [minValInp, setminValInp] = useState(rangeValues[0]);
  const [maxValInp, setmaxValInp] = useState(rangeValues[1]);
  const onMinChange = (e) => {
    if (e.target.value === "") {
      setminValInp("");
      return;
    }
    if (e.target.value < minVal || e.target.value > maxVal) {
      setminValInp(minVal);
    } else {
      const min = Math.max(e.target.value, minVal);
      setminValInp(min);
    }
  };
  const onMaxChange = (e) => {
    if (e.target.value === "") {
      setmaxValInp("");
      return;
    }
    if (e.target.value < minVal || e.target.value > maxVal) {
      setmaxValInp(maxVal);
    } else {
      const max = Math.min(e.target.value, maxVal);
      setmaxValInp(max);
    }
  };
  const handleSliderChange = (event, newValue) => {
    setminValInp(newValue[0]);
    setmaxValInp(newValue[1]);
  };
  const onApply = () => {
    let colfilterArr = [...filterValue];
    colfilterArr = colfilterArr.filter((filter) => {
      return filter.type !== "search";
    });
    if (Index === -1) {
      colfilterArr.push({
        type: "range",
        value: [parseInt(minValInp, 10), parseInt(maxValInp, 10)],
      });
    } else {
      colfilterArr[Index].value = [
        parseInt(minValInp, 10),
        parseInt(maxValInp, 10),
      ];
    }
    setFilter(colfilterArr);
    onClose();
  };

  const onClear = () => {
    let colfilterArr = [...filterValue];
    colfilterArr = colfilterArr.filter((filter) => {
      return filter.type !== "range";
    });
    setminValInp("");
    setmaxValInp("");
    setFilter(colfilterArr);
    onClose();
  };
  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea
          classes={{
            root: classes.actionArea,
            focusHighlight: classes.focusHighlight,
          }}
        >
          <CardContent>
            <RangeSliderComponent
              minVal={minVal}
              maxVal={maxVal}
              inputMinVal={minValInp}
              inputMaxVal={maxValInp}
              onChange={handleSliderChange}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                id="outlined-number"
                label="Min"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                value={minValInp}
                onChange={onMinChange}
                placeholder={`${minVal}`}
                InputProps={{
                  inputProps: { min: minVal, max: maxVal },
                  classes: { input: classes.Input },
                }}
                style={{
                  marginRight: "0.5rem",
                }}
                variant="outlined"
              />
              to
              <TextField
                id="outlined-number"
                label="Max"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  marginLeft: "0.5rem",
                }}
                value={maxValInp}
                onChange={onMaxChange}
                placeholder={`${maxVal}`}
                InputProps={{
                  inputProps: { min: minVal, max: maxVal },
                  classes: { input: classes.Input },
                }}
                variant="outlined"
              />
            </div>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="primary" onClick={onApply}>
            Apply
          </Button>
          <Button size="small" color="primary" onClick={onClear}>
            Clear
          </Button>
        </CardActions>
      </Card>
    </div>
  );
};

const RangeColumnFilter = ({
  column: { filterValue = [], preFilteredRows, setFilter, min_max_range },
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isRangeCleared =
    filterValue.findIndex((filter) => {
      return filter.type === "range";
    }) === -1;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <div>
        <FilterIcon
          aria-describedby={id}
          onClick={handleClick}
          isRangeCleared={isRangeCleared}
        />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          style={{ marginTop: 5 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <CardComponent
            filterValue={filterValue}
            // minVal={min_max_range.min}
            // maxVal={min_max_range.max}
            setFilter={setFilter}
            onClose={handleClose}
          />
        </Popover>
      </div>
    </>
  );
};

export default RangeColumnFilter;

const RangeOutput = (range, rows, id) => {
  const isMinNan = isNaN(range[0]);
  const isMaxNan = isNaN(range[1]);
  if (isMinNan && isMaxNan) {
    return rows;
  } else if (!isMinNan && isMaxNan) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= range[0];
    });
  } else if (isMinNan && !isMaxNan) {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue <= range[1];
    });
  } else {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue >= range[0] && rowValue <= range[1];
    });
  }
};

const searchOutput = (newValue, rows, id) => {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue === parseInt(newValue);
  });
};
export const btwnRange = (rows, id, filterValue) => {
  let filteredRows = [rows];
  for (const filter of filterValue) {
    switch (filter.type) {
      case "search":
        filteredRows.push(searchOutput(filter.value, rows, id));
        break;
      case "range":
        filteredRows.push(RangeOutput(filter.value, rows, id));
        break;
      default:
        break;
    }
  }
  return _.intersectionBy(...filteredRows, "id");
};

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
btwnRange.autoRemove = (val) => {
  return val.length === 0;
};
