import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  getMappedProductsInSTore,
  getMappedProductsInStoreGroup,
} from "../services/storeMappingService";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import SuccessBox from "../../../Utils/reactTable/components/successPopup";
import Loader from "../../../Utils/Loader/loader";
import { getColumnsAg } from "../../../actions/tableColumnActions";
import AgGridTable from "Utils/agGrid";
import { prepareMappedStoresOrProductsViewData } from "./common-mapping-functions";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    "& .MuiDialog-paperWidthSm": {
      minWidth: "60%",
    },
  },
  confirmBox: {
    "& .MuiDialog-paper": {
      borderRadius: "10px 10px 6px 6px",
    },
  },
}));

const SetAll = (props) => {
  const classes = useStyles();
  const [confirmBox, showConfirmBox] = useState(false);
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [successBox, showSuccessBox] = useState(false);

  useEffect(() => {
    const setOptions = async () => {
      try {
        let cols = await getColumnsAg("table_name=mapped_product")();
        //time period grouping
        cols = cols.map((col) => {
          if (col.column_name === "product_code") {
            col.cellRenderer = "agGroupCellRenderer";
          }
          return col;
        });
        if (props.dimension === "store_groups") {
          let storecodeCol = [
            {
              aggregate: null,
              column_name: "store_code",
              dimension: "Store",
              extra: null,
              footer: null,
              formatter: null,
              is_aggregated: false,
              is_editable: false,
              is_frozen: false,
              is_hidden: false,
              is_required: false,
              is_row_span: false,
              is_searchable: true,
              label: "Store Id",
              order_of_display: 2,
              sub_headers: [],
              tc_code: 25,
              tc_mapping_code: 171,
              type: "str",
            },
          ];
          storecodeCol = agGridColumnFormatter(storecodeCol);
          cols.splice(1, 0, ...storecodeCol);
        }
        setColumns(cols);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    setOptions();
  }, []);

  const onCancel = () => {
    props.onCancel();
  };
  const manualCallBack = async (manualbody, pageIndex, pageSize) => {
    setLoading(true);
    try {
      if (props.dimension === "store") {
        let body = {
          filters: [],
          meta: {
            ...manualbody,
          },
        };

        const { data: products } = await getMappedProductsInSTore(
          body,
          props.selectedID.store_code,
          `?page=${pageIndex + 1}`
        )();
        products.data = prepareMappedStoresOrProductsViewData(products.data);
        setLoading(false);
        return {
          data: products.data,
          totalCount: products.total,
        };
      } else {
        let body = {
          filters: [],
          meta: {
            ...manualbody,
          },
        };
        const { data: products } = await getMappedProductsInStoreGroup(
          body,
          props.selectedID.sg_code,
          `?page=${pageIndex + 1}`
        )();
        products.data = prepareMappedStoresOrProductsViewData(products.data);
        products.data = products.data.map((timeRange) => {
          return {
            ...timeRange,
            rowId: timeRange["product_code"] + "-" + timeRange["store_code"],
          };
        });
        setLoading(false);
        return {
          data: products.data,
          totalCount: products.total,
        };
      }
    } catch (err) {
      setLoading(true);
    }
  };

  return (
    <Dialog
      onClose={() => onCancel()}
      className={classes.root}
      maxWidth={"sm"}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      disableEscapeKeyDown={true}
    >
      {confirmBox && (
        <ConfirmBox
          onClose={() => showConfirmBox(false)}
          onConfirm={() => {
            showConfirmBox(false);
            props.handleClose();
          }}
        />
      )}
      {successBox && (
        <SuccessBox
          onClose={() => showSuccessBox(false)}
          onConfirm={() => {
            showSuccessBox(false);
            props.handleClose();
          }}
        />
      )}

      <Loader loader={loading}>
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            Mapped SKUs
            <IconButton
              aria-label="close"
              onClick={() => onCancel()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {columns.length > 0 && (
            <AgGridTable
              columns={columns}
              sizeColumnsToFitFlag
              onGridChanged
              onRowSelected
              manualCallBack={(body, pageIndex, params) =>
                manualCallBack(body, pageIndex, params)
              }
              rowModelType="serverSide"
              serverSideStoreType="partial"
              cacheBlockSize={10}
              uniqueRowId={
                props.dimension === "store" ? "product_code" : "rowId"
              }
              childKey={"validities"}
              purgeClosedRowNodes={true}
              treeData={true}
              groupDisplayType={"custom"}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            id="mappedProductCancelBtn"
            onClick={() => {
              onCancel();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            id="mappedProductModifyBtn"
            onClick={() => {
              let Obj = {
                selectedStores: [props.selectedID],
              };
              props.onModify(Obj, true);
            }}
            color="primary"
            disabled={!props?.disableModify}
          >
            Modify
          </Button>
        </DialogActions>
      </Loader>
    </Dialog>
  );
};

export default SetAll;
