import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StoretoProduct from "./components/store-to-product";
import HeaderBreadCrumbs from "../../Utils/HeaderBreadCrumbs";
import Modify from "./components/modify-store-mapping";
import ProductToDCFC from "./components/store-To-dcfc";
import { Prompt } from "react-router";
import ConfirmPrompt from "../../commonComponents/confirmPrompt";
import { Container } from "@mui/material";
import { connect } from "react-redux";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { addSnack } from "actions/snackbarActions";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function StoreMapping(props) {
  const [value, setValue] = React.useState(0);
  const [modifyMapping, setModifyMapping] = useState(false);
  const [defaultDataSet, updateDefaultDataSet] = useState([]);
  const [cols, updateCols] = useState([]);
  const [flagEdit, setFlagEdit] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [selectedStores, updateselectedStores] = useState([]);
  const [changedTabValue, setChangedtabValue] = useState(0);
  const isRedirectedFromMappedDialog = useRef(false);
  const handleChange = (_event, newValue) => {
    setChangedtabValue(newValue);
    if (flagEdit) {
      setConfirmBox(true);
    } else {
      setValue(newValue);
    }
  };
  const updateFlagEdit = (flag) => {
    setFlagEdit(flag);
  };
  const toggleModifyMapping = (data, isRedirected = false) => {
    if (isRedirected) {
      isRedirectedFromMappedDialog.current = true;
    }
    updateDefaultDataSet(data.defaultDataSet);
    updateCols(data.cols);
    updateselectedStores(data.selectedStores);
    setModifyMapping(true);
  };

  useEffect(() => {
    if (
      props.history?.location?.state &&
      props.history?.location?.state?.isRedirect
    ) {
      setValue(1);
    }
    const params = new URLSearchParams(props.location?.search);
    if (params.get("tab") === "Store-DC/FC") {
      setValue(1);
    }
  }, []);

  useEffect(() => {
    let activeTabName = value === 0 ? "tab=Store-Product" : "tab=Store-DC/FC";
    props.history &&
      props.history.push({
        pathname: window.location.pathname,
        search: activeTabName,
      });
  }, [value]);

  const updateModify = () => {
    if (flagEdit) {
      setConfirmBox(true);
    } else {
      setModifyMapping(false);
    }
  };
  return (
    <>
      {!props.hideBreadCrumbs && (
        <HeaderBreadCrumbs
          options={
            modifyMapping
              ? [
                  {
                    label: "Store Mapping",
                    id: 1,
                    action: () => {
                      updateModify();
                    },
                  },
                  {
                    label: "Modify Mapping",
                    id: 2,
                    action: () => null,
                  },
                ]
              : [
                  {
                    label: "Store Mapping",
                    id: 1,
                    action: () => {
                      setModifyMapping(false);
                    },
                  },
                ]
          }
        ></HeaderBreadCrumbs>
      )}
      <Container maxWidth={false}>
        {confirmBox && (
          <ConfirmPrompt
            confirmCallback={(val, _checked, tabValue) => {
              if (val) {
                if (!modifyMapping) {
                  setValue(tabValue);
                }
                setModifyMapping(false);
                setFlagEdit(false);
              }
              setConfirmBox(false);
            }}
            value={changedTabValue}
          />
        )}
        {!modifyMapping && (
          <div>
            {" "}
            <Prompt when={flagEdit} message="" />
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={`Store-${dynamicLabelsBasedOnTenant("product", "core")}`}
                {...a11yProps(0)}
              />
              <Tab label="Store-DC/FC" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <StoretoProduct
                updateFlagEdit={updateFlagEdit}
                toggleModifyMapping={toggleModifyMapping}
                module={props.module}
                roleBasedAccess={props.roleBasedAccess}
                screenName={props.screenName}
              ></StoretoProduct>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProductToDCFC
                isredirect={
                  props.history?.location?.state &&
                  props.history?.location?.state?.isRedirect
                    ? props.history.location.state.isRedirect
                    : null
                }
                updateFlagEdit={updateFlagEdit}
                module={props.module}
                roleBasedAccess={props.roleBasedAccess}
                screenName={props.screenName}
              >
                {" "}
              </ProductToDCFC>
            </TabPanel>
          </div>
        )}
        {modifyMapping && (
          <Modify
            ref={isRedirectedFromMappedDialog}
            selectedDimension={"store"}
            defaultDataSet={defaultDataSet}
            cols={cols}
            selectedProducts={selectedStores}
            updateFlagEdit={updateFlagEdit}
            goBack={() => setModifyMapping(false)}
          ></Modify>
        )}
      </Container>
    </>
  );
}

const mapStateToProps = () => {
  return {};
};

const mapActionsToProps = () => ({ addSnack });

export default connect(mapStateToProps, mapActionsToProps)(StoreMapping);
