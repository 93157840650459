import { connect } from "react-redux";
import ReactSelect from "../../../Utils/select";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import { FUNCTIONALITIES, FILTER_TYPES } from "../../../config/constants";
import Table from "../../../Utils/reactTable";
import CellRenderer from "../../../Utils/reactTable/components/cellRenderer";
import {
  setModuleConfigData,
  getFilterfields,
  getFilteredFields,
  setFilteredFields,
} from "../../../actions/filterAction";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";
import { isEmpty } from "lodash";
const useStyles = makeStyles({
  addFilter: {
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "70%",
  },
  moduleConfig: {
    margin: "20px 0px",
  },
  header: {
    marginBottom: 1,
  },
});
const ModuleConfig = (props) => {
  /**
   * Custom Variables
   */
  const classes = useStyles();
  let SELECTED_FILTERS_TABLE_COLUMNS = [
    {
      Header: "Filter",
      accessor: "label",
      // is_frozen: true,
      // sticky: "left",
    },
    {
      Header: "Level",
      is_editable: true,
      type: "list",
      accessor: "level",
      options: [],
    },
    {
      Header: "Functionality",
      is_editable: true,
      type: "list",
      accessor: "display_type",
      options: FUNCTIONALITIES,
    },
    {
      Header: "Filter Type",
      is_editable: true,
      type: "list",
      accessor: "filter_type",
      options: FILTER_TYPES,
    },
    {
      Header: "Mandatory",
      is_editable: true,
      type: "ToogleField",
      accessor: "is_mandatory",
    },
    {
      Header: "Actions",
      Cell: (tableProps) => {
        return (
          <IconButton
            disabled={tableProps.row.original.disableDeleteSelection}
            onClick={() => {
              const {
                value: initialValue,
                row,
                row: { index, id },
                column,
                updateMyData,
                ...wholeData
              } = tableProps;

              tableProps.updateMyData(
                index,
                column.id,
                null,
                null,
                column,
                wholeData,
                row,
                id,
                "delete"
              );
            }}
            size="large"
          >
            <CancelIcon />
          </IconButton>
        );
      },
    },
  ];
  /**
   * Custom functions
   */
  const onChange = (option) => {
    setselectedFilter(option);
    AddToRows(option);
  };

  const optionReset = (filterName) => {
    let updatedFilters = selectedFilter.filter((filter) => {
      return filter.value !== filterName;
    });
    setselectedFilter(updatedFilters);
  };
  const AddToRows = (options) => {
    let updatedRows = options.map((option, idx) => {
      return {
        label: option.label,
        column_name: option.value,
        level: idx + 1,
        display_type: "dropdown",
        filter_type: "cascaded",
        is_mandatory: 0,
        dimension: option.dimension,
      };
    });
    setselectedFiltersTableData(updatedRows);
  };
  const getOptions = (options) => {
    return options
      ? options.map((option) => {
          if (option) {
            return {
              label: option.label,
              value: option.column_name,
              dimension: option.dimension,
            };
          } else {
            return {
              label: "",
              value: "",
            };
          }
        })
      : [];
  };

  const handleDelete = (index) => {
    let rowData = [...selectedFiltersTableData];
    optionReset(rowData[index].column_name);
    rowData.splice(index, 1);
    rowData = rowData.map((item, idx) => {
      const item_level = idx + 1;
      return {
        ...item,
        level: item_level,
      };
    });
    setselectedFiltersTableData(rowData);
  };
  const updateMyData = async (
    rowIndex,
    columnId,
    value,
    initialValue,
    _column,
    _wholeData,
    _row,
    _id,
    actions
  ) => {
    if (actions === "delete") {
      handleDelete(rowIndex);
    } else if (columnId === "level") {
      let max = selectedFiltersTableData.length;
      let min = null;
      let oldValue = initialValue;
      if (value > oldValue) {
        min = oldValue;
        max = value;
        await setselectedFiltersTableData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              let finalValue = value;
              return {
                ...row,
                [columnId]: finalValue,
              };
            } else if (row[columnId] > min && row[columnId] <= max) {
              return {
                ...row,
                [columnId]: row[columnId] - 1,
              };
            }
            return row;
          })
        );
      } else if (value < oldValue) {
        min = value;
        max = oldValue;
        let finalData = selectedFiltersTableData.map((row, index) => {
          if (index === rowIndex) {
            let finalValue = value;
            return {
              ...row,
              [columnId]: finalValue,
            };
          } else if (row[columnId] >= min && row[columnId] < max) {
            return {
              ...row,
              [columnId]: row[columnId] + 1,
            };
          }
          return row;
        });
        await setselectedFiltersTableData(finalData);
      }
    } else {
      setselectedFiltersTableData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
  };
  const sortedData = (data) => {
    if (data) {
      return data.sort((a, b) => {
        if (a.level < b.level) return -1;
        return a.level > b.level ? 1 : 0;
      });
    }
    return [];
  };
  const selectedRowHandler = (rows) => {
    setselectedRows(rows.map((row) => row.original));
  };

  const checkDisabledRows = (filterData) => {
    return filterData.map((filter) => {
      if (
        props.mandatoryFilters.some(
          (mand_filter) => mand_filter.column_name === filter.column_name
        )
      ) {
        filter.disabledRowCheckbox = true;
      }
      return filter;
    });
  };
  const onRowsDelete = () => {
    //Remove all selected rows from selected row data
    let updatedRowData = selectedFiltersTableData.filter((row) => {
      return !selectedRows.some((selectedrow) => {
        return selectedrow.column_name === row.column_name;
      });
    });
    updatedRowData = updatedRowData.map((item, idx) => {
      const item_level = idx + 1;
      return {
        ...item,
        level: item_level,
      };
    });
    //Add all selected rows to options
    let updatedFilters = selectedFilter.filter((filter) => {
      return !selectedRows.some((row) => {
        return row.column_name === filter.value;
      });
    });
    setselectedFiltersTableData(updatedRowData);
    setselectedFilter(updatedFilters);
  };
  /**
   * State variables
   */
  const [filterOptions, setfilterOptions] = useState(
    props.filterfields.filter((filterfield) => {
      return filterfield.dimension === props.dimension;
    })
  );
  const [selectedFiltersTableData, setselectedFiltersTableData] = useState(
    props.selectedModuleConfigData[props.dimension]
      ? sortedData(
          checkDisabledRows(props.selectedModuleConfigData[props.dimension])
        )
      : []
  );
  const [selectedFiltersTableColumns, setselectedFiltersTableColumns] =
    useState(SELECTED_FILTERS_TABLE_COLUMNS);
  const [selectedFilter, setselectedFilter] = useState("");
  const [selectedRows, setselectedRows] = useState([]);
  /**
   * UseEffects
   */
  useEffect(() => {
    let cols = selectedFiltersTableColumns.map((item) => {
      if (item.accessor === "level") {
        item.options = selectedFiltersTableData.map((_row, idx) => {
          return {
            label: idx + 1 + "",
            value: idx + 1,
          };
        });
      }
      if (!item.Cell) {
        item.Cell = (cellProps, extraProps) => {
          if (item.is_editable) {
            return (
              <CellRenderer
                cellData={cellProps}
                column={item}
                extraProps={extraProps}
              ></CellRenderer>
            );
          } else {
            return cellProps.value || "";
          }
        };
      }

      return item;
    });

    setselectedFiltersTableColumns(cols);
  }, [selectedFiltersTableData]);
  useEffect(() => {
    props.setModuleConfigData(selectedFiltersTableData, props.dimension);
  }, [selectedFiltersTableData]);
  useEffect(() => {
    setfilterOptions(
      props.filterfields.filter((filterfield) => {
        return filterfield.dimension === props.dimension;
      })
    );
  }, [props.filterfields]);

  useEffect(async () => {
    if (
      !isEmpty(props.selectedModuleConfigData) &&
      props.selectedModuleConfigData[props.dimension]?.length > 0
    ) {
      const selectedFilterValues = props.selectedModuleConfigData[
        props.dimension
      ]?.map((element) => {
        return {
          label: element.label,
          value: element.column_name,
          dimension: element.dimension,
        };
      });
      setselectedFilter(selectedFilterValues);
    }
    //For mandatory filters
    if (props.mandatoryFilters.length > 0) {
      const mandatoryFiltersInDimension = props.mandatoryFilters.filter(
        (mand_filter) => mand_filter.dimension === props.dimension
      );
      const selectedFilterValues = mandatoryFiltersInDimension.map(
        (mand_filter) => {
          return {
            label: mand_filter.label,
            value: mand_filter.column_name,
            dimension: mand_filter.dimension,
          };
        }
      );
      const selectedFilterTableData = mandatoryFiltersInDimension.map(
        (element, idx) => {
          return {
            label: element.label,
            column_name: element.value,
            level: idx + 1,
            display_type: "dropdown",
            filter_type: "cascaded",
            is_mandatory: 1,
            dimension: element.dimension,
            disableDeleteSelection: true,
          };
        }
      );
      setselectedFilter(selectedFilterValues);
      setselectedFiltersTableData(selectedFilterTableData);
    }
  }, []);
  return (
    <>
      <Container classes={{ root: classes.moduleConfig }}>
        <Grid classes={{ root: classes.header }} container spacing={3}>
          <Grid classes={{ root: classes.addFilter }} item xs={6}>
            <label style={{ marginRight: 10, width: "24%" }}>Add Filter</label>
            <div style={{ position: "relative", zIndex: 2, width: "100%" }}>
              <ReactSelect
                isSearchable={false}
                className={classes.select}
                options={getOptions(filterOptions)}
                onChange={onChange}
                value={selectedFilter}
                maxMenuHeight={200}
                isOptionDisabled={(option) =>
                  props.mandatoryFilters.some(
                    (mand_filter) => mand_filter.column_name === option.value
                  )
                }
                isMulti={true}
                closeMenuOnSelect={false}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent="flex-end" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onRowsDelete}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary">
                  Copy Config
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table
          rowSelection={true}
          updateMyData={updateMyData}
          columns={selectedFiltersTableColumns}
          rowdata={selectedFiltersTableData}
          selectedRowHandler={selectedRowHandler}
          id="filter-table"
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    filterfields: state.filterElementsReducer.filterfields,
    selectedModuleConfigData:
      state.filterElementsReducer.selectedModuleConfigData,
    createdConfigs: state.filterElementsReducer.createdConfigs,
    selectedDimension: state.filterElementsReducer.selectedDimensions,
    mandatoryFilters: state.filterElementsReducer.mandatoryFilterFields,
  };
};
const ActionsToProps = {
  setModuleConfigData,
  getFilterfields,
  getFilteredFields,
  setFilteredFields,
};
export default connect(mapStateToProps, ActionsToProps)(ModuleConfig);
