import { cloneDeep, isEqual, capitalize } from "lodash";
import moment from "moment";
import { isDateRangeConflict } from "Utils/functions/helpers/validation-helpers";
import { END_DATE } from "config/constants";

// formats updated status attribute
export const formatAttribute = (
  attributeId,
  params,
  attributeType,
  actionType
) => {
  let setAllObject = {
    attributes: [],
    code: attributeId,
  };
  // creating payload for patch api call
  const attributeObj = {
    time_attr_id: params.data.time_attr_id,
    attribute_name: attributeType,
    attribute_value: params.data[attributeType],
    start_time: moment(params.data[`${attributeType}${"_start_time"}`]).format(
      "YYYY-MM-DD"
    ),
    end_time: params.data[`${attributeType}${"_end_time"}`]
      ? moment(params.data[`${attributeType}${"_end_time"}`]).format(
          "YYYY-MM-DD"
        )
      : END_DATE,
    action: actionType,
  };
  setAllObject.attributes = [attributeObj];
  return setAllObject;
};

export const checkConflictInAttributeType = (attributeList, attributeType) => {
  const attributeDateRangeList = attributeList?.map((item) => {
    return {
      start_time: item[`${attributeType}${"_start_time"}`],
      end_time: item[`${attributeType}${"_end_time"}`],
    };
  });
  return isDateRangeConflict(attributeDateRangeList, "YYYY-MM-DD", "[]");
};

// add deleted attribute in setAllData
export const updateSetAllDataDelete = (item, inputActionObj) => {
  // removing edit action of attribute for which delete action is performed
  item.attributes = item.attributes.filter((value) => {
    return value.time_attr_id != inputActionObj.attributes[0].time_attr_id;
  });
  // adding delete action
  item.attributes = [...item.attributes, inputActionObj.attributes[0]];
  return { item: item, isEdited: true };
};

// update or add edited attribute in setAllData
export const updateSetAllDataEdit = (
  item,
  inputActionObj,
  cellAttributeId,
  isEdited
) => {
  item.attributes = item.attributes.map((attributeObj) => {
    const { time_attr_id } = attributeObj;
    if (isEqual(time_attr_id, cellAttributeId)) {
      // update since attribute is already edited
      isEdited = true;
      attributeObj = { ...inputActionObj.attributes[0] };
    }
    return attributeObj;
  });
  if (!isEdited) {
    // add newly edited attribute object
    isEdited = true;
    item.attributes = [...item.attributes, inputActionObj.attributes[0]];
  }

  return { item, isEdited };
};

export const getUpdatedSetAllData = (
  inputActionObj,
  updateActionType,
  setAllData
) => {
  setAllData = cloneDeep(setAllData);
  const cellCode = inputActionObj.code;
  const cellAttributeId = inputActionObj.attributes[0].time_attr_id;
  let isEdited = false;

  // check if status attribute present in setAllData already
  let updatedSetAllData = setAllData.map((item) => {
    const { code } = item;
    if (isEqual(code, cellCode)) {
      // update in setAllData for corresponding status attribute code
      if (isEqual(updateActionType, "delete")) {
        ({ item, isEdited } = updateSetAllDataDelete(item, inputActionObj));
      } else if (isEqual(updateActionType, "edit")) {
        ({ item, isEdited } = updateSetAllDataEdit(
          item,
          inputActionObj,
          cellAttributeId,
          isEdited
        ));
      }
    }
    return item;
  });

  return { isEdited, updatedSetAllData };
};

export const getListOptions = (optionList) => {
  return optionList.map((item) => {
    return { label: capitalize(item), id: item, value: item };
  });
};
