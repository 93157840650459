import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  inputAdornmentClasses,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import globalStyles from "Styles/globalStyles";

import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const useStyles = makeStyles((theme) => ({
  symbolStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: theme.typography.pxToRem(38),
    padding: theme.typography.pxToRem(6),
  },
}));

const InputCell = React.memo(({ ...instance }) => {
  const {
    row,
    column,
    type,
    value,
    min,
    max,
    inputType,
    isRoundOffTwoDecimals,
    isWhole,
    isCeil,
    onInputChange,
    fixTo,
    className,
    titleText,
    showEmptyOnZero,
    disabled = false,
    onBlur,
    isCellLockable,
    handleCellLock,
  } = instance;
  const lockCellCustomConditionFn =
    instance.api.gridOptionsWrapper.gridOptions?.lockCellCustomConditionFn;
  const [inputValue, setInputValue] = useState(null);
  const classes = useStyles();
  const [inputTypeAdornment, setInputTypeAdornment] = useState();
  const globalClasses = globalStyles();

  const styleInputText = (value) => {
    let cellColorValues = instance?.data;
    let rowsWithDifferentCellStyle = ["variance"];
    let style = {};
    if (rowsWithDifferentCellStyle.indexOf(cellColorValues?.reference) > -1) {
      if (
        value >= cellColorValues?.min_val &&
        value <= cellColorValues?.max_val
      ) {
        style.color = cellColorValues?.max_color;
      } else if (!isNaN(value)) {
        style.color = cellColorValues?.min_color;
      }
      return { style };
    }
  };

  const getFormatedNumber = (temp) => {
    let formatedValue = parseFloat(temp);
    if (isRoundOffTwoDecimals) {
      //isRoundOffTwoDecimals is used when need value to be rounded off to 2 decimals
      formatedValue = Math.round(temp * 100) / 100;
    } else if (isWhole) {
      //isWhole is used when we need value to be a whole number (without decimals)
      formatedValue = Math.round(temp);
    } else if (isCeil) {
      //isCeil is used when we need value to be a whole number without rounding off (without decimals)
      formatedValue = parseInt(temp);
    } else if (inputType === "dollar" && isRoundOffTwoDecimals) {
      //if the input type is dollar then the value should always have 2 decimals like 0.00
      //columns like aur, aic
      formatedValue = parseFloat(temp).toFixed(2);
    }
    return formatedValue;
  };

  const getFormattedText = (formatText) => {
    if (
      (typeof formatText == Number ||
        (formatText && !isNaN(Number(formatText)))) &&
      !column?.id?.includes("date")
    ) {
      //if the value is of type number and  not nan then we are converting it to whole number and adding commas
      let newvaluefor =
        Number(formatText) < 0
          ? 0
          : new Intl.NumberFormat().format(Number(formatText).toFixed(0)); //Intl.NumberFormat enables language-sensitive number formatting
      setInputValue(newvaluefor);
    } else {
      //To include commas inside Input columns like quantity etc.
      //if the value is of type string
      let temp = formatText;
      let letters = /[a-zA-Z]/g;
      if (formatText) {
        if (!formatText.match(letters)) {
          //value doesn't have any alphabets then replace comma and convert to whole number
          temp = temp.replaceAll(",", "");
          temp = Math.round(temp) < 0 ? 0 : Math.round(temp);
        }
        //adding commas to value
        let newvaluefor1 = new Intl.NumberFormat(temp.toString()); //Intl.NumberFormat enables language-sensitive number formatting
        setInputValue(newvaluefor1);
      } else {
        setInputValue(formatText);
      }
    }
  };

  const formatInputValue = (formatVal) => {
    let formatedValue = formatVal;
    if (type === "number") {
      //fixTo is used to fix it to 2 or 0 or 1 like that
      let temp = formatVal;
      if ((!formatVal || formatVal < 0) && !showEmptyOnZero) {
        temp = 0;
      }
      if (temp) {
        if (fixTo || fixTo === 0) {
          temp = parseFloat(temp);
          temp = temp.toFixed(fixTo);
          formatedValue = temp;
        } else {
          formatedValue = getFormatedNumber(temp); //here
        }
      }
      setInputValue(formatedValue);
    } else if (type === "text" && formatVal !== null) {
      getFormattedText(formatVal); //here
    } else setInputValue(formatVal);
    return formatedValue;
  };

  const getNewValue = (p_value) => {
    try {
      // if user tries to remove value in cell, earlier we were setting to min value of the cell or 0 by default, with this condition we are allowing user to enter no value ("") in input cell.
      if (p_value === "") {
        return p_value;
      } else if (!Number.isInteger(parseInt(p_value))) {
        return 0;
      } else if (inputType === "int" && p_value) {
        return Math.min(
          Math.max(
            parseInt(Number(parseFloat(p_value.replace(/,/g, "")))),
            min
          ),
          max
        );
      } else if (inputType === "dollar" && p_value) {
        return parseFloat(p_value.replace(/,/g, ""));
      }
      return p_value;
    } catch {
      return p_value;
    }
  };

  useEffect(() => {
    setInputTypeAdornment(inputType);
  }, []);

  useEffect(() => {
    formatInputValue(value);
  }, [value]);

  const [showLock, setShowLock] = useState(false);
  const [cellLock, setCellLock] = useState(false);

  useEffect(() => {
    if (
      lockCellCustomConditionFn &&
      setCellLock(lockCellCustomConditionFn(instance))
    );
  }, [instance]);

  const LockCell = () => (
    <IconButton>
      {showLock && (
        <LockIcon
          onClick={() => {
            setCellLock(true);
            handleCellLock(true);
          }}
        />
      )}
    </IconButton>
  );
  const UnlockCell = () => (
    <IconButton>
      {showLock && (
        <LockOpenIcon
          onClick={() => {
            setCellLock(false);
            handleCellLock(false);
          }}
        />
      )}
    </IconButton>
  );

  return (
    <div className={globalClasses.flexRow} title={titleText || ""}>
      <TextField
        variant="outlined"
        className={className}
        value={inputValue !== value ? inputValue : value}
        disabled={disabled || cellLock}
        type={type || "text"}
        size="small"
        min={type === "number" ? 0 : ""}
        InputProps={{
          inputProps: styleInputText(value),
          classes: {
            input: classes.inputField,
          },
          startAdornment: (
            <InputAdornment position="start">
              {inputTypeAdornment === "dollar" && <span>$ </span>}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              position="end"
              sx={{
                "&.MuiInputAdornment-root ": {
                  marginRight: "-12px",
                },
              }}
            >
              {inputTypeAdornment === "percentage" && (
                <span className="endAdornment">% </span>
              )}
              {isCellLockable && (cellLock ? UnlockCell() : LockCell())}
            </InputAdornment>
          ),
        }}
        onChange={(e) => {
          let newValue = getNewValue(e.target.value);
          // to check for keys
          if (column.onlyPositiveNumbers && newValue < 0) {
            return;
          }
          setInputValue(newValue);
          if (onInputChange) {
            onInputChange(newValue, type);
          }
          setInputTypeAdornment(inputTypeAdornment);
        }}
        onBlur={onBlur}
        onFocus={() => {
          setShowLock(true);
        }}
        onMouseEnter={() => {
          setShowLock(true);
        }}
        onMouseLeave={() => {
          setShowLock(false);
        }}
      />
    </div>
  );
});

export default InputCell;
