import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NormalCalendarFiscalMapping } from "commonComponents/calendar";
import moment from "moment";
import { calendarConfig } from "config";
import { RANGE_FILTER_ERROR_MESSAGE } from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { addSnack } from "actions/snackbarActions";
import {
  setInventorysmartDatesLoader,
  setSelectedDates,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/decision-dashboard-services";
import { validateDateRange } from "../../inventorysmart-utility";
import { getCoreFiscalCalendar } from "modules/ada/services-ada/ada-dashboard/ada-dashboard-services";

const { fstDayOfWk } = calendarConfig;
moment.updateLocale("en", {
  week: {
    dow: fstDayOfWk,
  },
});

const DateRangeFilter = (props) => {
  const [fiscalCalendarData, setFiscalCalendarData] = useState([]);

  useEffect(() => {
    const getFiscalCalendarDataAndSetDates = async () => {
      props.setInventorysmartDatesLoader(true);
      /** Cannot select future date, so selecting previous week by default */
      const startDate = moment()
        .subtract(1, "weeks")
        .startOf("week")
        .format("MM-DD-YYYY");

      const financialCalendarData = await getCoreFiscalCalendar();
      setFiscalCalendarData([...financialCalendarData?.data?.data]);

      const fiscalStartDate = financialCalendarData?.data?.data?.find(
        (date) => {
          const formattedDate = moment(date?.calendar_week_start_date).format(
            "MM-DD-YYYY"
          );
          /** Moment isSame does not work with Mozilla Thus Using Or Operator */
          return (
            moment(formattedDate).isSame(startDate) ||
            formattedDate === startDate
          );
        }
      );

      if (
        !props.selectedDates?.fiscalInfoStartDate ||
        !props.selectedDates?.fiscalInfoEndDate
      ) {
        props.setSelectedDates({
          fiscalInfoStartDate: fiscalStartDate,
          fiscalInfoEndDate: fiscalStartDate,
        });
      }
      props.setInventorysmartDatesLoader(false);
    };

    getFiscalCalendarDataAndSetDates();
  }, []);

  const handleDateChange = (val) => {
    const dateRange = validateDateRange(val);
    if (dateRange.isValid) {
      props.setSelectedDates(val);
    } else {
      if (dateRange.error === RANGE_FILTER_ERROR_MESSAGE) {
        if (!val.startDate && !val.endDate) {
          displaySnackMessages(dateRange.error, "error");
        }
      } else {
        displaySnackMessages(dateRange.error, "error");
      }
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <>
      <NormalCalendarFiscalMapping
        selectedDate={props.selectedDates}
        onDateChange={handleDateChange}
        isMandatory
        fiscalCalendarData={fiscalCalendarData}
        displayRow={props.displayRow}
        setValueOnBlur
      />
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedDates:
      store.inventorysmartReducer.inventorySmartDashboardService.selectedDates,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedDates: (payload) => dispatch(setSelectedDates(payload)),
  setInventorysmartDatesLoader: (payload) =>
    dispatch(setInventorysmartDatesLoader(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeFilter);
