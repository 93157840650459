import { sideBarOptions as inventorysmart_sideBarOptions } from "modules/inventorysmart/routes-inventorysmart/routes";
import { sideBarOptions as assortsmart_sideBarOptions } from "modules/react-demo/routes/assort-smart-routes";
import { sideBarOptions as adda_sideBarOptions } from "modules/ada/routes-ada/routes";
import { sideBarOptions as marksmart_sideBarOptions } from "modules/marksmart/routes-marksmart";
import { sideBarOptions as plansmart_sideBarOptions } from "modules/plansmart/routes-plansmart";
import { sideBarOptions as pricesmart_sideBarOptions } from "modules/pricesmart/routes-pricesmart/routes";
import {
  notificationSideBarOptions,
  uamSideBarOptions,
  moduleConfigurationSideBarOptions,
} from "commonComponents/core-layout";
export const sideBarDataSet = {
  inventorysmart: inventorysmart_sideBarOptions,
  assortsmart: assortsmart_sideBarOptions,
  ada: adda_sideBarOptions,
  marksmart: marksmart_sideBarOptions,
  pricesmart: pricesmart_sideBarOptions,
  plansmart: plansmart_sideBarOptions,
  notification: notificationSideBarOptions,
  "application access management": uamSideBarOptions,
  "module configurator": moduleConfigurationSideBarOptions,
};
