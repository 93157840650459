import React from "react";
import "../index.scss";

const FooterMarkup = (props) => {
  const { rows, footerGroups } = props;

  const headerProps = (column) => {
    let cellProps = column.getHeaderProps();
    if (column.checkBoxColumn) {
      cellProps.style.width = "60px";
      cellProps.style.minWidth = "60px";
      cellProps.style.minHeight = "57px";
    }
    return cellProps;
  };

  return (
    <>
      {rows.length ? (
        <tfoot className="footer z-1">
          {/* if rowSpan is zero, aoid rendering TR */}
          {footerGroups.map((group, i) => {
            if (group.headers[i].rowSpan === 0) {
              return null;
            }
            return (
              <tr>
                {group.headers.map((column) => {
                  if (!column.footer) {
                    return (
                      <td
                        className="empty-footer"
                        {...column.getFooterProps({
                          rowSpan: column.rowSpan,
                        })}
                        {...headerProps(column)}
                      ></td>
                    );
                  }
                  return (
                    <td
                      className="td"
                      {...column.getFooterProps({
                        rowSpan: column.rowSpan,
                      })}
                      {...column.getHeaderProps()}
                    >
                      {column.render("Footer")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tfoot>
      ) : null}
    </>
  );
};
export default FooterMarkup;
