import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";

import Form from "Utils/form";
import AgGridComponent from "Utils/agGrid";
import globalStyles from "Styles/globalStyles";
import { addSnack } from "actions/snackbarActions";
import { getColumnsAg } from "actions/tableColumnActions";

import {
  setExcessInventoryTableLoader,
  setExcessInventoryTableData,
  getExcessInventoryTableData,
} from "../../../services-inventorysmart/Allocation-Reports/excess-inventory-service";
import {
  ERROR_MESSAGE,
  tableConfigurationMetaData,
  LOST_SALES_FISCAL_WEEK,
} from "../../../constants-inventorysmart/stringConstants";

const useStyles = makeStyles(() => ({
  fiscalWeekContainer: {
    width: "20%",
    marginBottom: "1rem",
  },
}));

const ExcessInventoryTableComponent = (props) => {
  const [excessInventoryTableConfig, setExcessInventoryTableConfig] = useState(
    []
  );
  const [excessInventoryFiscalWeek, setExcessInventoryFiscalWeek] = useState({
    fiscal_week: "",
  });
  const [fiscalWeekExcessInvDetails, setFiscalWeekExcessInvDetails] = useState({
    units: "",
    revenue: "",
  });

  const fiscalWeekRef = useRef({});
  const filterConfig = useRef({});
  const agGridTableInstance = useRef({});
  const globalClasses = globalStyles();
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      props.setExcessInventoryTableLoader(true);
      try {
        let lostSalesColDef = await getColumnsAg(
          "table_name=inventorysmart_excess_inv_list"
        )();
        setExcessInventoryTableConfig(lostSalesColDef);
      } catch (e) {
        props.setExcessInventoryTableLoader(false);
        props.displaySnack(ERROR_MESSAGE, "error");
      }
    })();
  }, []);

  useEffect(() => {
    if (!isEmpty(props.renderTable)) {
      filterConfig.current = props.renderTable.filters;
      agGridTableInstance.current?.api?.refreshServerSideStore({ purge: true });
    }
  }, [props.renderTable]);

  useEffect(() => {
    if (props.weeksAvailable.length) {
      LOST_SALES_FISCAL_WEEK[0].options = props.weeksAvailable.map((item) => {
        let fiscalWeekYearValue = `${item.fiscal_week}-${item.fiscal_year}`;
        return {
          label: fiscalWeekYearValue,
          value: fiscalWeekYearValue,
          id: fiscalWeekYearValue,
        };
      });
      setFiscalWeekExcessInvDetails({
        ...fiscalWeekExcessInvDetails,
        units: props.weeksAvailable[0].unit_sold_sum,
        revenue: props.weeksAvailable[0].excess_inv_sum,
      });
      let defaultSelectedYearVal = `${props.weeksAvailable[0].fiscal_week}-${props.weeksAvailable[0].fiscal_year}`;
      setExcessInventoryFiscalWeek({ fiscal_week: defaultSelectedYearVal });
      fiscalWeekRef.current = { fiscal_week: defaultSelectedYearVal };
    }
  }, [props.weeksAvailable]);

  const handleChangeFiscalWeekValue = (updatedFormData) => {
    setExcessInventoryFiscalWeek(updatedFormData);
    fiscalWeekRef.current = updatedFormData;
    // To populate the lost sales summary details
    // fetching the lost sales details based on selected fiscal week and year
    if (props.weeksAvailable.length) {
      let selectedFiscalYear = updatedFormData.fiscal_week.split("-");
      let filteredFiscalYearDetails = props.weeksAvailable.filter((item) => {
        if (
          item.fiscal_week === parseInt(selectedFiscalYear[0]) &&
          item.fiscal_year === parseInt(selectedFiscalYear[1])
        )
          return item;
      });
      filteredFiscalYearDetails.length &&
        setFiscalWeekExcessInvDetails({
          ...fiscalWeekExcessInvDetails,
          units: filteredFiscalYearDetails[0].unit_sold_sum,
          revenue: filteredFiscalYearDetails[0].excess_inv_sum,
        });
    }
    agGridTableInstance.current?.api?.refreshServerSideStore({ purge: true });
    manualCallBack(tableConfigurationMetaData.meta, 0);
  };

  const manualCallBack = async (manualbody, pageIndex) => {
    props.setExcessInventoryTableLoader(true);
    let selectedFiscalWeek = fiscalWeekRef.current.fiscal_week.split("-");
    let body = {
      meta: {
        ...manualbody,
        sort: isEmpty(manualbody.sort)
          ? [{ column: "total_execss_inv", order: "desc" }]
          : manualbody.sort,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      filters: filterConfig.current,
      week: selectedFiscalWeek[0],
      year: selectedFiscalWeek[1],
    };
    try {
      let response = await props.getExcessInventoryTableData(body);
      props.setExcessInventoryTableData(response.data?.data);
      props.setExcessInventoryTableLoader(false);
      return {
        data: response.data?.data,
        totalCount: response.data?.total,
      };
    } catch (e) {
      props.setExcessInventoryTableLoader(false);
      props.displaySnack(ERROR_MESSAGE, "error");
    }
  };

  const loadAggridTableInstance = (params) => {
    agGridTableInstance.current = params;
  };

  return (
    <div>
      <div>
        <Typography variant="h5" className={globalClasses.paddingVertical}>
          Excess Inventory
        </Typography>
        <Typography variant="h5" className={globalClasses.paddingVertical}>
          Total Excess Inv Units: {fiscalWeekExcessInvDetails.units}, Total
          Excess Inv Cost: {fiscalWeekExcessInvDetails.revenue}
        </Typography>
      </div>
      <div className={classes.fiscalWeekContainer}>
        <Form
          layout={"vertical"}
          maxFieldsInRow={1}
          handleChange={handleChangeFiscalWeekValue}
          fields={LOST_SALES_FISCAL_WEEK}
          updateDefaultValue={false}
          defaultValues={excessInventoryFiscalWeek}
          labelWidthSpan={2}
          fieldTypeWidthSpan={2}
        ></Form>
      </div>

      <AgGridComponent
        columns={excessInventoryTableConfig}
        manualCallBack={(body, pageIndex) => manualCallBack(body, pageIndex)}
        rowModelType="serverSide"
        serverSideStoreType="partial"
        cacheBlockSize={10}
        uniqueRowId={"key"}
        loadTableInstance={loadAggridTableInstance}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setExcessInventoryTableLoader: (snack) =>
      dispatch(setExcessInventoryTableLoader(snack)),
    getExcessInventoryTableData: (body) =>
      dispatch(getExcessInventoryTableData(body)),
    setExcessInventoryTableData: (body) =>
      dispatch(setExcessInventoryTableData(body)),
  };
};

export default connect("", mapDispatchToProps)(ExcessInventoryTableComponent);
