import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { cloneDeep, isEmpty } from "lodash";

import Filters from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import Loader from "Utils/Loader/loader";

import {
  setLostSalesFiscalGraphData,
  setLostSalesScreenLoader,
  getLostSalesFiscalWeekGraph,
  clearLostSalesStates,
  setSelectedFilters,
  setIsFiltersValid,
  setLostSalesFilterDependency,
  setLostSalesFilterElements,
} from "../../../services-inventorysmart/Allocation-Reports/lost-sales-service";
import LostSalesOpportunityComponent from "./lost-sales-opportunity";
import LostSalesTableViewComponent from "./lost-sales-table-view";
import {
  ERROR_MESSAGE,
  FILL_MANDATORY_FIELDS,
  INVENTORY_DASHBOARD_STATIC_FILTERS,
  tableConfigurationMetaData,
} from "../../../constants-inventorysmart/stringConstants";

import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../../inventorysmart-utility";
import { Button, Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import CustomFilter from "../filters/CustomFilter";
import { getCoreFiscalCalendar } from "modules/ada/services-ada/ada-dashboard/ada-dashboard-services";
import ActionButton from "modules/ada/pages-ada/Dashboard/filters/action-button";

const LostSalesComponent = (props) => {
  const globalClasses = globalStyles();
  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showLostSalesDetails, setShowLostSalesDetails] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);
  const [fiscalCalendarDetails, setFiscalCalendarDetails] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    fiscalInfoStartDate: null,
    fiscalInfoEndDate: null,
  });
  const [openModal, setOpenModal] = useState(false);

  const updateFilters = async (selected, current) => {
    await getFiltersOptions(selected, current);
    props.setLostSalesFilterDependency(selected);
  };

  const applyFilters = (filterElements, filterDependency, reset) => {
    const payload = filtersPayload(
      filterElements || props.lostSalesFilterElements,
      filterDependency || props.lostSalesFilterDependency,
      true
    );

    if (
      props.showFiscalCalendar &&
      (!selectedDates?.fiscalInfoStartDate || !selectedDates?.fiscalInfoEndDate)
    ) {
      payload.isValid = false;
    }

    const selectedFilters = payload.reqBody?.filter(
      (filterItem) => filterItem.values?.length > 0
    );

    props.setIsFiltersValid(payload.isValid);
    props.setSelectedFilters(selectedFilters);

    if (!payload.isValid && !reset) {
      displaySnackMessages(FILL_MANDATORY_FIELDS, "error");
    }
  };

  const resetSelectedFilterValues = async () => {
    props.setLostSalesFilterDependency([]);
    applyFilters(filterData, [], true);
    setShowLostSalesDetails(false);
    setOpenModal(false);
    setSelectedDates({
      fiscalInfoStartDate: null,
      fiscalInfoEndDate: null,
    });

    if (props.showFiscalCalendar) {
      setResetFilter(!resetFilter);
    }
  };

  const getFiltersOptions = async (selected, current) => {
    try {
      props.setLostSalesScreenLoader(true);
      const selectedFilters =
        props.lostSalesFilterDependency?.length > 0
          ? cloneDeep(props.lostSalesFilterDependency)
          : selected;
      const response = await fetchFilterOptions(
        filters || [],
        selectedFilters,
        current
      );

      let filterElements = cloneDeep(response);
      setFilterData(response);
      props.setLostSalesFilterElements(filterElements);
    } catch (error) {
      displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      props.setLostSalesScreenLoader(false);
    }
  };

  const fetchLostSalesData = async () => {
    try {
      props.setLostSalesScreenLoader(true);
      let graphReqBody = {
        meta: tableConfigurationMetaData.meta,
        filters: props.selectedFilters,
      };
      let response = await props.getLostSalesFiscalWeekGraph(graphReqBody);
      props.setLostSalesFiscalGraphData(response.data?.data);
      props.setLostSalesScreenLoader(false);
      response.data?.status && setShowLostSalesDetails(true);
    } catch (err) {
      displaySnackMessages(ERROR_MESSAGE, "error");
      props.setLostSalesScreenLoader(false);
    }
    setOpenModal(false);
  };

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        props.setLostSalesScreenLoader(true);
        const lostSalesFilters = await fetchFilterConfig(
          "inventorysmart_reportings"
        );

        if (props.showFiscalCalendar) {
          const getFinancialCalendarData = await getCoreFiscalCalendar();
          setFiscalCalendarDetails(getFinancialCalendarData?.data?.data);
        }

        setFilters(lostSalesFilters);
      } catch (error) {
        props.setLostSalesScreenLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    fetchFilters();

    return () => {
      props.clearLostSalesStates();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(props.selectedFilters)) {
      if (props.hideGraphComponent) {
        setShowLostSalesDetails(true);
      } else {
        fetchLostSalesData();
      }
    }
  }, [props.selectedFilters]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {props.lostSalesFilterDependency?.length > 0 && (
          <FilterChips
            filterConfig={props.lostSalesFilterDependency}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <Loader
            loader={props.lostSalesScreenLoader || props.lostSalesDataLoader}
          >
            <Filters
              filters={filterData}
              update={updateFilters}
              resetFilter={resetFilter}
              filterElevation={3}
              showBorderedWrapper={false}
              screen="lost-sales-report"
              doNotUpdateDefaultValue
              onFilter={applyFilters}
              onReset={resetSelectedFilterValues}
              customFilter={props.showFiscalCalendar}
              customFilterOrderTop={props.showFiscalCalendar}
              customComponent={
                props.showFiscalCalendar && (
                  <div className={globalClasses.fullWidth}>
                    <CustomFilter
                      staticFilters={INVENTORY_DASHBOARD_STATIC_FILTERS}
                      fiscalCalendarDetails={fiscalCalendarDetails}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                      restrictFutureDatesSelection
                      maxOneWeekSelection={1}
                      displaySnackMessages={displaySnackMessages}
                      isGroup={false}
                    />
                  </div>
                )
              }
            />
            {props.showFiscalCalendar && (
              <ActionButton
                onFilter={() =>
                  applyFilters(
                    props.lostSalesFilterElements,
                    props.lostSalesFilterDependency
                  )
                }
                onReset={resetSelectedFilterValues}
              />
            )}
          </Loader>
        </CustomAccordion>
      </FilterModal>

      {showLostSalesDetails && props.isFiltersValid && (
        <Loader
          loader={props.lostSalesScreenLoader || props.lostSalesDataLoader}
        >
          <div className={globalClasses.filterWrapper}>
            {!props.hideGraphComponent && (
              <LostSalesOpportunityComponent
                lostSalesGraph={
                  props.lostSalesFiscalWeekGraph?.lost_sales_graph
                }
              />
            )}
            <LostSalesTableViewComponent
              hideGraphComponent={props.hideGraphComponent}
              selectedDates={selectedDates}
              weeksAvailable={props.lostSalesFiscalWeekGraph?.weeks_available}
              initialTabledata={
                props.lostSalesFiscalWeekGraph?.lost_sales_details
              }
              enableDownload={props.enableDownload}
              toggleModal={setOpenModal}
            />
          </div>
        </Loader>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    selectedFilters:
      inventorysmartReducer.inventorySmartLostSalesService.selectedFilters,
    isFiltersValid:
      inventorysmartReducer.inventorySmartLostSalesService.isFiltersValid,
    lostSalesScreenLoader:
      inventorysmartReducer.inventorySmartLostSalesService
        .lostSalesScreenLoader,
    lostSalesDataLoader:
      inventorysmartReducer.inventorySmartLostSalesService.lostSalesDataLoader,
    lostSalesFilterElements:
      inventorysmartReducer.inventorySmartLostSalesService
        .lostSalesFilterElements,
    lostSalesFilterDependency:
      inventorysmartReducer.inventorySmartLostSalesService
        .lostSalesFilterDependency,
    lostSalesFiscalWeekGraph:
      inventorysmartReducer.inventorySmartLostSalesService
        .lostSalesFiscalWeekGraph,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    setLostSalesScreenLoader: (body) =>
      dispatch(setLostSalesScreenLoader(body)),
    setSelectedFilters: (body) => dispatch(setSelectedFilters(body)),
    setIsFiltersValid: (body) => dispatch(setIsFiltersValid(body)),
    setLostSalesFiscalGraphData: (body) =>
      dispatch(setLostSalesFiscalGraphData(body)),
    setLostSalesFilterElements: (body) =>
      dispatch(setLostSalesFilterElements(body)),
    setLostSalesFilterDependency: (body) =>
      dispatch(setLostSalesFilterDependency(body)),
    getLostSalesFiscalWeekGraph: (body) =>
      dispatch(getLostSalesFiscalWeekGraph(body)),
    clearLostSalesStates: (body) => dispatch(clearLostSalesStates(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LostSalesComponent);
