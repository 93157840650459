import { Typography, Box, Tabs, Tab, Button } from "@mui/material";
import { cloneDeep, isNaN, orderBy } from "lodash";
import {
  displayRowsForBudgetTable,
  monthList,
} from "modules/plansmart/utils-plansmart";
import {
  csvFormatter,
  getAllDropdownValues,
  getHeaderForExcel,
} from "../plansmart-utility";
import moment from "moment";
import get from "lodash/get";
import SavePivotMenu from "./SavePivotMenu";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import CellRenderers from "Utils/agGrid/cellRenderer";
import { decimalsFormatter } from "Utils/formatter";
import {
  dollarArr,
  intArr,
  MAX_ROW_ALLOWED_FOR_COMPARE,
  percentArr,
  referencePercentArr,
} from "modules/plansmart/constants-plansmart/stringConstants";
import { nonEditableCell } from "Utils/agGrid/table-functions";

const getValueSubstituteForVar = (
  tempFormula,
  key,
  tempDataCloned,
  varianceOrCurrent,
  columnId,
  bucket_key
) => {
  let splittedFormula = tempFormula[key]?.formula?.split(
      /([\+\-\*\(\)\[\]\/])/
    ),
    formulaSolved = "";
  for (let str of splittedFormula) {
    let mtrc = str.replace(" ", "");
    if (mtrc.match(/[a-z]/i)) {
      let temp = findValueByKeyForMetricData(
        tempDataCloned,
        mtrc,
        varianceOrCurrent,
        columnId,
        bucket_key
      )?.[mtrc];
      if (temp === undefined || temp === null || isNaN(temp)) {
        formulaSolved = 0;
        break;
      } else formulaSolved += temp;
    } else formulaSolved += mtrc;
  }
  return formulaSolved;
};

const findValueByKeyForMetricData = (
  data,
  str,
  varianceOrCurrent,
  columnId,
  bucket_key
) => {
  let foundValues = {};
  data?.filter((_obj) => {
    if (
      _obj.metric.includes(str) &&
      _obj.metric.includes(bucket_key) &&
      _obj.reference === varianceOrCurrent
    ) {
      foundValues[str] = _obj[columnId];
    }
  });
  return foundValues;
};

export const renamePlansmartPlanningTableKey = (obj, old_key, new_key) => {
  // check if old key = new key
  if (new_key && old_key && old_key !== new_key) {
    Object.defineProperty(
      obj,
      new_key, // modify old key
      // fetch description from object
      Object.getOwnPropertyDescriptor(obj, old_key)
    );
    delete obj[old_key]; // delete old key
  }
};

export const generateUpdatePayloadwithKeys = (obj, weekLevelKeys) => {
  Object.keys(obj).forEach((metric) => {
    Object.keys(obj[metric]).forEach((key) => {
      renamePlansmartPlanningTableKey(obj[metric], key, weekLevelKeys[key]);
    });
  });
  return obj;
};

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const setInputFormatForColumn = (cellProps, returnAttribute) => {
  //add metrics to respected array to assign appropriate symbol
  let inputAttribute = " ";
  let temp_bucket_keys = ["clr_", "fp_", "total_"];
  let metric = "";
  temp_bucket_keys.find((element) => {
    if (cellProps?.data?.metric?.includes(element)) {
      metric = cellProps?.data?.metric?.replace(element, "");
    }
  });
  //add metrics to respected array to assign appropriate symbol
  if (intArr.indexOf(metric) > -1) {
    cellProps.column.type = "int";
    cellProps.column.typeFormat = "number";
    cellProps.column.formatter = "roundOff";
    cellProps.column.fixTo = 0;
  }
  if (dollarArr.indexOf(metric) > -1) {
    cellProps.column.type = "dollar";
    cellProps.column.fixTo = 0;
    cellProps.column.formatter = "roundOff";
    inputAttribute = "$";
  }
  if (
    referencePercentArr.indexOf(cellProps?.data?.reference) > -1 ||
    percentArr.indexOf(metric) > -1
  ) {
    cellProps.column.type = "percentage";
    cellProps.column.fixTo = 2;
    cellProps.column.removeValidation = true;
    cellProps.column.formatter = "roundOfftoTwoDecimals";
    inputAttribute = "%";
  }
  cellProps.column.is_lockable = true;
  if (cellProps?.data?.metric?.includes("total_")) {
    cellProps.column.disabled = true;
    cellProps.column.is_lockable = false;
  } else {
    cellProps.column.disabled = false;
  }
  return returnAttribute ? inputAttribute : cellProps.column;
};

export const noEditableCustomCellRender = (cellProps) => {
  if (
    cellProps.column.colId.includes("week") ||
    cellProps.column.colId.includes("total")
  ) {
    const item = {
      ...setInputFormatForColumn(cellProps, false),
      is_editable: false,
    };
    return nonEditableCell(item)(cellProps) + "";
  }
  return nonEditableCell(cellProps?.colDef)(cellProps);
};

/**
 * @function
 * @description Used for conditional editable cells (reference === compare row will be non-editable)
 */
export const customCellRenderer = (cellProps) => {
  let rowsWithoutEditablerenderer = ["compare", "forcasted", "variance_fcst"];
  if (
    rowsWithoutEditablerenderer.indexOf(cellProps.data?.reference) > -1 ||
    cellProps.data?.comparePlan ||
    cellProps.data.disableEditable
  ) {
    let inputAttribute = setInputFormatForColumn(cellProps, true);
    if (cellProps.value) {
      return inputAttribute === "%"
        ? `${Math.round(cellProps.value * 100) / 100} ${inputAttribute}`
        : `${inputAttribute} ${Math.round(cellProps.value * 100) / 100}`;
    } else if (cellProps.value === 0) {
      return inputAttribute === "%"
        ? `${cellProps.value} ${inputAttribute}`
        : `${cellProps.value}`;
    } else {
      return "-";
    }
  }
  return (
    <CellRenderers
      cellData={cellProps}
      column={setInputFormatForColumn(cellProps, false)}
      className="reduced_space"
    ></CellRenderers>
  );
};

const isMetricHidden = (
  hiddenMetrics,
  category,
  metric,
  reference,
  planCode,
  isComparePlan = false
) => {
  if (isComparePlan) {
    return get(
      hiddenMetrics,
      `${planCode}.${category}.${metric}.${reference}`,
      false
    );
  } else {
    return get(hiddenMetrics, `${category}.${metric}.${reference}`, false);
  }
};

const checkInsideComparePlanData = (
  category,
  subCategory,
  comparePlan,
  hiddenMetrics
) => {
  if (comparePlan?.length > 0) {
    const result = [];
    for (const i in comparePlan) {
      const comparePlanData = comparePlan[i].compare_plans[0];
      const comparePlanRefColMap = comparePlan[i].ref_col_mapping;
      const metricsData = comparePlanData.metrics;
      const comparePlanCode = comparePlanData.plan_code;
      const comparePlanLabel = comparePlan[i].name;
      Object.keys(metricsData[category][subCategory]).forEach(
        (reference, inx) => {
          if (displayRowsForBudgetTable.includes(reference)) {
            result.push({
              ...metricsData[category][subCategory][reference],
              metric: subCategory,
              metricLabel: metricsData[category][subCategory].label,
              bucket_category: metricsData[category][subCategory].bucket_category,
              comparePlan: true,
              planCode: comparePlanCode,
              hide: isMetricHidden(
                hiddenMetrics,
                category,
                subCategory,
                reference,
                comparePlanCode,
                true
              ),
              reference: reference,
              referenceLabel: comparePlanRefColMap?.[reference] || reference,
              oldReference: reference,
              category: category.replace("_category", ""),
              originalCategory: category,
              ...calculateTotalForBudgetTable(
                metricsData[category][subCategory][reference]
              ),
              rowId: inx + reference + category + "imported",
              uniqueId:
                subCategory +
                reference +
                category +
                "compare" +
                comparePlanLabel +
                i,
            });
          }
        }
      );
    }
    return result.length > 0 ? result : false;
  } else {
    return false;
  }
};

/**
 * @function
 * @description Function to get the keys for match with dropdown
 */
const getMatchWithList = (data, setPlanRefColMapping) => {
  let arr = [];
  for (let key in data) {
    let obj = {};
    let splittedKey = data[key]["reference"].split("_")[0];
    if (
      !(
        (data[key]["reference"] === "current" || splittedKey === "variance") &&
        !data[key]["comparePlan"]
      )
    ) {
      if (data[key]["reference"] !== "variance") {
        obj["label"] = data[key]["comparePlan"]
          ? data[key]["planCode"]
          : data[key]["referenceLabel"];
        obj["value"] = data[key]["comparePlan"]
          ? data[key]["planCode"]
          : data[key]["reference"];
        arr.push(obj);
      }
    }
  }
  setPlanRefColMapping(arr);
};

/**
 * @function
 * @param {response}
 * @returns {table data}
 * @description parse the response data into rows of table.
 */
export const parseBudgetTableResponseData = (
  response,
  hiddenMetrics,
  comparePlanRes,
  setPlanRefColMapping
) => {
  let tempData = response?.metrics,
    ref_col_mapping = response?.ref_col_mapping;
  let subRow = [];
  let isCompareData = false;
  if (tempData) {
    Object.keys(tempData).forEach((category, index) => {
      let subRows = tempData[category];
      Object.keys(subRows).forEach((key) => {
        Object.keys(subRows[key]).forEach((reference) => {
          if (displayRowsForBudgetTable.includes(reference)) {
            let tempKeyValue = {
              category: category.replace("_category", ""),
              metric: key,
              max_val: subRows[key].max_val,
              min_val: subRows[key].min_val,
              max_color: subRows[key].max_color,
              min_color: subRows[key].min_color,
              hide: isMetricHidden(hiddenMetrics, category, key, reference),
              referenceLabel: ref_col_mapping?.[reference] || reference,
              reference: reference,
              ...subRows[key][reference],
              uniqueId: key + reference + category,
              metricLabel: subRows[key].label,
              rowId: key + reference + category,
              originalCategory: category,
              bucket_category: subRows[key].bucket_category,
            };
            subRow.push(tempKeyValue);
          }
        });
        isCompareData = checkInsideComparePlanData(
          category,
          key,
          comparePlanRes,
          hiddenMetrics
        );
        if (isCompareData) {
          subRow.push(...isCompareData);
        }
      });
    });
  }
  let listMatchList = isCompareData ? subRow.slice(0, 7) : subRow.slice(0, 5);
  getMatchWithList(listMatchList, setPlanRefColMapping);
  return subRow;
};

const addSubCategory = (
  mainData,
  metricObj,
  MetricName,
  keyName,
  moveLeft,
  metricInx,
  subRow
) => {
  Object.keys(metricObj).forEach((reference, refInx) => {
    if (reference !== "label") {
      const referenceObj = metricObj[reference];
      const obj = {
        metric: MetricName,
        reference: reference,
        referenceLabel: reference,
        ...referenceObj.value,
        uniqueId: reference + keyName + MetricName + refInx + reference,
        moveLeft: moveLeft,
      };
      subRow.push(obj);
      if (referenceObj.SubCategory) {
        addSubCategory(
          mainData,
          referenceObj.SubCategory,
          MetricName,
          keyName,
          moveLeft + 15,
          metricInx,
          subRow
        );
      }
    }
  });
  return subRow;
};

export const pivotViewParsing = (data) => {
  const result = [];
  Object.keys(data).forEach((categoryKey, inx) => {
    const categoryObj = data[categoryKey];
    Object.keys(categoryObj).forEach((metricCategory, metricInx) => {
      const metricObj = categoryObj[metricCategory];
      if (metricCategory !== "category_order" && metricObj.value) {
        const metricName = metricObj.label;
        const subRows = addSubCategory(
          metricObj,
          metricObj.value,
          metricName,
          metricCategory,
          0,
          metricInx,
          []
        );
        result.push({
          metric: data[metricCategory]?.label || metricName,
          metricLabel: metricName,
          expandLabel: metricName,
          subRows: subRows,
          uniqueId: metricCategory + metricInx,
          expandableCols: "metricLabel",
          originalMetric: metricCategory,
        });
      }
    });
  });
  return result;
};

export const parserFoSkuTable = (response) => {
  const { metrics, col_ref_mapping } = response;
  const tableRows = [];
  Object.keys(metrics).forEach((metricKey, metricInx) => {
    const metricObj = metrics[metricKey];
    const genCommonObj = (valueObj, disableEditable, category) => ({
      metricKey: metricKey,
      category: category,
      disableEditable: disableEditable,
      ...valueObj,
    });
    tableRows.push({
      ...genCommonObj(metricObj.value, true, col_ref_mapping[metricKey]),
      uniqueId: metricKey + metricInx,
    });
    Object.keys(metricObj.year_values).forEach((yearKey, yearInx) => {
      const specificYearObj = metricObj.year_values[yearKey];

      tableRows.push({
        ...genCommonObj(specificYearObj, true, col_ref_mapping[yearKey]),
        yearKey: yearKey,
        yearValues: true,
        uniqueId: metricKey + yearKey + metricInx + yearInx,
      });
    });
    Object.keys(metricObj.skuData).forEach((storeKey, storeInx) => {
      const specificStoreObj = metricObj.skuData[storeKey];
      tableRows.push({
        ...genCommonObj(
          specificStoreObj,
          false,
          col_ref_mapping.sku[metricKey][storeKey]
        ),
        storeKey: storeKey,
        sku: true,
        uniqueId: metricKey + storeKey + metricInx + storeInx,
      });
    });
  });
  return tableRows;
};

export const setFilterOptions = async (
  filter,
  plancode,
  getPlanFilterDropdownOptions,
  filtersForRows,
  planDetails
) => {
  let finalTabs = filter.map(async (item) => {
    let postBody = {
      filter_type: "cascaded",
      attribute_name: item.column_name,
      filters: updateFilterDependency(filtersForRows, filter, item),
    };
    let opt = await getPlanFilterDropdownOptions(plancode, postBody);
    let filterOptions = opt.data.data.map((option) => {
      return {
        value: option.attribute,
        label: option.attribute,
        id: option.attribute,
      };
    });
    return {
      id: item.column_name,
      type: item.label,
      options: filterOptions,
      column_name: item.column_name,
      label: item.label,
    };
  });
  finalTabs = await Promise.all(finalTabs);
  if (planDetails?.plan_type === "bottom-up") {
    return finalTabs?.reverse();
  } else return finalTabs;
};

const updateFilterDependency = (selectedOptions, tabData, item) => {
  if (selectedOptions) {
    let dependency = [];
    const filterKeys = Object.keys(selectedOptions);
    tabData?.forEach((key, inx) => {
      if (selectedOptions[key.id] && inx < filterKeys.indexOf(item.id)) {
        dependency.push({
          attribute_name: key.id,
          operator: "in",
          values: selectedOptions[key.id],
          filter_type: "cascaded",
        });
      }
    });
    return dependency;
  } else {
    return [];
  }
};

/* function to modify other related cells, based on current cell modification.
  1. Save the currently modified columnId and perfor m the following steps, except for the saved columnID state.  - done 
  2. Get the reference and metric of current row, oldValue, newValue, columnId. - done.
  3. Calculate the other same metric data first, like if current of sales_$ is modified then calulated these values. 
  4. calculate the total for these rows. 
  5. get the rows which are dependent on formula, say margin is dependent on sales_$ modification , apply formula and recalculate total. 
*/

export const calculateTotalForBudgetTable = (row) => {
  let totalMonth = {},
    total = 0;
  Object.keys(row).forEach((prop) => {
    if (monthList.some((v) => prop.includes(v) && !prop.includes("total"))) {
      let month = prop.split("_week")[0];
      total += row[prop];
      const totalVar = `${month}_total`;

      if (totalMonth[totalVar] === undefined) {
        totalMonth[totalVar] = 0;
      }
      //  get total for all the weeks level;
      totalMonth[totalVar] += row[prop];
    }
    return totalMonth;
  });
  return { total: total, ...totalMonth };
};

export const comparePlanBudgetTable = (list) => {
  return list.map((planObj) => ({
    ...planObj,
    plan_name: planObj.name,
    year: planObj.compare_year,
    created_by: planObj.created_at,
    plan_version: "",
    l0_name: planObj.l0_name[0],
    l1_name: planObj.l1_name[0],
    l2_name: planObj.l2_name[0],
    plan_status: "",
  }));
};

export const getCurPlanObjForCompReq = (list, isMetricObj = false) => {
  const result = {};
  const currentPlanObj = {};
  if (isMetricObj) {
    Object.keys(list).forEach((categoryKey) => {
      Object.keys(list[categoryKey]).forEach((metricKey) => {
        if (list[categoryKey][metricKey]["current"]) {
          result[metricKey] = {
            ...list[categoryKey][metricKey]["current"],
          };
        }
      });
    });
    return {
      curPlanForCompPlanReq: result,
    };
  }
  list.forEach((metric) => {
    const categoryKey = metric.originalCategory;
    const metricKey = metric.metric;
    const referenceKey = metric.reference;
    if (!metric.comparePlan) {
      if (!currentPlanObj[categoryKey]) currentPlanObj[categoryKey] = {};
      if (!currentPlanObj[categoryKey][metricKey])
        currentPlanObj[categoryKey][metricKey] = {
          label: metric.metricLabel,
          max_val: metric.max_val,
          min_val: metric.min_val,
          max_color: metric.max_color,
          min_color: metric.min_color,
          bucket_category: metric.bucket_category,
        };
      currentPlanObj[categoryKey][metricKey][referenceKey] = {
        ...metric,
      };

      if (referenceKey === "current") {
        const metricValueOnlyWeekObj = {};
        Object.keys(metric).forEach((metricDataKey) => {
          if (metricDataKey.includes("week")) {
            metricValueOnlyWeekObj[metricDataKey] = metric[metricDataKey];
          }
        });
        result[metricKey] = metricValueOnlyWeekObj;
      }
    }
  });
  return {
    curPlanForCompPlanReq: result,
    originalCurrentPlan: currentPlanObj,
  };
};

export const fetchBudgetTableColumn = async (data) => {
  const {
    history,
    props,
    pivotViewMode,
    setPlanBudgetColumns,
    showSnackMessage,
    filtersForRows,
    setTabData,
    setActiveTab,
    setTabIndex,
    setPlanBudgetData,
    skuViewMode,
    disableTabUpdate = false,
    editableMetricFormulas,
  } = data;
  const action = history?.location?.type === "view" ? "view" : "";
  const plancode = props.match.params.plancode;
  props.setPlansmartBudgetFilterLoader(true);
  props.setPlanSmartBudgetTableColDefLoader(true);
  try {
    const details = pivotViewMode
      ? await props.getPivotColDef(plancode)
      : skuViewMode
      ? await props.getPlanningTableSkuColumns(plancode, action)
      : await props.getPlanningTableColumns(plancode, action);
    if (skuViewMode) {
      const skuColumns = agGridColumnFormatter(details.data);
      skuColumns.forEach((column) => {
        //TODO: need to verify after api integration is done
        if (column.column_name === "category") {
          column.cellStyle = (params) => {
            if (params.data.sku) {
              return {
                textAlign: "right",
              };
            } else if (params.data.yearValues) {
              return {
                paddingLeft: "40px",
              };
            }
          };
        }
      });
      setPlanBudgetColumns(skuColumns);
      props.setPlanSmartBudgetTableColDefLoader(false);
      return;
    }
    details.data.data.forEach((item) => {
      if (item.column_name === "metric") {
        item.is_row_span = true;
      }
    });
    const tabsData = await props.getPlanHierarchies();
    const tempPlanDetails = await props.getPlanSmartPlanDetails(plancode);
    let tabInfo = await setFilterOptions(
      tabsData.data.data.level_info,
      plancode,
      props.getPlanFilterDropdownOptions,
      filtersForRows,
      tempPlanDetails.data.data
    );
    if (!disableTabUpdate) {
      if (details.data.data?.plan_type === "bottom-up") {
        setTabData(tabInfo?.reverse());
      } else setTabData(tabInfo);
    }
    let columns = pivotViewMode
      ? details?.data?.data
      : details?.data?.data.map((item) => {
          item.width = 90;
          if (item.column_name === "total") {
            item.Cell = (cellProps) => {
              let inputAttribute = setInputFormatForColumn(cellProps, true);
              if (cellProps.value) {
                return inputAttribute === "%"
                  ? `${
                      Math.round(cellProps.value * 100) / 100
                    } ${inputAttribute}`
                  : `${inputAttribute} ${
                      Math.round(cellProps.value * 100) / 100
                    }`;
              } else if (cellProps.value === 0) {
                return inputAttribute === "%"
                  ? `${cellProps.value} ${inputAttribute}`
                  : `${cellProps.value}`;
              } else {
                return " ";
              }
            };
          }
          return item;
        });
    if (!pivotViewMode && !disableTabUpdate) {
      setActiveTab(tabInfo?.[0]?.id);
      setTabIndex(0);
    }
    columns = getColumns(columns, history, editableMetricFormulas);
    setPlanBudgetColumns(columns);
    props.setPlansmartBudgetFilterLoader(false);
    props.setPlanSmartBudgetTableColDefLoader(false);
  } catch (error) {
    props.setPlansmartBudgetFilterLoader(false);
    props.setPlanSmartBudgetTableColDefLoader(false);

    showSnackMessage(
      error?.response?.data?.detail || "Error in fetching plan columns.",
      "error"
    );
  }
};

export const fetchComparePlanFilterDef = async (
  props,
  plancode,
  setComparePlanFilterData,
  setComparePlanFilter
) => {
  props.setPlanSmartComparePlanFilterLoader(true);
  try {
    const filterData = await props.getComparePlanFilters(plancode);
    const fields = getAllDropdownValues(filterData.data.data.filters, true);
    fields
      .then((data) => {
        const selection = filterData?.data?.data?.selection;
        const selectedFilters = {
          ...filterData?.data?.data?.selection,
        };
        data?.forEach((field) => {
          if (field.accessor === "plan_period") {
            const startDate = moment(
              selection?.plan_period_sdate,
              "YYYY-MM-DD"
            );
            const endDate = moment(selection?.plan_period_edate, "YYYY-MM-DD");
            selectedFilters["plan_period"] = [startDate, endDate];
          } else if (
            field.isMulti === false &&
            Array.isArray(selection?.[field.accessor])
          ) {
            selectedFilters[field.accessor] = selection?.[field.accessor]?.[0];
          } else {
            selectedFilters[field.accessor] = selection?.[field.accessor];
          }
        });
        setComparePlanFilterData(selectedFilters);
        setComparePlanFilter(data);

        props.setPlanSmartComparePlanFilterLoader(false);
      })
      .catch(() => {
        props.setPlanSmartComparePlanFilterLoader(false);
      });
  } catch (error) {
    props.setPlanSmartComparePlanFilterLoader(false);
  }
};

export const fetchBudgetTableFn = async (
  props,
  disablePlanningScreen,
  plancode,
  setDisableAllOptions,
  setPlanDetails,
  showSnackMessage
) => {
  try {
    const details = await props.getPlanSmartPlanDetails(plancode);
    if (disablePlanningScreen.indexOf(details.data.data.status) > -1) {
      setDisableAllOptions(true);
    } else {
      setDisableAllOptions(false);
    }
    setPlanDetails(details.data.data);
  } catch (error) {
    showSnackMessage(
      error?.response?.data?.detail || "Error in fetching plan data",
      "error"
    );
  }
};

export const fetchMetricConfig = async (props, setMetricConfig) => {
  try {
    const config = await props.getMetricsConfig();
    setMetricConfig(config?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

/**
 * @function
 * @description Function to get the metrics formula
 */

export const fetchMetricsFormulaForEditableMetrics = async (props) => {
  try {
    const config = await props.fetchEditableMetricsFormula();
    props.setMetricsFormula(config?.data?.data);
  } catch (error) {
    console.log(error);
  }
};

export const fetchBudgetTableData = async (dataTemp) => {
  const {
    props,
    planCode,
    pivotViewMode,
    filtersForRows,
    setCurrentConfig,
    setPlanBudgetData,
    setCurrentPlan,
    setWeekLevelKeys,
    setTargetsData,
    selectedComparePlanRows,
    showSnackMessage,
    setCompletePlanBudgetData,
    setPlanRefColMapping,
    setOriginalColRefMapping,
    setSelectedComparePlanRows,
    originalColRefMapping,
    prevSelectedPlans,
    setPrevSelectedPlans,
    setComparePlanRes,
    comparePlanRes,
    setComparePlanModal,
    planDetails,
    budgetTableRef,
    hiddenMetrics,
    skuViewMode,
  } = dataTemp;
  const postBody = {
    plan_code: planCode,
    level: {
      ...filtersForRows,
    },
  };
  let details = pivotViewMode
    ? props.fetchPivotViewData(postBody)
    : skuViewMode
    ? props.fetchPlanBudgetSkuDetails(postBody)
    : props.fetchPlanBudgetDetails(postBody);
  props.setPlansmartBudgetTableLoader(true);
  details
    .then(async (data) => {
      setCurrentConfig(data?.data?.data?.metrics);
      const budgetTableData = pivotViewMode
        ? pivotViewParsing(data?.data?.data?.metrics)
        : skuViewMode
        ? parserFoSkuTable(data.data)
        : parseBudgetTableResponseData(
            data?.data?.data,
            hiddenMetrics,
            [],
            setPlanRefColMapping
          );
      setPlanBudgetData(budgetTableData);
      if (skuViewMode) {
        props.setPlansmartBudgetTableLoader(false);
        budgetTableRef.current.api.refreshToolPanel();
        budgetTableRef.current.api.setSideBarVisible(false);
        return;
      }
      budgetTableRef.current.api.setSideBarVisible(true);
      setOriginalColRefMapping(data?.data?.data?.ref_col_mapping);
      setCompletePlanBudgetData(cloneDeep(budgetTableData));
      setCurrentPlan(data?.data?.data || {});
      if (!pivotViewMode) {
        setWeekLevelKeys(data?.data?.data?.week_mapping);
        setTargetsData(data?.data?.data?.targets);
        if (prevSelectedPlans.length > 0) {
          await handleImportPlan({
            selectedPlans: prevSelectedPlans,
            props,
            filtersForRows,
            setSelectedComparePlanRows,
            setComparePlanRes,
            comparePlanRes,
            setComparePlanModal,
            showSnackMessage,
            prevSelectedPlans: prevSelectedPlans,
            setPrevSelectedPlans,
            selectedComparePlanRows,
            planDetails,
            budgetTableRef,
            originalColRefMapping,
            setPlanBudgetData,
            fromTabChange: true,
            newTableData: data?.data?.data,
            hiddenMetrics,
            setPlanRefColMapping,
          });
        }
      }
      props.setPlansmartBudgetTableLoader(false);
    })
    .catch((error) => {
      props.setPlansmartBudgetTableLoader(false);
      setPlanBudgetData([]);
      showSnackMessage(
        error?.response?.data?.detail || "Error in fetching plan data",
        "error"
      );
    });
};

/**
 * @function
 * @description Function to update the dashboard after changes
 */
export const updateBudgetTable = (
  payloadUpdateBudgetTable,
  weekLevelKeys,
  formTargetDatas,
  setPayloadUpdateBudgetTable,
  props,
  showSnackMessage
) => {
  let updateDataPayload = {
    ...payloadUpdateBudgetTable,
    metrics: {
      ...generateUpdatePayloadwithKeys(
        payloadUpdateBudgetTable?.metrics,
        weekLevelKeys
      ),
    },
    targets: {
      target_metrics: Object.keys(formTargetDatas).map((key) => {
        return {
          attribute_name: key,
          attribute_value: formTargetDatas[key],
          update_flag: 0,
        };
      }),
      update_date: true,
    },
  };

  let response = props.updateBudgetTableData(updateDataPayload);
  props.setPlansmartBudgetUpdateLoader(true);
  response
    .then((responseData) => {
      if (responseData.status) {
        showSnackMessage("Plan updated successfully.", "success");
        props.setPlansmartBudgetUpdateLoader(false);
        setPayloadUpdateBudgetTable(
          Object.assign(payloadUpdateBudgetTable, { metrics: {} })
        );
      }
    })
    .catch((error) => {
      showSnackMessage(
        error?.response?.data?.detail || "Error in fetching plan details.",
        "error"
      );
      setPayloadUpdateBudgetTable(
        Object.assign(payloadUpdateBudgetTable, { metrics: {} })
      );
      props.setPlansmartBudgetUpdateLoader(false);
    });
};

export const handleImportPlan = async (data) => {
  let {
    selectedPlans,
    props,
    filtersForRows,
    setSelectedComparePlanRows,
    setComparePlanRes,
    comparePlanRes: prevComparePlan,
    setComparePlanModal,
    showSnackMessage,
    prevSelectedPlans,
    setPrevSelectedPlans,
    selectedComparePlanRows,
    planDetails,
    budgetTableRef,
    originalColRefMapping,
    setPlanBudgetData,
    fromTabChange,
    newTableData,
    hiddenMetrics,
    setPlanRefColMapping,
  } = data;
  props.setPlanSmartGetPlansToCompareLoader(true);
  const tableRows = get(budgetTableRef, "current.props.rowData", []);
  const {
    curPlanForCompPlanReq,
    originalCurrentPlan,
  } = getCurPlanObjForCompReq(
    fromTabChange ? newTableData.metrics : tableRows,
    !!newTableData
  );
  const bodyObj = {
    compare_plans: selectedPlans,
    metrics: curPlanForCompPlanReq,
    level: {
      ...filtersForRows,
    },
    channel: planDetails.channel,
    season: planDetails.season[0],
  };
  try {
    const comparePlansRes = await props.getPlansToCompare(bodyObj);
    const comparePlans = get(comparePlansRes, "data.data.compare_plans", []);
    const concatComparePlans = newTableData
      ? comparePlans.map((comparePlanData) => ({
          compare_plans: [comparePlanData],
          ref_col_mapping: comparePlansRes.data.data.ref_col_mapping,
          week_mapping: comparePlansRes.data.data.week_mapping,
        }))
      : [...prevComparePlan, comparePlansRes.data.data];
    setPrevSelectedPlans(prevSelectedPlans);
    setComparePlanRes(concatComparePlans);
    if (comparePlans.length > 0) {
      const parsedData = parseBudgetTableResponseData(
        {
          metrics: fromTabChange ? newTableData.metrics : originalCurrentPlan,
          ref_col_mapping: originalColRefMapping,
        },
        hiddenMetrics,
        concatComparePlans,
        setPlanRefColMapping
      );
      setPlanBudgetData(parsedData);
      budgetTableRef.current.api.setRowData(parsedData);
      budgetTableRef.current.api.refreshCells({
        force: true,
      });
    } else {
      showSnackMessage("Error in fetching import plan details.", "error");
      const removeImportedPlan = selectedComparePlanRows.filter((plan) =>
        selectedPlans.indexOf(plan.plan_code)
      );
      setSelectedComparePlanRows(removeImportedPlan);
      setComparePlanRes([]);
    }
  } catch (error) {
    showSnackMessage("Error in fetching import plan details.", "error");
    const removeImportedPlan = selectedComparePlanRows.filter((plan) =>
      selectedPlans.indexOf(plan.plan_code)
    );
    setSelectedComparePlanRows(removeImportedPlan);
    setComparePlanRes([]);
  }
  setComparePlanModal(false);
  props.setPlanSmartGetPlansToCompareLoader(false);
};

export const handleClearImportedPlan = (
  setSelectedComparePlanRows,
  setComparePlanRes,
  prevSelectedPlans,
  setPrevSelectedPlans,
  budgetTableRef,
  setPlanBudgetData,
  originalColRefMapping,
  hiddenMetrics,
  setHiddenMetrics,
  setPlanRefColMapping
) => {
  setSelectedComparePlanRows([]);
  setPrevSelectedPlans([]);
  setComparePlanRes([]);
  const rmComparePlanForHiddenMetric = cloneDeep(hiddenMetrics);
  prevSelectedPlans.forEach((planCode) => {
    delete rmComparePlanForHiddenMetric[planCode];
  });
  setHiddenMetrics(rmComparePlanForHiddenMetric);
  const tableRows = get(budgetTableRef, "current.props.rowData", []);
  const { originalCurrentPlan } = getCurPlanObjForCompReq(tableRows);
  const parsedData = parseBudgetTableResponseData(
    { metrics: originalCurrentPlan, ref_col_mapping: originalColRefMapping },
    hiddenMetrics,
    [],
    setPlanRefColMapping
  );
  setPlanBudgetData(parsedData);
  budgetTableRef.current.api.setRowData(parsedData);
  budgetTableRef.current.api.refreshCells({
    force: true,
  });
};

//to get the table data for download in csv format
export const getDownloadData = async (
  setCsvHeaders,
  setCsvData,
  planBudgetColumns,
  planBudgetData,
  csvHeaders
) => {
  setCsvHeaders(getHeaderForExcel(cloneDeep(planBudgetColumns)));
  setCsvData(csvFormatter(cloneDeep(planBudgetData), csvHeaders));
};

export const customHeader = (
  setActiveTab,
  setTabIndex,
  tabData,
  tabIndex,
  cssClasses
) => {
  return (
    <Tabs
      value={tabIndex}
      onChange={(e, value) => {
        setActiveTab(e.target.id);
        setTabIndex(value);
      }}
      aria-label="dasboard tabs"
      classes={{
        root: cssClasses.rootTab,
      }}
    >
      {tabData?.map((item) => (
        <Tab label={item.type} id={item.id} />
      ))}
      {/* <Tab label="SKU" id="sku" /> */}
    </Tabs>
  );
};

export const handlePivotView = (
  value,
  tabData,
  setActiveTab,
  setPivotViewMode
) => {
  if (value) {
    const tabValue = tabData[tabData.length - 1];
    setActiveTab(tabValue.id);
  }
  setPivotViewMode(value);
};

export const PivotViewBackButton = (
  planDetails,
  tabData,
  setActiveTab,
  setPivotViewMode,
  setPivotVersionsModal,
  submitPivotView,
  showSnackMessage
) => {
  return (
    <Box display="flex">
      <Button
        variant="text"
        color="primary"
        disableRipple={true}
        onClick={() => setPivotVersionsModal(true)}
      >
        View previous versions
      </Button>
      <Button
        variant="outlined"
        id="plansmartImportPlanBtn"
        onClick={() =>
          handlePivotView(false, tabData, setActiveTab, setPivotViewMode)
        }
      >
        Back to
        <Typography component="span" ml={0.5} noWrap width="50px">
          {planDetails?.name}
        </Typography>
      </Button>
      <SavePivotMenu
        onSave={submitPivotView}
        showSnackMessage={showSnackMessage}
      />
    </Box>
  );
};

const cellStyle = (params) => {
  let cellColorValues = params?.data;
  let rowsWithDifferentCellStyle = ["variance_fcst"];
  let style = {};
  if (rowsWithDifferentCellStyle.indexOf(params?.data?.reference) > -1) {
    if (
      params?.value >= cellColorValues?.min_val &&
      params?.value <= cellColorValues?.max_val
    ) {
      style.color = cellColorValues?.max_color;
    } else if (!isNaN(params?.value)) {
      style.color = cellColorValues?.min_color;
    }
    return style;
  } else {
    let rowsWithPaddingZero = ["variance", "current"];
    if (rowsWithPaddingZero.indexOf(params?.data?.reference) > -1)
      return { padding: "0" };
  }
};

export const getColumns = (
  planBudgetColumn,
  history,
  editableMetricFormulas
) => {
  let tempColumns = [
    {
      column_name: "category",
      type: "list",
      label: "KPI Category",
      rowGroup: true,
      tc_code: 69,
      is_frozen: true,
      hide: true,
    },
    {
      column_name: "bucket_category",
      type: "list",
      label: "KPI category",
      rowGroup: true,
      tc_code: 69,
      is_frozen: true,
      hide: true,
    },
  ];
  tempColumns.push(...planBudgetColumn);
  tempColumns.forEach((column, index) => {
    delete column.rowSpan;
    if (column?.column_name === "metric") {
      return Object.assign(column, {
        valueGetter: (params) => params?.data?.metricLabel,
        rowGroup: true,
        hide: true,
        rowSpan: (params) => 5,
      });
    }
    if (column?.column_name === "reference") {
      return Object.assign(column, {
        valueGetter: (params) => params?.data?.referenceLabel,
        is_frozen: true,
      });
    }
    if (column?.column_name === "total") {
      Object.assign(column, {
        valueGetter: seasonTotalValueGetter,
        is_editable: history?.location?.type === "view" ? false : true,
        cellStyle: cellStyle,
      });
    }
    if (column.sub_headers?.length > 0) {
      return column?.sub_headers?.forEach((item) => {
        delete item.rowSpan;

        item.cellStyle = cellStyle;
        return Object.assign(item, {
          is_lockable: true,
          formatter: false,
          valueGetter: (params) =>
            item?.column_name?.includes("total")
              ? totalValueGetter(params, editableMetricFormulas)
              : getBucketTotal(params),
        });
      });
    }
  });
  return agGridColumnFormatter(tempColumns);
};

export const totalValueGetter = (params, editableMetricFormulas) => {
  //TODO change metricsFormula to editableMetricFormulas
  let metric_key_for_formula = Object.keys(formula).filter((k) => {
    if (params?.data?.metric.includes(k)) return k;
  });
  let totalCalcFormula = formula[metric_key_for_formula]?.total;
  let splittedFormula = totalCalcFormula?.split(/([\+\-\*\(\)\[\]\/])/);
  let tableData = params.api.getModel().gridOptionsWrapper.gridOptions.rowData;
  return getTotalFormulaEquation(splittedFormula, params, tableData);
};

export const seasonTotalValueGetter = (params) => {
  let total = 0;
  let seasonMonthTotal =
    params?.data &&
    Object.keys(params.data).filter((k) => k && k?.includes("week")).length;
  if (params?.data) {
    Object.keys(params.data)?.flatMap((monthKey) => {
      if (monthKey && monthKey.includes("week")) {
        total = parseFloat(total) + parseFloat(params.data[monthKey]);
      }
    });
    if (params.data?.reference === "variance") return total / seasonMonthTotal;
    return total;
  }
};

export const lockCellApi = (cellProps, isLocked, agTableRef) => {
  const fieldName = cellProps.cellData.colDef.field;
  const currentNodeCellLocked = cellProps.cellData.data["cellLocked"] || {};
  cellProps.cellData.node["cellLocked"] = isLocked;
  cellProps.cellData.data["cellLocked"] = {
    ...currentNodeCellLocked,
    [fieldName]: isLocked,
  };
  const isAllCellsAreLocked = (cellLockedObj, parentColumns) => {
    let totalKey;
    const lockedCells = [];
    parentColumns.forEach((column) => {
      const cellLockKey = column.colId;
      if (cellLockKey.includes("total")) {
        totalKey = cellLockKey;
      } else {
        if (cellLockedObj[cellLockKey]) {
          lockedCells.push(cellLockKey);
        }
      }
    });
    if (lockedCells.length === parentColumns.length - 1) {
      return totalKey;
    }
    return null;
  };
  const updateParentRowLeaf = (colId, updateTotal) => {
    cellProps.cellData.node.parent.allLeafChildren.forEach((childRows) => {
      if (
        childRows.data.reference === "current" ||
        childRows.data.reference === "variance"
      ) {
        const lockedCells = childRows.data["cellLocked"] || {};
        childRows.data["cellLocked"] = {
          ...lockedCells,
          [colId]: isLocked,
        };
        if (updateTotal) {
          const totalKey = isAllCellsAreLocked(
            childRows.data["cellLocked"],
            cellProps.cellData.column.parent.children
          );
          if (totalKey) {
            childRows.data["cellLocked"] = {
              ...childRows.data["cellLocked"],
              [totalKey]: isLocked,
            };
          }
        }
      }
    });
  };
  const updateAllColumns = (onlyTotal) => {
    cellProps.cellData.column.parent.children.forEach((childColumn) => {
      if (onlyTotal ? childColumn.colId.includes("total") : true) {
        const latestCurrentNodeCellLocked =
          cellProps.cellData.data["cellLocked"] || {};
        cellProps.cellData.data["cellLocked"] = {
          ...latestCurrentNodeCellLocked,
          [childColumn.colId]: isLocked,
        };
        updateParentRowLeaf(childColumn.colId);
      }
    });
  };
  if (cellProps.cellData.column.colId.includes("total")) {
    updateAllColumns();
  } else {
    if (!isLocked) {
      updateAllColumns(true);
    }
    updateParentRowLeaf(fieldName, true);
  }
  agTableRef.current.api.refreshCells({
    force: true,
  });
};

export const lockCellCustomConditionFn = (instance) => {
  const condition = (instance.data?.cellLocked || {})[instance.colDef.field]
    ? true
    : false;
  return condition;
};

export const onChangeCalculation = (
  data,
  column,
  isChanged,
  value,
  agTableRef,
  editableMetricFormulas,
  oldValue,
  payloadForBudgetTableData,
  setPayloadUpdateBudgetTable
) => {
  //TODO change metricsFormula to editableMetricFormulas
  let metric_key_for_formula = Object.keys(formula)
    .filter((k) => {
      if (data?.metric.includes(k)) return k;
    })
    .flat();
  let metricsFormula = formula[metric_key_for_formula];
  if (data.sku) {
    return;
  }
  if (isChanged || oldValue !== data[column?.colId]) {
    if (column?.colId === "total")
      totalCalculationForBudgetTable(
        data,
        column,
        data[column?.colId],
        agTableRef,
        false,
        true,
        metricsFormula,
        oldValue
      );
    if (column?.colId?.includes("total")) {
      totalCalculationForBudgetTable(
        data,
        column,
        data[column?.colId],
        agTableRef,
        true,
        false,
        metricsFormula,
        oldValue
      );
    } else if (data?.reference === "variance") {
      updateCurrentOnVarianceChange(
        data,
        column,
        value,
        agTableRef,
        null,
        null,
        payloadForBudgetTableData,
        setPayloadUpdateBudgetTable
      );
    } else if (data?.reference === "current") {
      updateVarianceOnCurrentChange(
        data,
        column,
        value,
        agTableRef,
        null,
        null,
        payloadForBudgetTableData,
        setPayloadUpdateBudgetTable
      );
    }
    if (!column?.colId?.includes("total"))
      editableMetricCalculationBudgetTable(
        agTableRef?.current?.props?.rowData,
        data?.metric,
        column,
        data?.reference,
        payloadForBudgetTableData,
        agTableRef,
        metricsFormula,
        setPayloadUpdateBudgetTable
      );
  }
};

export const totalCalculationForBudgetTable = (
  data,
  column,
  value,
  agTableRef,
  totalOnChange,
  onTotalTotalChange,
  metricsFormula,
  oldValue
) => {
  let newValueTotalTotalValue = data?.[column?.colId];
  let month = column?.colId?.split("_total")[0];
  if (column?.colId?.includes("total") && totalOnChange) {
    let totalMinusValue = 0;
    Object.keys(data).flatMap((a) => {
      let a_month = a?.split("_week")[0];
      if (
        monthList.includes(a_month) &&
        column?.colId?.includes(a_month) &&
        data?.cellLocked?.[a] === true
      ) {
        totalMinusValue = totalMinusValue + data[a];
      }
    });
    Object.keys(data)?.map((item) => {
      if (item.includes(month) && !item.includes("total")) {
        if (
          data?.cellLocked?.[item] === undefined ||
          data?.cellLocked?.[item] === false
        ) {
          const proportionateNumber =
            (data[item] / (oldValue - totalMinusValue)) * 100;
          let newMetricValue =
            (proportionateNumber *
              (newValueTotalTotalValue - totalMinusValue)) /
            100;
          Object.assign(data, {
            [item]: newMetricValue,
          });
          if (data?.["reference"] === "variance") {
            updateCurrentOnVarianceChange(
              null,
              null,
              newMetricValue,
              agTableRef,
              data?.metric,
              item
            );
          } else if (data?.["reference"] === "current") {
            updateVarianceOnCurrentChange(
              null,
              null,
              newMetricValue,
              agTableRef,
              data?.metric,
              item
            );
          }
          return Object.assign(data, {
            [item]: newMetricValue,
          });
        }
      }
    });
  }
  if (onTotalTotalChange) {
    let totalMinusValue = 0;
    Object.keys(data).flatMap((a) => {
      let a_month = a?.split("_week")[0];
      if (monthList.includes(a_month) && data?.cellLocked?.[a] === true) {
        totalMinusValue = totalMinusValue + data[a];
      }
    });
    Object.keys(data)?.map((item) => {
      let month = item.split("_week")[0];
      if (monthList.includes(month) && !item.includes("total")) {
        if (
          data?.cellLocked?.[item] === undefined ||
          data?.cellLocked?.[item] === false
        ) {
          const proportionateNumber =
            (data[item] / (oldValue - totalMinusValue)) * 100;
          let newMetricValue =
            (proportionateNumber *
              (newValueTotalTotalValue - totalMinusValue)) /
            100;
          if (data?.["reference"] === "variance") {
            updateCurrentOnVarianceChange(
              null,
              null,
              newMetricValue,
              agTableRef,
              data?.metric,
              item
            );
          } else if (data?.["reference"] === "current") {
            updateVarianceOnCurrentChange(
              null,
              null,
              newMetricValue,
              agTableRef,
              data?.metric,
              item
            );
          }
          return Object.assign(data, {
            [item]: newMetricValue,
          });
        }
      }
    });
    // editableMetricCalculationBudgetTable(
    //   agTableRef?.current?.props?.rowData,
    //   data?.metric,
    //   column,
    //   data?.reference,
    //   null,
    //   agTableRef,
    //   metricsFormula
    // );
  }
  agTableRef.current.api.refreshCells({
    force: true,
  });
};

/* Updating the current of same metric row, where variance is changed */

export const updateCurrentOnVarianceChange = (
  data,
  column,
  value,
  agTableRef,
  metricRef,
  colId,
  payloadForBudgetTableData,
  setPayloadUpdateBudgetTable
) => {
  let columnId = column?.colId || colId,
    metric = data?.metric || metricRef;
  let lyDatForCalculation = agTableRef?.current?.props?.rowData?.filter(
    (item) => item?.reference === "compare" && item?.metric === metric
  )?.[0];
  let newModifiedValue =
    (value * lyDatForCalculation?.[columnId]) / 100 +
    lyDatForCalculation?.[columnId];
  agTableRef?.current?.props?.rowData?.map((item) => {
    if (item?.reference === "current" && item?.metric === metric)
      return Object.assign(item, { [columnId]: newModifiedValue });
    return item;
  });
  // updateBudgetTablePayload(
  //   payloadForBudgetTableData,
  //   newModifiedValue,
  //   value,
  //   metric,
  //   columnId,
  //   setPayloadUpdateBudgetTable
  // );
  agTableRef.current.api.refreshCells({
    force: true,
  });
};

export const updateVarianceOnCurrentChange = (
  data,
  column,
  value,
  agTableRef,
  metricRef,
  colId,
  payloadForBudgetTableData,
  setPayloadUpdateBudgetTable
) => {
  let columnId = column?.colId || colId,
    metric = data?.metric || metricRef,
    oldValue = 0;

  let lyDatForCalculation = agTableRef?.current?.props?.rowData?.filter(
    (item) => item?.reference === "compare" && item?.metric === metric
  )?.[0];
  let newModifiedValue =
    ((value - lyDatForCalculation?.[columnId]) /
      lyDatForCalculation?.[columnId]) *
    100;
  agTableRef?.current?.props?.rowData?.map((item) => {
    if (item?.reference === "variance" && item?.metric === metric) {
      oldValue = item[columnId];
      return Object.assign(item, { [columnId]: newModifiedValue });
    }
    return item;
  });
  // updateBudgetTablePayload(
  //   payloadForBudgetTableData,
  //   newModifiedValue,
  //   oldValue,
  //   metric,
  //   columnId,
  //   setPayloadUpdateBudgetTable
  // );
  agTableRef.current.api.refreshCells({
    force: true,
  });
};

export function currencyFormatter(value) {
  let sansDec = value.toFixed(2).toString();
  let formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formatted}`;
}

export const updateFilterChips = (
  filterList,
  selectedValue,
  setFilterChips,
  accessorKey = "id"
) => {
  const response = {
    filterConfig: [],
  };
  const chipsData = [];
  const dateFilter = {};
  filterList.forEach((filter) => {
    if (
      selectedValue[filter[accessorKey]] &&
      selectedValue[filter[accessorKey]]?.length > 0 &&
      filter?.display_type !== "rangePicker"
    ) {
      chipsData.push({
        dimension: filter?.dimension || filter.type,
        filter_id: "district",
        filter_type: filter.field_type,
        values: selectedValue[filter[accessorKey]]?.map((option) => ({
          value: option,
          label: option,
          id: option,
        })),
      });
    } else if (
      filter?.display_type === "rangePicker" &&
      selectedValue[filter[accessorKey]]?.length > 0
    ) {
      const [startDate, endDate] = selectedValue[filter[accessorKey]];
      if (startDate) {
        dateFilter.start_date = moment.utc(startDate).format("l");
      }
      if (endDate) {
        dateFilter.end_date = moment.utc(endDate).format("l");
      }
    }
  });
  response.filterConfig = chipsData;
  if (Object.keys(dateFilter).length == 2) {
    response.dateFilter = dateFilter;
  }
  setFilterChips(response);
};

const editableMetricCalculationBudgetTable = (
  tempDataCloned,
  metric,
  column,
  varianceOrCurrent,
  payloadForBudgetTableData,
  agTableRef,
  metricsFormula
) => {
  let columnId = column?.colId;
  let bucket_key = metric.split("_")[0];
  orderBy(metricsFormula, "priority", "asc");
  if (metricsFormula)
    Object.keys(metricsFormula).forEach((key, index) => {
      /*
      1. split the key formula.
      2. find all the respective values of splitted formula variables of respective metric from formula.
      3. calculate the value.
      4. if current row of given metric was updated, calculate the current value, and recalculate the variance value from new value.
      5. update the cloned instance with new rows.
      6. Repeat this until all the formulas are executed.
      7. update the table data instance.
      8. Keep this new updated instacne in stack for undo/redo.
    */
      let formulaTemp = getValueSubstituteForVar(
        metricsFormula,
        key,
        tempDataCloned,
        varianceOrCurrent,
        columnId,
        bucket_key
      );
      const newCalculatedValue =
        typeof formulaTemp === "string"
          ? eval(formulaTemp) === Infinity ||
            eval(formulaTemp) === null ||
            eval(formulaTemp) === -Infinity ||
            eval(formulaTemp) === 0
            ? 0
            : eval(formulaTemp)
          : 0;

      tempDataCloned.map((_obj) => {
        if (
          _obj.metric.includes(bucket_key) &&
          _obj.metric.includes(Object.keys(metricsFormula)[index]) &&
          _obj.reference === varianceOrCurrent
        ) {
          if (_obj.reference === "current") {
            Object.assign(_obj, { [columnId]: newCalculatedValue });
            updateVarianceOnCurrentChange(
              _obj,
              column,
              newCalculatedValue,
              agTableRef,
              metric,
              column?.colId
            );
          } else if (_obj.reference === "variance") {
            Object.assign(_obj, { [columnId]: newCalculatedValue });
            updateCurrentOnVarianceChange(
              _obj,
              column,
              newCalculatedValue,
              agTableRef,
              metric,
              column?.colId
            );
          }
        }
      });
      agTableRef.current.api.refreshCells({
        force: true,
      });
    });
};

/**
 * To change target value to default value
 * @param {object} targetsData target values from the API
 * @param {function} setFormData to change target form data
 * @param {function} setTargetFormValues to track target change values
 * @param {function} updateChangedFormKey to track target key
 * @param {object} formData current target form data
 */

export const setTargetDefaultValue = (
  targetsData,
  setFormData,
  setTargetFormValues,
  updateChangedFormKey,
  formData
) => {
  const obj = {
    target_margin: decimalsFormatter(
      { value: targetsData?.target_margin * 100 },
      true
    ),
    target_sell_through: decimalsFormatter(
      { value: targetsData?.target_sell_through * 100 },
      true
    ),
    target_revenue_growth: decimalsFormatter(
      { value: targetsData?.target_revenue_growth * 100 },
      true
    ),
  };
  setFormData(obj);
  setTargetFormValues(formData);
  updateChangedFormKey([]);
};

/**
 *
 * @param {object} hiddenMetrics show/hide metrics details
 * @param {list} rowData specific row data object to find metric levels
 * @param {boolean} isHidden to know that row is hidden are not from show/hide metrics
 * @returns updated object to track show/hide metrics
 */

export const trackShowHideMetric = (hiddenMetrics, rowData, isHidden) => {
  const {
    comparePlan,
    planCode,
    originalCategory,
    metric,
    reference,
  } = rowData;
  const newHiddenObj = {
    ...hiddenMetrics,
  };
  if (comparePlan) {
    if (!newHiddenObj[planCode]) newHiddenObj[planCode] = {};
    if (!newHiddenObj[planCode][originalCategory])
      newHiddenObj[planCode][originalCategory] = {};

    if (!newHiddenObj[planCode][originalCategory][metric])
      newHiddenObj[planCode][originalCategory][metric] = {};

    newHiddenObj[planCode][originalCategory][metric][reference] = isHidden;
  } else {
    if (!newHiddenObj[originalCategory]) newHiddenObj[originalCategory] = {};

    if (!newHiddenObj[originalCategory][metric])
      newHiddenObj[originalCategory][metric] = {};

    newHiddenObj[originalCategory][metric][reference] = isHidden;
  }

  return newHiddenObj;
};

/**
 *
 * @param {list} rowData it is from the ag grid table data
 * @returns boolean it will return true only if that reference level is less that MAX_ROW_ALLOWED_FOR_COMPARE
 */

export const rowsToCompareValidCheckForComparePlan = (rowData) => {
  const metricObj = {};
  for (let rowInx = 0; rowInx < rowData.length; rowInx++) {
    const metric = rowData[rowInx];
    const categoryKey = metric.originalCategory;
    const metricKey = metric.metric;
    const referenceKey =
      metric.reference + (metric.comparePlan ? metric.planCode : "");
    if (!metric.hide) {
      if (!metricObj[categoryKey]) metricObj[categoryKey] = {};
      if (!metricObj[categoryKey][metricKey])
        metricObj[categoryKey][metricKey] = {};
      metricObj[categoryKey][metricKey][referenceKey] = {
        ...metric,
      };
      if (
        Object.keys(metricObj[categoryKey][metricKey]).length >
        MAX_ROW_ALLOWED_FOR_COMPARE - 2
      ) {
        return false;
      }
    }
  }
  return true;
};

/**
 * remove particular imported version
 * @param {object} param0
 */

export const handleRemoveVersion = ({
  removedPlanDetail,
  budgetTableRef,
  comparePlanRes,
  setComparePlanRes,
  prevSelectedPlans,
  setPrevSelectedPlans,
  originalColRefMapping,
  setPlanBudgetData,
  hiddenMetrics,
  setHiddenMetrics,
  setPlanRefColMapping,
}) => {
  const prevSelectedPlanWithRemPlan = prevSelectedPlans.filter(
    (planCode) => planCode !== removedPlanDetail.plan_code
  );
  const comparePlanResWihRemPlan = comparePlanRes.filter(
    (data, inx) =>
      data.compare_plans[0].plan_code !== removedPlanDetail.plan_code
  );
  const rmComparePlanForHiddenMetric = cloneDeep(hiddenMetrics);
  if (hiddenMetrics[removedPlanDetail.plan_code]) {
    delete rmComparePlanForHiddenMetric[removedPlanDetail.plan_code];
  }
  const tableRows = get(budgetTableRef, "current.props.rowData", []);
  const { originalCurrentPlan } = getCurPlanObjForCompReq(tableRows);
  const parsedData = parseBudgetTableResponseData(
    {
      metrics: originalCurrentPlan,
      ref_col_mapping: originalColRefMapping,
    },
    hiddenMetrics,
    comparePlanResWihRemPlan,
    setPlanRefColMapping
  );
  setHiddenMetrics(rmComparePlanForHiddenMetric);
  setComparePlanRes(comparePlanResWihRemPlan);
  setPrevSelectedPlans(prevSelectedPlanWithRemPlan);
  setPlanBudgetData(parsedData);
  budgetTableRef.current.api.setRowData(parsedData);
  budgetTableRef.current.api.refreshCells({
    force: true,
  });
};

const formula = {
  auc: {
    margin_per: { formula: "aur-auc / aur", ranking: "" },
    bop_auc: { formula: "bop_auc * scaling_factor", ranking: "" },
    bop_cost: { formula: "bop_auc * bop_units", ranking: "" },
    eop_auc: { formula: "eop_units auc * scaling_factor", ranking: "" },
    eop_cost: { formula: "eop_units auc * eop_units", ranking: "" },
    rcpt_auc: { formula: "New auc", ranking: "" },
    rcpt_cost: { formula: "auc * rcpt_units", ranking: "" },
    cost: { formula: "qty * auc", ranking: "" },
    margin: { formula: "margin_per * sales", ranking: "" },
    total: "sum(cost)/sum(qty)",
  },
  aur: {
    msrp: { formula: "aur/(1 - disc )", ranking: 1 },
    margin_per: { formula: "aur-auc / aur", ranking: 1 },
    bop_aur: { formula: "aur * scaling_factor", ranking: "" },
    bop_dollars: { formula: "bop_aur * bop_units", ranking: "" },
    eop_aur: { formula: "eop_units aur * scaling_factor", ranking: "" },
    eop_dollars: { formula: "eop_units aur * eop_units", ranking: "" },
    rcpt_aur: { formula: "New aur", ranking: "" },
    rcpt_dollars: { formula: "aur * rcpt_units", ranking: "" },
    sales: { formula: "qty * aur", ranking: 1 },
    margin: { formula: "margin_per * sales", ranking: 2 },
    total: "sum(sales)/sum(qty)",
  },
  qty: {
    aur: { formula: "aur*(1/scaling_factor)", ranking: "" },
    bop_aur: { formula: "bop_aur*(1/scaling_factor)", ranking: "" },
    bop_dollars: { formula: "bop_aur * bop_units", ranking: "" },
    eop_aur: { formula: "eop_units aur *(1/scaling_factor)", ranking: "" },
    eop_dollars: { formula: "eop_units units * auc", ranking: "" },
    rcpt_aur: { formula: "aur*(1/scaling_factor)", ranking: "" },
    rcpt_dollars: { formula: "rcpt_units * aur", ranking: "" },
    rcpt_units: { formula: "(qty/ sell_through) - BOP", ranking: "" },
    rcpt_cost: { formula: "rcpt_units * auc", ranking: "" },
    eop_units: { formula: "rcpt_units units + BOP - qty", ranking: "" },
    margin_per: { formula: "aur-auc / aur", ranking: "" },
    margin: { formula: "margin_per * sales", ranking: "" },
    eop_cost: { formula: "eop_units units * aur", ranking: "" },
    total: "sum(qty)",
  },
  sales: {
    bop_aur: { formula: "bop_aur * scaling_factor", ranking: "" },
    bop_dollars: { formula: "bop_aur * bop_units", ranking: "" },
    eop_aur: { formula: "eop_units aur * scaling_factor", ranking: "" },
    eop_dollars: { formula: "eop_units aur * eop_units", ranking: "" },
    rcpt_aur: { formula: "aur*scaling_factor", ranking: "" },
    rcpt_dollars: { formula: "aur * rcpt_units", ranking: "" },
    aur: { formula: "aur*scaling_factor", ranking: "" },
    total: "sum(sales)",
  },
  bop_auc: {
    bop_units: { formula: "bop_cost/ bop_auc", ranking: "" },
    bop_dollars: { formula: "bop_dollars * bop_aur", ranking: "" },
    auc: { formula: "auc*scaling_factor", ranking: "" },
    total: "first(bop_auc)",
  },
  bop_aur: {
    bop_units: { formula: "bop_dollars/ bop_aur", ranking: "" },
    aur: { formula: "aur*scaling_factor", ranking: "" },
    total: "first(bop_aur)",
  },
  bop_cost: {
    bop_units: { formula: "bop_cost/ bop_auc", ranking: "" },
    bop_dollars: { formula: "bop_units * bop_aur", ranking: "" },
    total: "first(bop_cost)",
  },
  rcpt_auc: {
    rcpt_units: { formula: "rcpt_units cost/rcpt_units auc", ranking: "" },
    eop_units: { formula: "bop_units-qty + rcpt_units", ranking: "" },
    eop_dollars: { formula: "eop_units units * aur", ranking: "" },
    eop_cost: { formula: "eop_units units * auc", ranking: "" },
    total: "sum(rcpt_cost)/sum(rcpt_units)",
  },
  rcpt_aur: {
    rcpt_units: { formula: "rcpt_units Retail/rcpt_units aur", ranking: "" },
    eop_units: { formula: "bop_units + rcpt_units", ranking: "" },
    st_perc: { formula: "qty/(BOP + rcpt_units units)", ranking: "" },
    eop_dollars: { formula: "eop_units units * aur", ranking: "" },
    eop_cost: { formula: "eop_units units * auc", ranking: "" },
    rcpt_cost: { formula: "rcpt_units Units * rcpt_units auc", ranking: "" },
    total: "sum(rcpt_dollars)/sum(rcpt_units)",
  },
  bop_units: {
    rcpt_units: { formula: "(qty/ sell_through) - bop_units", ranking: "" },
    rcpt_cost: { formula: "rcpt_units * auc", ranking: "" },
    rcpt_dollars: { formula: "rcpt_units * aur", ranking: "" },
    bop_cost: { formula: "bop_units * bop_auc", ranking: "" },
    bop_dollars: { formula: "bop_units * bop_aur", ranking: "" },
    eop_units: { formula: "rcpt_units + BOP - qty", ranking: "" },
    eop_dollars: { formula: "eop_units units * aur", ranking: "" },
    eop_cost: { formula: "eop_units units * auc", ranking: "" },
    total: "first(bop_units)",
  },
  rcpt_cost: {
    rcpt_units: { formula: "rcpt_units cost/rcpt_units auc", ranking: "" },
    eop_units: { formula: "bop_units + rcpt_units", ranking: "" },
    st_perc: { formula: "qty/(BOP + rcpt_units units)", ranking: "" },
    eop_dollars: { formula: "eop_units units * aur", ranking: "" },
    eop_cost: { formula: "eop_units units * auc", ranking: "" },
    rcpt_dollars: { formula: "rcpt_units * rcpt_units aur", ranking: "" },
    total: "sum(rcpt_cost)",
  },
  margin_per: {
    aur: { formula: "auc/(1- margin_per)", ranking: "" },
    total: "sum(margin)/sum(sales)",
  },
  rcpt_units: {
    eop_units: { formula: "bop_units + rcpt_units", ranking: "" },
    rcpt_cost: { formula: "rcpt_units * rcpt_units auc", ranking: "" },
    st_perc: { formula: "qty/(BOP + rcpt_units units)", ranking: "" },
    eop_dollars: { formula: "eop_units * aur", ranking: "" },
    eop_cost: { formula: "eop_units * auc", ranking: "" },
    rcpt_dollars: { formula: "rcpt_units * rcpt_units aur", ranking: "" },
    total: "sum(rcpt_units)",
  },
  bop_dollars: {
    bop_units: { formula: "bop_dollars/ bop_auc", ranking: "" },
    bop_cost: { formula: "bop_units * bop_auc", ranking: "" },
    total: "first(bop_dollars)",
  },
  rcpt_dollars: {
    rcpt_units: { formula: "rcpt_units Retail/rcpt_units aur", ranking: "" },
    eop_units: { formula: "bop_units + rcpt_units", ranking: "" },
    st_perc: { formula: "qty/(BOP + rcpt_units units)", ranking: "" },
    eop_dollars: { formula: "eop_units units * aur", ranking: "" },
    eop_cost: { formula: "eop_units units * auc", ranking: "" },
    rcpt_cost: { formula: "rcpt_units Units * rcpt_units auc", ranking: "" },
    total: "sum(rcpt_dollars)",
  },
};

const getTotalFormulaEquation = (splittedFormula, params, tableData) => {
  let month = params?.column?.colId?.split("_total")[0],
    total = 0;
  let metric = params?.data?.metric;
  let sumMetrics = [
    "qty",
    "sales",
    "margin",
    "cost",
    "rcpt_cost",
    "rcpt_dollars",
    "rcpt_units",
    "comp_dollars",
    "comp_units",
    "non_comp_dollars",
    "non_comp_units",
  ];
  let firstWeekValuesList = [
    "bop_auc",
    "bop_aur",
    "bop_cost",
    "bop_dollars",
    "bop_units",
    "oo_units",
    "oo_dollars",
    "oo_cost",
    "it_units",
    "it_dollars",
    "it_cost",
  ];
  let LastWeekValueList = [
    "eop_auc",
    "eop_aur",
    "eop_cost",
    "eop_dollars",
    "eop_units",
  ];
  let typeOfFunction = () => {
    if (
      sumMetrics?.indexOf(sumMetrics?.find((key) => metric?.includes(key))) > -1
    )
      return "sum";
    else if (
      firstWeekValuesList.indexOf(
        firstWeekValuesList.find((key) => metric?.includes(key))
      ) > -1
    )
      return "first";
    else if (
      LastWeekValueList.indexOf(
        LastWeekValueList.find((key) => metric?.includes(key))
      ) > -1
    )
      return "last";
  };
  switch (typeOfFunction()) {
    case "sum":
      // let monthLength = Object.keys(params.data)?.filter((x) =>
      //   x.includes(month)
      // ).length;
      let total = 0;
      if (params?.data) {
        Object.keys(params.data)?.flatMap((monthKey) => {
          if (
            monthKey &&
            monthKey.includes(month) &&
            !monthKey.includes("total")
          ) {
            total = parseFloat(total) + parseFloat(params.data[monthKey]);
          }
        });
        // if (params.data.reference === "current") return total;
        // else return total / monthLength;
        return total;
      }
      break;
    case "first":
      if (params?.data) {
        let tempFirstKeyValue = Object.keys(params?.data)?.filter(
          (monthKey) => {
            if (
              monthKey &&
              monthKey.includes(month) &&
              !monthKey.includes("total")
            ) {
              return monthKey;
            }
          }
        );
        return params?.data?.[tempFirstKeyValue?.[0]];
      }
      break;
    case "last":
      if (params?.data) {
        let tempFirstKeyValue = Object.keys(params?.data)?.filter(
          (monthKey) => {
            if (
              monthKey &&
              monthKey.includes(month) &&
              !monthKey.includes("total")
            ) {
              return monthKey;
            }
          }
        );
        return params?.data?.[
          tempFirstKeyValue?.[tempFirstKeyValue.length - 1]
        ];
      }
      break;
  }
};

const getBucketTotal = (params) => {
  let column = params?.column?.colId;
  return params?.data?.[column];
};

const updateBudgetTablePayload = (
  payloadForBudgetTableData,
  newValue,
  oldValue,
  metric,
  columnId,
  setPayloadUpdateBudgetTable
) => {
  if (payloadForBudgetTableData?.metrics?.[metric]?.[columnId]) {
    Object.assign(payloadForBudgetTableData?.metrics[metric]?.[columnId], [
      newValue,
      oldValue,
    ]);
  } else if (payloadForBudgetTableData?.metrics?.[metric]) {
    Object.assign(payloadForBudgetTableData.metrics?.[metric], {
      [columnId]: [newValue, oldValue],
    });
  } else if (payloadForBudgetTableData) {
    Object.assign(payloadForBudgetTableData?.metrics, {
      [metric]: { [columnId]: [newValue, oldValue] },
    });
  }
  setPayloadUpdateBudgetTable(payloadForBudgetTableData);
};
