// Review Icon cell renderer
/*

    In the parent comp, defining onReviewClick function is Required
    Props:  spread the instance and pass onReviewClick function 

 */
import React from "react";
import { RateReview } from "@mui/icons-material/";
import { useStyles } from "modules/react-demo/pages/styles";

const ReviewCell = (props) => {
  const classes = useStyles();

  return (
    <div
      onClick={() => {
        props.onReviewClick(props);
      }}
      title="Review"
    >
      <RateReview className={classes.iconBlue} />
    </div>
  );
};

export default ReviewCell;
