import {
  SET_CURRENT_USER,
  LOGOUT_CURRENT_USER,
  LOADER,
  CHECK_USER_SESSION_INIT,
  ADD_SNACK,
  SET_USER_PREFERENCE,
  SET_USER_PREFER_CLIENT,
} from "./types";
import axiosinstance from "../Utils/axios";
import firebaseobj from "../commonComponents/auth/firebase";
import { FETCH_TENANT, USER_PREFERENCE } from "../config/api";
import posthog from "posthog-js";
const fetchUserProviders = (userEmail) => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

export const getUserPreference = (payload) => () => {
  return axiosinstance({
    url: USER_PREFERENCE,
    method: "POST",
    data: {
      model_names: ["tb_product_preference", "tb_product_preference_dev_ui"],
    },
  });
};

export const getConfigurator = () => {
  // return axiosinstance({
  //   // url: "http://127.0.0.1:25250/get_mutliple_data_models",,
  //   url: "https://sandbox.products.impactsmartsuite.com/common/api/v1//get_mutliple_data_models",
  //   headers:{
  //     authorization:"eyJhbGciOiJSUzI1NiIsImtpZCI6IjBhZDFmZWM3ODUwNGY0NDdiYWU2NWJjZjVhZmFlZGI2NWVlYzllODEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDkyMDg3MzI0MTc2LWFrMXFiYWNhMnFwcmQyNDAxa240MTduNTNucGo4czNtLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA5MjA4NzMyNDE3Ni1hazFxYmFjYTJxcHJkMjQwMWtuNDE3bjUzbnBqOHMzbS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMDUyNTU4MzkwMjg2ODIyNDc2MCIsImhkIjoiaW1wYWN0YW5hbHl0aWNzLmNvIiwiZW1haWwiOiJhYXF1aWIuYWhtZWRAaW1wYWN0YW5hbHl0aWNzLmNvIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5iZiI6MTcwMjU1MjI4OSwibmFtZSI6IkFhcXVpYiBBaG1lZCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NMaGQ2MzRodGZja1hOZUFDajlwalpWR0RCZW12dkN0TUtSYzFaTkduMmttZz1zOTYtYyIsImdpdmVuX25hbWUiOiJBYXF1aWIiLCJmYW1pbHlfbmFtZSI6IiBBaG1lZCIsImxvY2FsZSI6ImVuIiwiaWF0IjoxNzAyNTUyNTg5LCJleHAiOjE3MDI1NTYxODksImp0aSI6IjgxNjg0NGU5MDQ1NmI4NGNiZDJkODM0MmRmYjY4YmQ2ZjQxNWRiMmQifQ.Tf7DYgfamN3znfDiq_sv1Om9N3QAUlHS-ePDSvMr1JdrQESCTlLIbk_2dxb2wJc7jtl7Z2xbIFGhx5jTMqWkw7kJaXY6M5rM32HOD_4lo2J4v9LTfPH5Gw6jSL-YSS7h9nLdNusr8WSxfHDgd7I-g3GQ3RIsYcks4-KF7LjOqyP7sLAJdBQ9PQvkWIKxs2ko2zHx7O3wGiC1_3LIHW2g1UpmrLSkeS2kVsGFhqrFZMlBZb099r-VKgCWRYfR3Hgw2nY1_lFYHXqpUTMMh87vVob0w-5JLETvsVBs6SIbCAMN16I_T40C18HvV_d5trHufrIAB0CPkuMN1Dr5qgBukQ"
  //   },
  //   method: "POST",
  //   data: {
  //     client_name: "internal",
  //     product_name: "configurator",
  //     model_names: [
  //       "tb_configurator_description",
  //       "tb_product_description",
  //       "tb_client_product_mapping",
  //     ],
  //   },
  // });

  let raw = JSON.stringify({
    client_name: "internal",
    product_name: "configurator",
    model_names: [
      "tb_configurator_description",
      "tb_product_description",
      "tb_client_product_mapping",
      "tb_menu"
    ],
  });

  let requestOptions = {
    method: "POST",
    headers: {
      authorization:
        "eyJhbGciOiJSUzI1NiIsImtpZCI6IjBhZDFmZWM3ODUwNGY0NDdiYWU2NWJjZjVhZmFlZGI2NWVlYzllODEiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJhenAiOiIxMDkyMDg3MzI0MTc2LWFrMXFiYWNhMnFwcmQyNDAxa240MTduNTNucGo4czNtLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMTA5MjA4NzMyNDE3Ni1hazFxYmFjYTJxcHJkMjQwMWtuNDE3bjUzbnBqOHMzbS5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjExMDUyNTU4MzkwMjg2ODIyNDc2MCIsImhkIjoiaW1wYWN0YW5hbHl0aWNzLmNvIiwiZW1haWwiOiJhYXF1aWIuYWhtZWRAaW1wYWN0YW5hbHl0aWNzLmNvIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsIm5iZiI6MTcwMjU1MjI4OSwibmFtZSI6IkFhcXVpYiBBaG1lZCIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQ2c4b2NMaGQ2MzRodGZja1hOZUFDajlwalpWR0RCZW12dkN0TUtSYzFaTkduMmttZz1zOTYtYyIsImdpdmVuX25hbWUiOiJBYXF1aWIiLCJmYW1pbHlfbmFtZSI6IiBBaG1lZCIsImxvY2FsZSI6ImVuIiwiaWF0IjoxNzAyNTUyNTg5LCJleHAiOjE3MDI1NTYxODksImp0aSI6IjgxNjg0NGU5MDQ1NmI4NGNiZDJkODM0MmRmYjY4YmQ2ZjQxNWRiMmQifQ.Tf7DYgfamN3znfDiq_sv1Om9N3QAUlHS-ePDSvMr1JdrQESCTlLIbk_2dxb2wJc7jtl7Z2xbIFGhx5jTMqWkw7kJaXY6M5rM32HOD_4lo2J4v9LTfPH5Gw6jSL-YSS7h9nLdNusr8WSxfHDgd7I-g3GQ3RIsYcks4-KF7LjOqyP7sLAJdBQ9PQvkWIKxs2ko2zHx7O3wGiC1_3LIHW2g1UpmrLSkeS2kVsGFhqrFZMlBZb099r-VKgCWRYfR3Hgw2nY1_lFYHXqpUTMMh87vVob0w-5JLETvsVBs6SIbCAMN16I_T40C18HvV_d5trHufrIAB0CPkuMN1Dr5qgBukQ",
    },
    body: raw,
    redirect: "follow",
  };

  try {
    return fetch(
      "https://dev-ui-bkp.products.impactsmartsuite.com/ui-enhancement/api/v1/get_mutliple_data_models",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => result);
  } catch (err) {
    console.error(err);
  }
};

export const getChildModules = (payload) => () => {
  return axiosinstance({
    url: USER_PREFERENCE,
    method: "POST",
    data: { model_names: ["tb_child_modules"] },
  });
};

export const getUserModulePreference = (payload) => () => {
  return axiosinstance({
    url: USER_PREFERENCE,
    method: "POST",
    data: { model_names: ["tb_module_preference"] },
  });
};

// Login - Post User Token
export const loginUser = (userData) => async (dispatch) => {
  dispatch({
    type: LOADER,
    payload: {
      status: false,
      text: "Loading...",
    },
  });
  return true;
};

export const setUser = () => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: {
      isAuthenticated: true,
      isTokenVerified: true,
      user: {
        token: localStorage.getItem("token"),
        name: localStorage.getItem("name"),
      },
    },
  });
};

export const setUserPreferClient = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_PREFER_CLIENT,
    payload: payload,
  });
};

export const setUserPreferences = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER_PREFERENCE,
    payload: payload,
  });
};

export const checkUserProvider = (userEmail) => () => {
  return firebaseobj.auth().fetchSignInMethodsForEmail(userEmail);
};

export const refreshToken = async (user) => {
  // return new Promise((resolve, reject) => {
  //   user.getIdToken().then((token) => {
  //     axiosinstance({
  //       url: VERIFY_TOKEN,
  //       method: "POST",
  //       data: {
  //         token: token,
  //       },
  //     })
  //       .then((data) => {
  //         if (data.data.data.message == "Token verified") {
  //           localStorage.setItem("token", token);
  //           localStorage.setItem("name", user.email);
  //           // posthog.people.set({ email: localStorage.getItem("name") });
  //           // posthog.identify(
  //           //   {email:localStorage.getItem("name")},
  //           //   {host: window.location.hostname});
  //           localStorage.setItem(
  //             "session.expiry",
  //             user.toJSON().stsTokenManager.expirationTime
  //           );
  //           resolve([token, data]);
  //         } else {
  //           firebaseSignOut();
  //           reject();
  //         }
  //       })
  //       .catch((error) => {
  //         firebaseSignOut();
  //         reject();
  //       });
  //   });
  // });
};
export const verifyTokenStatus = () => async (dispatch) => {
  dispatch({
    type: CHECK_USER_SESSION_INIT,
  });
  // firebaseobj.auth().onAuthStateChanged((user) => {
  //   if (user) {
  //     dispatch({
  //       type: LOADER,
  //       payload: {
  //         status: true,
  //         text: "Authenticating user...",
  //       },
  //     });
  //     refreshToken(user)
  //       .then((response) => {
  //         const token = response[0];
  //         const data = response[1];
  //         dispatch({
  //           type: SET_CURRENT_USER,
  //           payload: {
  //             isAuthenticated: true,
  //             isTokenVerified: true,
  //             user: {
  //               token: token,
  //               name: user.email,
  //             },
  //             landingPage: data.data.data.landing_page,
  //           },
  //         });

  //         dispatch({
  //           type: LOADER,
  //           payload: {
  //             status: false,
  //             text: "Loading...",
  //           },
  //         });
  //       })
  //       .catch((error) => {
  //         dispatch({
  //           type: SET_CURRENT_USER,
  //           payload: {
  //             isAuthenticated: false,
  //             isTokenVerified: true,
  //             user: {},
  //           },
  //         });
  //         dispatch({
  //           type: ADD_SNACK,
  //           payload: {
  //             message: `Unable to verify the token`,
  //             options: {
  //               variant: "error",
  //             },
  //           },
  //         });
  //         dispatch({
  //           type: LOADER,
  //           payload: {
  //             status: false,
  //             text: "Loading...",
  //           },
  //         });
  //       });
  //   } else {
  //     dispatch({
  //       type: SET_CURRENT_USER,
  //       payload: {
  //         isAuthenticated: false,
  //         isTokenVerified: true,
  //         user: {},
  //       },
  //     });
  //   }
  // });
};

const firebaseSignOut = () => {
  // return firebaseobj.auth().signOut();
};
export const logoutUser = (token) => (dispatch) => {
  // firebaseSignOut()
  //   .then(() => {
  //     localStorage.removeItem("token", token);
  //     localStorage.removeItem("session.expiry");
  //     localStorage.removeItem("CLIENT_NAME");
  //     posthog.reset(true);
  //   })
  //   .catch((error) => {
  //     return;
  //     // An error happened.
  //   });
  dispatch({
    type: LOGOUT_CURRENT_USER,
  });
  dispatch({
    type: LOADER,
    payload: {
      status: false,
    },
  });
};

export const getTenantId = (hostname) => (dispatch) => {
  //Logic to fetch tenantID from backend using axios
  // dispatch({
  //   type: TENANT_FETCH_INIT,
  // });
  return true;
  // return axiosinstance
  //   .get(`${FETCH_TENANT}?url=dev.products.impactsmartsuite.com`)
  //   .then((data) => {
  // dispatch({
  //   type: TENANT_FETCH_SUCCESS,
  //   payload: data.data.data,
  // });
  // dispatch({
  //   type: TENANT_FETCH_SUCCESS,
  //   payload: {
  //     tenant_code: 1,
  //     url: "sandbox.products.impactsmartsuite.com",
  //     google_tenant_identity: "tenant1-sqxp3",
  //     sign_in_option: ["1", "2", "3"],
  //     saml_id: "",
  //     saml_provider_name: "",
  //     saml_provider_icon: "",
  //     tenant_name: "PUMA",
  //   },
  // });
  // })
  // .catch((error) => {
  //Handling the error
  // dispatch({
  //   type: TENANT_FETCH_FAILED,
  // });
  // });
};

export const forgotPassword = (emailAddress) => (dispatch) => {
  firebaseobj
    .auth()
    .sendPasswordResetEmail(emailAddress)
    .then(function () {
      // Email sent.
      dispatch({
        type: ADD_SNACK,
        payload: {
          message: `Password reset link sent to the registered email successfully!`,
          options: {
            variant: "success",
          },
        },
      });
    })
    .catch(function (error) {
      // An error happened.
      dispatch({
        type: ADD_SNACK,
        payload: {
          message: error.message,
          options: {
            variant: "success",
          },
        },
      });
    });
};
