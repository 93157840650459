import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import {
  getAlertsActionTableConfiguration,
  setAlertsActionTableConfigLoader,
} from "modules/inventorysmart/services-inventorysmart/StoreInventoryAlerts/alerts-actions-service";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";
import { STORE_INVENTORY_ALERT_ACTION_CONFIG } from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { cloneDeep } from "lodash";

const AlertsActionTable = (props) => {
  const [
    alertsActionTableTableConfig,
    setAlertsActionTableTableConfig,
  ] = useState([]);
  const [alertsActionTableData, setAlertsActionTableData] = useState([]);
  const agGridInstance = useRef(null);

  useEffect(() => {
    const fetchColumnConfig = async () => {
      props.setAlertsActionTableConfigLoader(true);

      let columns;
      if (props.tableConfigName) {
        const payload = {
          tableConfigName: props.tableConfigName,
        };
        columns = await props.getAutoRecommendationTableConfiguration(payload);
      } else if (props.tableConfig) {
        columns = {
          data: {
            data: cloneDeep(props.tableConfig),
          },
        };
      }

      let reviewRecommendationColumn = STORE_INVENTORY_ALERT_ACTION_CONFIG[0];
      reviewRecommendationColumn.order_of_display =
        columns.data.data.length + 1;
      reviewRecommendationColumn.tc_code = columns.data.data[0]?.tc_code;
      reviewRecommendationColumn.tc_mapping_code =
        columns.data.data[0]?.tc_mapping_code;

      columns.data.data.push(reviewRecommendationColumn);
      let formattedColumns = agGridColumnFormatter(columns?.data?.data);
      setAlertsActionTableTableConfig(formattedColumns);
      props.setAlertsActionTableConfigLoader(false);
    };
    fetchColumnConfig();

    return () => {
      setAlertsActionTableTableConfig([]);
      setAlertsActionTableData([]);
    };
  }, []);

  useEffect(() => {
    if (props.tableData?.length > 0) {
      const data = props.tableData.map((item, index) => {
        item.index = index;
        item.action = "Review";
        return item;
      });

      setAlertsActionTableData(data);
      agGridInstance.current?.api?.redrawRows();
    }
  }, [props.tableData]);

  const loadAlertsTableInstance = (params) => {
    agGridInstance.current = params;
  };

  useEffect(() => {
    agGridInstance.current?.api?.redrawRows();
  }, [props.totalModelStockSum]);

  return (
    <>
      <Loader
        loader={props.alertsActionTableConfigLoader || props.loading}
        minHeight={"188px"}
      >
        <AgGridComponent
          columns={alertsActionTableTableConfig}
          rowdata={alertsActionTableData}
          onReviewClick={(tableInfo) => props.onReviewClick(tableInfo.data)}
          uniqueRowId={"index"}
          loadTableInstance={loadAlertsTableInstance}
          getRowStyle={(params) => {
            if (params.data.is_resolved) {
              return { background: "rgb(57 255 20 / 20%)" };
            }
          }}
        />
      </Loader>
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    alertsActionTableConfigLoader:
      store.inventorysmartReducer.inventorySmartAlertsActionService
        .alertsActionTableConfigLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getAutoRecommendationTableConfiguration: (payload) =>
    dispatch(getAlertsActionTableConfiguration(payload)),
  setAlertsActionTableConfigLoader: (payload) =>
    dispatch(setAlertsActionTableConfigLoader(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertsActionTable);
