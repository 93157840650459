import React from "react";
import PropTypes from "prop-types"; // for our bonus, see below
// eslint-disable-next-line
import ReactSelect, { components } from "react-select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./index.css";
import muiTheme from "Styles/theme";
import { Tooltip } from "@mui/material";

const ValueContainer = ({ children, selectProps, ...props }) => {
  const { getValue, hasValue } = props;
  const { inputValue } = selectProps;
  const values = getValue();
  return (
    // to handle for multiple selections later as the list can have large number of values
    <components.ValueContainer {...props}>
      {children[1]}
      {!hasValue && children[0]}
      {hasValue && inputValue === "" && (
        <Tooltip title={values[0].label}>
          <span className="valueStyle">{values[0].label}</span>
        </Tooltip>
      )}
      {hasValue && inputValue === "" && values.length !== 1 && (
        <span className="counterStyle">+{selectProps.value.length - 1}</span>
      )}
    </components.ValueContainer>
  );
};

const MenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.isMulti && !!props.options.length && (
        <div
          className="select-all"
          onClick={() => {
            if (
              props.options.length ===
              (props.selectProps.value && props.selectProps.value.length)
            ) {
              props.setValue([]);
            } else {
              props.setValue(props.options);
            }
          }}
        >
          Select/Unselect All
        </div>
      )}
      {props.children}
    </components.MenuList>
  );
};
const customValueStyle = (provided) => {
  const position = "relative";

  return { ...provided, position };
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowDropDownIcon />
    </components.DropdownIndicator>
  );
};

const Select = (props) => {
  const [pageIndex, setPageIndex] = React.useState(2);
  const [options, setOptions] = React.useState([]);
  const onMenuScrollToBottom = (_event) => {
    if (props.pagination) {
      props.fetchOptions(pageIndex);
      setPageIndex(pageIndex + 1);
    }
    if (props?.options?.length > 50) {
      setOptions(props.options.slice(0, options.length + 50));
    }
  };
  React.useEffect(() => {
    if (props?.options?.length > 50) {
      setOptions(props.options.slice(0, 50));
    } else {
      setOptions(props.options);
    }
  }, [props.options]);
  return (
    <ReactSelect
      {...props}
      menuPlacement={"auto"}
      options={options}
      hideSelectedOptions={false}
      components={{ ValueContainer, MenuList, DropdownIndicator }}
      closeMenuOnSelect={props.isMulti ? false : true}
      onMenuScrollToBottom={onMenuScrollToBottom}
      styles={{
        valueContainer: (provided) => {
          return props.valueStyles
            ? props.valueStyles
            : customValueStyle(provided);
        },
        menuPortal: (provided) => ({
          ...provided,
          zIndex: "500",
        }),
        menu: (provided, _state) => ({
          ...provided,
          zIndex: "500",
          marginTop: "0.75rem",
        }),
        indicatorSeparator: (_provided, _state) => ({
          display: "none",
        }),
        indicatorsContainer: (_provided, _state) => ({
          display: "flex",
          padding: "0 5px",
        }),
        dropdownIndicator: (provided, _state) => ({
          ...provided, padding: "0"
        }),
        control: (provided, _state) => {
          return {...provided, minHeight: "2.125rem"}
        }, 
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: muiTheme.palette.primary.lighter,
          primary: muiTheme.palette.primary.main,
        },
      })}
    />
  );
};

Select.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

Select.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
};

export default Select;
