import GroupType from "./groupType";
import PageRouteTitles from "../PageRouteTitles";
import { useHistory } from "react-router-dom";
import { Container, Typography } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import FormLabel from "@mui/material/FormLabel";
import GroupTypeFilters from "./grpTypeFilters";
import FilteredProducts from "./productFilters";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import { connect } from "react-redux";
import {
  getGroupInfo,
  ToggleLoader,
  setManualGrpFilterType,
  setSelectedProductGrpType,
  setManualGrpDefns,
  setSelectedGroupToEdit,
} from "pages/product-grouping/product-grouping-service";
import { useEffect, useState, useRef } from "react";
import { addSnack } from "actions/snackbarActions";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const ModifyProductGroup = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const [grpObject, setgrpObj] = useState({});
  const productGrpTypeOptions = [
    {
      label: "Manual",
      value: "manual",
    },
    // {
    //   label: "Objective based",
    //   value: "objective",
    // },
    // {
    //   label: "Custom",
    //   value: "custom",
    // },
  ];
  const routeOptions = [
    {
      id: "product_grping_scr",
      label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
      action: () => history.push("/product-grouping"),
    },
    {
      id: "view Group",
      label: "View Group",
      action: () =>
        history.push(`/product-grouping/view/${props.match.params.group_id}`),
    },
    {
      id: "Modify Group",
      label: "Modify Group",
      action: () => null,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.ToggleLoader(true);
        const grpId = history.location.pathname.split("/")[3];
        const res = await props.getGroupInfo(grpId);
        const grpObj = res.data.data[0];
        setgrpObj(grpObj);
        props.setSelectedProductGrpType("manual");
        props.setManualGrpFilterType({
          type: "product_hierarchy",
          isDisabled: true,
        });
        props.setSelectedGroupToEdit(grpObj.name);
      } catch (error) {
        //Fetching Error
        props.addSnack({
          message: "Unable to fetch group information",
          options: {
            variant: "error",
          },
        });
      }
    };
    fetchData();
  }, []);
  const manualProductsTableRef = useRef(null);
  const manualProductGroupsTableRef = useRef(null);
  const manualStyleLvlTableRef = useRef(null);
  return (
    <>
      <PageRouteTitles options={routeOptions} />
      <LoadingOverlay loader={props.isLoading} spinner>
        <Container maxWidth={false} className={globalClasses.marginBottom}>
          <div
            className={`${globalClasses.flexRow} ${globalClasses.verticalAlignCenter}`}
          >
            <FormLabel component="legend">GroupType</FormLabel>
            <GroupType
              isEdit={true}
              grpObj={grpObject}
              type="product"
              options={productGrpTypeOptions}
            />
            <Typography variant="h4" className={globalClasses.marginHorizontal}>
              Selected Group :{" "}
              <Typography variant="body1" component="span">
                {props.groupName}
              </Typography>
            </Typography>
          </div>
        </Container>
        <Container maxWidth={false}>
          <GroupTypeFilters
            ref={{
              productLvlRef: manualProductsTableRef,
              styleLvlRef: manualStyleLvlTableRef,
              productGroupLvlRef: manualProductGroupsTableRef,
            }}
            grpObj={grpObject}
            isEdit={true}
          />
          <FilteredProducts
            ref={{
              productLvlRef: manualProductsTableRef,
              styleLvlRef: manualStyleLvlTableRef,
              productGroupLvlRef: manualProductGroupsTableRef,
            }}
            isEdit={true}
            grpObj={grpObject}
          />
        </Container>
      </LoadingOverlay>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.productGroupReducer.isLoading,
    groupName: state.productGroupReducer.selectedGroupToEdit,
  };
};

const mapActionsToProps = {
  getGroupInfo,
  ToggleLoader,
  setManualGrpFilterType,
  setSelectedProductGrpType,
  setManualGrpDefns,
  addSnack,
  setSelectedGroupToEdit,
};
export default connect(mapStateToProps, mapActionsToProps)(ModifyProductGroup);
