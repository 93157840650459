import {
  fetchUnitDefnById,
  setSelectedStyleId,
  setUnitDefnName,
  setUnitAttributeType,
  setStyleAttributes,
} from "../product-unit-definition-service";
import { connect } from "react-redux";
import Create from "./createnew";
const EditDefinition = (props) => {
  return (
    <>
      <Create
        defn_id={props.match.params.defn_id}
        style_id={props.match.params.defn_id}
        screen_status="edit"
      />
    </>
  );
};
const mapActionsToProps = {
  fetchUnitDefnById,
  setSelectedStyleId,
  setUnitDefnName,
  setUnitAttributeType,
  setStyleAttributes,
};
export default connect(null, mapActionsToProps)(EditDefinition);
