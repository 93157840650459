import ModuleConfig from "./moduleConfig";
import { capitalize } from "Utils/formatter";
/**
 *
 * @param {Object} selectedModuleConfigData
 * @returns Array
 *
 * This method combines respective values for each key into a single array
 */
export const flattenSelectedFiltersData = (selectedModuleConfigData) => {
  const combinedfilters = Object.keys(selectedModuleConfigData).reduce(
    function (res, key) {
      return res.concat(selectedModuleConfigData[key]);
    },
    []
  );
  return combinedfilters.map((element) => {
    return {
      label: element.label,
      column_name: element.column_name,
      filter_type: element.filter_type,
      display_type: element.display_type,
      level: element.level,
      dimension: element.dimension,
      is_mandatory: !!element.is_mandatory,
    };
  });
};

/**
 * This method is used in formatting tabs array passed to tabs component in both
 * create filter config and update filter config
 */
export const formatTabsArray = (tabsInfoArr, id) => {
  return tabsInfoArr.map((tab) => {
    return {
      label: capitalize(tab.label),
      id: tab.value,
      TabPanel: <ModuleConfig id={id} dimension={tab.value} />,
    };
  });
};

/**
 *
 * @param {Object} selectedFilterConfigData
 * @param {Array} dimensions
 * @returns boolean
 *
 * This method checks if atleast one filter is selected for every dimension
 */
export const checkForEmptyDimensionFilters = (
  selectedFilterConfigData,
  dimensions
) => {
  for (let dimension of dimensions) {
    if (
      !selectedFilterConfigData[dimension.value] ||
      selectedFilterConfigData[dimension.value].length === 0
    ) {
      return true;
    }
  }
  return false;
};
