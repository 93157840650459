import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { isEmpty } from "lodash";
import FilterGroup from "commonComponents/filters/filterGroup";
import UserFilterTable from "./user-filter-table";

const useStyles = makeStyles(() => ({
  editHeader: {
    margin: 0,
  },
  filterGroupStyle: {
    width: "100%",
  },
}));

const UserFilters = (props) => {
  const [filterConfig, setFilterConfig] = useState([]);
  const [selectedDependency, setSelectedDependency] = useState([]);

  const {
    editHeaderStyle,
    getFilterConfiguration,
    setAssignRoleFilterConfiguration,
    assignRoleFilterList,
    assignRoleToUserTableData,
    getTableHierarchyOnAssignRole,
    setTableHierarchyOnAssignRole,
    setAssignRoleUserRoleMgmtLoader,
    saveRowSelectionsRoleMappings,
    getTableConfig,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    const getInitialData = async () => {
      setAssignRoleUserRoleMgmtLoader(true);
      let response = await getFilterConfiguration();
      setAssignRoleFilterConfiguration(response.data.data);
    };
    getInitialData();
  }, []);

  useEffect(() => {
    setAssignRoleUserRoleMgmtLoader(true);
    props.setLoadingOfHierarchyFilters(true);
    const getFilterValues = async () => {
      try {
        if (assignRoleFilterList.length > 0) {
          const filterElements = assignRoleFilterList.map(async (key) => {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: [],
              dimension: key.dimension,
            };
            const configurationOptions = await props.getCrossFiltersData(body);
            return {
              ...key,
              filter_keyword: key.column_name,
              initialData: configurationOptions.data.data.attribute.map(
                (item) => {
                  return {
                    value: item.attribute,
                    label: item.attribute,
                    id: item.attribute,
                  };
                }
              ),
            };
          });
          let data = await Promise.all(filterElements);
          setFilterConfig(data);
          props.setLoadingOfHierarchyFilters(false);
          setAssignRoleUserRoleMgmtLoader(false);
        }
      } catch (err) {
        displaySnackMessages("Something went wrong", "error");
        setAssignRoleUserRoleMgmtLoader(false);
      }
    };
    getFilterValues();
  }, [assignRoleFilterList]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const updateFilterValuesOnChange = async (dependency, filter) => {
    setAssignRoleUserRoleMgmtLoader(true);
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              values: Array.isArray(opt.values)
                ? opt.values.map((option) => option.value)
                : opt.values,
              filter_type: opt.filter_type,
              dimension: opt.dimension,
            };
          })
        : [];
    try {
      if (!filter || filter.filter_type === "cascaded") {
        let initialFilterElements = [...filterConfig];
        const filterElements = initialFilterElements.map(async (key) => {
          if (key.type === "cascaded") {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency,
              dimension: key.dimension,
            };
            const options = await props.getCrossFiltersData(body);
            key.initialData = options.data.data.attribute.map((opt) => {
              return {
                value: opt.attribute,
                label: opt.attribute,
                id: opt.attribute,
              };
            });
          }
          return key;
        });
        await Promise.all(filterElements);
        setFilterConfig(initialFilterElements);
      }
      setSelectedDependency(selectionValueDependency);
      setAssignRoleUserRoleMgmtLoader(false);
    } catch (err) {
      displaySnackMessages("Something went wrong", "error");
      setAssignRoleUserRoleMgmtLoader(false);
    }
  };

  const resetFilterValuesSelected = async () => {
    setAssignRoleUserRoleMgmtLoader(true);
    setSelectedDependency([]);
    setTableHierarchyOnAssignRole([]);
    let response = await getFilterConfiguration();
    setAssignRoleFilterConfiguration(response.data.data);
  };

  const fetchValuesBasedOnFilterApplied = async (
    _hideloader,
    initialDependency
  ) => {
    if (isEmpty(selectedDependency)) {
      displaySnackMessages("Select atleast one filter", "warning");
    } else {
      setAssignRoleUserRoleMgmtLoader(true);
      let dependency = initialDependency
        ? initialDependency
        : selectedDependency;
      try {
        let body = {
          meta: {},
          filters: dependency,
        };
        let response = await getTableHierarchyOnAssignRole(body);
        setTableHierarchyOnAssignRole(response.data.data);
        props.selectedFiltersDependency(dependency);
      } catch (err) {
        displaySnackMessages("Something went wrong", "error");
        setAssignRoleUserRoleMgmtLoader(false);
      }
    }
  };

  return (
    <div className={editHeaderStyle ? classes.editHeader : ""}>
      <div className={classes.filterGroupStyle}>
        <FilterGroup
          filters={filterConfig}
          onFilter={fetchValuesBasedOnFilterApplied}
          doNotUpdateDefaultValue={true}
          onReset={resetFilterValuesSelected}
          update={updateFilterValuesOnChange}
        />
      </div>
      <UserFilterTable
        getTableConfig={getTableConfig}
        assignRoleToUserTableData={assignRoleToUserTableData}
        saveRowSelectionsRoleMappings={saveRowSelectionsRoleMappings}
        formDataOnEdit={props.formDataOnEdit}
        addSnack={props.addSnack}
        setAssignRoleUserRoleMgmtLoader={setAssignRoleUserRoleMgmtLoader}
        hierarchyTableListOnEdit={props.hierarchyTableListOnEdit}
        hierarchyTableData={props.hierarchyTableData}
      />
    </div>
  );
};

export default UserFilters;
