import {
  Typography,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import globalStyles from "Styles/globalStyles";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import {
  fetchGroupProducts,
  setProdGrpFilteredCols,
  deletedRowsInEdit,
  getGroupInfo,
  EditGroup,
  ToggleLoader,
  setSelectedGroupToEdit,
} from "pages/product-grouping/product-grouping-service";
import { addSnack } from "../../../../actions/snackbarActions";
import { connect } from "react-redux";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import PageRouteTitles from "../PageRouteTitles";
import AgGridComponent from "Utils/agGrid";
import { getColumnsAg } from "actions/tableColumnActions";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles({
  contentStyle: {
    height: "20vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {
    fontWeight: 500,
    fontSize: 18,
  },
  actionStyle: {
    backgroundColor: "#F7F7F7",
  },
});

const EditGrpProducts = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const history = useHistory();
  const [confirmType, setconfirmType] = useState("");
  const [openPopUp, setopenPopUp] = useState(false);
  const tableInstance = useRef({});

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.ToggleLoader(true);
        const cols = await props.getColumnsAg(
          "table_name=product_group_filter"
        );
        props.setProdGrpFilteredCols(cols);
        props.ToggleLoader(false);
        const grpId = props.match.params.group_id;
        const res = await props.getGroupInfo(grpId);
        props.setSelectedGroupToEdit(res?.data?.data[0]?.name);
      } catch (err) {
        props.ToggleLoader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };
    fetchData();
  }, []);

  const selectionHandler = (event) => {
    let selectedRows = event.api.getSelectedRows();
    let tableRows = event.api.getRenderedNodes().map((item) => {
      return item.data;
    });
    let updatedDelete = props.deletedProds.filter((prod) => {
      return !selectedRows.some((selection) => {
        return selection.product_code === prod.product_code;
      });
    });
    const deleteRows = tableRows.filter((row) => {
      return !selectedRows.some((selection) => {
        return selection.product_code === row.product_code;
      });
    });
    updatedDelete.push(...deleteRows);
    props.deletedRowsInEdit(updatedDelete);
  };

  const editGroupManualCallBack = async (body, pageIndex, params) => {
    props.ToggleLoader(true);
    try {
      let res = {
        data: {
          data: [],
          total: 0,
        },
      };
      let manualBody = {
        meta: {
          ...body,
          limit: { limit: 10, page: pageIndex + 1 },
        },
        selection: {
          data: [
            {
              searchColumns: {},
              checkAll: true,
            },
            ...params?.api?.checkConfiguration,
          ],
          unique_columns: ["product_code"],
        },
      };
      if (
        body.search.length > 0 ||
        body.sort.length > 0 ||
        body.range.length > 0
      ) {
        const grpId = props.match.params.group_id;
        res = await props.fetchGroupProducts(grpId, manualBody);
      } else {
        const grpId = props.match.params.group_id;
        res = await props.fetchGroupProducts(
          grpId,
          manualBody,
          pageIndex + 1,
          10
        );
      }

      res.data.data = res.data.data.map((item) => {
        item.is_selected = true;
        return item;
      });

      props.ToggleLoader(false);
      return {
        data: res.data.data,
        totalCount: res.data.total,
      };
    } catch (err) {
      props.ToggleLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const goBack = () => {
    if (props.deletedProds.length === 0) {
      history.push(`/product-grouping/`);
    } else {
      setopenPopUp(true);
      setconfirmType("back");
    }
  };
  const onModify = () => {
    if (props.deletedProds.length === 0) {
      history.push({
        pathname: `/product-grouping/modify/${props.match.params.group_id}`,
      });
    } else {
      setopenPopUp(true);
      setconfirmType("modify");
    }
  };
  const handleClose = () => {
    setopenPopUp(false);
  };
  const onSave = async () => {
    props.ToggleLoader(true);
    const grpId = props.match.params.group_id;
    const res = await props.getGroupInfo(grpId);
    const grpObj = res.data.data[0];
    let grpType = grpObj.special_classification;
    if (grpType !== "manual" && props.deletedProds.length !== 0) {
      grpType = "manual";
    }
    const body = {
      name: grpObj.name,
      group_type: grpType,
      objective_metrics: grpObj.selection_metadata["objective_metrics"],
      group_definition_ids: grpObj.selection_metadata["defintion_ids"],
      product_ids: {
        filters: [],
        meta: {
          search: [],
          range: [],
          sort: [],
        },
        metrics: [],
        selection: {
          data: tableInstance?.current?.api?.checkConfiguration,
          unique_columns: ["product_code"],
        },
      },
      product_group_ids: {
        filters: [],
        meta: {
          search: [],
          range: [],
          sort: [],
        },
        metrics: [],
        selection: {
          data: [],
          unique_columns: ["pg_code"],
        },
      },
    };
    try {
      await props.EditGroup(body, grpId);
      props.deletedRowsInEdit([]);
      props.addSnack({
        message: "Successfully updated",
        options: {
          variant: "success",
          onClose: () => history.push("/product-grouping/"),
        },
      });
      props.ToggleLoader(false);
    } catch (error) {
      displaySnackMessages("Update Failed.Please try again", "error");
    }
  };
  const routeOptions = [
    {
      id: "product_grping_scr",
      label: dynamicLabelsBasedOnTenant("product_grouping", "core"),
      action: () => history.push("/product-grouping"),
    },
    {
      id: "view Group",
      label: "View Group",
      action: () => null,
    },
  ];

  const onConfirm = () => {
    props.deletedRowsInEdit([]);
    if (confirmType === "modify") {
      history.push(`/product-grouping/modify/${props.match.params.group_id}`);
    } else {
      history.push("/product-grouping");
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <LoadingOverlay loader={props.isLoading} spinner>
      <PageRouteTitles options={routeOptions} />
      <Container maxWidth={false}>
        <Dialog
          id="productGrpingEditGrpsDialog"
          open={openPopUp}
          fullWidth
          maxWidth="xs"
        >
          <DialogContent classes={{ root: classes.contentStyle }}>
            <Typography className={classes.textStyle}>
              Changes will be lost. Are you sure want to proceed ?
            </Typography>
          </DialogContent>
          <DialogActions classes={{ root: classes.actionStyle }}>
            <Button
              id="productGrpingEditGrpsDialogCnclBtn"
              size="small"
              className={classes.btnStyle}
              onClick={handleClose}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              id="productGrpingEditGrpsDialogCnfmBtn"
              size="small"
              variant="contained"
              onClick={onConfirm}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <div
          className={`${globalClasses.layoutAlignSpaceBetween} ${globalClasses.marginBottom}`}
        >
          <Typography variant="h4">{`Edit ${dynamicLabelsBasedOnTenant(
            "product",
            "core"
          )}s`}</Typography>
          <Typography variant="h4">
            Selected Group :{" "}
            <Typography variant="body1" component="span">
              {props.groupName}
            </Typography>
          </Typography>
          <Button
            onClick={() => onModify()}
            size="large"
            variant="contained"
            color="primary"
            id="productGrpingEditGrpsModifyBtn"
          >
            Modify
          </Button>
        </div>
        {props.columns.length > 0 && (
          <AgGridComponent
            columns={props.columns}
            selectAllHeaderComponent={true}
            sizeColumnsToFitFlag
            onGridChanged
            onRowSelected
            manualCallBack={(body, pageIndex, params) =>
              editGroupManualCallBack(body, pageIndex, params)
            }
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            uniqueRowId={"product_code"}
            loadTableInstance={setNewTableInstance}
            suppressClickEdit={true}
            hideSelectAllRecords={true}
            onSelectionChanged={selectionHandler}
          />
        )}
      </Container>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
      >
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={goBack}
          id="productGrpingEditGrpsBackBtn"
        >
          Back
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={onSave}
          id="productGrpingEditGrpsSaveBtn"
        >
          Save
        </Button>
      </div>
    </LoadingOverlay>
  );
};

const mapActionsToProps = {
  getColumnsAg,
  setProdGrpFilteredCols,
  fetchGroupProducts,
  deletedRowsInEdit,
  getGroupInfo,
  EditGroup,
  ToggleLoader,
  addSnack,
  setSelectedGroupToEdit,
};

const mapStateToProps = (state) => {
  return {
    columns: state.productGroupReducer.manualFilteredProdsCols,
    deletedProds: state.productGroupReducer.deletedProdsInEdit,
    isLoading: state.productGroupReducer.isLoading,
    groupName: state.productGroupReducer.selectedGroupToEdit,
  };
};

export default connect(mapStateToProps, mapActionsToProps)(EditGrpProducts);
