import { createSelector, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  GET_BUDGET_L2,
  OPTIMIZE_L3,
  UPDATE_BUDGET_L2,
  GET_L3_OPT,
  UPDATE_L3_OPT,
  GET_CLUSTER_OPT,
  UPDATE_CLUSTER_OPT,
  GET_PRODUCT_LIST,
  CREATE_NEW_L3,
  GET_DROPS_DATA,
  UPDATE_BUDGET_L2_DROP,
  DROP_CONFIG,
  GET_STORE_ELIGIBILITY_DATA
} from "../../../constants/apiConstants";

export const planInitialService = createSlice({
  name: "planInitialService",
  initialState: {
    loader_2_1: false,
    budgetL2Data: [],
    l3OptData: [],
    clusterOptData: [],
    productList: [],
    dynamicLevelFilters: [],
    clusterOptGraphData: {},
    dropsData: {},
    storeEligibilityData: {}
  },
  reducers: {
    set2_1_Loader: (state, action) => {
      state.loader_2_1 = action.payload;
    },
    setBudgetL2Data: (state, action) => {
      state.budgetL2Data = action.payload;
    },
    setL3OptData: (state, action) => {
      state.l3OptData = action.payload;
    },
    setClusterOptData: (state, action) => {
      state.clusterOptData = action.payload;
    },
    setProductList: (state, action) => {
      state.productList = action.payload;
    },
    setDynamicLevelFilters: (state, action) => {
      state.dynamicLevelFilters = action.payload;
    },
    setClusterGraphdata: (state, action) => {
      state.clusterOptGraphData = action.payload;
    },
    setDropsData: (state, action) => {
      state.dropsData = action.payload;
    },
    setStoreEligibilityData: (state, action) => {
      state.storeEligibilityData = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  set2_1_Loader,
  setBudgetL2Data,
  setL3OptData,
  setClusterOptData,
  setProductList,
  setDynamicLevelFilters,
  setClusterGraphdata,
  setDropsData,
  setStoreEligibilityData
} = planInitialService.actions;

//budget level2 table apis
export const getBudgetL2Data = (postBody) => () => {
  return axiosInstance({
    url: GET_BUDGET_L2,
    method: "POST",
    data: postBody,
  });
};
export const getOptimizeL3Data = (postBody) => () => {
  return axiosInstance({
    url: OPTIMIZE_L3,
    method: "POST",
    data: postBody,
  });
};
export const updateBudgetL2Data = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_BUDGET_L2,
    method: "PUT",
    data: postBody,
  });
};

export const fetchDropsData = (postBody) => () => {
  return axiosInstance({
    url: GET_DROPS_DATA,
    method: "POST",
    data: postBody,
  });
};

export const updateBudgetL2DropData = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_BUDGET_L2_DROP,
    method: "POST",
    data: postBody,
  });
};

export const fetchDropConfig = (postBody) => () => {
  return axiosInstance({
    url: DROP_CONFIG,
    method: "POST",
    data: postBody,
  });
};

//budget level3 table apis
export const getL3OptData = (postBody) => () => {
  return axiosInstance({
    url: GET_L3_OPT,
    method: "POST",
    data: postBody,
  });
};
export const updateL3OptData = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_L3_OPT,
    method: "PUT",
    data: postBody,
  });
};

//budget cluster table apis
export const getClusterOptData = (postBody) => () => {
  return axiosInstance({
    url: GET_CLUSTER_OPT,
    method: "POST",
    data: postBody,
  });
};
export const updateClusterOptData = (postBody) => () => {
  return axiosInstance({
    url: UPDATE_CLUSTER_OPT,
    method: "PUT",
    data: postBody,
  });
};

//new class creation related apis
export const getProductList = (postBody) => () => {
  return axiosInstance({
    url: GET_PRODUCT_LIST,
    method: "POST",
    data: postBody,
  });
};
export const createNewL3 = (postBody) => () => {
  return axiosInstance({
    url: CREATE_NEW_L3,
    method: "POST",
    data: postBody,
  });
};
export const getStoreEligibilityData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_ELIGIBILITY_DATA,
    method: "POST",
    data: postBody,
  });
};

// Selectors

export const planInitialReducerSelector = createSelector(
  (state) => state,
  (state) => state.assortsmartReducer.planInitialReducer
);

export const budgetL2TableDataSelector = createSelector(
  planInitialReducerSelector,
  (state) => state.budgetL2Data
);

export const budgetClusterOcrptTableDataSelector = createSelector(
  planInitialReducerSelector,
  (state) => state.clusterOptData
);

export const budgetClusterOptGraphDataSelector = createSelector(
  planInitialReducerSelector,
  (state) => state.clusterOptGraphData
);

export const budgetLevelThreeTableDataSelector = createSelector(
  planInitialReducerSelector,
  (state) => state.l3OptData
);

export const loader_2_1_Selector = createSelector(
  planInitialReducerSelector,
  (state) => state.loader_2_1
);

export const productListSelector = createSelector(
  planInitialReducerSelector,
  (state) => state.productList
);

export const ClusterStoreEligibilitySelector = createSelector(
  planInitialReducerSelector,
  (state) => state.storeEligibilityData
);


export default planInitialService.reducer;

