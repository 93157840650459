import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import { Dialog, DialogContent, Typography } from "@mui/material";
import {
  ERROR_MESSAGE,
  STORE_INVENTORY_LABEL_MAPPINGS,
  tableConfigurationMetaData,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import {
  getStoreInventoryTableConfiguration,
  getStoreInventoryTableData,
  setStoreInventoryTableConfigLoader,
  setStoreInventoryLoader,
  setStoreInventoryTableData,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/store-inventory-services";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";

import globalStyles from "Styles/globalStyles";
import makeStyles from "@mui/styles/makeStyles";
import { addSnack } from "actions/snackbarActions";

const useStyles = makeStyles((theme) => ({
  moduleTitle: {
    ...theme.typography.h3,
  },
  dialogContentBody: {
    borderTop: "none",
  },
}));

const StoreArticleInventoryPopup = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const [storeInventoryTableColumns, setStoreInventoryTableColumns] = useState(
    []
  );
  const [storeInventoryData, setStoreInventoryData] = useState([]);

  const fetchStoreInventoryData = async () => {
    try {
      props.setStoreInventoryLoader(true);
      let body = {
        filters: props.filters,
        meta: {
          ...tableConfigurationMetaData.meta,
          limit: { limit: 10, page: 1 },
        },
        article: props.article,
        metrics: props.metric,
      };
      let response = await props.getStoreInventoryTableData(body);
      if (response.data.status) {
        setStoreInventoryData(response.data.data);
        props.setStoreInventoryTableData(response.data);
        props.setStoreInventoryLoader(false);
        return { ...response.data, totalCount: response.data.total };
      } else {
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    } catch {
      displaySnackMessages(ERROR_MESSAGE, "error");
      return [];
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  useEffect(() => {
    const fetchColumnData = async () => {
      props.setStoreInventoryTableConfigLoader(true);
      let columns = await props.getStoreInventoryTableConfiguration();
      props.setStoreInventoryTableConfigLoader(false);
      let formattedColumns = agGridColumnFormatter(columns?.data?.data);
      setStoreInventoryTableColumns(formattedColumns);
    };

    if (props.active) {
      fetchColumnData();
      fetchStoreInventoryData();
    }
  }, [props.active]);

  return props.active ? (
    <Dialog
      id="storeInventoryDialog"
      aria-labelledby="store-invenotry-dialog"
      open={props.active}
      maxWidth="md"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        props.closeModal();
      }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      <DialogContent
        dividers
        classes={{
          root: classnames(
            classes.dialogContentRoot,
            globalClasses.flexRow,
            globalClasses.layoutAlignBetweenCenter
          ),
        }}
      >
        <Typography classes={{ root: classes.moduleTitle }}>
          {STORE_INVENTORY_LABEL_MAPPINGS[props.metric]}
        </Typography>
        <IconButton color="primary" onClick={props.closeModal} size="large">
          <CloseIcon fontSize="medium" />
        </IconButton>
      </DialogContent>
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContentBody,
        }}
      >
        <Loader
          loader={
            props.storeInventoryLoader || props.storeInventoryTableConfigLoader
          }
          minHeight={"350px"}
        >
          {storeInventoryTableColumns?.length > 0 && (
            <AgGridComponent
              columns={storeInventoryTableColumns}
              rowdata={storeInventoryData}
              selectAllHeaderComponent={false}
              uniqueRowId={"store_code"}
            />
          )}
        </Loader>
      </DialogContent>
    </Dialog>
  ) : null;
};

const mapStateToProps = (store) => {
  return {
    storeInventoryLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .storeInventoryLoader,
    storeInventoryTableConfigLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .storeInventoryTableConfigLoader,
    storeInventoryTableData:
      store.inventorysmartReducer.inventorySmartStoreInventoryService
        .storeInventoryTableData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStoreInventoryTableConfiguration: (payload) =>
    dispatch(getStoreInventoryTableConfiguration(payload)),
  getStoreInventoryTableData: (payload) =>
    dispatch(getStoreInventoryTableData(payload)),
  setStoreInventoryTableConfigLoader: (payload) =>
    dispatch(setStoreInventoryTableConfigLoader(payload)),
  setStoreInventoryLoader: (payload) =>
    dispatch(setStoreInventoryLoader(payload)),
  setStoreInventoryTableData: (payload) =>
    dispatch(setStoreInventoryTableData(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreArticleInventoryPopup);
