import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useReducer, useState, useRef, forwardRef } from "react";
import { connect } from "react-redux";
import {
  getAllFilters,
  getFiltersValues,
  setSelectedFilters,
  getCrossDimensionFiltersData,
} from "../../../actions/filterAction";
import {
  fetchStoreGrpFilteredStores,
  setStoreGroupFilteredStores,
  ToggleLoader,
  setStoreGrpFilteredCols,
  resetFilterStores,
  addSelectedGroups,
  setSelectedStores,
  setSelectedStoresSelectAllState,
  setUnselectedStoresInSelectAll,
} from "../services-store-grouping/custom-store-group-service";
import { getColumns, getColumnsAg } from "../../../actions/tableColumnActions";
import {
  configureAttributeOptions,
  fetchStoreFilters,
  formatFiltersDependency,
  filterStoreChannels,
  convertFilterStrValsToDropdwnObjects,
} from "./common-functions";
import globalStyles from "Styles/globalStyles";
import StoreGroupFiltersHelper from "./store-group-filters-helper";
import { addSnack } from "actions/snackbarActions";
import storeGroupStyles from "./store-group-styles";
import clsx from "clsx";
import { find } from "lodash";

/**
 * New set of filters imports
 */
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import LoadingOverlay from "Utils/Loader/loader";

const useStyles = makeStyles((theme) => ({
  filterLabel: {
    marginRight: 15,
    marginBottom: theme.typography.pxToRem(10),
  },
  defnIcon: {
    marginLeft: 20,
    padding: 8,
    backgroundColor: "#68727E",
    "&:hover": {
      backgroundColor: "#3D5772",
    },
  },
  listIcon: {
    backgroundColor: "white",
    fontSize: 22,
    color: "#68727E",
    "&:hover": {
      color: "#3D5772",
    },
  },
  action: {
    width: "30%",
    display: "flex",
    justifyContent: "center",
    marginRight: "6rem",
  },
  button: {
    marginLeft: "0.6rem",
  },
}));

const initialState = {
  filteredStores: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FILTERED_STORES":
      return {
        ...state,
        filteredStores: action.payload,
      };
    default:
      return state;
  }
};
const ManualGroup = forwardRef((props, ref) => {
  const storeGroupCommonClasses = storeGroupStyles();
  const globalClasses = globalStyles();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [storeFiltersSelection, setstoreFiltersSelection] = useState(
    props.SGdashboardStoreFilters || []
  );
  const [productFiltersSelection, setproductFiltersSelection] = useState(
    props.SGdashboardProductFilters || []
  );
  const [productFiltersReset, setproductFiltersReset] = useState(false);
  const [storeFiltersReset, setstoreFiltersReset] = useState(false);
  const [showProductFilters, setshowProductFilters] = useState(false);

  /**
   * state variables for filters
   */
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterDependencyChips, setFilterDependencyChips] = useState([]);
  const [showFilterLoader, setShowFilterLoader] = useState(false);

  const resetBtnClicked = useRef(false);

  useEffect(() => {
    return () => {
      props.resetFilterStores();
      let store_filters_selection_obj = {};
      store_filters_selection_obj["store_filters_create_group"] = [];
      store_filters_selection_obj["product_filters_create_group"] = [];
      props.setSelectedFilters(store_filters_selection_obj);
    };
  }, []);

  useEffect(() => {
    props.setStoreGroupFilteredStores({ data: [], count: 0 });
    const fetchHierarchyData = async () => {
      props.ToggleLoader(true);
      const storeFilters = await fetchStoreFilters();
      // let currentAppName = props.location.pathname.includes("inventory-smart")
      //   ? "InventorySmart"
      //   : null;

      // const storeFilters = await fetchStoreFilters([], false, currentAppName);
      dispatch({
        type: "FILTERED_STORES",
        payload: storeFilters,
      });
      const cols = await props.getColumnsAg("table_name=store_group_filter");
      props.setStoreGrpFilteredCols(cols);
      props.ToggleLoader(false);
    };

    fetchHierarchyData();
    manualFiltersOnChange(storeFiltersSelection, "store");
    manualFiltersOnChange(productFiltersSelection, "product");
  }, []);

  const onFilter = async () => {
    props.ToggleLoader(true);
    let store_filters_selection_obj = {};
    let storeFilters = formatFiltersDependency(
      storeFiltersSelection,
      "store",
      true
    );
    let productFilters = formatFiltersDependency(
      productFiltersSelection,
      "product",
      true
    );
    let reqFieldsErr = false;
    for (const filter of state.filteredStores) {
      const key = {
        filter_id: filter.column_name,
        filter_type: filter.type,
      };
      let match = find(
        filter.dimension === "product" ? productFilters : storeFilters,
        key
      );
      if (
        filter.dimension === "store"
          ? filter.is_mandatory && !match
          : filter.is_mandatory && showProductFilters && !match
      ) {
        reqFieldsErr = true;
      }
    }
    if (reqFieldsErr) {
      props.addSnack({
        message: "Select the required fields",
        options: {
          variant: "error",
        },
      });
      props.ToggleLoader(false);
      return;
    }
    store_filters_selection_obj["store_filters_create_group"] = storeFilters;
    store_filters_selection_obj["product_filters_create_group"] =
      productFilters;
    props.setSelectedFilters(store_filters_selection_obj);
    if (storeFilters.length === 0) {
      props.setStoreGroupFilteredStores({ data: [], count: 0 });
      props.ToggleLoader(false);
      return;
    }
    props.setSelectedStores([]);
    props.setSelectedStoresSelectAllState(false);
    props.setUnselectedStoresInSelectAll([]);
    setOpenFilterModal(false);
    ref.storeTableRef.current?.api?.refreshServerSideStore({ purge: true });
    props.ToggleLoader(false);
  };

  const productFiltersOnReset = () => {
    setproductFiltersSelection([]);
    let product_filters_selection_obj = {};
    product_filters_selection_obj["product_filters_create_group"] = [];
    props.setSelectedFilters(product_filters_selection_obj);
    if (ref) {
      manualFiltersOnChange([], "product");
    }
  };

  const storeFiltersOnReset = () => {
    setstoreFiltersSelection([]);
    setFilterDependencyChips([]);
    setproductFiltersSelection([]);
    let store_filters_selection_obj = {};
    store_filters_selection_obj["product_filters_create_group"] = [];
    store_filters_selection_obj["store_filters_create_group"] = [];
    props.setSelectedFilters(store_filters_selection_obj);
    props.setStoreGroupFilteredStores({ data: [], count: 0 });
    ref.storeFiltersRef.current = [];
    ref.productFiltersRef.current = [];
  };

  const onToggleProductFiltersSwitch = (event) => {
    if (!event.target.checked) {
      setproductFiltersReset(!productFiltersReset);
    }
    setshowProductFilters(event.target.checked);
  };

  const manualFiltersOnChange = async (dependency, dimension) => {
    setShowFilterLoader(true);
    const updatedManualFiltersSelection =
      dependency.length > 0 ? formatFiltersDependency(dependency) : [];
    try {
      let initialFilterElements = [...state.filteredStores];
      let nonFilteredElementsByDimension =
        dimension === "product"
          ? storeFiltersSelection
          : productFiltersSelection;
      if (resetBtnClicked.current) {
        nonFilteredElementsByDimension = [];
      }
      const updatedFilteredElements = initialFilterElements.map(async (key) => {
        if (key.type === "cascaded") {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: [
              ...updatedManualFiltersSelection,
              ...formatFiltersDependency(nonFilteredElementsByDimension),
            ],
            application_code: 3,
            dimension: key.dimension,
          };
          const options = await getCrossDimensionFiltersData(body)();
          if (key.column_name === "channel") {
            key.initialData = configureAttributeOptions(
              filterStoreChannels(options.data.data.attribute)
            );
          } else {
            key.initialData = configureAttributeOptions(
              options.data.data.attribute
            );
          }
        }
        return key;
      });
      await Promise.all(updatedFilteredElements);
      dispatch({
        type: "FILTERED_STORES",
        payload: initialFilterElements,
      });
      setFilterDependencyChips([
        ...dependency,
        ...nonFilteredElementsByDimension,
      ]);
      if (dimension === "product") {
        ref.productFiltersRef.current = dependency;
        setproductFiltersSelection(
          convertFilterStrValsToDropdwnObjects(updatedManualFiltersSelection)
        );
      } else {
        ref.storeFiltersRef.current = dependency;
        setstoreFiltersSelection(
          convertFilterStrValsToDropdwnObjects(updatedManualFiltersSelection)
        );
      }
      if (resetBtnClicked.current) {
        resetBtnClicked.current = false;
      }
      setShowFilterLoader(false);
    } catch (error) {
      props.addSnack({
        message: "Something went wrong",
        options: {
          variant: "error",
        },
      });
      setShowFilterLoader(false);
    }
  };
  return (
    <>
      <div className={globalClasses.marginBottom}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenFilterModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {filterDependencyChips.length > 0 && (
          <FilterChips filterConfig={filterDependencyChips}></FilterChips>
        )}
      </div>
      <FilterModal
        open={openFilterModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenFilterModal(false)}
      >
        <CustomAccordion
          label="Store"
          defaultExpanded={true}
          customClass={globalClasses.accordianWrapper}
        >
          <LoadingOverlay loader={showFilterLoader}>
            <StoreGroupFiltersHelper
              doNotUpdateDefaultValue={true}
              defaultFilterSelection={storeFiltersSelection}
              dimension="store"
              updateData={manualFiltersOnChange}
              customFilter={true}
              onReset={storeFiltersOnReset}
              filterArray={state.filteredStores}
              resetFilter={storeFiltersReset}
              screenName="store_filters_create_group"
            />
          </LoadingOverlay>
        </CustomAccordion>
        <CustomAccordion
          label="Product"
          defaultExpanded={true}
          customClass={globalClasses.accordianWrapper}
        >
          <LoadingOverlay loader={showFilterLoader}>
            <StoreGroupFiltersHelper
              doNotUpdateDefaultValue={true}
              defaultFilterSelection={productFiltersSelection}
              dimension="product"
              updateData={manualFiltersOnChange}
              customFilter={true}
              onReset={productFiltersOnReset}
              filterArray={state.filteredStores}
              resetFilter={productFiltersReset}
              screenName="product_filters_create_group"
            />
          </LoadingOverlay>
        </CustomAccordion>
        <div
          className={clsx(
            storeGroupCommonClasses.rightAlign,
            storeGroupCommonClasses.applyBottomMargin
          )}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={onFilter}
            className={storeGroupCommonClasses.applyRightMargin}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              resetBtnClicked.current = true;
              setstoreFiltersReset(!storeFiltersReset);
            }}
          >
            Reset
          </Button>
        </div>
      </FilterModal>
    </>
  );
});

const mapStateToProps = (state) => {
  return {
    SGdashboardStoreFilters:
      state.filterReducer.selectedFilters["SG_dashboard_store_filters"],
    SGdashboardProductFilters:
      state.filterReducer.selectedFilters["SG_dashboard_product_filters"],
  };
};

const mapActionsToProps = {
  fetchStoreGrpFilteredStores,
  setStoreGroupFilteredStores,
  getAllFilters,
  getFiltersValues,
  ToggleLoader,
  getColumns,
  setStoreGrpFilteredCols,
  setSelectedFilters,
  resetFilterStores,
  addSelectedGroups,
  setSelectedStores,
  setSelectedStoresSelectAllState,
  setUnselectedStoresInSelectAll,
  addSnack,
  getColumnsAg,
};
export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(ManualGroup);
