import React, { useEffect, useState, useRef } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import { Tooltip, Button, Box } from "@mui/material";
import { Delete } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import update from "immutability-helper";
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useColumnOrder,
  useGroupBy,
  useBlockLayout,
} from "react-table";
import { useSticky } from "react-table-sticky";
import "./index.scss";
import {
  useGetInstance,
  useRowSpan,
  useCheckBoxColumn,
  useCheckBoxLastColumn,
  useFilteredColumns,
} from "./hooks/utilityHooks";

import { useColumnExpander } from "./hooks/useColumnExpander";
import SetAll from "./components/setall-form";
import { Pagination } from "./components/pagination";
import ColPreference from "./components/column-preference";
import SuccessBox from "./components/successPopup";
import { btwnRange } from "./components/range-column-filter";
import { CustomDragLayer } from "./components/custom-drag-layer";
import { setTableState } from "../../actions/tableColumnActions";
import DefaultColumnFilter from "./components/default-column-filter";
import GlobalFilter from "./components/global-filter";
import ColumnLayout from "./components/column-layout";
import {
  orderByFn,
  getBody,
  textFn,
} from "./components/sortby-filter-functions";
import FooterMarkup from "./markup/FooterMarkup";
import theme from "Styles/theme";
import { cloneDeep } from "lodash";
// adding classnames for row for styling

export const getRowExpandedStyle = (
  _expandedColumns,
  row,
  cell,
  changeSubrows,
  Icon
) => {
  if (row.subRows?.length) {
    return (
      <Box
        component="span"
        display={row?.original?.expandLabel ? "flex" : ""}
        alignItems="center"
        style={{ cursor: "pointer" }}
        onClick={() => {
          changeSubrows(cell, row);
          row.getToggleRowExpandedProps().onClick();
        }}
      >
        {row?.original?.expandLabel && (
          <span>{row?.original?.expandLabel}</span>
        )}
        <Icon color="primary" />
      </Box>
    );
  }
  return null;
};

function Table(props) {
  const [columns, fetchColumns] = useState(props.columns);
  const firstTimeRender = useRef(true);
  const isReverted = useRef(false);
  const [data, setRowData] = useState(props.rowdata);
  const [colPreference, setColPreference] = useState(
    props.columns.filter((item) => item.accessor)
  );
  const [showSetAll, setShowSetAll] = useState(false);
  const [successBox, showSuccessBox] = useState(false);
  const [showFieldChooser, setshowFieldChooser] = useState(false);
  const [expandedColumns, setExpandedColumns] = useState({});

  const {
    getInstanceOnMount,
    callDeleteApi,
    onEditClick,
    onReviewClick,
    onChartClick,
    ExpandIcon,
    ColapseIcon,
    lockCellApi,
    customCellRenderer,
    onBlurForWeek,
    columnsToFilter,
    onDownloadClick,
    selectAllCallBack,
    manualIsAllRowsSelected,
    unselectedRowsInSelectAll,
    deletePlanColumn,
    displayMsg = "No Data",
    InfoComponent,
    handleValidation
  } = props;
  const PlusIcon = ExpandIcon || ChevronRightIcon;
  const MinusIcon = ColapseIcon || KeyboardArrowDownIcon;

  const filterTypes = React.useMemo(
    () => ({
      range: btwnRange,
      text: textFn,
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      filter: props.isColumnsToFilter? "includes" : "text",
    }),
    []
  );

  let callbacksToTable = {};
  callbacksToTable.settabledata = setRowData;
  if (getInstanceOnMount) {
    callbacksToTable.getInstanceOnMount = getInstanceOnMount;
  }
  if (callDeleteApi) {
    callbacksToTable.callDeleteApi = callDeleteApi;
  }
  if (onEditClick) {
    callbacksToTable.onEditClick = onEditClick;
  }
  if (onReviewClick) {
    callbacksToTable.onReviewClick = onReviewClick;
  }
  if (lockCellApi) {
    callbacksToTable.lockCellApi = lockCellApi;
  }
  if (onChartClick) {
    callbacksToTable.onChartClick = onChartClick;
  }
  if (customCellRenderer) {
    callbacksToTable.customCellRenderer = customCellRenderer;
  }

  if (onBlurForWeek) {
    callbacksToTable.onBlurForWeek = onBlurForWeek;
  }

  if (onDownloadClick) {
    callbacksToTable.onDownloadClick = onDownloadClick;
  }
  if (selectAllCallBack) {
    callbacksToTable.onSelectAll = selectAllCallBack;
  }
  const plugins = [
    useBlockLayout,
    useSticky,
    useGlobalFilter,
    useFilters,
    useColumnOrder,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useGetInstance,
    useRowSpan,
    useColumnExpander,
    useFilteredColumns,
  ];

  if (props.rowSelection) {
    plugins.push(
      props.checkboxPosition === "right"
        ? useCheckBoxLastColumn
        : useCheckBoxColumn
    );
    callbacksToTable.checkboxFocusedRow = props.selectedRowHandler;
  }
  const styleForRowOnselection = (cell) => {
    let subHeaderExist = setSubHeaderStyle(cell.column);
    let className = "td";
    if (cell.column.is_editable && cell.column.type === "dollar") {
      className = "td input-field-td";
    }
    if (cell.column.id === "selection") {
      className = "td selectionbox";
    } else if (cell.rowSpan > 1) {
      className = props?.width ? "" : "row-span-td";
    }
    if (subHeaderExist) {
      return `${className} groupedCellBorder`;
    }
    return className;
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
      getInstanceOnMount,
      columnsToFilter,
      tableId: props.tableId,
      initialState: {
        groupBy: props.groupBy || [],
        pageIndex: props.currentPage ? props.currentPage : 0,
        pageSize: 10,
        selectedRowIds: props.selectedRows || {},
      },
      orderByFn: orderByFn,
      expandSubRows: true,
      filterTypes,
      manualPagination: props.manualCallBack ? true : false,
      manualFilters: props.manualFilters,
      manualSortBy: props.manualSortBy,
      getRowId: (row, relativeIndex, parent) => {
        if (props.primaryKey) {
          return row[props.primaryKey];
        } else {
          return parent ? [parent.id, relativeIndex].join(".") : relativeIndex;
        }
      },
      getSubRows: (row) => {
        return row.subRows || [];
      },
      autoResetPage: false,
      autoResetExpanded: false,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      disabledRowCheckbox: props.disabledRowCheckbox,
      pageCount: props.totalCount
        ? Math.ceil(props.totalCount / 10)
        : Math.ceil(data.length / 10),
      updateMyData: props.updateMyData,
      expandedColumns: expandedColumns,
      features: props.features,
      primaryKey: props.primaryKey,
      customRowSelection: props.customRowSelection
        ? props.customRowSelection
        : null,
      enableSelectAll: props.enableSelectAll ? true : false,
      manualIsAllRowsSelected: manualIsAllRowsSelected
        ? manualIsAllRowsSelected
        : false,
      unselectedRowsInSelectAll: unselectedRowsInSelectAll,
      ...callbacksToTable,
      shouldShowCheckBoxHandler: props.shouldShowCheckBoxHandler
        ? props.shouldShowCheckBoxHandler
        : null,
    },
    ...plugins
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    rows,
    rowSpanHeaders,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    visibleColumns,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    setColumnOrder,
    setHiddenColumns,
    state: {
      pageIndex,
      pageSize,
      globalFilter,
      selectedRowIds,
      filters,
      hiddenColumns,
      sortBy,
    },
  } = tableInstance;
  useEffect(() => {
    props.hiddenColumns
      ? setHiddenColumns(props.hiddenColumns)
      : setHiddenColumns([]);
  }, [props.hiddenColumns]);

  useEffect(() => {
    setRowData(props.rowdata);
    if (props.rowdata.length === 0 && pageIndex !== 0) {
      gotoPage(pageIndex - 1);
    }
  }, [props.rowdata]);
  const changeSubrows = (cell, row) => {
    let obj = {};
    let existingIds = { ...expandedColumns };

    if (existingIds[row.id] && existingIds[row.id] === cell.column.id) {
      delete existingIds[row.id];
    } else {
      obj[row.id] = cell.column.id;
    }
    Object.keys(existingIds).forEach((key) => {
      if (key.substr(0, row.id.length) === row.id) {
        delete existingIds[key];
      }
    });

    obj = { ...existingIds, ...obj };
    setExpandedColumns(obj);
  };
  useEffect(() => {
    if (!firstTimeRender.current && hiddenColumns.length > 0) {
      let visibleColsArray = tableInstance.allColumns.filter(
        (item) => item.isVisible
      );
      setColumnWidth(
        tableInstance.columns,
        props.rowSelection
          ? visibleColsArray.length - 1
          : visibleColsArray.length
      );
    }
  }, [hiddenColumns]);

  useEffect(() => {
    if (props.toggleSetAll) {
      setShowSetAll(true);
    }
  }, [props.toggleSetAll]);

  useEffect(() => {
    setColPreference(props.columns.filter((item) => item.accessor));
    setColumnWidth(props.columns);
    let hideCols = [];
    props.columns
      .filter((item) => item.is_hidden)
      .forEach((item) => {
        hideCols.push(item.accessor);
        if (item.columns?.length > 1) {
          item.columns.forEach((col) => {
            hideCols.push(col.accessor);
          });
        }
      });
    setHiddenColumns(hideCols);
  }, [props.columns]);

  useEffect(() => {
    if (!firstTimeRender.current) {
      props.selectedRowHandler &&
        props.selectedRowHandler(selectedFlatRows, selectedRowIds);
    }
  }, [selectedRowIds]);

  const objectsEqual = (o1, o2) =>
    typeof o1 === "object" && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
      : o1 === o2;

  const arraysEqual = (a1, a2) =>
    a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

  useEffect(() => {
    let pageNo = pageIndex;
    if (!firstTimeRender.current && props.manualCallBack) {
      if (
        !arraysEqual(filters, props.tableState[props.table_name].filters) &&
        pageIndex !== 0
      ) {
        gotoPage(0);
        pageNo = 0;
        isReverted.current = true;
        const body = getBody(filters, sortBy);
        props.manualCallBack(body, 0, pageSize);
      } else {
        if (!isReverted.current) {
          const body = getBody(filters, sortBy);
          props.manualCallBack(body, pageIndex, pageSize);
        }
        isReverted.current = false;
      }
    }
    let obj = {};
    obj[props.table_name] = {
      filters: filters,
      pageIndex: pageNo,
      pageSize: pageSize,
    };
    props.setTableState(obj);
  }, [pageIndex, pageSize, filters, sortBy]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  const applyPreference = (
    columnOrdered,
    hiddenCols,
    freezedColumns,
    colData
  ) => {
    setColumnOrder(columnOrdered);
    setHiddenColumns(hiddenCols);
    setColPreference([...colData]);
    fetchColumns((old) =>
      old.map((col) => {
        if (freezedColumns.indexOf(col.accessor) > -1) {
          col.sticky = "left";
        } else if (col.accessor) {
          delete col.sticky;
        }
        return col;
      })
    );
    setshowFieldChooser(false);
  };

  const onSetAllApply = async (newRow) => {
    let selectedRowIdsArray = Object.keys(selectedRowIds);
    if (selectedRowIdsArray.length > 0) {
      let selectedPrimaryIds = data
        .filter(
          (item) => selectedRowIdsArray.indexOf(item[props.primaryKey]) > -1
        )
        .map((item) => item[props.primaryKey]);
      let dataObj = {
        table_name: props.tableName,
        update_all: false,
        fields_values: [{ ...newRow }],
        object_ids: selectedPrimaryIds,
      };

      let newData = data.map((row) => {
        if (
          selectedRowIdsArray.indexOf(row[props.primaryKey].toString()) > -1
        ) {
          return {
            ...row,
            ...newRow,
          };
        }
        return row;
      });
      if (props.setAllChanges) {
        await props.setAllChanges(dataObj, newData);
      }
      setRowData(newData);
    } else {
      let dataObj = {
        table_name: props.table_name,
        update_all: true,
        fields_values: [{ ...newRow }],
      };
      await props.setAllChanges(dataObj);
      setRowData((old) =>
        old.map((row) => {
          return {
            ...row,
            ...newRow,
          };
        })
      );
    }

    showSuccessBox(true);
    setShowSetAll(false);
  };

  const moveCol = (dragIndex, hoverIndex) => {
    if (visibleColumns.some((e) => e.id === "selection")) {
      dragIndex = dragIndex - 1;
      hoverIndex = hoverIndex - 1;
    }
    if (visibleColumns.some((e) => e.id === "expander")) {
      dragIndex = dragIndex - 1;
      hoverIndex = hoverIndex - 1;
    }
    const dragRecord = colPreference[dragIndex];
    const visibleCols = colPreference.map((col) => {
      return col.accessor || col.id;
    });
    setColumnOrder(
      update(visibleCols, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord.accessor],
        ],
      })
    );

    let cols = [...colPreference];
    cols = updateDisplayOrder(cols, dragIndex, hoverIndex);
    setColPreference(
      update(cols, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
  };
  const getColProps = (cell) => {
    let cellProps = cell.getCellProps();
    if (cell.rowSpan > 1 || cell.isRowSpanned) {
      cellProps.style.boxShadow = "2px 0px 0px #ccc";
      cellProps.style.backgroundColor = "#f9f9f9";
      if (cell.rowSpan > 1) {
        cellProps.style.borderTop = "1px solid #ddd";
      }
    }
    cellProps.style.minHeight = "40px";
    cellProps.style.padding = "0.5rem";
    cellProps.style.lineHeight = "2";
    if (cell.row.subRows.length > 0 && props.subRowStyle) {
      let subRowStyles = props.subRowStyle(cell.row);
      cellProps.style = { ...cellProps.style, ...subRowStyles };
    }
    if (props.dynamicCellStyle) {
      let dynamicCellStyle = props.dynamicCellStyle(cell);
      cellProps.style = { ...cellProps.style, ...dynamicCellStyle };
    }
    if (props.conditionalCellStyle) {
      let conditionalCellStyle = props.conditionalCellStyle(cell);
      cellProps.style = { ...cellProps.style, ...conditionalCellStyle };
    }
    if (props.cellStyle) {
      cellProps.style = { ...cellProps.style, ...props.cellStyle };
    }
    return cellProps;
  };
  const setColumnWidth = (columnData, visibleColumnlength) => {
    let box = document.getElementById(
      props.tableId ? props.tableId : "tableConatiner"
    );
    let clientWidth = document.getElementById(
      props.tableId ? props.tableId : "tableConatiner"
    ).clientWidth;

    let width = box.scrollWidth;
    let cols = columnData.filter((item) => !item.is_hidden);
    let initialLength = 0;
    let finalColLength = cols.reduce((prev, curr) => {
      if (curr.columns && curr.columns.length > 0) {
        return prev + curr.columns.length;
      }
      return prev + 1;
    }, initialLength);
    width = props.rowSelection ? width - 60 : width;
    let visibleNoOfColumns = visibleColumnlength
      ? visibleColumnlength
      : finalColLength;
    let cellWidth = Math.floor(width / visibleNoOfColumns);
    cellWidth = width - clientWidth > 5 || cellWidth < 150 ? 150 : cellWidth;
    let finalCols = columnData.map((item, index) => {
      let data = cloneDeep(item);
      if (data.columns && data.columns.length > 0) {
        data.columns = data.columns.map((x) => {
          x.width = props?.width ? props.width : cellWidth;
          return x;
        });
        data.width = cellWidth * data.columns.length;
      } else {
        data.width = cellWidth;
      }
      return data;
    });
    fetchColumns(finalCols);
  };

  const updateDisplayOrder = (Arr, initialIndex, finalIndex) => {
    if (initialIndex < finalIndex) {
      Arr = Arr.map((item, index) => {
        if (index === initialIndex) {
          item.order_of_display = Arr[finalIndex].order_of_display;
        } else if (index > initialIndex && index <= finalIndex) {
          item.order_of_display = item.order_of_display - 1;
        }
        return item;
      });
    } else if (initialIndex > finalIndex) {
      Arr[initialIndex].order_of_display = Arr[finalIndex].order_of_display;
      Arr = Arr.map((item, index) => {
        if (index < initialIndex && index >= finalIndex) {
          item.order_of_display = item.order_of_display + 1;
        }
        return item;
      });
    }
    return Arr;
  };

  const tableData = props.showPagination ? page : rows;

  const prepareRowSpan = () => {
    tableData.forEach((row, i) => {
      prepareRow(row);
      row.allCells.forEach((cell, j) => {
        let rowSpanHeader =
          rowSpanHeaders && rowSpanHeaders.find((x) => x.id === cell.column.id);

        if (rowSpanHeader !== undefined) {
          if (
            rowSpanHeader.topCellValue === null ||
            rowSpanHeader.topCellValue !== cell.value
          ) {
            //when the cell value between the rows are not same
            row.allCells[j].isRowSpanned = false;
            rowSpanHeader.topCellValue = cell.value;
            rowSpanHeader.topCellIndex = i;
            row.allCells[j].rowSpan = 1;
          } else {
            //when the cell value between the rows are same
            tableData[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
            row.allCells[j].isRowSpanned = true;
          }
        }
      });
    });
  };
  const getGroupedCell = (row, cell) => {
    return (
      <>
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? "▼" : "►"}
        </span>{" "}
        {cell.render("Cell")} ({row.subRows.length})
      </>
    );
  };

  const getUnGroupedCell = (cell, canRowExpand) => {
    var canRowExpandVar = cell.render("Cell", {
      canRowExpand: canRowExpand,
    });
    return cell.isAggregated
      ? // If the cell is aggregated, use the Aggregated
        // renderer for cell
        cell.render("Aggregated")
      : cell.isPlaceholder
      ? null // For cells with repeated values, render null
      : // Otherwise, just render the regular cell
        canRowExpandVar;
  };

  const renderGroupedrUngroupedCell = (row, cell, canRowExpand) => {
    return cell.isGrouped
      ? // If it's a grouped cell, add an expander and row count
        getGroupedCell(row, cell)
      : getUnGroupedCell(cell, canRowExpand);
  };
  const setSubHeaderStyle = (column, headerLevel) => {
    let colStyle = column.getHeaderProps();
    let condition = false;
    let borderStyle = `solid 2px ${theme.palette.colours.tableBorderColor}`;
    if (column.sub_headers && column.sub_headers.length > 0) {
      colStyle.style.borderRight = borderStyle;
      condition = true;
    }

    if (column.parent) {
      let length = column.order_of_display.toString().includes(".")
        ? column.order_of_display.toString().split(".")[1]
        : column.order_of_display;
      if (length == column.parent.sub_headers.length) {
        colStyle.style.borderRight = borderStyle;
        condition = true;
      }
    }
    return headerLevel ? colStyle.style : condition;
  };

  const generateTooltip = (cell, row) => {
    if (["percentage", "dollar", "int", "float"].includes(cell.column.type)) {
      //in case of numbers we should display only 2 decimal points
      return Math.round(row.original[cell.column.id] * 100) / 100;
    } else if (cell.column.type === "array") {
      return cell?.value?.join(", ");
    } else {
      return row.original[cell.column.id];
    }
  };

  return (
    <>
      <div>
        {props.showSearchBar && (
          <div
            colSpan={visibleColumns.length}
            style={{
              textAlign: "right",
            }}
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}
        {props.fieldChooser && (
          <Button
            id="setAll"
            variant="contained"
            color="primary"
            onClick={() => {
              setshowFieldChooser(true);
            }}
          >
            <FilterListIcon></FilterListIcon>
          </Button>
        )}
        {props.showSetAll && (
          <div
            className="setAllButton"
            style={{
              textAlign: "right",
            }}
          >
            <Button
              id="setAll"
              variant="contained"
              color="primary"
              className="setAllButton"
              disabled={!tableInstance?.selectedFlatRows?.length}
              onClick={() => {
                setShowSetAll(true);
              }}
            >
              Set All
            </Button>
          </div>
        )}
      </div>
      <div className="table-container">
        {showSetAll && (
          <SetAll
            rowdata={data}
            setAllInterdependentFields={props.setAllInterdependentFields}
            primaryKey={props.primaryKey}
            selectedRowIds={Object.keys(selectedRowIds)}
            onApply={onSetAllApply}
            fields={tableInstance.allColumns.filter(
              (item) =>
                !item.is_hidden && item.is_editable && !item.system_field
            )}
            handleModalClose={() => setShowSetAll(false)}
            handleValidation={(value)=>handleValidation(value)}
          />
        )}

        {showFieldChooser && (
          <ColPreference
            rowdata={colPreference}
            apply={applyPreference}
            handleModalClose={() => setshowFieldChooser(false)}
          />
        )}
        {successBox && <SuccessBox onClose={() => showSuccessBox(false)} />}
        <DndProvider backend={HTML5Backend}>
          <CustomDragLayer />
          <div>
            <div
              {...getTableProps()}
              className="table sticky"
              style={props.style}
              id={props.tableId ? props.tableId : "tableConatiner"}
            >
              <div className="header">
                {headerGroups.map((headerGroup) => (
                  <>
                    <div {...headerGroup.getHeaderGroupProps()} className="tr">
                      {headerGroup.headers.map((column, index) => (
                        <div
                          {...column.getHeaderProps()}
                          key={props.tableName + "column" + "_" + index}
                          className={
                            column.show_delete_icon || column.show_info_icon
                              ? "th selectcheckbox"
                              : column.id === "selection"
                              ? "th selectionbox"
                              : "th"
                          }
                          style={setSubHeaderStyle(column, true)}
                        >
                          <ColumnLayout
                            index={index}
                            column={column}
                            {...tableInstance}
                            moveCol={moveCol}
                            {...column.getHeaderProps()}
                            columns={columns}
                          />
                          {column.show_delete_icon && (
                            <Tooltip title="Delete" arrow>
                              <div
                                className="icon-blue"
                                onClick={() => deletePlanColumn(column)}
                              >
                                <Delete />
                              </div>
                            </Tooltip>
                          )}
                          {column.show_info_icon && (
                            <InfoComponent instance={tableInstance} column={column} />
                          )}
                        </div>
                      ))}
                    </div>
                    {Boolean(
                      headerGroup.headers.filter((column) => column.showFilter)
                        .length
                    ) && (
                      <div
                        {...headerGroup.getHeaderGroupProps()}
                        className="tr"
                      >
                        {headerGroup.headers.map((column) => (
                          <div
                            {...column.getHeaderProps()}
                            className={
                              column.id === "selection"
                                ? "th selectionbox"
                                : "th"
                            }
                          >
                            {column.showFilter ? column.render("Filter") : null}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
              <div {...getTableBodyProps()} className="body">
                {props.enableRowSpan && prepareRowSpan()}
                {tableData.map((row) => {
                  if (!props.enableRowSpan) {
                    prepareRow(row);
                  }
                  let canRowExpand = row.original?.expandableCols
                    ? true
                    : false;
                  return (
                    <>
                      <div
                        {...row.getRowProps()}
                        className="tr"
                        onClick={(e) =>{
                           const checkboxElements = document.getElementsByTagName("input");
                            for(const checkbox of checkboxElements) {
                              if(checkbox.checked) {
                                checkbox.parentElement.parentElement.parentElement.classList.add("tr-checked");
                              }
                              else{
                                checkbox.parentElement.parentElement.parentElement.classList.remove("tr-checked");
                              }
                            }
                          props.rowclickHandler &&
                          props.rowclickHandler(row.original, e)
                        }
                      }
                      >
                        {row.cells.map((cell) => {
                          let checkExpandable = false;
                          if (canRowExpand) {
                            checkExpandable = row?.original?.expandableCols.includes(
                              cell.column.id
                            );
                          }
                          if (cell.isAggregated !== 0) {
                            cell.render("Aggregated");
                          }
                          if (cell.isRowSpanned)
                            return (
                              <div
                                {...getColProps(cell)}
                                className={props?.width ? "" : "row-span-td"}
                              ></div>
                            );
                          return (
                            <>
                              <Tooltip
                                title={
                                  cell.column.showTooltip && row?.original
                                    ? generateTooltip(cell, row) || ""
                                    : ""
                                }
                                arrow
                              >
                                <div
                                  {...getColProps(cell)}
                                  column-id={cell.column.id}
                                  className={styleForRowOnselection(cell)}
                                >
                                  {checkExpandable ? (
                                    <span>
                                      {row.isExpanded ? (
                                        getRowExpandedStyle(
                                          expandedColumns,
                                          row,
                                          cell,
                                          changeSubrows,
                                          MinusIcon
                                        )
                                      ) : (
                                        <Box
                                          component="span"
                                          display={
                                            row?.original?.expandLabel
                                              ? "flex"
                                              : ""
                                          }
                                          alignItems="center"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            changeSubrows(cell, row);
                                            row
                                              .getToggleRowExpandedProps()
                                              .onClick();
                                          }}
                                        >
                                          {row?.original?.expandLabel && (
                                            <span>
                                              {row?.original?.expandLabel}
                                            </span>
                                          )}
                                          <PlusIcon color="primary" />
                                        </Box>
                                      )}
                                    </span>
                                  ) : (
                                    renderGroupedrUngroupedCell(
                                      row,
                                      cell,
                                      canRowExpand
                                    )
                                  )}
                                </div>
                              </Tooltip>
                            </>
                          );
                        })}
                      </div>
                      {/*row.isExpanded &&
                        (props.renderSubComp ? (
                          props.renderSubComp({
                            row,
                            rowProps,
                            visibleColumns,
                            expandedColumns,
                          })
                        ) : (
                          <SubRowsFirstRow
                            row={row}
                            rowProps={rowProps}
                            visibleColumns={visibleColumns}
                            expandedColumns={expandedColumns}
                            prepareRow={prepareRow}
                            changeSubrows={changeSubrows}
                            parents_col_Array={[row.id]}
                            showPagination={props.showPagination}
                          />
                        ))*/}
                    </>
                  );
                })}

                {
                  // isFooterRequired will be true if a table needs footer
                  props.isFooterRequired && <FooterMarkup {...tableInstance} />
                }
                {tableData.length === 0 && (
                  <div className="no-data-Container">{displayMsg}</div>
                )}
              </div>
            </div>
          </div>
        </DndProvider>
      </div>
      {props.showPagination && data.length !== 0 && (
        <div>
          <Pagination
            page={page}
            pageIndex={pageIndex}
            pageSize={pageSize}
            pageCount={pageCount}
            data={data}
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            previousPage={previousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            setPageSize={setPageSize}
            totalCount={props.totalCount}
            hidePageSelection={props.hidePageSelection}
          />
        </div>
      )}
    </>
  );
}
textFn.autoRemove = (val) => val.length === 0;
const mapStateToProps = (state) => {
  return {
    tableState: state.tableReducer.tableState,
  };
};
const mapActionsToProps = {
  setTableState,
};
export default connect(mapStateToProps, mapActionsToProps)(Table);
