import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import ManageUser from "./components/manage-user";
import UserRoleConfig from "./components/userRoleConfig/manage-user-role";
import { setLocationStateValue } from "./services/TenantManagement/User-Management/user-management-service";

const UserMangement = (props) => {
  const globalClasses = globalStyles();
  const [configurationTab, setConfigurationTab] = useState("manage_user");

  const handleConfiguration = (_event, newSelection) => {
    if (newSelection !== null) {
      setConfigurationTab(newSelection);
      props.setLocationStateValue(newSelection);
    }
  };

  useEffect(() => {
    setConfigurationTab(props.locationStateValue);
  }, [props.locationStateValue]);

  return (
    <>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.centerAlign} ${globalClasses.marginTop} ${globalClasses.marginBottom}`}
      >
        <ToggleButtonGroup
          value={configurationTab}
          exclusive
          onChange={handleConfiguration}
          aria-label="text alignment"
          size="small"
        >
          <ToggleButton value="manage_user" aria-label="left aligned">
            Manage Users on Platform
          </ToggleButton>
          <ToggleButton value="manage_user_role" aria-label="centered">
            Manage User role on Platform
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      {configurationTab === "manage_user" && <ManageUser></ManageUser>}
      {configurationTab === "manage_user_role" && <UserRoleConfig />}
    </>
  );
};

const mapStateToProps = (store) => {
  const { tenantUserRoleMgmtReducer } = store;
  return {
    locationStateValue:
      tenantUserRoleMgmtReducer.userManagementReducer.locationStateValue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLocationStateValue: (snack) => dispatch(setLocationStateValue(snack)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMangement);
