import { useEffect, useState } from "react";
import { connect } from "react-redux";
import globalStyles from "Styles/globalStyles";
import StoreInventoryAlerts from "../../StoreInventoryAlerts";

import CustomAccordion from "commonComponents/Custom-Accordian";
import {
  SCREENS_LIST_MAP,
  STORE_INVENTORY_ALERT_ACTION_CONFIG,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import Loader from "Utils/Loader/loader";

import {
  getStoreInventoryAlertsTableConfiguration,
  getStoreInventoryAlertsTableData,
  setStoreInventoryAlertsTableConfigLoader,
  setStoreInventoryAlertsTableDataLoader,
} from "modules/inventorysmart/services-inventorysmart/StoreInventoryAlerts/store-inventory-alerts-service";

import {
  getStoreForecastAlertsTableData,
  setStoreForecastAlertsTableLoader,
} from "modules/inventorysmart/services-inventorysmart/StoreInventoryAlerts/store-forecast-alerts-service";
import { setInventoryDashboardAlertCount } from "modules/inventorysmart/services-inventorysmart/KPI-Matrix/kpi-services";

const InventoryDashboardAlerts = function (props) {
  const globalClasses = globalStyles();

  const [alertsTableColumnConfig, setAlertsTableColumnConfig] = useState([]);
  const [alertsTableData, setAlertsTableData] = useState([]);

  const fetchStoreInventoryAlertsTableConfig = async () => {
    try {
      props.setStoreInventoryAlertsTableConfigLoader(true);
      let response = await props.getStoreInventoryAlertsTableConfiguration();
      const actionColumn = {
        ...STORE_INVENTORY_ALERT_ACTION_CONFIG[0],
        tc_code: response.data.data[0].tc_code,
        tc_mapping_code: response.data.data[0].tc_mapping_code,
      };

      const config = [...response.data.data, actionColumn];
      setAlertsTableColumnConfig([...config]);
    } finally {
      props.setStoreInventoryAlertsTableConfigLoader(false);
    }
  };

  const fetchStoreInventoryAlertsData = async () => {
    try {
      props.setStoreInventoryAlertsTableDataLoader(true);

      const filters = {
        product_attributes: [],
        store_attributes: [],
      };

      props.selectedFilters.forEach((filter) => {
        if (filter.dimension === "Product" && filter?.values?.length > 0) {
          filters.product_attributes.push(filter);
        } else if (filter.dimension === "Store" && filter?.values?.length > 0) {
          filters.store_attributes.push(filter);
        }
      });

      let body = {
        ...filters,
      };

      let response = await props.getStoreInventoryAlertsTableData(body);
      let alertsCount = response?.data?.data?.count;
      let alerts = response?.data?.data?.alert?.map((alert, index) => {
        alert.disableAction = "article_count";
        alert.action = "Review Recommendation";
        alert.current_level = 0;
        alert.index = index;
        alert.is_selectable = alert.is_selected;

        delete alert.is_selected;

        return alert;
      });
      setAlertsTableData(alerts);
      props.setInventoryDashboardAlertCount(alertsCount);
    } finally {
      props.setStoreInventoryAlertsTableDataLoader(false);
    }
  };

  const fetchForecastAlertsTableData = async () => {
    try {
      props.setStoreForecastAlertsTableLoader(true);
      const filters = {
        product_attributes: [],
        store_attributes: [],
      };
      props.selectedFilters.forEach((filter) => {
        if (filter.dimension === "Product" && filter?.values?.length > 0) {
          filters.product_attributes.push(filter);
        } else if (filter.dimension === "Store" && filter?.values?.length > 0) {
          filters.store_attributes.push(filter);
        }
      });

      let body = {
        ...filters,
      };

      let response = await props.getStoreForecastAlertsTableData(body);
      let alertsCount = response?.data?.data?.count;
      let alerts = response?.data?.data?.alert?.map((alert, index) => {
        alert.disableAction = "article_count";
        alert.action = "Review Recommendation";
        alert.current_level = 0;
        alert.index = index;
        alert.is_selectable = alert.is_selected;

        delete alert.is_selected;
        return alert;
      });
      setAlertsTableData(alerts);
      props.setInventoryDashboardAlertCount(alertsCount);
    } finally {
      props.setStoreForecastAlertsTableLoader(false);
    }
  };

  useEffect(() => {
    if (
      props.screen === SCREENS_LIST_MAP.INVENTORYSMART_DASHBOARD_STORE_INVENTORY
    ) {
      fetchStoreInventoryAlertsData();
      fetchStoreInventoryAlertsTableConfig();
    } else {
      // a new table config will be created, to use that later, write a function and call respective table config api
      fetchStoreInventoryAlertsTableConfig();
      fetchForecastAlertsTableData();
    }
  }, [props.selectedFilters]);

  return (
    <div className={globalClasses.marginVertical1rem}>
      <CustomAccordion label="Alerts">
        <Loader
          loader={
            props.storeForecastAlertsTableLoader ||
            props.storeInventoryAlertsTableDataLoader ||
            props.storeInventoryAlertsTableConfigLoader
          }
          minHeight={"188px"}
        >
          {!props.storeForecastAlertsTableLoader &&
            !props.storeInventoryAlertsTableDataLoader &&
            !props.storeInventoryAlertsTableConfigLoader && (
              <StoreInventoryAlerts
                columnConfig={alertsTableColumnConfig}
                data={alertsTableData}
                canEdit={props.canEdit}
                canDelete={props.canDelete}
                canCreate={props.canCreate}
              />
            )}
        </Loader>
      </CustomAccordion>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    selectedFilters:
      store.inventorysmartReducer.inventorySmartDashboardService
        .selectedFilters,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
    storeForecastAlertsTableLoader:
      store.inventorysmartReducer.inventorySmartStoreForecastAlerts
        .storeForecastAlertsTableLoader,
    storeInventoryAlertsTableDataLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryAlertsService
        .storeInventoryAlertsTableDataLoader,
    storeInventoryAlertsTableConfigLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryAlertsService
        .storeInventoryAlertsTableConfigLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setStoreInventoryAlertsTableConfigLoader: (payload) =>
    dispatch(setStoreInventoryAlertsTableConfigLoader(payload)),
  setStoreInventoryAlertsTableDataLoader: (payload) =>
    dispatch(setStoreInventoryAlertsTableDataLoader(payload)),
  getStoreInventoryAlertsTableConfiguration: (payload) =>
    dispatch(getStoreInventoryAlertsTableConfiguration(payload)),
  getStoreInventoryAlertsTableData: (payload) =>
    dispatch(getStoreInventoryAlertsTableData(payload)),
  getStoreForecastAlertsTableData: (payload) =>
    dispatch(getStoreForecastAlertsTableData(payload)),
  setStoreForecastAlertsTableLoader: (payload) =>
    dispatch(setStoreForecastAlertsTableLoader(payload)),
  setInventoryDashboardAlertCount: (payload) =>
    dispatch(setInventoryDashboardAlertCount(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryDashboardAlerts);
