import { getAllFilters, getFiltersValues } from "actions/filterAction";
import { isEmpty } from "lodash";
import { Dashboard } from "modules/react-demo/constants/stringContants";
import moment from "moment";
import {
  DESC_ORDER,
  FUTURE_DATE_RANGE_ERROR,
  RANGE_FILTER_ERROR_MESSAGE,
} from "../constants-inventorysmart/stringConstants";
import {
  getCrossFiltersData,
  getCustomFiltersData,
} from "../services-inventorysmart/Product-Profile/product-profile-dashboard-service";

const configureAttributeOptions = (options) => {
  return options.map((item) => {
    return {
      value: item.attribute,
      label: item.attribute,
      id: item.attribute,
    };
  });
};

export const scrollIntoView = (ref) => {
  setTimeout(() => {
    ref?.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, 1000);
};

export const configurePlanHierarchyLevels = async (screenName, crossFilter) => {
  //API to fetch the hierarchy levels
  let planLvlsResp = await getAllFilters(screenName)();
  const filterElements = planLvlsResp.data.data.map(async (key) => {
    key.accessor = key.column_name;
    if (key.display_type === "dropdown") {
      let filterBody = {
        attribute_name: key.column_name,
        filter_type: key.type,
        filters: [],
      };
      let isProduct = key.dimension === "product";

      //After fetching the levels, for each hierarchy level, fetch the values under the level
      let options = [];
      if (crossFilter) {
        let body = {
          attribute_name: key.column_name,
          filter_type: key.type,
          filters: [],
          meta: {},
          application_code: 1, // this util function is used across all modules, hence hardcoding the application code as it is constant (1) for inv module
          dimension: key.dimension,
        };
        options = await getCrossFiltersData(body)();
      } else {
        options = await (isProduct
          ? getFiltersValues("product", filterBody)()
          : getFiltersValues("store", filterBody)());
      }
      //For now else condition is null because we don't have filters other than hierarchy
      key.filter_keyword = key.column_name;
      key.filter_type = key.type;
      key.field_type = key.display_type;
      //map the fetched options to initial data key
      key.initialData = configureAttributeOptions(options.data.data.attribute);
      key.options = configureAttributeOptions(options.data.data.attribute);
      key.required = key.is_mandatory;
      key.isMulti = key.hasOwnProperty("is_multiple_selection")
        ? key.is_multiple_selection
        : key.isMulti;
    }
    return key;
  });
  await Promise.all(filterElements);
  return planLvlsResp.data.data;
};

export const fetchFilterConfig = async (screenName) => {
  const response = await getAllFilters(screenName)();
  return response.data.data;
};

export const getFilterDependency = (elementsData, selectedOptions, index) => {
  if (selectedOptions) {
    let dependency = [];
    elementsData.forEach((key) => {
      if (selectedOptions[key.accessor]) {
        let val = [selectedOptions[key.accessor]].flat();
        if (val.length > 0) {
          dependency.push({
            attribute_name: key.accessor,
            operator: "in",
            values: val,
            filter_type: key.filter_type,
            dimension: key.dimension ? key.dimension : "product",
          });
        }
      }
    });
    return dependency;
  } else {
    return [];
  }
};

export const fetchFilterOptions = async (
  allFilters,
  appliedFilters,
  current,
  fetchOption,
  rolesBasedAccess,
  screenName
) => {
  if (current?.filter_type === "non-cascaded") {
    return allFilters;
  }
  const filters = appliedFilters?.map((item) => ({
    attribute_name: item?.filter_id,
    operator: "in",
    values: Array.isArray(item?.values)
      ? item?.values?.map(
          (selectedValue) => selectedValue?.value || selectedValue
        )
      : item?.values,
    filter_type: item?.filter_type,
    filter_id: item?.filter_id,
    dimension: item?.dimension,
  }));

  const filterElements = allFilters?.map(async (key) => {
    // l_customApi -- custom api to get options for filter as cross filters doesn't support
    // l_customAttributeIgnoreInRequest -- custom api ignores few attribute_name in request body
    let fetchCondition =
      fetchOption === true
        ? key.type === "non-cascaded"
          ? false
          : true
        : true;
    if (fetchCondition) {
      const l_customApi = key?.extra?.custom_api;
      const l_customAttributeIgnoreInRequest =
        key?.extra?.ignore_attributes_custom_api;
      let l_filters = filters;
      if (!isEmpty(l_customAttributeIgnoreInRequest)) {
        l_filters = filters?.filter(
          (config) =>
            !l_customAttributeIgnoreInRequest?.includes(config.attribute_name)
        );
      }

      let body = {
        attribute_name: key?.column_name,
        filter_type: key?.type,
        filters: l_filters || [],
        application_code: 1,
        dimension: key?.dimension,
      };

      if (rolesBasedAccess) {
        body.is_urm_filter = true;
        body.screen_name = screenName;
      }

      const apiToFetchOptions = l_customApi
        ? getCustomFiltersData
        : getCrossFiltersData;
      const options = await apiToFetchOptions(body, l_customApi)();
      key.filter_keyword = key?.column_name;
      key.levelLabel = "Hierarchy";
      key.initialData = configureAttributeOptions(
        options?.data?.data?.attribute
      );
    }
    return key;
  });
  return await Promise.all(filterElements);
};

export const getFiltersOptions = async (
  elementsData,
  initialDependency,
  index
) => {
  let fields = [...elementsData];
  const filterElements = fields.map(async (key, Idx) => {
    if (Dashboard.__plan_levels.indexOf(key.accessor) > -1 && Idx > index) {
      let body = {
        attribute_name: key.accessor,
        filter_type: key.filter_type,
        filters: initialDependency,
      };

      const options = await getFiltersValues("product", body)();
      key.options = configureAttributeOptions(options.data.data.attribute);
    }
    return key;
  });

  await Promise.all(filterElements);
  return fields;
};

export const updateCrossFiltersData = async (
  elementsData,
  initialDependency,
  requestType
) => {
  const filterElements = elementsData.map(async (key) => {
    if (
      (key.type === "cascaded" && requestType === "updateOptions") ||
      requestType === "fetchOptions"
    ) {
      let body = {
        attribute_name: key.column_name,
        filter_type: key.type,
        filters: initialDependency,
        meta: {},
        application_code: 1,
        dimension: key.dimension,
      };
      const options = await getCrossFiltersData(body)();
      key.filter_keyword = key.column_name;
      key.initialData = options.data.data.attribute.map((opt) => {
        let attrVal =
          typeof opt.attribute === "boolean"
            ? opt.attribute.toString().toUpperCase()
            : opt.attribute;
        return {
          value: attrVal,
          label: attrVal,
          id: attrVal,
        };
      });
    }
    return key;
  });
  return await Promise.all(filterElements);
};

export const filtersPayload = (
  filterValues,
  filterDependency,
  isNewFilterComponent
) => {
  let isValid = true;
  let error = null;
  const reqBody = filterValues.map((filter) => {
    let value;
    if (isNewFilterComponent) {
      const currentFilter = filterDependency.find(
        (dependency) => dependency.filter_id === filter.column_name
      );

      if (currentFilter) {
        value = currentFilter.values.map((filterItem) => filterItem.value);
      } else {
        value = null;
      }
    } else {
      value = filterDependency[filter.column_name];
    }
    if (filter.field_type === "rangePicker" && value.length) {
      isValid = !(Boolean(value[0]) ^ Boolean(value[1]));
      value =
        Boolean(value[0]) & Boolean(value[1])
          ? [
              moment(value[0]).format("YYYY-MM-DD"),
              moment(value[1]).format("YYYY-MM-DD"),
            ]
          : [];
    }
    if (filter.is_mandatory && !value) {
      isValid = false;
      error = `${filter.label} is required`;
    }
    return {
      filter_type: filter.type,
      attribute_name: filter.column_name,
      operator: "in",
      dimension: filter.dimension === "product" ? "Product" : "Store",
      values: value ? [value].flat() : [],
    };
  });

  isValid =
    isValid &&
    reqBody.some((filter) => {
      return filter.values.length > 0;
    });

  return { reqBody: reqBody, isValid: isValid, error };
};

export const generateRandomIndex = (min, max) => {
  return Math.floor(Math.random() * (max - min) + 1) + min;
};

export const validateDateRange = (dateRange) => {
  let isValid = true;
  let error = null;

  const currentDate = moment();

  let startDate = dateRange?.fiscalInfoStartDate?.calendar_week_start_date;
  let endDate = dateRange?.fiscalInfoEndDate?.calendar_week_start_date;

  if (!startDate || !endDate) {
    isValid = false;
    error = RANGE_FILTER_ERROR_MESSAGE;
  } else if (
    moment(startDate).isAfter(currentDate) ||
    moment(endDate).endOf("week").isAfter(currentDate)
  ) {
    isValid = false;
    error = FUTURE_DATE_RANGE_ERROR;
  }

  return { isValid, error };
};

export const fetchProductCode = (article) => {
  const productCode = article?.sku
    ? article.sku
    : article?.product_code
    ? article?.product_code
    : article?.article;

  return productCode;
};

export const fetchProductCodes = (articles) => {
  const productCodes = [];
  articles?.forEach((article) => {
    const productCode = fetchProductCode(article);

    if (productCodes.indexOf(productCode) === -1) {
      productCodes.push(productCode);
    }
  });

  return productCodes;
};

export const sortByNumber = (data, order, key) => {
  if (order === DESC_ORDER) {
    data.sort((a, b) => b?.[key] - a?.[key]);
  } else {
    data.sort((a, b) => a?.[key] - b?.[key]);
  }

  return data;
};

export const modifyInvetoryAlertsCount = (data, productCodes) => {
  const noOfProductCodes = productCodes?.length || 0;

  const modifiedData = data?.map((countItem) => {
    if (countItem.label === "Resolved") {
      countItem.value = countItem.value + noOfProductCodes;
    } else if (countItem.label === "Pending") {
      countItem.value = countItem.value - noOfProductCodes;
    }

    return countItem;
  });
  return modifiedData;
};

export const isActionAllowedOnSubModule = (
  permissions,
  moduleName,
  subModuleName,
  action
) => {
  if (!permissions || !moduleName) {
    return true;
  }
  return permissions?.[moduleName]?.[subModuleName]?.indexOf(action) > -1;
};
