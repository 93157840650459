import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import Table from "../../../Utils/reactTable";
import "./filter.scss";
import Button from "@mui/material/Button";
import globalStyles from "Styles/globalStyles";
import { getAllStoreAndGroup } from "../services/storeMappingService";
import MappedStore from "./mapped-products";
import { setProductStatusData } from "../../../actions/productStoreStatusActions";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { uniqBy, isEmpty } from "lodash";
import StoreToProductTables from "./store-To-Product-Table";
import { agGridRowFormatter } from "Utils/agGrid/row-formatter";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import { addSnack } from "actions/snackbarActions";
import { configureViewButton } from "./common-mapping-functions";
import {
  fetchAllStoreCodes,
  fetchStoreGroups,
} from "pages/store-grouping/services-store-grouping/custom-store-group-service";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import { setFilterConfiguration } from "actions/filterAction";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

function StoretoProduct(props) {
  const [showloader, setloader] = useState(true);
  const [defaultFilterDataSet, setDefaultDataSet] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [showMappedProduct, setShowMappedProduct] = useState(false);
  const [selectedID, setSelectedID] = useState("");
  const [productDimension, setProductDimension] = useState("store");
  const [filterDependency, setDependency] = useState([]);
  const [storeStatusValues, setStoreStatusValue] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [storeGroupData, setStoreGroupData] = useState([]);
  const [totalStore, setStoreTotal] = useState("");
  const [totalStoreGroup, setSGTotal] = useState("");

  const storeTableRef = useRef(null);
  const storeGroupTableRef = useRef(null);
  const filterDependencyRef = useRef([]);
  const storeStatusValuesRef = useRef([]);
  const radioDimensionRef = useRef("store");
  const storeToProdRefObject = useRef({ storeTableRef, storeGroupTableRef });
  const globalClasses = globalStyles();

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let data = props?.roleBasedAccess
          ? await fetchFilterFieldValues(
              "store mapping",
              [],
              true,
              props.screenName,
              1
            )
          : await fetchFilterFieldValues("store mapping");

        if (isEmpty(props.filterDashboardConfiguration)) {
          let filterConfigData = [
            {
              filterDashboardData: data,
              expectedFilterDimensions: ["store"],
              screenDimension: "store",
              isCrossDimensionFilter: false,
              onReset: onReset,
            },
          ];
          if (props?.roleBasedAccess) {
            filterConfigData[0] = {
              ...filterConfigData[0],
              is_urm_filter: true,
              screen_name: props.screenName,
              application_code: 1,
            };
          }
          const filterConfig = formattedFilterConfiguration(
            "storeMappingStoreToProductFilterConfiguration",
            filterConfigData,
            "Store Mapping Store To Product"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          filterDependencyRef.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        onFilter(true, filterDependencyRef.current);
        setDependency(filterDependencyRef.current);
        setDefaultDataSet(data);
        setloader(false);
      } catch (error) {
        displaySnackMessages(
          error?.response?.data?.message || "Something went wrong.",
          "error"
        );
      }
    };

    getInitialData();
  }, []);

  const manualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    if (filterDependencyRef.current.length === 0) {
      setloader(false);
      return {
        data: [],
        totalCount: 0,
      };
    }
    try {
      let body = {
        filters: filterDependencyRef.current,
        meta: {
          ...manualbody,
          search:
            storeStatusValuesRef.current.length > 0
              ? manualbody.search.concat(storeStatusValuesRef.current)
              : manualbody.search,
        },
      };
      if (radioDimensionRef.current === "store") {
        const { data: store } = await getAllStoreAndGroup(
          `stores?store_level=store&page=${pageIndex + 1}`,
          body
        )();
        //agGridRowFormatter should be removed once it's handled from BE
        //to handle it from BE pass params.api.checkConfiguration in request body to the above api call
        let formatedData = agGridRowFormatter(
          store.data,
          params.api.checkConfiguration,
          `store_code`
        );
        formatedData = configureViewButton(
          formatedData,
          "mapped_products_count"
        );
        setloader(false);
        return {
          data: formatedData,
          totalCount: store.total,
        };
      } else {
        const { data: group } = await fetchStoreGroups(
          body,
          "",
          pageIndex + 1
        )();
        //agGridRowFormatter should be removed once it's handled from BE
        //to handle it from BE pass params.api.checkConfiguration in request body to the above api call
        let formatedData = agGridRowFormatter(
          group.data,
          params.api.checkConfiguration,
          `sg_code`
        );
        formatedData = configureViewButton(
          formatedData,
          "mapped_products_count"
        );
        setloader(false);
        return {
          data: formatedData,
          totalCount: group.total,
        };
      }
    } catch (err) {
      console.log(err);
      setloader(false);
    }
  };

  const onFilter = async (hideloader, initialDependency) => {
    !hideloader && setloader(true);
    let dependency = initialDependency ? initialDependency : filterDependency;
    try {
      let body = {
        filters: dependency,
        meta: {
          range: [],
          sort: [],
          search: storeStatusValues,
        },
      };
      const { data: store } = await getAllStoreAndGroup(
        "stores?store_level=store",
        body
      )();
      const { data: group } = await getAllStoreAndGroup(
        "stores?store_level=store_group",
        body
      )();
      setStoreTotal(store.total);
      setSGTotal(group.total);
      setStoreGroupData(group.data);
      setStoreData(store.data);
      setloader(false);
    } catch (err) {
      setloader(false);
    }
  };

  const onClickFilter = () => {
    if (storeTableRef.current)
      storeTableRef.current.api.refreshServerSideStore({ purge: true });
    if (storeGroupTableRef.current)
      storeGroupTableRef.current.api.refreshServerSideStore({ purge: true });
  };

  const onFilterDashboardClick = (dependencyData) => {
    filterDependencyRef.current = dependencyData;
    onClickFilter();
  };

  const handleChangeDimension = (event) => {
    setProductDimension(event.target.value);
    radioDimensionRef.current = event.target.value;
  };

  const onReset = () => {
    setStoreStatusValue([]);
    storeStatusValuesRef.current = [];
    setDependency([]);
    filterDependencyRef.current = [];
    onClickFilter();
  };

  const getStoresInSelectedGroups = async (storeGroups) => {
    const promises = [];
    // Fetch Store using selected Store Groups
    storeGroups.forEach((storeGroup) => {
      let body = {
        filters: [],
        range: [],
        sort: [],
        search: [],
      };
      promises.push(props.fetchAllStoreCodes(storeGroup.sg_code, body));
    });
    let flattenedData = [];
    // Accumulate store codes based on the store values
    await Promise.all(promises).then((data) => {
      const promiseData = data.map((promise) => {
        return promise.data.data;
      });
      flattenedData = promiseData.reduce(function (prev, next) {
        return prev.concat(next);
      });
      flattenedData = uniqBy(flattenedData, "store_code");
    });
    return flattenedData;
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const renderContent = () => {
    return (
      <CoreComponentScreen
        showPageRoute={false}
        showPageHeader={false}
        // Filter dashboard props
        showFilterDashboard={true}
        filterConfigKey={"storeMappingStoreToProductFilterConfiguration"}
        onApplyFilter={onFilterDashboardClick}
      >
        <Loader loader={showloader}>
          <div data-testid="filterContainer">
            {showMappedProduct && (
              <MappedStore
                selectedID={selectedID}
                dimension={productDimension}
                onModify={async (dataBody) => {
                  props.toggleModifyMapping(
                    {
                      selectedStores:
                        productDimension === "store"
                          ? dataBody.selectedStores
                          : await getStoresInSelectedGroups(
                              dataBody.selectedStores
                            ),
                    },
                    true
                  );
                  setShowMappedProduct(false);
                }}
                onCancel={() => {
                  setShowMappedProduct(false);
                }}
                disableModify={canTakeActionOnModules(
                  INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_MAPPING,
                  "edit"
                )}
              ></MappedStore>
            )}

            <div data-testid="resultContainer">
              <div
                className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
              >
                <RadioGroup
                  row
                  aria-label="gender"
                  name="controlled-radio-buttons-group"
                  value={productDimension}
                  onChange={handleChangeDimension}
                >
                  <FormControlLabel
                    value="store"
                    control={<Radio color="primary" id="StoreRadioBtn" />}
                    label="Store"
                  />
                  <FormControlLabel
                    value="store_groups"
                    control={<Radio color="primary" id="StoreGroupRadioBtn" />}
                    label="Store groups"
                  />
                </RadioGroup>

                <Button
                  variant="contained"
                  color="primary"
                  id="storetoproductModifyBtn"
                  onClick={async () => {
                    if (selectedRowsIDs.length > 0) {
                      props.toggleModifyMapping({
                        defaultDataSet: defaultFilterDataSet,
                        cols: columns,
                        selectedStores:
                          productDimension === "store"
                            ? selectedRowsIDs
                            : await getStoresInSelectedGroups(selectedRowsIDs),
                      });
                    } else {
                      displaySnackMessages(
                        "Please select atleast one store",
                        "error"
                      );
                    }
                  }}
                  disabled={
                    !canTakeActionOnModules(
                      INVENTORY_SUBMODULES_NAMES.INVENTORY_STORE_MAPPING,
                      "edit"
                    )
                  }
                >
                  Modify
                </Button>
              </div>
              {columns.length > 0 && (
                <Table
                  columns={columns}
                  rowSelection={true}
                  tableId={"storeproduct"}
                  primaryKey={
                    productDimension === "store" ? "store_code" : "sg_code"
                  }
                  totalCount={
                    productDimension === "store" ? totalStore : totalStoreGroup
                  }
                  selectedRowHandler={(flatids, ids) => {
                    setSelectedRowsIDs(flatids.map((item) => item.original));
                  }}
                  rowdata={
                    productDimension === "store" ? storeData : storeGroupData
                  }
                  showPagination={true}
                  backgroundColor={"white"}
                  hidePageSelection={true}
                  paginatorCallback={(page) => null}
                  manualFilters={true}
                  manualSortBy={true}
                  manualCallBack={(body, pageIndex, pageSize) =>
                    manualCallBack(body, pageIndex, pageSize)
                  }
                >
                  {" "}
                </Table>
              )}
              <StoreToProductTables
                ref={storeToProdRefObject}
                manualCallBack={manualCallBack}
                radioDimension={productDimension}
                filterDependency={filterDependency}
                storeStatusValues={storeStatusValues}
                setSelectedRowsIDs={setSelectedRowsIDs}
                setShowMappedProduct={setShowMappedProduct}
                setSelectedID={setSelectedID}
              />
            </div>
          </div>
        </Loader>
      </CoreComponentScreen>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
}
const mapStateToProps = (state) => {
  return {
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "storeMappingStoreToProductFilterConfiguration"
      ],
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    addSnack: (snackObject) => dispatch(addSnack(snackObject)),
    fetchAllStoreCodes,
    setFilterConfiguration: (filterConfig) =>
      dispatch(setFilterConfiguration(filterConfig)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoretoProduct);
