import React, {
  useReducer,
  useState,
  useCallback,
  useEffect,
  useRef,
  forwardRef,
} from "react";
import "../Filters.scss";
import { StyledCheckbox } from "Utils/selection/selection";
import Select, { components } from "react-select";
import { Inbox } from "@mui/icons-material/";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ClearIcon from "@mui/icons-material/Clear";
import axiosInstance from "../../../Utils/axios";
import { find, indexOf, cloneDeep, debounce } from "lodash";
import { replaceCharacter } from "Utils/formatter";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  positionedDropdown: (props) => {
    let scrollTop = `calc((${props.anchorPostionTop}px) - (${theme.customVariables.closedNavWidth} + 2rem))`;
    return {
      top: props.menuPosition === "fixed" ? scrollTop : "unset",
    };
  },
  positionedMenu: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.3)",
    marginTop: 8,
    minWidth: "100%",
    position: "absolute",
    zIndex: 999,
  },
}));

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
  noOptionsMessage: (styles) => ({
    ...styles,
    height: "300px",
  }),
};

const reducer = (state, action) => {
  switch (action.type) {
    case "OPTION_INIT":
      return { ...state, isLoading: true };
    case "OPTION_ERROR":
      return { ...state, isLoading: false, isError: true };
    case "OPTION_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [...state.data, ...action.payload],
      };
    case "OPTION_SET":
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: [...action.payload],
      };
    case "OPTION_RESET":
      return { ...state, isLoading: false, isError: false, data: [] };
    case "SEARCH_INIT":
      return { ...state, isSearching: true };
    case "SEARCH_SUCCESS":
      return {
        ...state,
        isSearching: false,
        searchData: [...state.searchData, ...action.payload],
      };
    case "SEARCH_RESET":
      return { ...state, isSearching: false, searchData: [] };
    default:
      break;
  }
};

function MultiSelect(props) {
  const [isOpen, setisOpen] = useState(false);
  const [page, setpage] = useState(2);
  const [optionSelected, setoptionSelected] = useState(
    props.selectedOptions || []
  );
  const [prevDependency, setprevDependency] = useState(null);
  const [nextSet, setNextSet] = useState([]);
  const [searchPage, setsearchPage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [flag_edit, setFlagEdit] = useState(false);
  const searchBarRef = useRef(null);
  const firstTimeRender = useRef(true);
  const [anchorPostionTop, setAnchorPostionTop] = useState(0);
  const [menuPosition, setMenuPosition] = useState("");
  const anchorElem = useRef(null);
  const menuElem = useRef(null);
  const classes = useStyles({ anchorPostionTop, menuPosition });
  const [{ isLoading, data, isSearching, searchData }, dispatch] = useReducer(
    reducer,
    {
      isLoading: false,
      isError: false,
      data: [],
      isSearching: false,
      searchData: [],
    }
  );

  const getDependency = () => {
    //todo - calculate the dependency selections for current select
    //that is find all selected values before selecting the current

    let dependency = cloneDeep(props.dependency);
    let index = indexOf(
      dependency,
      find(dependency, { field: props.filter_keyword })
    );
    if (index !== -1) {
      dependency = dependency.slice(0, index);
      return dependency;
    } else {
      return dependency;
    }
  };

  const fetchData = useCallback(
    async (page) => {
      if (page === 1) {
        dispatch({ type: "OPTION_RESET" });
      }

      // dispatch({ type: 'OPTION_INIT' })
      try {
        const response = await axiosInstance({
          url: props.data_url,
          method: "POST",
          data: {
            filter_id: props.filter_keyword,
            fields_values: prevDependency,
            page: page,
            filter_type: "hierarchy",
          },
        });

        setNextSet(response.data.data.results);
        // dispatch({ type: 'OPTION_SUCCESS', payload: response.data.data.results })
        setpage(response.data.data.next_page);
      } catch (error) {
        dispatch({ type: "OPTION_ERROR" });
      }
    },
    [page, prevDependency]
  );

  const fetchSearch = useCallback(
    async (_searchPage, searchValue) => {
      try {
        if (searchValue) {
          let filteredValues = props.initialData.filter((item) =>
            item.label
              ? item.label
                  .toString()
                  .toUpperCase()
                  .includes(searchValue.toString().toUpperCase())
              : null
          );
          dispatch({ type: "SEARCH_SUCCESS", payload: filteredValues });
          if (filteredValues.length > 50) {
            dispatch({
              type: "OPTION_SET",
              payload: filteredValues ? filteredValues.slice(0, 50) : [],
            });
          } else {
            dispatch({
              type: "OPTION_SET",
              payload: filteredValues ? filteredValues : [],
            });
          }
        } else {
        }
        // setsearchPage(response.data.data.next_page)
      } catch (error) {
        //dispatch({ type: 'OPTION_ERROR' })
      }
    },
    [searchPage, searchValue, prevDependency]
  );

  useEffect(() => {
    if (!firstTimeRender.current) {
      fetchSearch(searchPage, searchValue);
    }
    return () => {
      //cleanup
    };
  }, [searchValue]);

  useEffect(() => {
    if (props.selectedOptions && !props.doNotUpdateDefaultValue) {
      setoptionSelected(props.selectedOptions);
    }
  }, [props.selectedOptions]);

  useEffect(() => {
    firstTimeRender.current = false;

    return () => {
      //cleanup
    };
  }, []);

  useEffect(() => {
    if (props.reset) {
      setFlagEdit(false);
      setoptionSelected([]);
    }
  }, [props.reset]);

  const dropdownOpen = (_params) => {
    //load the data on initial dropdown click
    // let initialData = props.filterData.filter(item => item.key === props.filter_keyword);
    if (props.initialData.length > 50) {
      dispatch({
        type: "OPTION_SET",
        payload: props.initialData ? props.initialData.slice(0, 50) : [],
      });
    } else {
      dispatch({
        type: "OPTION_SET",
        payload: props.initialData ? props.initialData : [],
      });
    }

    setprevDependency(getDependency());

    //todo - check if the dependency list of selected  value has changed or not
    // if yes set all state to initial and fire new data fetch req
    // else do nothing
    setisOpen(!isOpen);
  };

  const dropdownClose = async (_params) => {
    //todo -
    // verify if there is difference between the previous selected values and current
    // if yes send selected options list to parent on close to save
    // else if selected values is null remove the option from the selected value of parent
    // else do nothing
    dispatch({ type: "SEARCH_RESET" });
    setsearchPage(1);
    setsearchValue("");
    if (props.initialData.length > 50) {
      dispatch({
        type: "OPTION_SET",
        payload: props.initialData ? props.initialData.slice(0, 50) : [],
      });
    } else {
      dispatch({
        type: "OPTION_SET",
        payload: props.initialData ? props.initialData : [],
      });
    }
    if (props.pagination) {
      fetchData(2);
    }
    if (flag_edit && !props.isDisabled) {
      props.updateDependency(
        {
          filter_id: props.filter_keyword,
          filter_type: props.type,
          dimension: props.dimension,
        },
        optionSelected
      );
      setFlagEdit(false);
    }
    setisOpen(!isOpen);
  };

  const dropdownSelectedMessage = () => {
    //todo-
    // items selected
    if (optionSelected.length) {
      if (
        optionSelected.length === data.length &&
        searchValue === "" &&
        optionSelected.length > 1
      ) {
        return "All Selected";
      }
      if (!props.is_multiple_selection) {
        return optionSelected[0]?.label;
      } else {
        return `${optionSelected.length} ${
          optionSelected.length > 1 ? " items" : " item"
        } selected`;
      }
    }
    return props.customPlaceholder || "Select...";
  };

  const onSelectAll = () => {
    //todo -
    // select all options on clicked
    //select only if there is data to pe selected and not all selected
    if (data.length && data.length !== optionSelected.length) {
      setFlagEdit(true);
      setoptionSelected(props.initialData);
    }
  };

  const onClearAll = () => {
    //todo -
    //clear all options on clicked if there is something to be cleared
    if (optionSelected.length) {
      setFlagEdit(true);
      setoptionSelected([]);
    }
  };

  const onMenuScrollToBottom = () => {
    //if data already loading dont call fetchData again
    if (!isLoading) {
      if (searchData.length > 0 && searchData.length !== data.length) {
        let newlength = data.length + 50;
        dispatch({
          type: "OPTION_SET",
          payload: searchData ? searchData.slice(0, newlength) : [],
        });
      } else if (props.initialData.length !== data.length) {
        let newlength = data.length + 50;
        dispatch({
          type: "OPTION_SET",
          payload: props.initialData
            ? props.initialData.slice(0, newlength)
            : [],
        });
      }
      //Backend pagniation code
      // dispatch({ type: 'OPTION_SUCCESS', payload: nextSet })
      // dispatch({ type: 'OPTION_SET', payload: props.initialData ? props.initialData.slice(0,50): [] })
      // if (page && props.initialData.slice(0,50)) {
      // fetchData(page)
      // }else{
      // setNextSet([])
      // }
    }
  };

  const onChange = (selected, _event) => {
    //on option selected
    if (props.isDisabled) {
      return;
    }
    if (props.is_multiple_selection) {
      setoptionSelected(selected);
    } else {
      setoptionSelected([selected]);
      setFlagEdit(true);
    }
    setFlagEdit(true);
  };

  const search = useCallback(
    debounce((searchKey) => {
      setsearchValue(searchKey);
    }, 200),
    []
  );

  const handleSearch = (event) => {
    //todo check if search input values are spaces or special characters
    //before calling search

    if (event.target.value.length > 0) {
      search(event.target.value);
    } else {
      dispatch({ type: "SEARCH_RESET" });
      if (props.initialData.length > 50) {
        dispatch({
          type: "OPTION_SET",
          payload: props.initialData ? props.initialData.slice(0, 50) : [],
        });
      } else {
        dispatch({
          type: "OPTION_SET",
          payload: props.initialData ? props.initialData : [],
        });
      }
    }
  };

  /**
   * @func
   * @desc Update the Position style applied and the scroll position for the anchored element when dropdown Opens
   */
  useEffect(() => {
    const scrollTop =
      anchorElem.current.getBoundingClientRect().top +
      anchorElem.current.clientHeight;
    const position = menuElem?.current
      ? getComputedStyle(menuElem.current).getPropertyValue("position")
      : false;
    if (isOpen) {
      setMenuPosition(position);
      setAnchorPostionTop(scrollTop);
      searchBarRef.current.focus();
    }
  }, [isOpen]);

  const onKeyDown = (e) => {
    if (isOpen) {
      const searchResults = Array.from(
        document.getElementsByClassName("multi-select__option")
      );
      let currentlyPseudoHoveredElement = -1;
      // See if one of the items already are "hovered" over
      searchResults.forEach((element, index) => {
        if (element.classList.contains("multi-select__option--is-focused")) {
          currentlyPseudoHoveredElement = index;
        }
      });
      if (e.keyCode === 13) {
        //When a enter key is pressed, currently focused element would be selected
        if (currentlyPseudoHoveredElement !== -1) {
          searchResults[currentlyPseudoHoveredElement].click();
        }
      }
    }
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={dropdownClose}
      className="dropdown-wrapper"
      menuClasses={`${classes.positionedDropdown} ${classes.positionedMenu}`}
      label={props.label}
      ref={{ anchorElem, menuElem }}
      target={
        <button
          data-testid="dropdown-button"
          onClick={dropdownOpen}
          disabled={props.disabledDropdown}
          className={`dropdown-button ${
            props.isDisabled && "dropdown-button-disabled"
          }`}
        >
          <span className="selected-text">{dropdownSelectedMessage()}</span>
          <span className="toggle-icon">
            <KeyboardArrowDownIcon fontSize="small" />
          </span>
        </button>
      }
    >
      <div className="filter-options-group">
        {!props.isDisabled && props.isClearable && (
          <div className="filter-options-item">
            <div className="filter-options-item-icon">
              <ClearIcon />
            </div>
            <div
              data-testid="dropdown-clear"
              className="filter-options-item-text"
              onClick={onClearAll}
            >
              Clear selection
            </div>
          </div>
        )}
        {!props.isDisabled && props.is_multiple_selection && (
          <div className="filter-options-item">
            <div className="filter-options-item-icon">
              <PlaylistAddCheckIcon />
            </div>
            <div className="filter-options-item-text" onClick={onSelectAll}>
              Select All from{" "}
              <span style={{ textTransform: "uppercase" }}>{props.label}</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <div onKeyDown={onKeyDown} className="search-select__control">
          <div className="multi-select__indicators">
            <SearchIcon fontSize="small" />
          </div>
          <div className="search-select__value-container">
            <div
              className="multi-select__input"
              style={{ display: "inline-block" }}
            >
              <input
                ref={searchBarRef}
                id="search-select-input"
                autocapitalize="none"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
                tabindex="0"
                type="text"
                aria-autocomplete="list"
                placeholder="Search..."
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
      <Select
        //style select
        className="multi-select-container"
        classNamePrefix="multi-select"
        styles={selectStyles}
        placeholder="Search..."
        customLabel={props.customLabel}
        //dropdown behaviour change
        menuIsOpen
        autoFocus
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        tabSelectsValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        isMulti={props.is_multiple_selection ? true : false}
        //custom components
        components={{
          DropdownIndicator,
          IndicatorSeparator: null,
          Option,
          NoOptionsMessage,
          MenuList,
          LoadingMessage,
        }}
        //custom functions and data load
        options={data}
        value={optionSelected}
        isLoading={isLoading || isSearching}
        // onMenuScrollToBottom=
        onScroll={onMenuScrollToBottom}
        onChange={onChange}
        isOptionDisabled={(option) =>
          props.isDisabled &&
          !optionSelected.some((selOpt) => selOpt.value === option.value)
        }
      />
    </Dropdown>
  );
}

export default MultiSelect;

//custom select components

const LoadingMessage = (_props) => {
  let loadingElement = [];
  for (let i = 0; i < 10; i++) {
    loadingElement.push(
      <div className="multi-select__option" style={{ padding: " 8px 12px" }}>
        <div className="checkbox">
          <StyledCheckbox color="primary" onChange={() => null} />
          <label htmlFor="checkbox">
            <span>Loading...</span>
          </label>
        </div>
      </div>
    );
  }
  return <>{loadingElement}</>;
};

const MenuList = (props) => {
  let loadingElement = [];
  loadingElement.push(props.children);
  if (props.isLoading) {
    for (let i = 0; i < 4; i++) {
      loadingElement.push(
        <div className="multi-select__option" style={{ padding: " 8px 12px" }}>
          <div className="checkbox">
            <StyledCheckbox color="primary" onChange={() => null} />
            <label htmlFor="checkbox">
              <span>Loading...</span>
            </label>
          </div>
        </div>
      );
    }
  }

  return (
    <div
      className="ScrollCheck"
      onScroll={(e) => {
        let element = e.target;
        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
          // do something at end of scroll
          props.selectProps.onScroll();
        }
      }}
    >
      <div className="checkbox">
        {props.selectProps.customLabel ? props.selectProps.customLabel : null}
      </div>
      <components.MenuList className="menulistScroll" {...props}>
        {loadingElement}
      </components.MenuList>
    </div>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props} className="loading">
      <div className="checkbox">
        {props.selectProps.isMulti && (
          <StyledCheckbox
            color="primary"
            data-testid={`${props.label}select`}
            checked={props.isSelected}
            onChange={() => null}
          />
        )}
        <label
          className={
            !props.selectProps.isMulti && props.isSelected
              ? "single-select-selected-option"
              : null
          }
          htmlFor="checkbox"
        >
          <span data-testid={props.label}>{props.label}</span>
        </label>
      </div>
    </components.Option>
  );
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      <div>
        <div>No Data</div>
        <Inbox size={32} />
      </div>
    </components.NoOptionsMessage>
  );
};

// component to create dropdown dropdown
const Menu = forwardRef((props, ref) => {
  return <div ref={ref} {...props} />;
});

const Blanket = (props) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: "fixed",
      zIndex: 2,
    }}
    {...props}
  />
);
const Dropdown = forwardRef(
  (
    { children, isOpen, target, onClose, className, label, menuClasses },
    ref
  ) => {
    const { anchorElem, menuElem } = ref;
    return (
      <div
        id={
          label
            ? `${replaceCharacter(label.toLowerCase(), / /g, "-")}-select`
            : "select-dropdown"
        }
        style={{ position: "relative" }}
        className={className}
        ref={anchorElem}
      >
        {target}
        {isOpen ? (
          <Menu ref={menuElem} className={menuClasses}>
            {children}
          </Menu>
        ) : null}
        {isOpen ? <Blanket onClick={onClose} /> : null}
      </div>
    );
  }
);
const Svg = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <KeyboardArrowDownIcon />
    </components.DropdownIndicator>
  );
};

const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>
);
