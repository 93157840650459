import React from "react";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import globalStyles from "Styles/globalStyles";

export default function Grouping() {
  const globalClasses = globalStyles();

  return (
    <div>
      <HeaderBreadCrumbs
        options={[
          {
            label: "Grouping",
            id: 1,
          },
        ]}
      ></HeaderBreadCrumbs>
      {/* <div className={`${globalClasses.filterWrapper} page-content`}>
        <iframe width="100%" height="840" src="https://inventory.impactsmartsuite.com/home" frameBorder="0" allowFullScreen></iframe> */}
      {/* <object width="100%" height="840" src="https://sandbox.inventorysmart.impactsmartsuite.com/testapp/frontend/home" frameBorder="0" allowFullScreen></object> */}
      {/* </div> */}
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100vh" }}
          data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
          // data={`https://inventory.impactsmartsuite.com/generic/old-inventory/grouping?queryPath=${window.location.pathname}`}
        ></object>
      </div>
    </div>
  );
}
