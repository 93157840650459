import React, { useEffect, useState, useRef, forwardRef } from "react";
import { connect } from "react-redux";
import Loader from "../../../Utils/Loader/loader";
import "./filter.scss";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import {
  fetchAllProducteGroups,
  fetchAllProductCodes,
} from "pages/product-grouping/product-grouping-service";
import {
  getAllRefStores,
  getAllStoreProducts,
  getAllStoreGroupProducts,
  mapStoreGroupToProduct,
  mapStoreToProduct,
  validateTimeBounds,
  editOrDelete,
} from "../services/storeMappingService";
import { setProductStatusData } from "../../../actions/productStoreStatusActions";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getColumns } from "../../../actions/tableColumnActions";
import { Dialog, DialogActions, DialogContent, Container } from "@mui/material";
import ReferenceStore from "./reference-store";
import Exceptions from "./exception";
import ConfirmBox from "../../../Utils/reactTable/components/confirmPopup";
import { addSnack } from "../../../actions/snackbarActions";
import { Prompt } from "react-router";
import { filterAccessibleTableData } from "Utils/filter-accessible-data";
import { isEmpty } from "lodash";
import ModifyTable from "./modify-table";
import TimeBoundDialog from "./time-bound-dialog";
import {
  parseMappings,
  prepareCreatePayload,
  splitMappedAndUnmappedData,
} from "./common-mapping-functions";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import { setFilterConfiguration } from "actions/filterAction";

import globalStyles from "Styles/globalStyles";
import SetAllComponent from "./set-all-component";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles((theme) => ({
  extraLength: {
    backgroundColor: theme.palette.colours.disabledSelectBackground,
    borderRadius: theme.shape.borderRadius,
    padding: "0 0.2rem",
  },
  labelText: {
    color: theme.palette.text.disabled,
  },
  fieldText: {
    color: theme.palette.text.secondary,
  },
}));

const ProductsFilter = forwardRef((props, ref) => {
  const [showloader, setloader] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [defaulttableData, setDefaulttableData] = useState([]);
  const [showException, updateShowException] = useState(false);
  const [alignment, setAlignment] = useState("product");
  const [referenceStore, setReferenceStore] = useState([]);
  const [productData, setProductData] = useState([]);
  const [confirmBox, showConfirmBox] = useState(false);
  const [editedProductsData, setEditedProductsData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  //To display set all multi row pop up
  const [showSetAllPopUp, setshowSetAllPopUp] = useState(false);
  const [setAllPopUpFields, setsetAllPopUpFields] = useState([]);
  const [commonSetAllStores, setcommonSetAllStores] = useState([]);
  const [isCancelled, setisCancelled] = useState(false); // isCancelled is made true when we click on cancel button
  const [
    displayTimeBoundMappingDialog,
    setdisplayTimeBoundMappingDialog,
  ] = useState(false);
  const classes = useStyles();
  const globalClasses = globalStyles();
  /**
   * New Modify Table State variables
   */
  const [selectedProductObjects, setselectedProductObjects] = useState([]); //All the selected Product Ids in modify table
  const modifyTableRef = useRef(null);
  const [
    editOrDeleteEditedAPIPayload,
    seteditOrDeleteEditedAPIPayload,
  ] = useState([]);
  const [setAllConfirmBox, showsetAllConfirmBox] = useState(false);
  const [conflictStoreMappingData, setconflictStoreMappingData] = useState({});
  const onFilterDependency = useRef([]);
  const [selectedRefStore, setSelectedRefStore] = useState({});
  const [refStoreLoader, setRefStoreLoader] = useState(false);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };
  /**
   * Whenever payload to send to backend is changed, we update the edited flag to true for
   * prompt dialog to appear when navigating out of the page
   */
  useEffect(() => {
    props.updateFlagEdit(productData.length > 0);
  }, [productData]);

  /**
   * @func
   * @desc Fetch filter Options and Product Groups and initialise filter options
   */
  useEffect(() => {
    const getInitialData = async () => {
      setRefStoreLoader(true);
      try {
        let columnsResp = await getColumns(
          "table_name=store_mapping_set_all_fields"
        )();
        setsetAllPopUpFields(columnsResp);
        let data = await fetchFilterFieldValues("product status");
        if (isEmpty(props.filterDashboardConfiguration)) {
          const filterConfigData = [
            {
              filterSectionHeader: "Merchant Category",
              filterDashboardData: data,
              expectedFilterDimensions: ["product"],
              screenDimension: "product",
              isCrossDimensionFilter: false,
            },
          ];
          const filterConfig = formattedFilterConfiguration(
            "storeToProductMappingModifyFilterConfiguration",
            filterConfigData,
            "Store To Product Mapping"
          );
          props.setFilterConfiguration(filterConfig);
        } else {
          onFilterDependency.current =
            props.filterDashboardConfiguration.appliedFilterData.dependencyData;
        }

        let { data: reference_store } = await getAllRefStores()();
        reference_store.data = reference_store.data.map((item) => {
          return {
            value: item.store_code,
            label: item.store_name,
            id: item.store_code,
          };
        });
        setReferenceStore(reference_store.data);
        setRefStoreLoader(false);
      } catch (error) {
        displaySnackMessages("Something went wrong", "error");
        setRefStoreLoader(false);
      }
    };

    getInitialData();
  }, []);

  /**
   * @func
   * @desc Return structure object to utilize as filter options
   * @param {Array} data Array of Objects
   * @returns {Array}
   */
  const generateFilterValues = (data) => {
    const filterValues = data.map((item) => {
      return {
        label: item.name,
        value: item.pg_code,
      };
    });
    return filterValues;
  };

  const setFilteredTableData = (tableData) => {
    const data = filterAccessibleTableData(tableData, props.userAccessList);
    setTableData(data);
  };

  const checkEditedRow = (arr) => {
    return arr.map((item) => {
      let editedRow = editedProductsData.filter(
        (row) => row.product_code === item.product_code
      );
      return editedRow.length > 0 ? editedRow[0] : item;
    });
  };

  const updateProductsData = async (
    body,
    pageIndex,
    alignmentSelection,
    selectedRefStore = null,
    refStoreCount = 0
  ) => {
    let queryParams =
      pageIndex && alignmentSelection !== "reference_store"
        ? `?page=${pageIndex + 1}`
        : alignmentSelection === "reference_store"
        ? `?reference_store=${selectedRefStore}`
        : "";
    let productsRespObj = {};
    if (props.selectedDimension === "store") {
      const { data: products } = await getAllStoreProducts(body, queryParams)();
      productsRespObj = products;
    } else {
      const { data: products } = await getAllStoreGroupProducts(
        body,
        queryParams
      )();
      productsRespObj = products;
    }
    productsRespObj.data = productsRespObj.data.map((item) => {
      item.include_unmapped =
        item.num_stores_mapped.split("/")[0] ===
        item.num_stores_mapped.split("/")[1];
      item.is_calendar_disabled = true;
      //we disable the include unmapped as there are no include unmapped to perform
      item.is_include_unmapped_disabled =
        item.num_stores_mapped.split("/")[0] ===
        item.num_stores_mapped.split("/")[1];
      return item;
    });
    if (editedProductsData.length > 0 && !isCancelled) {
      productsRespObj.data = checkEditedRow(productsRespObj.data);
    }
    setFilteredTableData(productsRespObj.data);
    setDefaulttableData([...defaulttableData, ...productsRespObj.data]);
    props.updateFlagEdit(false);
    if (isCancelled) {
      setisCancelled(false);
      setEditedProductsData([]);
    }
    setloader(false);
    return {
      data: productsRespObj.data,
      total: productsRespObj.total,
    };
  };

  //Reset the changes that were made when we either reset or toggle between reference store tab
  //or when applied a new filter
  const resetSavedChanges = () => {
    setProductData([]);
    seteditOrDeleteEditedAPIPayload([]);
  };

  const saveRequest = () => {
    onConfirm();
  };

  const onClickFilter = () => {
    modifyTableRef.current.api.deselectAll(true);
    resetSavedChanges();
    modifyTableRef.current.api.refreshServerSideStore({ purge: true });
  };

  const onConfirm = async () => {
    try {
      setloader(true);
      if (productData.length > 0 || editOrDeleteEditedAPIPayload.length > 0) {
        if (!isEmpty(conflictStoreMappingData)) {
          displaySnackMessages(
            "Please resolve the overlapping dates before saving",
            "error"
          );
          setloader(false);
          return;
        }
        if (props.selectedDimension === "store") {
          let [
            individualMappedObjects,
            individualUnMappedObjects,
          ] = splitMappedAndUnmappedData(productData, "store_mapping");
          let includeUnmappedObjects = productData.filter(
            (data) => data.include_unmapped
          );
          individualMappedObjects = prepareCreatePayload(
            [...individualMappedObjects, ...includeUnmappedObjects],
            props.selectedProducts,
            "store_mapping"
          );
          includeUnmappedObjects = individualMappedObjects.filter(
            (data) => data.include_unmapped
          );
          individualMappedObjects = individualMappedObjects.filter(
            (data) => !data.include_unmapped && data.select.length > 0
          );
          // this gives an object with dates as keys
          const payloadResp = individualMappedObjects.reduce(
            (payload, product) => {
              const date =
                product.valid_from +
                "+" +
                product.valid_to +
                "+" +
                product.product_code;
              if (!payload[date]) {
                payload[date] = [];
              }
              payload[date].push(product.select[0]);
              return payload;
            },
            {}
          );
          // Edit: to add it in the array format instead
          const productsArray = Object.keys(payloadResp).map((date) => {
            const KeyArr = date.split("+");
            return {
              product_code: KeyArr[2],
              include_unmapped: false,
              map: true,
              select: payloadResp[date],
              unselect: [],
              valid_from: KeyArr[0],
              valid_to: KeyArr[1],
            };
          });
          if (productsArray.length > 0 || includeUnmappedObjects.length > 0) {
            let body = {
              products: [...includeUnmappedObjects, ...productsArray],
              stores: props.selectedProducts.map((item) => item.store_code),
              action: "conflict_combine",
            };
            await mapStoreToProduct(body)();
          }
          if (
            editOrDeleteEditedAPIPayload.length > 0 ||
            individualUnMappedObjects.length > 0
          ) {
            let body = {
              body: [
                ...parseMappings([...editOrDeleteEditedAPIPayload]),
                ...individualUnMappedObjects,
              ],
            };
            await props.editOrDeleteAPI(body);
          }
          setShowModal(false);
        } else {
          let body = {
            products: productData,
            store_groups: props.selectedProducts.map((item) => item.sg_code),
          };
          await mapStoreGroupToProduct(body)();
          setShowModal(false);
        }
        setEditedProductsData([]);
        props.addSnack({
          message: "Store Mapping Data Saved Successfully",
          options: {
            variant: "success",
          },
        });
        props.goBack();
      } else {
        props.addSnack({
          message: "There is no change to save.",
          options: {
            variant: "warning",
          },
        });
      }
      setloader(false);
    } catch (err) {
      setloader(false);
      props.addSnack({
        message: err?.response?.data?.detail || "Something went wrong.",
        options: {
          variant: "error",
        },
      });
    }
  };

  const handleChange = (event, newAlignment) => {
    if (newAlignment && newAlignment !== alignment) {
      setAlignment(newAlignment);
    }
  };

  const onApply = async (exceptions) => {
    setloader(true);
    let productBody = [];
    Object.keys(exceptions).forEach((key) => {
      const storecodes = exceptions[key];
      storecodes.forEach((store_code) => {
        let body = {
          product_code: key,
          store_code: store_code,
          valid_from: null,
          valid_to: null,
        };
        productBody.push(body);
      });
    });
    if (props.selectedDimension === "store") {
      let postReq = {
        body: productBody,
      };
      await props.editOrDeleteAPI(postReq);
    } else {
      let postReq = {
        products: productData,
        store_groups: props.selectedProducts.map((item) => item.sg_code),
      };
      await mapStoreGroupToProduct(postReq)();
    }
    onClickFilter();
    setloader(false);
    setShowModal(false);
    displaySnackMessages("Applied unmappings done successfully", "success");
    updateShowException(false);
  };

  const renderContent = () => {
    let storeIdText;
    let exceptionsFields = [
      {
        label: `Select ${dynamicLabelsBasedOnTenant("product", "core")}s`,
        field_type: "dropdown",
        isMulti: true,
        required: true,
        options: [],
        accessor: "select_product",
      },
      {
        label: "Select Store",
        field_type: "list",
        isMulti: true,
        required: true,
        options: [],
        accessor: "select_store",
      },
    ];
    if (props.selectedDimension === "store") {
      storeIdText = props.selectedProducts.map((item) => item.store_code);
      storeIdText =
        props.selectedProducts.length > 3
          ? storeIdText.slice(0, 3).join(" , ")
          : storeIdText.join(" , ");
    } else {
      storeIdText = props.selectedProducts.map((item) => item.sg_code);
      storeIdText =
        props.selectedProducts.length > 3
          ? storeIdText.slice(0, 3).join(" , ")
          : storeIdText.join(" , ");
    }

    /**
     * This function checks for common mapped stores presence and opens the pop up
     * if there are common mapped stores or else throws an error saying no common stores present
     */
    const openSetAllPopUp = () => {
      setcommonSetAllStores(
        props.selectedProducts.map((store) => store.store_code)
      );
      setshowSetAllPopUp(true);
    };

    /**
     * Close the Time bound Dialog box
     */
    const onTimeBoundDialogClose = () => {
      setdisplayTimeBoundMappingDialog(false);
    };

    const getPromptStatus = (loc) => {
      const message = `Are you sure you want to go to ${loc.pathname}?`;
      if (
        productData.length !== 0 ||
        editOrDeleteEditedAPIPayload.length !== 0
      ) {
        return message;
      }
      return true;
    };

    const onSetAllBtnClick = () => {
      if (!isEmpty(selectedProductObjects)) {
        if (productData.length > 0 || editOrDeleteEditedAPIPayload.length > 0) {
          showsetAllConfirmBox(true);
          return;
        }
        openSetAllPopUp();
      } else {
        props.addSnack({
          message: "Please select atleast one Product(s)",
          options: {
            variant: "error",
          },
        });
      }
    };

    const onFilterDashboardClick = (dependencyData) => {
      onFilterDependency.current = dependencyData;
      onClickFilter();
    };

    return (
      <>
        <Container maxWidth={false}>
          <Prompt when={productData.length > 0 ? true : false} message={""} />
          <div
            className={`${globalClasses.marginTop}`}
            data-testid="filterContainer"
          >
            <Typography variant="body1" paragraph={true}>
              <Typography component="span" className={classes.labelText}>
                {" "}
                {props.selectedDimension === "store"
                  ? "Selected Store"
                  : "Selected Store Group "}
                -
              </Typography>
              <Typography
                component="span"
                className={`${classes.labelText} ${globalClasses.marginLeft1rem}`}
              >
                {props.selectedDimension === "store"
                  ? "Store ID"
                  : "Store Group ID"}
                : {""}
                <Typography
                  component="span"
                  className={`${classes.fieldText} ${globalClasses.marginHorizontal}`}
                >
                  {`${storeIdText}`}
                </Typography>
                {props.selectedProducts.length > 3 && (
                  <Typography component="span" className={classes.extraLength}>
                    {`+${props.selectedProducts.length - 3}`}{" "}
                  </Typography>
                )}
              </Typography>
            </Typography>
            {confirmBox && (
              <ConfirmBox
                onClose={() => showConfirmBox(false)}
                onConfirm={() => {
                  props.goBack();
                  showConfirmBox(false);
                }}
              />
            )}
            {setAllConfirmBox && (
              <ConfirmBox
                onClose={() => showsetAllConfirmBox(false)}
                onConfirm={() => {
                  onClickFilter();
                  seteditOrDeleteEditedAPIPayload([]);
                  setProductData([]);
                  showsetAllConfirmBox(false);
                }}
              />
            )}
            <Dialog
              open={showModal}
              className={classes.confirmBox}
              onClose={() => setShowModal(false)}
              id={"routePrompt"}
            >
              <DialogContent className={classes.contentBody}>
                <div className={classes.title}> Update Changes</div>
                <div className={classes.text}>
                  Are you sure to update all changes ?
                </div>
              </DialogContent>
              <DialogActions className={classes.action}>
                <Button
                  id="closeBtn"
                  onClick={() => setShowModal(false)}
                  color="primary"
                  autoFocus
                >
                  Close
                </Button>
                <Button
                  id="updateBtn"
                  onClick={() => onConfirm()}
                  color="primary"
                  autoFocus
                >
                  Update
                </Button>
              </DialogActions>
            </Dialog>
            <div className={globalClasses.marginBottom}>
              <Typography
                variant="h4"
                component="span"
                className={classes.labelText}
              >
                Map by
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                size="small"
                exclusive
                onChange={handleChange}
                className={globalClasses.marginLeft1rem}
              >
                <ToggleButton value="product">{`Selecting ${dynamicLabelsBasedOnTenant(
                  "product",
                  "core"
                )}`}</ToggleButton>
                <ToggleButton
                  className={globalClasses.marginLeft1rem}
                  value="reference_store"
                >
                  Selecting reference store
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            {alignment === "product" ? (
              <>
                <CoreComponentScreen
                  showFilterDashboard={true}
                  filterConfigKey={
                    "storeToProductMappingModifyFilterConfiguration"
                  }
                  onApplyFilter={onFilterDashboardClick}
                />
              </>
            ) : (
              <ReferenceStore
                options={referenceStore}
                rowData={props.selectedProducts}
                selectedDimension={props.selectedDimension}
                selectedProducts={selectedProductObjects}
                setSelectedRefStore={setSelectedRefStore}
                ref={modifyTableRef}
                {...props}
                showFilterLoader={refStoreLoader}
              ></ReferenceStore>
            )}
            <Loader loader={showloader}>
              <div data-testid="resultContainer">
                <div
                  className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
                >
                  <Typography variant="h6" gutterBottom>
                    Review Status
                  </Typography>
                  <div
                    className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.verticalAlignCenter}`}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      id="storeSetAllBtn"
                      onClick={onSetAllBtnClick}
                    >
                      Set All
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      id="modifyAddExceptionBtn"
                      className={globalClasses.marginLeft1rem}
                      onClick={() => {
                        updateShowException(true);
                      }}
                    >
                      Add Exceptions
                    </Button>
                  </div>
                </div>
                {showException && (
                  <Exceptions
                    fields={exceptionsFields}
                    onApply={onApply}
                    toggleError={(errMsg) => {
                      displaySnackMessages(errMsg, "error");
                    }}
                    handleModalClose={() => {
                      updateShowException(false);
                    }}
                    selectedStoreOrGrps={props.selectedProducts}
                    tabDimension={props.selectedDimension}
                    allStoresInfo={referenceStore}
                  ></Exceptions>
                )}
                <SetAllComponent
                  ref={modifyTableRef}
                  screenName="store_mapping"
                  showSetAllPopUp={showSetAllPopUp}
                  commonSetAllStores={commonSetAllStores}
                  selectedProducts={props.selectedProducts}
                  setAllPopUpFields={setAllPopUpFields}
                  setshowSetAllPopUp={setshowSetAllPopUp}
                  selectedProductObjects={selectedProductObjects}
                  onClickFilter={async () => {
                    onClickFilter();
                  }}
                  filterDependency={onFilterDependency.current}
                />

                {displayTimeBoundMappingDialog && (
                  <TimeBoundDialog
                    ref={modifyTableRef}
                    setAllPopUpFields={setAllPopUpFields}
                    screenName="store_mapping"
                    displayDialog={displayTimeBoundMappingDialog}
                    selectedStores={props.selectedProducts}
                    onCloseDialog={onTimeBoundDialogClose}
                    selectedProductObjects={selectedProductObjects}
                    editedAPIPayload={productData}
                    setEditedAPIPayload={setProductData}
                    setdisplayTimeBoundMappingDialog={
                      setdisplayTimeBoundMappingDialog
                    }
                    editOrDeleteEditedAPIPayload={editOrDeleteEditedAPIPayload}
                    seteditOrDeleteEditedAPIPayload={
                      seteditOrDeleteEditedAPIPayload
                    }
                    modifyTableLoader={showloader}
                    conflictObject={conflictStoreMappingData}
                    updateConflictObject={setconflictStoreMappingData}
                  />
                )}
                <ModifyTable
                  ref={{ modifyTableRef: modifyTableRef, isRedirected: ref }}
                  screenName={"store_mapping"}
                  fetchData={updateProductsData}
                  filterDependency={onFilterDependency.current}
                  selectedProducts={props.selectedProducts}
                  setloader={setloader}
                  selectedDimension={props.selectedDimension}
                  updateProductsData={updateProductsData}
                  selectedProductObjects={selectedProductObjects}
                  toggleTimeBoundDialogView={setdisplayTimeBoundMappingDialog}
                  setselectedProductObjects={setselectedProductObjects}
                  defaulttableData={defaulttableData}
                  editedAPIPayload={productData}
                  setEditedAPIPayload={setProductData}
                  displayTimeBoundMappingDialog={displayTimeBoundMappingDialog}
                  editOrDeleteEditedAPIPayload={editOrDeleteEditedAPIPayload}
                  seteditOrDeleteEditedAPIPayload={
                    seteditOrDeleteEditedAPIPayload
                  }
                  conflictObject={conflictStoreMappingData}
                  updateConflictObject={setconflictStoreMappingData}
                  selectedRefStore={selectedRefStore}
                  alignment={alignment}
                />
              </div>
              <div
                className={`${globalClasses.centerAlign} ${globalClasses.evenPaddingAround}`}
              >
                <Button
                  variant="contained"
                  color="primary"
                  id="modifySaveBtn"
                  onClick={() => saveRequest()}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className={globalClasses.marginLeft1rem}
                  id="modifyCancelBtn"
                  onClick={() => {
                    if (tableData.length && productData.length > 0) {
                      showConfirmBox(true);
                    } else {
                      props.goBack();
                    }
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Loader>
          </div>
        </Container>
      </>
    );
  };

  return <React.Fragment>{renderContent()}</React.Fragment>;
});

const mapStateToProps = (state) => {
  return {
    userAccessList:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.userAccessList,
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "storeToProductMappingModifyFilterConfiguration"
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    addSnack: (payload) => dispatch(addSnack(payload)),
    validateTimeBounds: (payload) => dispatch(validateTimeBounds(payload)),
    editOrDeleteAPI: (payload) => dispatch(editOrDelete(payload)),
    fetchAllProducteGroups,
    fetchAllProductCodes,
    setFilterConfiguration: (filterConfiguration) =>
      dispatch(setFilterConfiguration(filterConfiguration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ProductsFilter);
