import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import KeyboardArrowLeftSharp from "@mui/icons-material/KeyboardArrowLeftSharp";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";

import { getColumnsAg } from "actions/tableColumnActions";
import AgGridComponent from "Utils/agGrid";
import Loader from "Utils/Loader/loader";

const useStyles = makeStyles((theme) => ({
  buttonMargin: {
    marginBottom: "1rem",
  },
  resultContainer: {
    margin: `0 ${theme.typography.pxToRem(20)} ${theme.typography.pxToRem(20)}`,
    display: "flex",
    borderTop: "1px solid #ccc",
    paddingTop: theme.typography.pxToRem(15),
    justifyContent: "space-between",
  },
  transferUserTableContainer: {
    width: "45%",
  },
  shiftIcons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const TransferUser = (props) => {
  const [transferUserTableColumn, setTransferUserTableColumn] = useState([]);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [databaseUser, setDatabaseUser] = useState([]);
  const [showSelectedUser, setShowSelectedUser] = useState([]);
  const [transferUserComponentLoader, setTransferUserComponentLoader] =
    useState(true);

  const {
    dropDownSelectedListOfUsers,
    userSelectedTableList,
    dbTableList,
    dbUserList,
    listOfUsers,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    const getInitialState = async () => {
      let cols = await getColumnsAg("table_name=user_mgmt")();
      setTransferUserTableColumn(cols);
      if (dropDownSelectedListOfUsers?.length === 0) {
        if (listOfUsers.length > 0) {
          setDatabaseUser(listOfUsers);
        }
      } else {
        setShowSelectedUser(dropDownSelectedListOfUsers);
        setDatabaseUser(dbUserList);
      }
      setTransferUserComponentLoader(false);
    };
    getInitialState();
  }, [dropDownSelectedListOfUsers]);

  const showSelectedUserList = () => {
    let toRightTable = databaseUser.filter((item) =>
      selectedRowsIDs.some((row) => row.user_code === item.user_code)
    );
    let toLeftTable = databaseUser.filter(
      (item) => !selectedRowsIDs.some((row) => row.user_code === item.user_code)
    );
    setDatabaseUser(toLeftTable);
    dbTableList(toLeftTable);
    if (showSelectedUser.length === 0) {
      setShowSelectedUser(toRightTable);
      userSelectedTableList(toRightTable);
    } else {
      let newlist = [...showSelectedUser, ...toRightTable];
      setShowSelectedUser(newlist);
      userSelectedTableList(newlist);
    }
  };

  const showUnSelectedUserList = () => {
    let toRightTable = showSelectedUser.filter(
      (item) => !selectedRowsIDs.some((row) => row.user_code === item.user_code)
    );
    let toLeftTable = showSelectedUser.filter((item) =>
      selectedRowsIDs.some((row) => row.user_code === item.user_code)
    );
    setShowSelectedUser(toRightTable);
    userSelectedTableList(toRightTable);
    if (databaseUser.length === 0) {
      setDatabaseUser(toLeftTable);
      dbTableList(toLeftTable);
    } else {
      let newlist = [...databaseUser, ...toLeftTable];
      dbTableList(newlist);
      setDatabaseUser(newlist);
    }
  };

  const setSelectedRecords = (event) => {
    let selections = event.api.getSelectedRows().map((obj) => obj.user_code);
    let selectedRows = event.api
      .getModel()
      .rowsToDisplay.filter((item) =>
        selections.some((id) => item.data.user_code == id)
      )
      .map((obj) => obj.data);
    setSelectedRowsIDs(selectedRows);
  };

  return (
    <Loader loader={transferUserComponentLoader}>
      <div className={classes.resultContainer}>
        <div className={classes.transferUserTableContainer}>
          <Typography gutterBottom>Selected Users From Database</Typography>
          <Paper elevation={0}>
            <AgGridComponent
              rowdata={databaseUser}
              columns={transferUserTableColumn}
              uniqueRowId={"user_code"}
              rowSelection={"multiple"}
              selectAllHeaderComponent={true}
              onSelectionChanged={setSelectedRecords}
            />
          </Paper>
        </div>
        <div className={classes.shiftIcons}>
          <Button
            color="primary"
            variant="contained"
            className={classes.buttonMargin}
            size="small"
            onClick={() => showUnSelectedUserList()}
          >
            <KeyboardArrowLeftSharp />
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => showSelectedUserList()}
          >
            <ChevronRightSharpIcon />
          </Button>
        </div>
        <div className={classes.transferUserTableContainer}>
          <Typography gutterBottom>Selected Users</Typography>
          <Paper elevation={0}>
            <AgGridComponent
              columns={transferUserTableColumn}
              rowdata={showSelectedUser}
              uniqueRowId={"user_code"}
              rowSelection={"multiple"}
              selectAllHeaderComponent={true}
              onSelectionChanged={setSelectedRecords}
            />
          </Paper>
        </div>
      </div>
    </Loader>
  );
};

export default TransferUser;
