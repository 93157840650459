import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, DialogContent } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const useStyles = makeStyles((theme) => ({
  confirmBox: {
    "& .MuiDialog-paper": {
      maxWidth: "350px",
      borderRadius: "10px 10px 6px 6px",
    },
  },
  contentBody: {
    minHeight: "12rem",
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    minHeight: "7rem",
    alignItems: "center",
    backgroundColor: "#60b960ba",
  },
  successicon: {
    fill: "white",
    fontSize: "4rem",
  },
  title: {
    fontSize: "1rem",
    fontWeight: "600",
    paddingTop: "1.5rem",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    minHeight: "5rem",
    alignItems: "center",
    fontSize: "1rem",
  },
}));

const SuccessPopup = (props) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      props.onClose();
    }, 2000);
  });

  return (
    <Dialog
      onClose={() => props.onClose()}
      className={classes.confirmBox}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      maxWidth={"xs"}
      disableEscapeKeyDown={true}
    >
      <DialogContent classesName={classes.contentBody}>
        <div className={classes.icon}>
          <CheckCircleOutlineIcon className={classes.successicon} />
        </div>
        <div className={classes.text}>
          <h5>{props.successMessage || "Successfully applied values"}</h5>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessPopup;
