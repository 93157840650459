import {
  ToggleLoader,
  fetchDefinitions,
  deleteDefinition,
  setViewDefnTableCols,
  setDefinitions,
  getDefinitions,
} from "pages/product-grouping/product-grouping-service";
import { connect } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useHistory } from "react-router-dom";
import LoadingOverlay from "../../../../Utils/Loader/loader";
import AgGridComponent from "Utils/agGrid";
import { getColumnsAg } from "actions/tableColumnActions";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import { addSnack } from "actions/snackbarActions";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const ViewDefinitions = (props) => {
  const history = useHistory();
  const [cols, setcols] = useState([]);
  const [deleteConfirmOpen, setdeleteConfirmOpen] = useState(false);
  const [delRow, setdelRow] = useState({});

  const tableInstance = useRef({});
  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.ToggleLoader(true);
        if (props.defnTableCols.length === 0) {
          let columns = await props.getColumnsAg("table_name=group_definition");
          columns = agGridColumnFormatter(columns);
          props.setViewDefnTableCols(columns);
        }
        props.ToggleLoader(false);
      } catch (error) {
        props.ToggleLoader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let columns = [...props.defnTableCols];
    if (columns.length > 0) {
      columns.push({
        headerName: "Action",
        id: "grp_def_action",
        is_frozen: true,
        sticky: "right",
        cellRenderer: (params, extraProps) => {
          return (
            <>
              <IconButton
                onClick={() => {
                  history.push(
                    `${props.prevScr}/edit-definitions/${params.data.pgd_code}`
                  );
                }}
                size="large"
                disabled={
                  params.data.is_accessible || props.isSuperUser ? false : true
                }
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setdeleteConfirmOpen(true);
                  setdelRow(params.data.pgd_code);
                }}
                size="large"
                disabled={
                  params.data.is_accessible || props.isSuperUser ? false : true
                }
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      });
      setcols(columns);
    }
  }, [props.defnTableCols]);

  const viewDefinitionsManualCallBack = async (
    manualbody,
    pageIndex,
    params
  ) => {
    props.ToggleLoader(true);
    try {
      const res = await props.getDefinitions(manualbody, 10, pageIndex);
      setDefinitions({ definitions: res.data.data, count: res.data.total });
      props.ToggleLoader(false);
      return {
        data: res.data.data,
        totalCount: res.data.total,
      };
    } catch (err) {
      props.ToggleLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const onConfirmDelete = async () => {
    props.ToggleLoader(true);
    setdeleteConfirmOpen(false);
    try {
      await props.deleteDefinition(delRow);
      handleClose();
      displaySnackMessages("Deleted successfully", "success");
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
    } catch (error) {
      setdeleteConfirmOpen(false);
      displaySnackMessages("Delete failed", "error");
    }
    return null;
  };

  const handleClose = () => {
    setdeleteConfirmOpen(false);
    props.ToggleLoader(false);
  };

  return (
    <>
      <LoadingOverlay loader={props.isLoading} spinner>
        <ConfirmPrompt
          showModal={deleteConfirmOpen}
          title="Confirm Changes"
          message="Are you sure want to delete the definition?"
          ariaLabeledBy="delete-definition-dialog"
          primaryBtnText="Confirm"
          secondaryBtnText="Cancel"
          showCloseIcon={true}
          setConfirm={handleClose}
          confirmCallback={(val) => {
            if (val) {
              onConfirmDelete();
            }
          }}
        />

        {cols.length > 0 && (
          <AgGridComponent
            columns={cols}
            selectAllHeaderComponent={false}
            sizeColumnsToFitFlag
            onGridChanged
            manualCallBack={(body, pageIndex, params) =>
              viewDefinitionsManualCallBack(body, pageIndex, params)
            }
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            uniqueRowId={"pgd_code"}
            loadTableInstance={setNewTableInstance}
            suppressClickEdit={true}
          />
        )}
      </LoadingOverlay>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.productGroupReducer.isLoading,
    definitions: state.productGroupReducer.productGrpDefinitions,
    defnCount: state.productGroupReducer.productGrpDefinitionsCount,
    isDataChanged: state.productGroupReducer.isDataChanged,
    dataChangeType: state.productGroupReducer.dataChangeType,
    defnTableCols: state.productGroupReducer.viewDefnTableCols,
    isSuperUser:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.isSuperUser,
  };
};

const mapActionsToProps = {
  fetchDefinitions,
  deleteDefinition,
  getColumnsAg,
  ToggleLoader,
  setViewDefnTableCols,
  addSnack,
  setDefinitions,
  getDefinitions,
};

export default connect(mapStateToProps, mapActionsToProps)(ViewDefinitions);
