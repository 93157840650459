import { attributeFormatter } from "modules/react-demo/utils/utilityFunctions";
import { levelsArray } from "./constants";

import {
  percentFormatter,
  numbersWithComma,
  dollarFormatter,
  decimalsFormatter,
  arrayToCommaFormatter,
  formattedDate,
} from "../formatter";
import moment from "moment";

export const nonEditableCell = (item, forFooter) => {
  //it returns the formatter to cell based on type
  const isDecimal = item.formatter === "roundOfftoTwoDecimals" ? true : false;
  switch (item.type) {
    case "percentage":
      return (ins) =>
        percentFormatter(
          ins,
          isDecimal,
          forFooter && item.is_editable ? true : false //for footer we don't want to multiply it by 100 so passing true
        );
    case "int":
      return (ins) => numbersWithComma(ins, isDecimal);
    case "float":
      return (ins) => decimalsFormatter(ins, isDecimal);
    case "dollar":
      return (ins) => dollarFormatter(ins, isDecimal);
    case "attribute":
      return (ins) => attributeFormatter(ins.value, false);
    case "bool":
      return (ins) => ins?.value || "";
    case "array":
      return (ins) => arrayToCommaFormatter(ins.value);
    case "date":
    case "DateTimeField":
      return (ins) => formattedDate(ins.value, item?.dateFormatter);
    default:
      return (ins) => {
        if (ins.value === 0 || ins.value === false || ins.value) {
          return fetchDefaultValue(ins);
        }
        return "";
      };
  }
};

const fetchDefaultValue = (ins) => {
  // explicitly converting to string to get comma separated values in aggrid table.
  if (Array.isArray(ins.value)) return ins.value.toString();
  else return ins.value;
};

export const generateHeader = (item, levelsJson) => {
  //For levels column label will be dynamic so fetching it from levels api - eg :- cluster break down component
  if (levelsArray.includes(item.column_name)) {
    return levelsJson?.[item?.column_name] || item.label;
  } else {
    if (item.label === "LY") {
      return levelsJson?.[item.label] || item.label;
    }
    return item.label;
  }
};

/**
 *
 * @param {object} properties
 * @param {table instance} tableRef
 * @returns updated table instance
 * This function appends properties to table instance
 * grid api
 * We need to pass all the properties as an object
 * key - value would be inserted in similar way to grid
 */
export const appendPropertiesToTableInstance = (properties, tableRef) => {
  for (const propertyKey in properties) {
    tableRef.current.api[propertyKey] = properties[propertyKey];
  }
  return tableRef;
};
