// Edit Icon cell renderer
/*

    In the parent comp, defining onEditClick function is Required
    Props:  spread the instance and pass onEditClick function 

 */
import React from "react";
import { Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useStyles } from "../../../modules/react-demo/pages/styles";

const EditCell = (props) => {
  const classes = useStyles();

  return (
    <IconButton
      variant="text"
      color="primary"
      className={classes.actionIcon}
      onClick={() => {
        props.onEditClick(props);
      }}
      title="Edit"
      //disabled={props.row.original.disableEditSelection}
      size="large"
    >
      <Edit />
    </IconButton>
  );
};

export default EditCell;
