import { createSlice } from "@reduxjs/toolkit";
import {
  GET_STORE_LEVEL_TABLE_DATA,
  POST_STORE_LEVEL_TABLE_DATA,
  GET_STORE_GRADE_LEVEL_TABLE_DATA,
  GET_STORE_GROUP_LEVEL_TABLE_DATA,
  GET_STORE_MODAL_TABLE_DATA,
  SET_ALL_TABLE_DATA,
  GET_STORE_GROUP_AGGREGATE,
  GET_STORE_GRADE_AGGREGATE,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";
import axiosInstance from "../../../../Utils/axios/index";

export const inventorySmartConstraintsService = createSlice({
  name: "inventorySmartConstraintsService",
  initialState: {
    constraintsLoader: false,
    constraintsTableConfigLoader: false,
    constraintsTableConfig: [],
    constraintsTableData: [],
    selectedConstraintArticles: [],
    selectedStoreCodes: [],
    selectedFilters: [],
    inventorysmartConstraintsFilterDependency: [],
  },
  reducers: {
    setConstraintsLoader: (state, action) => {
      state.constraintsLoader = action.payload;
    },
    setConstraintsTableConfigLoader: (state, action) => {
      state.constraintsTableConfigLoader = action.payload;
    },
    setConstraintsArticles: (state, action) => {
      state.selectedConstraintArticles = action.payload;
    },
    setConstraintsStoreCodes: (state, action) => {
      state.selectedStoreCodes = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setInventorysmartConstraintsFilterDependency: (state, action) => {
      state.inventorysmartConstraintsFilterDependency = action.payload;
    },
    resetConstraintsStoreState: (state) => {
      state.constraintsLoader = false;
      state.constraintsTableConfigLoader = false;
      state.constraintsTableConfig = [];
      state.constraintsTableData = [];
      state.selectedConstraintArticles = [];
      state.selectedStoreCodes = [];
      state.selectedFilters = [];
      state.inventorysmartConstraintsFilterDependency = [];
    },
  },
});

export const {
  setConstraintsLoader,
  setConstraintsTableConfigLoader,
  setConstraintsArticles,
  setConstraintsStoreCodes,
  setSelectedFilters,
  setInventorysmartConstraintsFilterDependency,
  resetConstraintsStoreState,
} = inventorySmartConstraintsService.actions;

export const getStoreTableData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_LEVEL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};
export const getStoreGradeTableData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_GRADE_LEVEL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getStoreGroupTableData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_GROUP_LEVEL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};
export const getStoreGroupAggregateData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_GROUP_AGGREGATE,
    method: "POST",
    data: postBody,
  });
};
export const getStoreGradeAggregateData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_GRADE_AGGREGATE,
    method: "POST",
    data: postBody,
  });
};

export const getStoreModalTableData = (postBody) => () => {
  return axiosInstance({
    url: GET_STORE_MODAL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const saveStoreTableData = (postBody) => () => {
  return axiosInstance({
    url: POST_STORE_LEVEL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};
export const setAllTableData = (postBody) => () => {
  return axiosInstance({
    url: SET_ALL_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export default inventorySmartConstraintsService.reducer;
