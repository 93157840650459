import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import DCtoStore from "./components/dc-store-mapping";
import DCtoProduct from "./components/dc-product-mapping";
import TabsComponentScreen from "commonComponents/coreComponentScreen/TabsComponentScreen";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

function DCMapping(props) {
  const tabsComponentList = [
    {
      label: "DC-Store",
      id: "dc-store-fc",
      TabPanel: (
        <DCtoStore
          module={props.module}
          screenName={props.screenName}
          roleBasedAccess={props.inventorysmartScreenConfig?.roleBasedAccess}
        />
      ),
    },
    {
      label: `DC-${dynamicLabelsBasedOnTenant("product", "core")}`,
      id: "dc-product",
      TabPanel: (
        <DCtoProduct
          module={props.module}
          screenName={props.screenName}
          roleBasedAccess={props.inventorysmartScreenConfig?.roleBasedAccess}
        />
      ),
    },
    // {
    //   label: "FC-Store",
    //   id: "fc-store",
    //   TabPanel: <StoreToFc />,
    // },
  ];

  return (
    <TabsComponentScreen
      pageLabel={"DC Mapping"}
      tabsComponentList={tabsComponentList}
    />
  );
}
const StoreToFc = (props) => {
  const history = useHistory();
  useEffect(() => {
    history.push({
      pathname: "/store-mapping",
      state: {
        isRedirect: true,
      },
    });
  }, []);
  return <></>;
};

const mapStateToProps = (state) => {
  return {
    inventorysmartScreenConfig:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartScreenConfig,
  };
};
export default connect(mapStateToProps, null)(DCMapping);
