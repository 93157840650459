import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: (props) => {
    let rules = {
      borderRadius: 3,
      ...theme.typography.h6,
    };
    let iconColorOnHover = {
      "& .MuiChip-icon": {
        color: theme.palette.common.white,
      },
    };
    switch (props.color) {
      case "success":
        rules = {
          ...rules,
          background: theme.palette.success.light,
          color: theme.palette.success.main,
          "& .MuiChip-icon": {
            color: theme.palette.success.main,
          },

          "&.MuiChip-clickable": {
            "&:hover, &:focus": {
              background: theme.palette.success.main,
              color: theme.palette.common.white,
              ...iconColorOnHover,
            },
          },
        };
        break;
      case "error":
        rules = {
          ...rules,
          background: theme.palette.error.light,
          color: theme.palette.error.main,
          "& .MuiChip-icon": {
            color: theme.palette.error.main,
          },

          "&.MuiChip-clickable": {
            "&:hover, &:focus": {
              background: theme.palette.error.main,
              color: theme.palette.common.white,
              ...iconColorOnHover,
            },
          },
        };
        break;
      case "warning":
        rules = {
          ...rules,
          background: theme.palette.warning.light,
          color: theme.palette.warning.main,
          "& .MuiChip-icon": {
            color: theme.palette.warning.main,
          },

          "&.MuiChip-clickable": {
            "&:hover, &:focus": {
              background: theme.palette.warning.main,
              color: theme.palette.common.white,
              ...iconColorOnHover,
            },
          },
        };
        break;
      case "secondary":
        rules = {
          ...rules,
          background: theme.palette.colours.secondary,
          color: theme.palette.common.white,
          "& .MuiChip-icon": {
            color: theme.palette.common.white,
          },

          "&.MuiChip-clickable": {
            "&:hover, &:focus": {
              background: theme.palette.colours.secondary,
              color: theme.palette.common.white,
              ...iconColorOnHover,
            },
          },
        };
        break;
      default:
        rules = {
          ...rules,
          background: theme.palette.primary.lighter,
          color: theme.palette.primary.dark,
          "& .MuiChip-icon": {
            color: theme.palette.primary.dark,
          },

          "&.MuiChip-clickable": {
            "&:hover, &:focus": {
              background: theme.palette.primary.dark,
              color: theme.palette.common.white,
              ...iconColorOnHover,
            },
          },
        };
    }

    if (props.isiconvariant) {
      rules["& .MuiChip-icon"] = {
        ...rules["& .MuiChip-icon"],
        width: theme.typography.pxToRem(13),
        height: theme.typography.pxToRem(13),
      };
    }

    if (props.textonly) {
      rules.background = "transparent";
      if (props.color === "secondary") {
        rules.color = theme.palette.colours.secondary;
        rules["& .MuiChip-icon"] = {
          ...rules["& .MuiChip-icon"],
          color: theme.palette.colours.secondary,
        };
      }
    }
    return rules;
  },
}));

const StyledChip = ({ isiconvariant, textonly, ...props }) => {
  const { color = "primary" } = { ...props };
  const classes = useStyles({ color, isiconvariant, textonly });

  return (
    <Chip label="Basic" color={color} className={classes.root} {...props} />
  );
};

StyledChip.propTypes = {
  isiconvariant: PropTypes.bool,
  textonly: PropTypes.bool,
};

StyledChip.defaultProps = {
  isiconvariant: false,
  textonly: false,
};

export default StyledChip;
