// Dashboard API URLs
export const GET_PLAN_DETAILS = "/assort/plan";
export const GET_CREATED_PLANS = "/assort/plan/search";
export const DELETE_PLAN = "/assort/plan/delete";
export const CREATE_PLAN = "/assort/plan";
export const GET_PLAN_LEVELS = "/assort/plan/level";
export const GET_PLAN_ATTRIBUTES = "/assort/plan/attributes";
export const GET_DASHBOARD_FILTERS = "/assort/plan/dashboard-filters";
export const DOWNLOAD_BUY_ROLLUP = "/assort/buy-rollups";
export const GET_DASHBOARD_PLANS_TABLE_CONFIG =
  "/assort/table-fields/dashboard";
export const COPY_ASSORT_PLAN = "/assort/copy-plan";
export const FINALIZE_FOR_PO = "/assort/plan/finalize-po";
export const GET_SEASON_YEAR = "/assort/plan/get-season-year";
export const GET_SEASON_OPTIONS = "/master/season/get-season-details";
//1-1 api urls
export const GET_STORE_CHANNELS = "/master/store/channels";
export const GET_CHANNELS_BASED_STORE_GROUPS = "/core/group/store";
export const CALCULATE_SIG_SCORE = "/cluster-smart/attributes/calculate-attributes";
export const GET_PERFORMANCE_ATTRIBUTES = "/assort/plan/performance-attributes";
export const GET_PRODUCT_ATTRIBUTES = "/assort/plan/product-attributes";
export const GET_CLUSTER_ATTRIBUTES = "/cluster-smart/attributes";
export const RUN_CLUSTER = "/cluster-smart/cluster";
export const CLUSTER_POLL = "/cluster-smart/cluster/poll/";
export const UPDATE_ATTRIBUTES = "/cluster-smart/attributes/update-attributes";
export const ADD_ECOM_CLUSTER = "cluster-smart/cluster/add-ecom-details";
export const MULTIPLE_CHANNEL_DYNAMIC_COL = "assort/table-fields/multiple-channel"
//1-2 API URLs
export const GET_ATTRIBUTE_GRAPH_DATA = "cluster-smart/attributes/info";
export const GET_PERFORMANCE_GRAPH_DATA = "cluster-smart/performance/info";
export const GET_CLUSTER_BREAKDOWN_DATA = "cluster-smart/finalize/grade-breakdown";
export const FINAL_CLUSTER_BUCKET_SAVE ="cluster-smart/finalize/final-save"
export const GET_CLUSTER_STORE_LIST_DATA = "cluster-smart/stores/fetch-stores"
export const GET_STORE_DATA_FROM_CLUSTER = "/master/store/attributes";
export const UPDATE_SWAP_STORE_DATA ="cluster-smart/stores/store-swaps"
//2-1 API URLs
export const GET_BUDGET_L2 = "assort/optimization/get-l2-budget";
export const OPTIMIZE_L3 = "assort/optimization/2-1-cloud-task";
export const GET_DROPS_DATA = "assort/plan/fetch-drops-data";
export const BUDGET_POLL = "/assort/task/poll/";
export const UPDATE_BUDGET_L2 = "assort/optimization/update-l2-budget";
export const UPDATE_BUDGET_L2_DROP = "assort/plan/update-l2-budget-drop";
export const GET_L3_OPT = "assort/optimization/get-l3-optimization";
export const UPDATE_L3_OPT = "assort/optimization/update-l3-optimization";
export const GET_CLUSTER_OPT = "assort/optimization/get-cluster-optimization";
export const UPDATE_CLUSTER_OPT =
  "assort/optimization/update-cluster-optimization";
export const DROP_CONFIG = "assort/optimization/drop-config";
export const GET_PRODUCT_LIST = "assort/plan-budget/product-list";
export const CREATE_NEW_L3 = "assort/new-l3";
export const GET_STORE_ELIGIBILITY_DATA = "assort/optimization/get-ip-eligibility-store-count";
//2-2 API URLs
export const OPTIMIZE_APS_ST = "assort/depth-choice/calculate-aps-st";
export const GET_APS_ST = "assort/depth-choice/get-aps-st";
export const UPDATE_L3_APS_ST = "assort/depth-choice/update-aps-st-l3";
export const UPDATE_CLUSTER_APS_ST =
  "assort/depth-choice/update-cluster-aps-st";
export const GET_DEPTH_CHOICE = "assort/depth-choice/get-depth-choice";
export const UPDATE_DEPTH_CHOICE = "assort/depth-choice/update-depth-choice";
export const OPTIMIZE_DEPTH_CHOICE =
  "assort/depth-choice/calculate-depth-choice";

// 2-3 API URLs
export const GET_PLAN_OPTMIZATION_CONSTRAINT =
  "assort/wedge/get-plan-optimization-constraint";
export const UPDATE_OPTMIZATION_CONSTRAINT_DATA =
  "assort/wedge/update-plan-optimization-constraint";
export const GET_WEDGE_METRICS = "assort/wedge/get-wedge-metrics";
export const GET_WEDGE_ATTRIBUTES = "assort/wedge/get-wedge-attributes";
export const GET_WEDGE_TABLE_DATA = "assort/wedge/get-wedge-data";
export const UPDATE_WEDGE_TABLE_DATA = "assort/wedge/plan-wedge-details";
export const OPTIMIZE_WEDGE = "assort/wedge/initiate-wedge-process";
export const DEPTH_MULTIPLIER = "assort/wedge/get-depth-multiplier-details";
export const UPLOAD_WEDGE = "assort/wedge/upload-file";
export const GET_PLAN_SETUP_DROPS = "assort/wedge/get-plan-drop";
export const UPDATE_PLAN_SETUP_DROPS = "assort/wedge/plan-drop-details";
export const WEDGE_ADD_CHOICE = "assort/wedge/wedge-add-choice";
export const WEDGE_ADD_CHOICE_VALIDATION = "assort/wedge/wedge-add-choice-validation"
export const WEDGE_DELETE_CHOICE = "assort/wedge/delete-choices";
export const WEDGE_DELETE_FETCH_DATA = "assort/wedge/delete-data-fetch";
export const ADD_DROP_SHIP_CHOICES = "assort/wedge/add-dropship-choices";

//2-4 API URLs
export const GET_FINALISE_PLAN_METRICS =
  "assort/finalize/get-finalise-plan-metrics";
export const OPTIMIZE_SIZE_REVIEW = "assort/finalize/calculate-size-split";
export const GET_SIZE_REVIEW_CONFIGURATION = "assort/finalize/get-size-details";
export const GET_FINALIZE_CLUSTER_GRADE_DATA =
  "assort/finalize/get-grade-details";
export const GET_FINALIZE_ATTRIBUTE_GRADE_DATA =
  "assort/finalize/get-attribute-grade-details";
export const OPTIMIZE_ATTRIBUTE_REVIEW = "assort/finalize/calculate-attr-split";
export const FINALIZE_PLAN = "assort/plan/finalize/";
export const DOWNLOAD_PO_SHEET = "assort/finalize/get-finalise-po-sheet";
//Receipt drawer and BOP URLs
export const GET_RECEIPT_DRAWER_DATA = "assort/plan/get-plan-receipt-drawer";
export const GET_BOP_L3_LEVEL_DATA = "assort/plan/get-bop-summary-l3";
export const GET_BOP_CHOICE_LEVEL_DATA = "assort/plan/get-bop-summary-choice";
//NLE
export const NON_LINEAR_EDIT = "assort/finalize/non_linear_changes";
export const NON_LINEAR_CHANGES_REPLACE_DATA = "assort/finalize/non-linear-changes-replace-data"

//Default Attributes
export const GET_DEFAULT_ATTRIBUTES = "master/attribute/get-default-attribute";

//comapare-plan url
export const GET_COMPARE_PLAN_DATA = "assort/plan/compare-plan";
export const VALIDATE_COMPARE_PLAN = "assort/plan/validate-compare-plan";
export const GET_COMPARE_PLAN_CLUSTER_DATA = "assort/plan/compare-cluster";

//Import plan url
export const IMPORT_CLUSTER_PLAN = "assort/plan/import-cluster";

//Hindsight cluster save api url
export const HINDSIGHT_FINAL_CLUSTER_BUCKET_SAVE =
  "assort/cluster/hindsight-final-save";

//Omni/Hindsight Dashboard table columns api url
export const OMNI_HINDSIGHT_DASHBOARD_TABLE_CONFIG = "assort/table-fields";

//Omni API urls
export const GET_OMNI_WEDGE_MAPPING_DATA = "assort/omni-wedge/get-mapping";
export const GET_OMNI_WEDGE_METRICS_DATA = "assort/omni-wedge/get-metrics";
export const UPLOAD_OMNI_MAPPING_FILE = "assort/omni-wedge/upload-file";
export const MERGE_OMNI_PLAN = "assort/omni-wedge/merge-plan";
export const ADD_REFRESH_PLAN = "assort/omni-wedge/refresh-plan";
export const UPDATE_OMNI_DATA = "assort/omni-wedge/update-omni-wedge";
export const OMNI_DELETE = "assort/omni-wedge/delete";
export const OMNI_DELETE_ROW = "assort/omni-wedge/delete-global-choice";
export const OMNI_CREATE_PLACEHOLDER_AND_CHOICE_ID =
  "assort/wedge/create-placeholder-and-choice-id";
export const OMNI_CALL_PLACEHOLDER_OID =
  "assort/placeholder/call-placeholder-oid";
export const GET_CARRYOVER_COLORWAY = "assort/omni-wedge/get-carryover-colorway-details";
export const OMNI_CALL_COLORWAY_SEASON_FINALIZED = "assort/omni-plan/callback-colorway-season-finalized-omni-plan";

//COre choice configuration api urls
export const CORE_CHOICE_DASHBOARD_TABLE_CONFIG = "assort/table-fields/assort_core_choice_dashboard";
export const GET_CORE_CHOICE_DASHBOARD = "assort/all_door_choice/get-dashboard";
export const CREATE_CORE_CHOICE_FILTERS = "core/filter-configuration/screen/Assort create core choice";
export const CREATE_CORE_CHOICE = "assort/all_door_choice/create-all-door-choice";

//Dashboard filters api
export const DASHBOARD_FILTER = "core/filter-configuration/screen";
