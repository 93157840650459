/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const DemoPage = (props) => {
  const globalClasses = globalStyles();
  const homeIcon = [
    {
      label: props.title,
      id: 1,
    },
  ];

  return (
    <>
      {props.breadcrumb === "Yes" && <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>}
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%" }}
          data={`${process.env.REACT_APP_BKP_URL}${window.location.pathname}?timestamp=${Date.now()}`}
        ></object>
      </div>
    </>
  );
};

export default DemoPage;
