import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

const EditableCell = (
  {
    value: initialValue,
    row,
    row: { index, id },
    column,
    updateMyData,
    ...wholeData
    // This is a custom function that we supplied to our table instance
  },
  type
) => {
  const [value, setValue] = useState(initialValue);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onBlur = () => {
    let revertChanges = updateMyData(
      index,
      column.id,
      type ? value : Number(value),
      initialValue,
      column,
      wholeData,
      row,
      id
    );
    if (revertChanges) {
      setValue(initialValue);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <TextField
      variant="outlined"
      type={type ? type : "number"}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      inputProps={{ style: { padding: "5px" } }}
    />
  );
};
export default EditableCell;
