import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import { useHistory } from "react-router";
import { VIEW_PAST_ALLOCATION } from "../../constants-inventorysmart/routesConstants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../inventorysmart-utility";
import classNames from "classnames";
import {
  ORDER_BATCHING_FILTERS_HEADER,
  ERROR_MESSAGE,
  RANGE_FILTER_ERROR_MESSAGE,
  INVENTORY_DASHBOARD_STATIC_FILTERS,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { useStyles } from "modules/inventorysmart/pages-inventorysmart/styles-inventorysmart";
import globalStyles from "Styles/globalStyles";
import { addSnack } from "actions/snackbarActions";
import Filters from "commonComponents/filters/filterGroup";
import { cloneDeep, isEmpty } from "lodash";
import LoadingOverlay from "Utils/Loader/loader";
import { Button, Grid, Paper } from "@mui/material";
import ViewPastAllocationsTable from "./components/ViewPastAllocationTable";
import CustomAccordion from "commonComponents/Custom-Accordian";
import {
  setInventorysmartPastAllocationFilterLoader,
  setSelectedFilters,
  setIsFiltersValid,
  setInventorysmartPastAllocationFilterElements,
  setInventorysmartPastAllocationFilterDependency,
  resetPastAllocationState,
} from "modules/inventorysmart/services-inventorysmart/View-Past-Allocation/view-past-allocation";
import moment from "moment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomFilter from "../Allocation-Reporting/filters/CustomFilter";
import { getCoreFiscalCalendar } from "modules/ada/services-ada/ada-dashboard/ada-dashboard-services";
import ActionButton from "modules/ada/pages-ada/Dashboard/filters/action-button";

const ViewPastAllocation = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);

  const [filters, setFilters] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [fiscalCalendarDetails, setFiscalCalendarDetails] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    fiscalInfoStartDate: null,
    fiscalInfoEndDate: null,
  });
  const [startEndDate, setStartEndDate] = useState({
    start_date: null,
    end_date: null,
  });

  const updateFilters = async (selected, current) => {
    await getFiltersOptions(selected, current);
    props.setInventorysmartPastAllocationFilterDependency(selected);
  };

  const applyFilters = (filterElements, filterDependency) => {
    const payload = filtersPayload(
      filterElements || props.inventorysmartPastAllocationFilterElements,
      filterDependency || props.inventorysmartPastAllocationFilterDependency,
      true
    );

    let formattedDates = ["Invalid date"];

    if (
      selectedDates?.fiscalInfoStartDate?.calendar_week_start_date &&
      selectedDates?.fiscalInfoEndDate?.calendar_week_start_date
    ) {
      formattedDates = [
        moment(
          selectedDates?.fiscalInfoStartDate?.calendar_week_start_date
        ).format("YYYY-MM-DD"),
        moment(selectedDates?.fiscalInfoEndDate?.calendar_week_start_date)
          .endOf("week")
          .format("YYYY-MM-DD"),
      ];
    }

    if (formattedDates?.includes("Invalid date")) {
      if (
        !(
          formattedDates[0] === "Invalid date" &&
          formattedDates[1] === "Invalid date"
        )
      ) {
        displaySnackMessages(RANGE_FILTER_ERROR_MESSAGE, "error");
        return;
      } else {
        setStartEndDate({
          start_date: null,
          end_date: null,
        });
      }
    } else if (!isEmpty(formattedDates)) {
      setStartEndDate({
        start_date: formattedDates[0],
        end_date: formattedDates[1],
      });
    }
    props.setIsFiltersValid(payload.isValid);
    props.setSelectedFilters(payload.reqBody);
    setOpenModal(false);
  };

  const resetSelectedFilterValues = async () => {
    props.setInventorysmartPastAllocationFilterDependency([]);
    applyFilters(filterData, []);
    setOpenModal(false);
  };

  const getDates = () => {
    let dates = {};
    if (startEndDate?.start_date && startEndDate.end_date) {
      dates = startEndDate;
    } else if (
      selectedDates?.fiscalInfoStartDate &&
      selectedDates?.fiscalInfoEndDate
    ) {
      dates = {
        start_date: moment(
          selectedDates?.fiscalInfoStartDate?.calendar_week_start_date
        ).format("YYYY-MM-DD"),
        end_date: moment(
          selectedDates?.fiscalInfoEndDate?.calendar_week_start_date
        )
          .endOf("week")
          .format("YYYY-MM-DD"),
      };
    }

    return dates;
  };

  const getFiltersOptions = async (selected, current) => {
    try {
      props.setInventorysmartPastAllocationFilterLoader(true);
      const selectedFilters = props.backButtonClicked
        ? cloneDeep(props.inventorysmartPastAllocationFilterDependency)
        : selected;
      const response = await fetchFilterOptions(
        filters || [],
        selectedFilters,
        current
      );
      if (props.backButtonClicked) {
        applyFilters(response, selectedFilters);
      }
      let filterElements = cloneDeep(response);
      setFilterData(response);
      props.setInventorysmartPastAllocationFilterElements(filterElements);
    } catch (error) {
      displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      props.setInventorysmartPastAllocationFilterLoader(false);
    }
  };

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        props.setInventorysmartPastAllocationFilterLoader(true);
        const response = await fetchFilterConfig(
          "inventorysmart_view_past_allocations"
        );
        const getFinancialCalendarData = await getCoreFiscalCalendar();

        setFilters(response);
        setFiscalCalendarDetails(getFinancialCalendarData?.data?.data);
      } catch (error) {
        props.setInventorysmartPastAllocationFilterLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    };
    fetchFilters();

    return () => {
      props.resetPastAllocationState();
    };
  }, []);

  useEffect(() => {
    if (props.backButtonClicked) {
      setFilters([]);
      setFilterData([]);
      setSelectedDates(props.formFilters.selectedDates);
    }
  }, [props.backButtonClicked, props.formFilters]);

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  return (
    <>
      <HeaderBreadCrumbs
        options={[
          {
            label: "View Past Allocations",
            id: 1,
            action: () => {
              history.push(VIEW_PAST_ALLOCATION);
            },
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {props.inventorysmartPastAllocationFilterDependency.length > 0 && (
          <FilterChips
            filterConfig={props.inventorysmartPastAllocationFilterDependency}
            dateFilter={getDates()}
          ></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay
            loader={props.inventorysmartPastAllocationFilterLoader}
          >
            <Paper className={globalClasses.paperWrapper}>
              <div className={classes.filterBoardHeader}>
                <h3 className={classes.createDetailsTitle}>
                  {ORDER_BATCHING_FILTERS_HEADER}
                </h3>
              </div>
              <Filters
                filters={filterData}
                update={updateFilters}
                onFilter={applyFilters}
                onReset={resetSelectedFilterValues}
                filterElevation={3}
                showBorderedWrapper={false}
                screen="inventory-smart-view-past-allocation"
                inititalSelection={
                  props.inventorysmartPastAllocationFilterDependency
                }
                customFilter
                customFilterOrderTop
                customComponent={
                  <div className={globalClasses.fullWidth}>
                    <CustomFilter
                      staticFilters={INVENTORY_DASHBOARD_STATIC_FILTERS}
                      fiscalCalendarDetails={fiscalCalendarDetails}
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                      isGroup={false}
                      displaySnackMessages={displaySnackMessages}
                    />
                  </div>
                }
              />
              <ActionButton
                onFilter={() =>
                  applyFilters(
                    props.inventorysmartPastAllocationFilterElements,
                    props.inventorysmartPastAllocationFilterDependency
                  )
                }
                onReset={resetSelectedFilterValues}
              />
            </Paper>
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>
      {props.isFiltersValid && (
        <div
          className={classNames(
            globalClasses.filterWrapper,
            globalClasses.marginVertical1rem
          )}
        >
          <CustomAccordion label="Past Allocations">
            <ViewPastAllocationsTable
              startEndDate={startEndDate}
              selectedDates={selectedDates}
            />
          </CustomAccordion>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    inventorysmartPastAllocationFilterLoader:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .inventorysmartPastAllocationFilterLoader,
    isFiltersValid:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .isFiltersValid,
    inventorysmartPastAllocationFilterElements:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .inventorysmartPastAllocationFilterElements,
    inventorysmartPastAllocationFilterDependency:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .inventorysmartPastAllocationFilterDependency,
    backButtonClicked:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .backButtonClicked,
    formFilters:
      store.inventorysmartReducer.inventorySmartPastAllocationService
        .formFilters,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventorysmartPastAllocationFilterLoader: (payload) =>
    dispatch(setInventorysmartPastAllocationFilterLoader(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setIsFiltersValid: (payload) => dispatch(setIsFiltersValid(payload)),
  setInventorysmartPastAllocationFilterElements: (payload) =>
    dispatch(setInventorysmartPastAllocationFilterElements(payload)),
  setInventorysmartPastAllocationFilterDependency: (payload) =>
    dispatch(setInventorysmartPastAllocationFilterDependency(payload)),
  addSnack: (payload) => dispatch(addSnack(payload)),
  resetPastAllocationState: () => dispatch(resetPastAllocationState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPastAllocation);
