import {
  Button,
  Grid,
  Typography,
  Container,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
} from "@mui/material";
import PageRouteTitles from "./PageRouteTitles";
import { useHistory } from "react-router";
import { camelCase, isEmpty, find, cloneDeep, uniqBy } from "lodash";
import { useEffect, useState, useRef } from "react";
import globalStyles from "Styles/globalStyles";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import LoadingOverlay from "Utils/Loader/loader";
import FilterGroup from "commonComponents/filters/filterGroup";
import { addSnack } from "actions/snackbarActions";
import { connect } from "react-redux";
import { mapDataToLabel, getSelectionDependency } from "./utils";
import {
  setFilterConfiguration,
  setSavedFilterSelection,
  setSavedFilterData,
} from "actions/filterAction";
import { updateFilterData } from "commonComponents/coreComponentScreen/utils";
import { setSelectedFilters } from "../../actions/filterAction";

/**
 * @param {*} props
 */
const CoreComponentScreen = (props) => {
  const globalClasses = globalStyles();
  const prevScr = props.location?.state?.prevScr;
  const history = useHistory();
  const [pageId, setPageId] = useState("");
  const firstTimeRender = useRef(true);

  // Filter Variables
  const [openModal, setOpenModal] = useState(false);
  const [filterDependencyChips, setFilterDependencyChips] = useState([]);
  const [showFilterLoader, setShowFilterLoader] = useState(false);
  const [filterSectionRadio, setFilterSectionRadio] = useState(0);

  useEffect(() => {
    setPageId(camelCase(props.label));
  }, []);

  useEffect(() => {
    // saved filter selection is set only when filter dashboard is first rendered
    if (
      !isEmpty(props.filterDashboardConfiguration) &&
      firstTimeRender.current
    ) {
      const { filterData } = getFilterConfigurationData();

      let savedFilterSelection = getRequiredFilterList(
        filterData,
        props.savedFilterSelection
      );
      savedFilterSelection = savedFilterSelection.map((item) => {
        item.filter_id = item.attribute_name;
        item.values = item.values.map((attribute) => {
          return {
            value: attribute,
            label: attribute,
            id: attribute,
          };
        });
        return item;
      });
      let filterSelectionDependency = [];
      props.filterDashboardConfiguration?.[0]?.filterDashboardClassification.forEach(
        (item) => {
          let obj = {};

          // set saved filter selection based on dimension
          let filterSelection = savedFilterSelection.filter(
            (filter) => filter.dimension === item.dimension
          );
          if (!isEmpty(props.selectedFilters[item.screenName])) {
            filterSelection = [
              ...filterSelection,
              ...props.selectedFilters[item.screenName],
            ];
          }

          const filterSelectionData = uniqBy(filterSelection, "filter_id");
          filterSelectionDependency = [
            ...filterSelectionDependency,
            ...filterSelectionData,
          ];
          obj[item.screenName] = filterSelectionData;
          props.setSelectedFilters(obj);
        }
      );

      // set combined filter dependency of all dimensions and set filter data to original dataset
      updateFilterConfigurationData(
        props.filterDashboardConfiguration[0].originalFilterDashboardData,
        getSelectionDependency(filterSelectionDependency)
      );
      firstTimeRender.current = false;
    }
  }, [props.filterDashboardConfiguration]);

  useEffect(() => {
    if (!isEmpty(props.appliedFilterData?.filterHeader)) {
      setFilterSectionRadio(props.appliedFilterData?.filterHeader);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(props.appliedFilterData?.dependencyData)) {
      // setting filter dashboard chips of the filters applied
      let chipsDependencyData = cloneDeep(
        props.appliedFilterData?.dependencyData
      )?.map((item) => {
        item.values = item.values.map((value) => {
          return mapDataToLabel({ attribute: value });
        });
        return item;
      });

      setFilterDependencyChips(chipsDependencyData);
    } else {
      setFilterDependencyChips([]);
    }
  }, [props.filterConfigKey]);

  const getRequiredFilterList = (filterData, filterDependency) => {
    const requiredFilters = filterData
      .filter((filter) => filter.is_mandatory)
      .map((item) => item.column_name);
    let copyFilterDependency = cloneDeep(filterDependency);
    const saveFilterList = copyFilterDependency.filter((filter) =>
      requiredFilters.includes(filter.attribute_name)
    );
    return saveFilterList;
  };

  const onSaveFilterClickHandler = async (filterData, filterDependency) => {
    try {
      if (!requiredFieldCheck(filterData, filterDependency)) {
        return;
      }
      // get only required filter selections from dependency list
      const saveFilterList = getRequiredFilterList(
        filterData,
        filterDependency
      );
      const allSavedFilterSelection = uniqBy(
        [...saveFilterList, ...props.savedFilterSelection],
        "attribute_name"
      );
      await setSavedFilterSelection({
        saved_filter_preference: allSavedFilterSelection,
      })();
      displaySnackMessages("Successfully saved filter selection", "success");
      props.setSavedFilterData(allSavedFilterSelection);
    } catch (err) {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const requiredFieldCheck = (filterData, dependencyData) => {
    // required field check on filters being applied
    let reqFieldsErr = false;
    for (const filter of filterData) {
      const key = {
        filter_id: filter.column_name,
        filter_type: filter.type,
      };
      let match = find(dependencyData, key);
      if (filter.is_mandatory && !match) {
        reqFieldsErr = true;
      }
    }
    if (reqFieldsErr) {
      displaySnackMessages("Please select required fields", "error");
      return false;
    }

    return true;
  };

  const onFilterClickHandler = async (
    filterData,
    dependencyData,
    customOnFilterFunc
  ) => {
    if (!requiredFieldCheck(filterData, dependencyData)) {
      return;
    }
    // updating applied filters data in filter configuration
    updateFilterAppliedData();

    // setting filter dashboard chips of the filters applied
    const copyDependencyData = cloneDeep(dependencyData);
    const chipsDependencyData = copyDependencyData.map((item) => {
      item.values = item.values.map((value) => {
        return mapDataToLabel({ attribute: value });
      });
      return item;
    });
    setFilterDependencyChips(chipsDependencyData);

    // calling filter function which is passed in filter configurations
    customOnFilterFunc(dependencyData, filterData, filterSectionRadio);
    setOpenModal(false);
  };

  const updateDataHandler = async (dependency, filter) => {
    setShowFilterLoader(true);
    await updateData(dependency, filter.dimension, filter);
    setShowFilterLoader(false);
  };

  const getFilterConfigurationData = () => {
    const copyFilterConfigurations = cloneDeep(
      props.filterDashboardConfiguration
    );
    let filterSectionData = {};
    copyFilterConfigurations.forEach((item, index) => {
      if (
        index == filterSectionRadio ||
        copyFilterConfigurations.length === 1
      ) {
        filterSectionData = item;
      }
    });

    return {
      filterDependency: filterSectionData?.filterDependencyData,
      filterData: filterSectionData?.filterDashboardData,
      screenDimension: filterSectionData?.screenDimension,
      isCrossDimensionFilter: filterSectionData?.isCrossDimensionFilter,
      is_urm_filter: filterSectionData.is_urm_filter,
      screen_name: filterSectionData.screen_name,
      application_code: filterSectionData.application_code,
    };
  };

  const updateFilterAppliedData = () => {
    let updatedFilterConfigurations = cloneDeep(
      props.filterDashboardConfiguration
    );
    let updatedAppliedFilter = cloneDeep(props.appliedFilterData);
    updatedFilterConfigurations.forEach((item, index) => {
      if (
        index == filterSectionRadio ||
        updatedFilterConfigurations.length === 1
      ) {
        updatedAppliedFilter.dependencyData = item.filterDependencyData;
        updatedAppliedFilter.filterHeader = filterSectionRadio;
      }
    });

    let obj = {};
    obj[props.filterConfigKey] = {
      filterConfig: updatedFilterConfigurations,
      appliedFilterData: updatedAppliedFilter,
    };
    props.setFilterConfiguration(obj);
  };

  const updateFilterConfigurationData = (filterData, filterDependency) => {
    let updatedFilterConfigurations = cloneDeep(
      props.filterDashboardConfiguration
    );
    let updatedAppliedFilter = cloneDeep(props.appliedFilterData);
    updatedFilterConfigurations = updatedFilterConfigurations.map(
      (item, index) => {
        if (
          index == filterSectionRadio ||
          updatedFilterConfigurations.length === 1
        ) {
          return {
            ...item,
            filterDependencyData: filterDependency
              ? filterDependency
              : item.filterDependencyData,
            filterDashboardData: filterData
              ? filterData
              : item.filterDashboardData,
          };
        } else return item;
      }
    );

    let obj = {};
    obj[props.filterConfigKey] = {
      filterConfig: updatedFilterConfigurations,
      appliedFilterData: updatedAppliedFilter,
    };
    props.setFilterConfiguration(obj);
  };

  const updateData = async (dependency, dimension, filter) => {
    /// send onFilterDependency and filterData based on filterSection selected
    const {
      filterDependency,
      filterData,
      screenDimension,
      isCrossDimensionFilter,
      is_urm_filter,
      screen_name,
      application_code,
    } = getFilterConfigurationData();
    const { initialFilterElements, selectionDependency, loader } =
      await updateFilterData(
        dependency,
        filterData,
        screenDimension,
        isCrossDimensionFilter, // isCrossDimensionFilter
        filterDependency,
        dimension,
        is_urm_filter,
        screen_name,
        application_code
      );
    /// update onFilterDependency and filterData based on filterSection selected
    updateFilterConfigurationData(initialFilterElements, selectionDependency);
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const routeOptions = [
    {
      id: `${pageId}${"_home_scr"}`,
      label: props.pageLabel,
      action: () => {},
    },
  ];

  return (
    <>
      {props.showPageRoute && (
        <PageRouteTitles
          id={`${pageId}${"MainBrdCrmbs"}`}
          options={props.routeOptions ? props.routeOptions : routeOptions}
        />
      )}
      <div id={`${pageId}${"MainCnt"}`}>
        {(props.showFilterDashboard || props.showPageHeader) && (
          <Container maxWidth={false} className={globalClasses.marginBottom}>
            <Grid direction="row" justifyContent="space-between" container>
              <Grid item xs={4}>
                <Typography
                  variant="h3"
                  component="h3"
                  className={`${globalClasses.pageHeader}`}
                >
                  {props.showPageHeader ? props.pageLabel : ""}
                </Typography>
              </Grid>
              {props.showFilterDashboard && (
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row-reverse"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FilterAltOutlinedIcon />}
                        onClick={() => setOpenModal(true)}
                      >
                        Select Filters
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {props.showFilterDashboard && !firstTimeRender.current && (
              <div id={"filter-container"}>
                <div>
                  {!isEmpty(filterDependencyChips) && (
                    <FilterChips
                      filterConfig={filterDependencyChips}
                    ></FilterChips>
                  )}
                </div>
                <FilterModal
                  open={openModal}
                  isModalFixedTop={true}
                  closeOnOverlayClick={() => setOpenModal(false)}
                >
                  <LoadingOverlay
                    loader={isEmpty(props.filterDashboardConfiguration)}
                    minHeight={100}
                  >
                    {props.filterDashboardConfiguration?.length > 1 && (
                      <FormControl>
                        <RadioGroup
                          aria-labelledby="filter-group-radio"
                          value={filterSectionRadio}
                          name="filter-group-radio"
                          row
                          onChange={(event) =>
                            setFilterSectionRadio(event.target.value)
                          }
                        >
                          {props.filterDashboardConfiguration?.map(
                            (filter, index) => {
                              return (
                                <FormControlLabel
                                  value={index}
                                  control={<Radio />}
                                  label={filter.filterSectionHeader}
                                />
                              );
                            }
                          )}
                        </RadioGroup>
                      </FormControl>
                    )}

                    {props.filterDashboardConfiguration?.map(
                      (filter, index) => {
                        return (
                          (filterSectionRadio == index ||
                            props.filterDashboardConfiguration?.length ===
                              1) && (
                            <div>
                              <>
                                {filter.filterDashboardClassification?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <CustomAccordion
                                          label={item.filterLabel}
                                          defaultExpanded={true}
                                          customClass={
                                            globalClasses.accordianWrapper
                                          }
                                        >
                                          <LoadingOverlay
                                            loader={
                                              showFilterLoader ||
                                              isEmpty(
                                                filter.filterDashboardData
                                              )
                                            }
                                            minHeight={70}
                                          >
                                            <FilterGroup
                                              dimension={item.dimension}
                                              filters={filter.filterDashboardData.filter(
                                                (filter) =>
                                                  filter.dimension ===
                                                  item.dimension
                                              )}
                                              update={(
                                                dependency,
                                                filterValue
                                              ) => {
                                                updateDataHandler(
                                                  dependency,
                                                  filterValue
                                                );
                                              }}
                                              filterElevation={3}
                                              showBorderedWrapper={false}
                                              screen={item.screenName}
                                              doNotUpdateDefaultValue={true}
                                              customFilter={true}
                                              showResetButton={true}
                                              onReset={item.onReset}
                                              customComponent={
                                                item.customFilterComponent
                                                  ? item.customFilterComponent
                                                  : null
                                              }
                                              inititalSelection={
                                                props.selectedFilters?.[
                                                  item.screenName
                                                ]
                                                  ? props.selectedFilters?.[
                                                      item.screenName
                                                    ]
                                                  : []
                                              }
                                            />
                                          </LoadingOverlay>
                                        </CustomAccordion>
                                      </>
                                    );
                                  }
                                )}
                              </>
                              <div
                                className={`${globalClasses.flexRow} ${globalClasses.gap}`}
                              >
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  id="saveFilterBtn"
                                  disabled={isEmpty(
                                    filter.filterDependencyData
                                  )}
                                  onClick={() =>
                                    onSaveFilterClickHandler(
                                      filter.filterDashboardData,
                                      filter.filterDependencyData
                                    )
                                  }
                                >
                                  Save Filter
                                </Button>
                                <Button
                                  variant="contained"
                                  color="primary"
                                  id="filterBtn"
                                  onClick={() =>
                                    onFilterClickHandler(
                                      filter.filterDashboardData,
                                      filter.filterDependencyData,
                                      props.onApplyFilter
                                    )
                                  }
                                >
                                  Apply Filter
                                </Button>
                              </div>
                            </div>
                          )
                        );
                      }
                    )}
                  </LoadingOverlay>
                </FilterModal>
              </div>
            )}
          </Container>
        )}
        {props.children}
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    filterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[ownProps.filterConfigKey]
        ?.filterConfig,
    appliedFilterData:
      state.filterReducer.filterDashboardConfiguration[ownProps.filterConfigKey]
        ?.appliedFilterData,
    selectedFilters: state.filterReducer.selectedFilters,
    savedFilterSelection: state.filterReducer.savedFilterSelection,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (messageProperties) => dispatch(addSnack(messageProperties)),
    setFilterConfiguration: (filterConfiguration) =>
      dispatch(setFilterConfiguration(filterConfiguration)),
    setSavedFilterData: (data) => dispatch(setSavedFilterData(data)),
    setSelectedFilters: (data) => dispatch(setSelectedFilters(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoreComponentScreen);
