/**
 * Add generic functions here which are abstract tasks and dont contain business logic
 * Ex: all lodash functions are generic functions
 */

import moment from "moment";

// Create array of numbers with start, end, step i.e. diff between two consecutive numbers
export const rangexy = (start, end, step) =>
  Array.from({ length: end / step + 1 - start }, (_, i) => {
    return step * i + start;
  });

// find closest number for a given range of integers
// ex [5,10,15,20] if target is 12 return value will be 10 and if target is 13 return value will be 15
export const binaryClosestIdx = (target, min, max, step) => {
  const arr = rangexy(min, max, step);
  let start = 0;
  let end = arr.length - 1;
  let mid = Math.floor((start + end) / 2);

  while (true) {
    if (arr[mid] === target) {
      return arr[mid];
    } else if (start >= end) {
      break;
    } else if (arr[mid] > target) {
      end = mid - 1;
    } else {
      start = mid + 1;
    }

    mid = Math.floor((start + end) / 2);
  }

  // Return the closest between the last value checked and it's surrounding neighbors
  const first = Math.max(mid - 1, 0);
  const neighbors = arr.slice(first, mid + 2);
  const best = neighbors.reduce((b, el) =>
    Math.abs(el - target) < Math.abs(b - target) ? el : b
  );

  return arr[first + neighbors.indexOf(best)];
};

/**
 *
 * @param {string} dateString
 * @param {date format} oldFormat
 * @param {date format} newFormat
 * @returns datestring
 * changeDateStringToOtherFormat function takes datestring as input which is in one X format and
 * converts it to other Y format and returns back the new formatted string
 * Ex: 10-12-1996 is converted to 12-10-1996 if we 10-12-1996, DD-MM-YYYY, MM-DD-YYYY as params
 */
export const changeDateStringToOtherFormat = (
  dateString,
  oldFormat,
  newFormat
) => {
  return moment(dateString, oldFormat).format(newFormat);
};

export const getFormattedApplicationName = (applicationURL) => {
  //Function to return the application name that matches global.application_master. TO DO: Make the URL expedition more dynamic taking it from the landing screen itself
  switch (applicationURL) {
    case "assort-smart":
      return "AssortSmart";
    case "inventory-smart":
      return "InventorySmart";
    default:
      return "Workflow Input Center";
  }
};

/**
 *
 * @param {*} string
 * @param {*} delimiter
 * @returns string split array with extra whitespaces trimmed
 */
export const stringSplitWithTrim = (string, delimiter) => {
  const stringSplitArr = string.split(delimiter);
  return [stringSplitArr[0].trim(), stringSplitArr[1].trim()];
};

/**
 *
 * @param {moment} date
 * @param {moment, dateString} selectedDate
 * @returns boolean
 * date: The date to test
 * selectedDate: Date value currently set in the datePicker field
 * returns (boolean): Returns true if the date should be disabled.
 * Since datePicker calendar selects the first enabled date if past dates are disabled,
 * we need to keep the past date enabled if selected date in input fields in the past
 */
export const setDisabledDates = (date, selectedDate) => {
  date = date.format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");
  if (moment(selectedDate).isSame(date)) {
    return false;
  } else if (moment(date).isBefore(today)) {
    return true;
  }
};

export const checkForSpecialCharacters = (value) => {
  //should contain only strings, numbers and spaces
  let regExp = /^[A-Za-z0-9\s]*$/;
  return regExp.test(value);
};

/**
 *
 * @param {moment, dateString} value
 * @returns moment
 * value: The date to convert to UTC
 * returns (moment): converts input date to UTC format
 */
export const timeConversionToUTC = (value) => {
  const utcDate = moment.utc(value).format();
  return utcDate;
};

/**
 *
 * @param {moment, dateString} value
 * @param {string} tenantFormat
 * @param {string} tenantTimeZone
 * @returns dateString in input format
 * value: The date to convert
 * tenantFormat: tenant configured date format
 * tenantTimeZone: tenant configured timezone
 * returns (moment): converts input to tenant timezone and date format
 */
export const timeConversionToTenantLocal = (
  value,
  tenantFormat,
  tenantTimeZone
) => {
  const utcDate = moment.utc(value);
  const localValue = utcDate.tz(tenantTimeZone).format(tenantFormat);
  return localValue;
};
