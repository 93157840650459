import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CalendarToday,
  FactCheck,
  Storefront,
  PieChart,
  Factory,
} from "@mui/icons-material";
import Layout from "commonComponents/layout";
import {
  TRACKING,
  ALRT_CONFIGURATION,
} from "../constants-trackmart/routesConstants";

import TrackingComponent from "../pages-tracksmart/react-demo/tracking";
import PreseasonDashboardComponent from "../pages-tracksmart/react-demo/alert-config";
import "commonComponents/layout/layout.css";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + TRACKING,
    title: "Tracking",
    icon: React.createElement(CalendarToday),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ALRT_CONFIGURATION,
    title: "Alert Configuration",
    icon: React.createElement(FactCheck),
    order: 2,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + TRACKING,
      component: TrackingComponent,
      title: "Tracking",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ALRT_CONFIGURATION,
      component: PreseasonDashboardComponent,
      title: "Alert Configuration",
    },
  ];
  return (
    <Layout
      routes={routes}
      sideBarOptions={sideBarOptions}
      app={"tracksmart"}
    />
  );
};

const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
