import { Typography, Button, Container } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import {
  fetchGroupStores,
  setStoreGrpFilteredCols,
  deletedRowsInEdit,
  getGroupInfo,
  EditGroup,
  ToggleLoader,
  setSelectedGroupToEdit,
} from "../services-store-grouping/custom-store-group-service";
import { addSnack } from "../../../actions/snackbarActions";
import { connect } from "react-redux";
import LoadingOverlay from "../../../Utils/Loader/loader";
import PageRouteTitles from "./PageRouteTitles";
import { Prompt } from "react-router-dom";
import { storeGrouping } from "config/routes";
import AgGridComponent from "Utils/agGrid";
import { getColumnsAg } from "actions/tableColumnActions";
import { cloneDeep } from "lodash";

const EditGrpProducts = (props) => {
  const globalClasses = globalStyles();
  const history = useHistory();
  const [tableData, settableData] = useState([]);
  const tableInstance = useRef({});

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.ToggleLoader(true);
        const cols = await props.getColumnsAg("table_name=store_group_filter");
        props.setStoreGrpFilteredCols(cols);
        props.ToggleLoader(false);
        const grpId = props.match.params.group_id;
        const res = await props.getGroupInfo(grpId);
        const grpObj = res.data.data[0];
        props.setSelectedGroupToEdit(grpObj.name);
      } catch (error) {
        props.ToggleLoader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };
    fetchData();
    return () => {
      props.deletedRowsInEdit([]);
    };
  }, []);

  const selectionHandler = (event) => {
    let selectedRows = event.api.getSelectedRows();
    let tableRows = event.api.getRenderedNodes().map((item) => {
      return item.data;
    });

    let updatedDelete = props.deletedStores.filter((prod) => {
      return !selectedRows.some((selection) => {
        return selection.store_code === prod.store_code;
      });
    });

    const deleteRows = tableRows.filter((row) => {
      return !selectedRows.some((selection) => {
        return selection.store_code === row.store_code;
      });
    });
    updatedDelete.push(...deleteRows);
    props.deletedRowsInEdit(updatedDelete);
  };

  const manualCallBack = async (body, pageIndex, params) => {
    props.ToggleLoader(true);
    let res = {
      data: {
        data: [],
        total: 0,
      },
    };
    let manualbody = {
      meta: {
        ...body,
      },
      selection: {
        data: [
          {
            searchColumns: {},
            checkAll: true,
          },
          ...params?.api?.checkConfiguration,
        ],
        unique_columns: ["store_code"],
      },
    };
    try {
      if (
        body.search.length > 0 ||
        body.sort.length > 0 ||
        body.range.length > 0
      ) {
        const grpId = props.match.params.group_id;
        res = await props.fetchGroupStores(grpId, manualbody);
      } else {
        const grpId = props.match.params.group_id;
        res = await props.fetchGroupStores(grpId, manualbody, pageIndex, 10);
      }

      settableData(res.data.data);

      props.ToggleLoader(false);
      return {
        data: res.data.data,
        totalCount: res.data.total,
      };
    } catch (error) {
      props.ToggleLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const goBack = () => {
    history.push(prevScr ? prevScr : storeGrouping.home);
  };

  const onModify = () => {
    let modifyRoute = prevScr ? `${prevScr}/modify` : storeGrouping.modifyGroup;
    history.push(
      `${modifyRoute}/${props.match.params.group_id}`,

      {
        prevScr: prevScr,
      }
    );
  };

  const onSave = async () => {
    props.ToggleLoader(true);
    const grpId = props.match.params.group_id;
    const res = await props.getGroupInfo(grpId);
    const grpObj = res.data.data[0];
    let grpType = grpObj.special_classification;
    if (grpType !== "manual" && props.deletedProds.length !== 0) {
      grpType = "manual";
    }
    const body = {
      name: grpObj.name,
      group_type: grpType,
      channel: tableData.length > 0 ? tableData[0].channel : "", //For now keeping it as empty channel
      objective_metrics: grpObj?.selection_metadata?.objective_metrics,
      store_ids: {
        filters: [],
        meta: {
          search: [],
          range: [],
          sort: [],
        },
        metrics: [],
        selection: {
          data: tableInstance?.current?.api?.checkConfiguration,
          unique_columns: ["store_code"],
        },
      },
      store_group_ids: {
        filters: [],
        meta: {
          search: [],
          range: [],
          sort: [],
        },
        metrics: [],
        selection: {
          data: [],
          unique_columns: ["sg_code"],
        },
      },
    };
    try {
      await props.EditGroup(body, grpId);
      props.deletedRowsInEdit([]);
      props.addSnack({
        message: "Successfully updated",
        options: {
          variant: "success",
          onClose: () => history.push(prevScr ? prevScr : storeGrouping.home),
        },
      });
      props.ToggleLoader(false);
    } catch (error) {
      displaySnackMessages("Update Failed.Please try again", "error");
      props.ToggleLoader(false);
    }
  };
  const prevScr = props.location.state?.prevScr;

  const routeOptions = [
    {
      id: "product_grping_scr",
      label: "Store Grouping",
      action: () => history.push(prevScr ? prevScr : storeGrouping.home),
    },
    {
      id: "view Group",
      label: "View Group",
      action: () => null,
    },
  ];

  const getPromptStatus = (loc) => {
    const message = `Are you sure you want to go to ${loc.pathname}?`;
    if (
      (loc.pathname === storeGrouping.home ||
        loc.pathname.includes(storeGrouping.modifyGroup) ||
        !loc.pathname.includes("store-grouping")) &&
      props.deletedStores.length !== 0
    ) {
      return message;
    }
    return true;
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <>
      <Prompt
        message={(location) => {
          return getPromptStatus(location);
        }}
      />
      <LoadingOverlay loader={props.isLoading} spinner>
        <PageRouteTitles
          id="storeGrpingEditGrpBrdCrmbs"
          options={routeOptions}
        />
        <Container maxWidth={false}>
          <div
            className={`${globalClasses.layoutAlignSpaceBetween} ${globalClasses.verticalAlignCenter} ${globalClasses.marginBottom}`}
          >
            <Typography variant="h4">Edit Stores</Typography>
            <Typography variant="h4">
            Selected Group :{" "}
            <Typography variant="body1" component="span">
              {props.groupName}
            </Typography>
          </Typography>
            <Button
              onClick={() => onModify()}
              size="large"
              variant="contained"
              color="primary"
              id="storeGrpingEditGrpModifyBtn"
            >
              Modify
            </Button>
          </div>
          {props.columns.length > 0 && (
            <AgGridComponent
              columns={cloneDeep(props.columns)}
              selectAllHeaderComponent={true}
              sizeColumnsToFitFlag
              onGridChanged
              onRowSelected
              manualCallBack={(body, pageIndex, params) =>
                manualCallBack(body, pageIndex, params)
              }
              rowModelType="serverSide"
              serverSideStoreType="partial"
              cacheBlockSize={10}
              uniqueRowId={"store_code"}
              loadTableInstance={setNewTableInstance}
              suppressClickEdit={true}
              onSelectionChanged={selectionHandler}
            />
          )}
        </Container>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
        >
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={goBack}
            id="storeGrpingEditBckBtn"
          >
            Back
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onSave}
            id="storeGrpingEditSaveBtn"
          >
            Save
          </Button>
        </div>
      </LoadingOverlay>
    </>
  );
};

const mapActionsToProps = {
  getColumnsAg,
  setStoreGrpFilteredCols,
  fetchGroupStores,
  deletedRowsInEdit,
  getGroupInfo,
  EditGroup,
  ToggleLoader,
  addSnack,
  setSelectedGroupToEdit,
};
const mapStateToProps = (state) => {
  return {
    columns: state.storeGroupReducer.manualFilteredStoresCols,
    deletedStores: state.storeGroupReducer.deletedStoresInEdit,
    isLoading: state.storeGroupReducer.isLoading,
    groupName: state.storeGroupReducer.selectedGroupToEdit,
  };
};
export default connect(mapStateToProps, mapActionsToProps)(EditGrpProducts);
