import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import {
  getFilterDimensions,
  setFilterDimensions,
  getFilterModules,
  setFilterModules,
  ToggleLoader,
  addToSelDimen,
  delSelDimen,
  setSelModules,
  setSelDimens,
  getFilterfields,
  createNewFilterConfig,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
} from "../../../actions/filterAction";
import { addSnack, closeSnack } from "../../../actions/snackbarActions";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import ReactSelect from "../../../Utils/select";
import makeStyles from "@mui/styles/makeStyles";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ConfigName from "./configName";
import FormGroup from "@mui/material/FormGroup";
import Tabs from "commonComponents/tabs";
import {
  checkForEmptyDimensionFilters,
  flattenSelectedFiltersData,
  formatTabsArray,
} from "./common-functions";
const useStyles = makeStyles({
  dialogButtons: {
    background: "#F9F9F9",
    marginRight: "2%",
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    marginBottom: 20,
  },
  dimensions: {
    marginBottom: 15,
  },
  select: {
    width: "80%",
  },
  moduleTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
});
/**
 * Filter Modules
 * @param {*} param0
 */
const FilterModules = ({
  modules = [],
  setSelModules,
  selectedModules,
  _selectedDimensions,
}) => {
  const classes = useStyles();
  const getOptions = (items) => {
    return items.map((item) => {
      return {
        label: item.label,
        value: item.id,
      };
    });
  };
  const onChange = (options) => {
    setSelModules(options);
  };
  return (
    <>
      <Typography>Modules applicable to</Typography>
      <ReactSelect
        menuPortalTarget={document.querySelector(".MuiDialog-root")}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        className={classes.select}
        value={selectedModules}
        isMulti={true}
        isSearchable={false}
        options={getOptions(modules)}
        onChange={onChange}
        closeMenuOnSelect={false}
      />
    </>
  );
};
/**
 * Individual Dimension Component
 * @param {*} props
 */
const DimensionComponent = (props) => {
  const checked = props.currentSelection.some((item) => {
    return item.value === props.dimension.value;
  });
  return (
    <>
      <Checkbox
        checked={checked}
        value={props.dimension.value + ""}
        onChange={props.handleChange}
        disableRipple={true}
        icon={
          <Chip
            size="medium"
            style={{ padding: 15, fontSize: 16 }}
            label={props.dimension.label}
          />
        }
        checkedIcon={
          <Chip
            size="medium"
            color="primary"
            style={{ padding: 15, fontSize: 16 }}
            label={props.dimension.label}
          />
        }
      />
    </>
  );
};

/**
 *
 * @param {*} param0 dimensions Array
 */
const FilterDimensions = ({
  dimensions,
  addToSelDimen,
  delSelDimen,
  setSelDimens,
  selectedValue,
  _selectedModuleConfigData,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(
    selectedValue.length === 0 ? [] : selectedValue
  );
  const handleChange = (event) => {
    const Idx = dimensions.findIndex((dimension) => {
      return dimension.value + "" === event.target.value;
    });
    if (event.target.checked) {
      addToSelDimen(dimensions[Idx]);
    } else {
      delSelDimen(dimensions[Idx]);
    }
  };

  useEffect(() => {
    setValue(selectedValue);
  }, [selectedValue]);
  return (
    <>
      <FormControl classes={{ root: classes.dimensions }} component="fieldset">
        <Typography>Select filter Dimensions</Typography>
        <FormGroup row>
          {dimensions.map((dimension) => {
            return (
              <DimensionComponent
                currentSelection={value}
                dimension={dimension}
                addToSelDimen={addToSelDimen}
                delSelDimen={delSelDimen}
                setSelDimens={setSelDimens}
                handleChange={handleChange}
              />
            );
          })}
        </FormGroup>
        {/* </RadioGroup> */}
      </FormControl>
    </>
  );
};

const CreateNewConfig = (props) => {
  const classes = useStyles();
  return (
    <>
      <Typography classes={{ root: classes.title }} gutterBottom>
        Create New Config
      </Typography>
      <div style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}>
        <FilterDimensions
          dimensions={props.filterDimensions}
          addToSelDimen={props.addToSelDimen}
          delSelDimen={props.delSelDimen}
          setSelDimens={props.setSelDimens}
          selectedValue={props.selectedDimension}
          selectedModuleConfigData={props.selectedModuleConfigData}
        />
        <FilterModules
          selectedDimensions={props.selectedDimension}
          modules={props.filterModules}
          setSelModules={props.setSelModules}
          selectedModules={props.selectedModules}
        />
      </div>
    </>
  );
};
/**
 * Modal Component
 * @param {*} props
 */
const CreateModal = (props) => {
  const [createFilterConfigLoader, setcreateFilterConfigLoader] =
    React.useState(false);
  const [currentScr, setcurrentScr] = React.useState(0);
  const [maxWidth, setmaxWidth] = React.useState("xs");
  const classes = useStyles();
  const goToNext = () => {
    if (currentScr < 2) {
      setcurrentScr(currentScr + 1);
    }
    props.closeSnack();
  };
  const handleClose = () => {
    props.handleClose();
    props.resetFilterConfigSelections();
  };

  const showError = (errorMsg) => {
    props.addSnack({
      message: errorMsg,
      options: {
        variant: "error",
      },
    });
  };

  const fetchFromMasterTable = () => {
    if (
      props.selectedDimension.length === 0 ||
      props.selectedModules.length === 0
    ) {
      const msg = "please select dimensions and modules to proceed";
      showError(msg);
      return;
    }
    goToNext();
  };

  const saveFilters = () => {
    if (
      checkForEmptyDimensionFilters(
        props.selectedModuleConfigData,
        props.selectedDimension
      )
    ) {
      const msg = "Please add atleast one filter for the chosen dimension(s)";
      showError(msg);
      return;
    }
    goToNext();
  };
  const saveConfig = async () => {
    if (props.configName === "") {
      const msg = "please add a name to the configuration";
      showError(msg);
      return;
    }
    const screens = props.selectedModules.map((module) => {
      return module.value.toLowerCase();
    });
    const elements = flattenSelectedFiltersData(props.selectedModuleConfigData);
    const configJSON = {
      name: props.configName,
      screens: screens,
      elements: elements,
    };
    try {
      setcreateFilterConfigLoader(true);
      await props.createNewFilterConfig(props.id, configJSON);
      const body = {
        filters: {
          search: [],
          sort: [],
          range: [],
        },
      };
      props.getFilterConfigsTableData(body);
      props.addSnack({
        message: "Configuration created successfully",
        options: {
          variant: "success",
        },
      });
      setcreateFilterConfigLoader(false);
      handleClose();
    } catch (error) {
      const msg = "Failed to add Configuration";
      showError(msg);
      setcreateFilterConfigLoader(false);
    }
  };
  const contentMap = {
    0: <CreateNewConfig {...props} />,
    1: (
      <>
        <Typography classes={{ root: classes.moduleTitle }}>
          New Configuration
        </Typography>
        <Tabs tabsData={formatTabsArray(props.selectedDimension, props.id)} />
      </>
    ),
    2: <ConfigName />,
  };

  const buttonGrpMap = {
    0: (
      <>
        {" "}
        <Button
          onClick={handleClose}
          color="primary"
          disabled={createFilterConfigLoader}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          color="primary"
          variant="contained"
          onClick={fetchFromMasterTable}
          disabled={createFilterConfigLoader}
        >
          Fetch From master Table
        </Button>
      </>
    ),
    1: (
      <>
        {" "}
        <Button
          onClick={handleClose}
          color="primary"
          disabled={createFilterConfigLoader}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          color="primary"
          variant="contained"
          disabled={createFilterConfigLoader}
          onClick={saveFilters}
        >
          Save Filters
        </Button>
      </>
    ),
    2: (
      <>
        {" "}
        <Button
          disabled={createFilterConfigLoader}
          onClick={handleClose}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          disabled={createFilterConfigLoader}
          autoFocus
          color="primary"
          variant="contained"
          onClick={saveConfig}
        >
          Save
        </Button>
      </>
    ),
  };

  React.useEffect(() => {
    setcreateFilterConfigLoader(props.showModalLoader);
  }, [props.showModalLoader]);
  React.useEffect(() => {
    const fetchData = async (scrIdx) => {
      switch (scrIdx) {
        case 0:
          setmaxWidth("sm");
          setcreateFilterConfigLoader(true);
          const dimensions_data = await props.getFilterDimensions(props.id);
          props.setFilterDimensions(
            dimensions_data.data.data.map((dimension) => {
              return {
                label: dimension.name,
                value: dimension.name,
              };
            })
          );
          setcreateFilterConfigLoader(false);
          return;
        case 1:
          setmaxWidth("lg");
          props.getFilterfields(
            props.selectedDimension.map((dimension) => dimension.value),
            props.selectedModules.map((module) => {
              return module.value.toLowerCase();
            })
          );
          return;
        case 2:
          setmaxWidth("xs");
          return;
        default:
          return;
      }
    };
    fetchData(currentScr);
  }, [currentScr]);
  React.useEffect(() => {
    const fetchScreenModules = async () => {
      setcreateFilterConfigLoader(true);
      props.setSelModules([]);
      if (props.selectedDimension.length === 0) {
        props.setFilterModules([]);
        return;
      }
      const modules_data = await props.getFilterModules(
        props.selectedDimension.map((dimension) => dimension.value)
      );
      props.setFilterModules(
        modules_data.data.data.map((module) => {
          return {
            label: module.attribute,
            id: module.attribute,
          };
        })
      );
      setcreateFilterConfigLoader(false);
    };
    fetchScreenModules();
  }, [props.selectedDimension]);
  return (
    <Dialog
      id="filterDialog"
      aria-labelledby="customized-dialog-title"
      open={props.open}
      maxWidth={maxWidth}
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={(_event, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        props.handleClose();
      }}
      classes={{
        paperFullWidth: classes.paperFullWidth,
      }}
    >
      {createFilterConfigLoader && <LinearProgress />}
      <DialogContent
        dividers
        classes={{
          root: classes.dialogContentRoot,
        }}
      >
        {contentMap[currentScr]}
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogButtons }}>
        {buttonGrpMap[currentScr]}
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state) => {
  return {
    showModalLoader: state.filterElementsReducer.showModalLoader,
    filterDimensions: state.filterElementsReducer.filterDimensions,
    filterModules: state.filterElementsReducer.filterModules,
    selectedDimension: state.filterElementsReducer.selectedDimensions,
    selectedModules: state.filterElementsReducer.selectedModules,
    configName: state.filterElementsReducer.configName,
    selectedModuleConfigData:
      state.filterElementsReducer.selectedModuleConfigData,
    createdConfigs: state.filterElementsReducer.createdConfigs,
  };
};

const mapActionsToProps = {
  getFilterDimensions,
  setFilterDimensions,
  getFilterModules,
  setFilterModules,
  addToSelDimen,
  delSelDimen,
  setSelModules,
  setSelDimens,
  getFilterfields,
  ToggleLoader,
  createNewFilterConfig,
  addSnack,
  closeSnack,
  resetFilterConfigSelections,
  getFilterConfigsTableData,
};
const ConnectedCreateModal = connect(
  mapStateToProps,
  mapActionsToProps
)(CreateModal);

/**
 * Create Filter
 * @param {*} props
 */
const CreateFilter = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Create New
      </Button>
      {open && (
        <ConnectedCreateModal
          open={open}
          handleClose={handleClose}
          id={props.id}
        />
      )}
    </>
  );
};

export default CreateFilter;
