export const DASHBOARD = "/generic-inventory-smart/decision-dashboard";
export const WHATIF = "/generic-inventory-smart/what-if";
export const CREATE_ALLOCATION = "/generic-inventory-smart/create-allocation";
export const FINALIZE_ALLOCATION =
  "/generic-inventory-smart/finalize-allocation";
export const PRODUCT_PROFILE = "/generic-inventory-smart/product-profile";
export const CREATE_PRODUCT_PROFILE =
  "/generic-inventory-smart/create-product-profile";
export const GROUPING =
  "/generic-inventory-smart/grouping";
export const CONFIGURATION = "/generic-inventory-smart/configuration";
export const CONSTRAINTS = "/generic-inventory-smart/constraints";
export const PRODUCT_CONFIGURATION =
  "/generic-inventory-smart/product-configuration";
export const SETTINGS = "/generic-inventory-smart/settings";
export const ALLOCATION_REPORT = "/generic-inventory-smart/allocation-reports";
export const ORDER_BATCHING =
  "/generic-inventory-smart/view-current-allocations";
export const VIEW_PAST_ALLOCATION =
  "/generic-inventory-smart/view-past-allocations";
export const ORDER_MANAGEMENT = "/generic-inventory-smart/order-management";
export const ADA_VISUAL = "/generic-inventory-smart/ada-visuals";
export const ORDER_REPOSITORY = "/generic-inventory-smart/order-repository";
export const ORDER_REPOSITORY_WHATIF =
  "/generic-inventory-smart/order-repository-whatif";
export const CREATE_NEW_ORDER = "/generic-inventory-smart/create_new_order";
