import React, { useState } from "react";
import { connect } from "react-redux";
import globalStyles from "Styles/globalStyles";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";

export const ReviewGrading = (props) => {
  const globalClasses = globalStyles();
  const [viewBy, setViewBy] = useState("grade-view");

  const gotoPreviousStep = () => {};
  const gotoNextStep = () => {};

  return (
    <>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.layoutAlignSpaceBetween}`}
      >
        <RadioGroup
          row
          aria-label="view-by"
          name="controlled-radio-buttons-group"
          value={viewBy}
          onChange={(e) => {
            setViewBy(e.target.value);
          }}
        >
          <FormControlLabel
            value="grade-view"
            control={<Radio color="primary" />}
            label="Grade View"
          />
          <FormControlLabel
            value="store-view"
            control={<Radio color="primary" />}
            label="Store View"
          />
        </RadioGroup>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.centerAlign} ${globalClasses.gap}`}
        >
          <Tooltip title="Upload">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {}}
              size="small"
            >
              <PublishOutlinedIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Download">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {}}
              size="small"
            >
              <DownloadOutlinedIcon />
            </Button>
          </Tooltip>
          <Button color="primary" variant="contained" onClick={() => {}}>
            Update With Rules
          </Button>
        </div>
      </div>
      <div>View the Table</div>
      <div className={`${globalClasses.flexRow} ${globalClasses.gap}`}>
        <Button color="primary" variant="outlined" onClick={gotoPreviousStep}>
          Back
        </Button>
        <Button color="primary" variant="contained" onClick={gotoNextStep}>
          Done
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewGrading);
