import { createSelector, createSlice } from "@reduxjs/toolkit";
import { addSnack } from "actions/snackbarActions";
import {
  skuColDefResp,
  skuDataResp,
} from "modules/plansmart/utils-plansmart/skuPayload";
import axiosInstance from "../../../../Utils/axios/index";
import {
  PLANSMART_BUDGET_PLAN_DETAILS,
  PLANSMART_BUDGETTABLE_PLANCODE_DETAILS,
  PLANSMART_PLANNING_COLUMN_CONF,
  PLANSMART_PLAN_HIERARCHIES,
  PLANSMART_PLAN_FILTER_OPTIONS,
  PLANSMART_FORECASTED_DATA,
  PLANSMART_UPDATE_BUDGET_TABLE,
  PLANSMART_SAVE_PLAN,
  PLANSMART_PLAN_METRIC_CONFIG,
  PLANSMART_PIVOT_VIEW_DATA,
  PLANSMART_PIVOT_VIEW_COL_DEF,
  PLANSMART_UPDATE_BUDGET_TABLE_MATCH_WITH,
  PLANSMART_PIVOT_VIEW_SAVE_TEMPLATE,
  PLANSMART_SKU_VIEW_COL_DEF,
  PLANSMART_SKU_VIEW_DATA,
  PLANSMART_EDITABLE_METRIC_FETCH_FORMULA,
} from "../../constants-plansmart/apiConstants";

export const planSmartBudgetTableLoader = createSlice({
  name: "planSmartBudgetTable",
  initialState: {
    plansmartBudgetTableLoader: false,
    planSmartBudgetFilterLoader: false,
    planSmartBudgetUpdateLoader: false,
    planSmartSavePlanLoader: false,
    planSmartSavePivotViewLoader: false,
    planSmartUpdateBudgetTableMatchLoader: false,
    planSmartBudgetTableColDefLoader: false,
    editableMetricFormulas: {},
  },
  reducers: {
    setPlansmartBudgetTableLoader: (state, action) => {
      state.plansmartBudgetTableLoader = action.payload;
    },
    setPlansmartBudgetUpdateLoader: (state, action) => {
      state.planSmartBudgetUpdateLoader = action.payload;
    },
    setPlansmartBudgetFilterLoader: (state, action) => {
      state.planSmartBudgetFilterLoader = action.payload;
    },
    setPlanSmartSavePlanLoader: (state, action) => {
      state.planSmartSavePlanLoader = action.payload;
    },
    setPlanSmartSavePivotViewLoader: (state, action) => {
      state.planSmartSavePivotViewLoader = action.payload;
    },
    setPlanSmartUpdateBudgetTableMatchLoader: (state, action) => {
      state.planSmartUpdateBudgetTableMatchLoader = action.payload;
    },
    setPlanSmartBudgetTableColDefLoader: (state, action) => {
      state.planSmartBudgetTableColDefLoader = action.payload;
    },
    setEditableMetricsFormula: (state, action) => {
      state.editableMetricFormulas = action.payload;
    },
  },
});

export const planSmartBudgetTableSelector = createSelector(
  (state) => state,
  (state) => state.plansmartReducer.planBudgetTableReducer
);
export const planBudgetTableLoader = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.planBudgetTableLoader
);

export const planSmartBudgetUpdateLoader = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.planSmartBudgetUpdateLoader
);

export const plansmartBudgetFilterLoader = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.plansmartBudgetFilterLoader
);

export const planSmartSavePlanLoaderSelector = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.planSmartSavePlanLoader
);

export const planSmartSavePivotViewLoaderSelector = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.planSmartSavePivotViewLoader
);

export const planSmartBudgetTableColDefLoaderSelector = createSelector(
  planSmartBudgetTableSelector,
  (state) => state.planSmartBudgetTableColDefLoader
);

// Action creators are generated for each case reducer function

export const {
  setPlansmartBudgetFilterLoader,
  setPlansmartBudgetTableLoader,
  setPlansmartBudgetUpdateLoader,
  setPlanSmartSavePlanLoader,
  setPlanSmartSavePivotViewLoader,
  setPlanSmartUpdateBudgetTableMatchLoader,
  setPlanSmartBudgetTableColDefLoader,
  setEditableMetricsFormula,
} = planSmartBudgetTableLoader.actions;

// Actions
export const getPlanSmartPlanDetails = (plancode) => async () => {
  return axiosInstance({
    url: `${PLANSMART_BUDGET_PLAN_DETAILS}/${plancode}`,
    method: "GET",
  });
};
export const getPlanningTableColumns = (plancode, action) => async () => {
  if (action === "view") {
    return axiosInstance({
      url: `${PLANSMART_PLANNING_COLUMN_CONF}/view/${plancode}`,
      method: "GET",
    });
  } else {
    return axiosInstance({
      url: `${PLANSMART_PLANNING_COLUMN_CONF}/${plancode}`,
      method: "GET",
    });
  }
};
export const getPlanHierarchies = () => async () => {
  return axiosInstance({
    url: `${PLANSMART_PLAN_HIERARCHIES}`,
    method: "GET",
  });
};
export const getMetricsConfig = () => async () => {
  return axiosInstance({
    url: `${PLANSMART_PLAN_METRIC_CONFIG}`,
    method: "GET",
  });
};
export const fetchPlanBudgetDetails = (postBody) => async () => {
  return axiosInstance({
    url: PLANSMART_BUDGETTABLE_PLANCODE_DETAILS,
    method: "POST",
    data: postBody,
  });
};
export const getPlanFilterDropdownOptions = (id, postBody) => async () => {
  return axiosInstance({
    url: `${PLANSMART_PLAN_FILTER_OPTIONS}${id}`,
    method: "POST",
    data: postBody,
  });
};
export const updateForecastedData = (planCode, postBody) => async () => {
  return axiosInstance({
    url: `${PLANSMART_FORECASTED_DATA}${planCode}`,
    method: "GET",
  });
};

export const updateBudgetTableData = (postBody) => async () => {
  return axiosInstance({
    url: `${PLANSMART_UPDATE_BUDGET_TABLE}`,
    method: "PUT",
    data: postBody,
  });
};

export const savePlanAPI = (planCode, status) => async () => {
  return axiosInstance({
    url: `${PLANSMART_SAVE_PLAN}/${planCode}?save_type=${status}`,
    method: "POST",
  });
};

export const getPivotDataAPI = (payload) => async () => {
  return axiosInstance({
    url: PLANSMART_PIVOT_VIEW_DATA,
    method: "POST",
    data: payload,
  });
};

export const getPivotColDefAPI = (planCode) => async () => {
  return axiosInstance({
    url: `${PLANSMART_PIVOT_VIEW_COL_DEF}/${planCode}`,
    method: "GET",
  });
};

export const updateBudgetTableDataMatchWith = (postBody) => async (
  dispatch
) => {
  try {
    const response = await axiosInstance({
      url: `${PLANSMART_UPDATE_BUDGET_TABLE_MATCH_WITH}`,
      method: "PUT",
      data: postBody,
    });
    if (response.data.status) {
      dispatch(
        addSnack({
          message: "Budget table updated successfully",
          options: {
            variant: "success",
          },
        })
      );
    } else {
      dispatch(
        addSnack({
          message: response.data.message || "Error while updating budget table",
          options: {
            variant: "error",
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      addSnack({
        message: "Something went wrong please try again later",
        options: {
          variant: "error",
        },
      })
    );
  }
};

export const savePivotView = (planCode, reqBody) => async (dispatch) => {
  dispatch(setPlanSmartSavePivotViewLoader(true));
  try {
    const response = await axiosInstance({
      url: `${PLANSMART_PIVOT_VIEW_SAVE_TEMPLATE}/${planCode}`,
      method: "POST",
      data: reqBody,
    });
    if (response.data.status) {
      dispatch(
        addSnack({
          message: "Pivot view saved successfully",
          options: {
            variant: "success",
          },
        })
      );
    } else {
      dispatch(
        addSnack({
          message: response.data.message || "Error while saving pivot view",
          options: {
            variant: "error",
          },
        })
      );
    }
  } catch (error) {
    dispatch(
      addSnack({
        message: "Something went wrong please try again later",
        options: {
          variant: "error",
        },
      })
    );
  }
  dispatch(setPlanSmartSavePivotViewLoader(false));
};

export const getSkuColDef = (plancode, action) => async () => {
  return skuColDefResp;
  if (action === "view") {
    return axiosInstance({
      url: `${PLANSMART_SKU_VIEW_COL_DEF}/view/${plancode}`,
      method: "GET",
    });
  } else {
    return axiosInstance({
      url: `${PLANSMART_SKU_VIEW_COL_DEF}/${plancode}`,
      method: "GET",
    });
  }
};

export const fetchPlanBudgetSkuDetails = (postBody) => async () => {
  return skuDataResp;
  return axiosInstance({
    url: PLANSMART_SKU_VIEW_DATA,
    method: "POST",
    data: postBody,
  });
};

export const fetchFormulasForEditableMetrics = () => async () => {
  return axiosInstance({
    url: PLANSMART_EDITABLE_METRIC_FETCH_FORMULA,
    method: "GET",
  });
};

export default planSmartBudgetTableLoader.reducer;
