import { createSelector, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../Utils/axios/index";
import {
  PLANSMART_DASHBOARD_COLUMN_DATA,
  PLANSMART_DASHBOARD_TABLE_DATA,
  PLANSMART_DASHBOARD_FILTER_CONFIG,
  PLANSMART_DELETE_PLAN,
  PLANSMART_COPY_PLAN,
  PLANSMART_BUDGETTABLE_PLANCODE_DETAILS,
  PLANSMART_PLANNING_COLUMN_CONF,
} from "../../constants-plansmart/apiConstants";

export const planSmartDashboardService = createSlice({
  name: "planSmartDashboardService",
  initialState: {
    plansmartDashboardLoader: false,
    plansmartFilterLoader: false,
    plansmartCopyPlanLoader: false,
    plansmartDashboardData: [],
  },
  reducers: {
    setPlansmartDashboardLoader: (state, action) => {
      state.plansmartDashboardLoader = action.payload;
    },
    setPlansmartFilterLoader: (state, action) => {
      state.plansmartFilterLoader = action.payload;
    },
    setPlansmartCopyPlanLoader: (state, action) => {
      state.plansmartCopyPlanLoader = action.payload;
    },
    setPlansmartDashboardData: (state, action) => {
      state.plansmartDashboardData = action.payload;
    },
  },
});

// Selectors
export const planSmartDashboardSelector = createSelector(
  (state) => state,
  (state) => state.plansmartReducer.planDashboardReducer
);

export const planSmartCopyLoaderSelector = createSelector(
  planSmartDashboardSelector,
  (state) => state.plansmartCopyPlanLoader
);

// Action creators are generated for each case reducer function
export const {
  setPlansmartDashboardLoader,
  setPlansmartFilterLoader,
  setPlansmartCopyPlanLoader,
  setPlansmartDashboardData,
} = planSmartDashboardService.actions;

export const getPlanSmartDashboardColumns = (postBody) => (dispatch) => {
  return axiosInstance({
    url: PLANSMART_DASHBOARD_COLUMN_DATA,
    method: "GET",
    data: postBody,
  });
};

export const getPlanSmartDashboardData = (postBody) => (dispatch) => {
  return axiosInstance({
    url: PLANSMART_DASHBOARD_TABLE_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getFilterConfiguration = () => (dispatch) => {
  return axiosInstance({
    url: PLANSMART_DASHBOARD_FILTER_CONFIG,
    method: "GET",
  });
};

export const deletePlanSmartPlanAPI = (body) => (dispatch) => {
  return axiosInstance({
    url: PLANSMART_DELETE_PLAN,
    method: "DELETE",
    data: body,
  });
};

export const copyPlanAPI = (planCode, seasonType, body) => () => {
  return axiosInstance({
    url: `${PLANSMART_COPY_PLAN}/${planCode}?season_type=${seasonType}`,
    method: "POST",
    data: body,
  });
};
export const getPlanningTableColumns = (plancode) => async () => {
  return axiosInstance({
    url: `${PLANSMART_PLANNING_COLUMN_CONF}/${plancode}`,
    method: "GET",
  });
};

export const fetchPlanBudgetDetails = (postBody) => async () => {
  return axiosInstance({
    url: PLANSMART_BUDGETTABLE_PLANCODE_DETAILS,
    method: "POST",
    data: postBody,
  });
};

export default planSmartDashboardService.reducer;
