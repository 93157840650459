import { isEmpty } from "lodash";
import store from "store";
import { attributeFormatter } from "../modules/react-demo/utils/utilityFunctions";

export const dynamicLabelsBasedOnTenant = (key, application) => {
  if (!isEmpty(store?.getState())) {
    let dynamicLabels =
      application === "core"
        ? store.getState()?.tenantConfigReducer?.["coreScreenNames"]?.[
            "attribute_value"
          ]?.dynamicLabels
        : store.getState()?.inventorysmartReducer?.inventorySmartCommonService
            ?.inventorysmartScreenConfig?.dynamicLabels; // To be made more expansive for other products

    return dynamicLabels?.[key] || attributeFormatter(key); //If in case that config isnt present on the DB layer, then display the key itself
  } else return "";
};
