import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../Utils/axios";
import {
  GET_RECEIPT_DRAWER_DATA,
  GET_BOP_L3_LEVEL_DATA,
  GET_BOP_CHOICE_LEVEL_DATA,
} from "../../../constants/apiConstants";

export const receiptDrawerBopService = createSlice({
  name: "receiptDrawerBopService",
  initialState: {
    loaderReceiptDrawer: false,
    loaderBOP: false,
  },
  reducers: {
    setReceiptDrawerLoader: (state, action) => {
      state.loaderReceiptDrawer = action.payload;
    },
    setBOPLoader: (state, action) => {
      state.loaderBOP = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setReceiptDrawerLoader,
  setBOPLoader,
} = receiptDrawerBopService.actions;

export const getReceiptDrawerData = (postBody) => () => {
  return axiosInstance({
    url: GET_RECEIPT_DRAWER_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getBopL3LevelData = (postBody) => () => {
  return axiosInstance({
    url: GET_BOP_L3_LEVEL_DATA,
    method: "POST",
    data: postBody,
  });
};

export const getBopChoiceLevelData = (postBody) => () => {
  return axiosInstance({
    url: GET_BOP_CHOICE_LEVEL_DATA,
    method: "POST",
    data: postBody,
  });
};

export default receiptDrawerBopService.reducer;
