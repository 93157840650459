import React from "react";
import Tabs from "commonComponents/tabs";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import globalStyles from "Styles/globalStyles";

export default function CreateNewAllocation() {
  const globalClasses = globalStyles();

  return (
    <div>
      <HeaderBreadCrumbs
        options={[
          {
            label: "Create new allocation",
            id: 1,
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "85vh" }}
          // data="https://sandbox.inventory.impactsmartsuite.com/inventory-smart/new-product-allocation"
          data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
        ></object>
      </div>
    </div>
  );
}
