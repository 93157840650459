import React, { useState, useEffect } from "react";
import { Checkbox, Link, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReactSelect from "../../select";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Switch from "@mui/material/Switch";
import InputCell from "../cellRendres/inputCell";
import DeleteCell from "../cellRendres/DeleteCell";
import EditCell from "../cellRendres/EditCell";
import ChartCell from "../cellRendres/chartCell";
import ReviewCell from "../cellRendres/reviewCell";
import DownloadCell from "../cellRendres/DownloadCell";
const useStyles = makeStyles(() => ({
  TextField: {
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100 %",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      padding: "0px",
    },
    "& .MuiInputBase-input": {
      padding: "5px",
      paddingRight: "1px",
    },
    "& .MuiIconButton-root": {
      paddingLeft: "1px",
    },
    "& .MuiOutlinedInput-root": {
      minHeight: "38px",
    },
  },

  checkbox: {
    padding: "0px",
  },
  label: {
    color: "black",
  },
  link: {
    cursor: "pointer",
    color: "#5295e1",
  },
  datePicker: {
    width: "100%",
    "& .MuiInputBase-input": {
      padding: "10px",
    },
  },
}));

const CellRenderer = (props) => {
  const classes = useStyles();
  const {
    value: initialValue,
    row,
    row: { index, id },
    column,
    updateMyData,
    getSelectedRowIds,
    callDeleteApi,
    onEditClick,
    onChartClick,
    onDownloadClick,
    ...wholeData
    // This is a custom function that we supplied to our table instance
  } = props.cellData;
  const [value, setValue] = useState(initialValue);
  const [flag_edit, setFlagEdit] = useState(false);

  const handleChange = (e, type) => {
    setFlagEdit(true);
    if (type === "bool" || type === "ToogleField") {
      setValue(e?.target?.checked);
    } else if (type === "datetime") {
      setValue(e);
    } else if (type === "list") {
      if (column.isMulti) {
        let newValue = e.map((opt) => opt.value);
        setValue([...newValue]);
      } else {
        setValue(e?.value);
      }
    } else {
      setValue(e?.target?.value);
    }
  };

  const onBlur = () => {
    if (flag_edit) {
      let revertChanges = updateMyData(
        index,
        column.id,
        value,
        initialValue,
        column,
        wholeData,
        row,
        id
      );
      if (revertChanges) {
        setValue(initialValue);
      }
      setFlagEdit(false);
    }
  };

  useEffect(() => {
    //This useeffect is temporary till we find a solution for material ui
    //date picker not having onBlur function
    //Due to that whenever there is a change we are using this useeffect to trigger
    //onBlur function
    if (props.column.type === "datetime") {
      onBlur();
    }
  }, [value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue, props.cellData?.data]);

  const renderForm = (item) => {
    switch (item.type) {
      case "list":
        return (
          <ReactSelect
            {...props.extraProps}
            menuPosition={"fixed"}
            menuShouldBlockScroll={true}
            name={item.accessor}
            onBlur={onBlur}
            isMulti={column.isMulti}
            isSearchable={false}
            // pagination={column.pagination}
            // fetchOptions={column.fetchOptions}
            options={
              (item.options &&
                item.options.map((option) => {
                  return {
                    label: option.label || option.name,
                    value: option.value,
                  };
                })) ||
              []
            }
            value={
              value
                ? column.isMulti
                  ? item.options
                      ?.filter((option) => value.indexOf(option.value) > -1)
                      .map((opt) => {
                        return {
                          label: opt.label || opt.name,
                          value: opt.value,
                        };
                      }) || value
                  : item.options
                      ?.filter((option) => {
                        return option.value === value;
                      })
                      .map((opt) => {
                        return {
                          label: opt.label || opt.name,
                          value: opt.value,
                        };
                      })[0] || value
                : ""
            }
            data-testid={`select${item.name}`}
            onChange={(option) => handleChange(option, item.type)}
            isDisabled={
              (row.original && row.original.disableEditSelection) ||
              !item.is_editable
                ? true
                : false
            }
          />
        );
      case "bool":
        return (
          <Checkbox
            name={item.accessor}
            color="primary"
            onBlur={onBlur}
            className={classes.checkbox}
            checked={value || false}
            disabled={
              wholeData.isDisabled ||
              row.original.disableEditSelection ||
              !item.is_editable
                ? true
                : false
            }
            onChange={(e) => {
              handleChange(e, item.type);
            }}
          />
        );
      case "datetime":
        return (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {" "}
            <DatePicker
              disableToolbar
              variant="inline"
              onClose={onBlur}
              inputVariant="outlined"
              inputFormat="MM-DD-YYYY"
              className={classes.datePicker}
              id="date-picker"
              value={value || null}
              onChange={(e) => {
                handleChange(e, item.type);
              }}
              renderInput={(props) => <TextField {...props} />}
            />
          </LocalizationProvider>
        );
      case "ToogleField":
        return (
          <Switch
            checked={value || false}
            onChange={(e) => {
              handleChange(e, item.type);
            }}
            name={item.accessor}
            onBlur={onBlur}
            color="primary"
            disabled={
              row.original.disableApproveSelection
                ? true
                : typeof item.disabled === "function"
                ? item.disabled(row, item)
                : item.disabled
            }
          />
        );
      case "link":
        return (
          <>
            {value ? (
              <Link
                onClick={() => props.column.onClick(props)}
                className={classes.link}
                underline="hover"
              >
                {value}
              </Link>
            ) : (
              "-"
            )}
          </>
        );
      case "percentage":
        return (
          <InputCell
            type="number"
            inputType="percentage"
            className={classes.TextField}
            onInputChange={handleChange}
            disabled={
              typeof item.disabled === "function"
                ? item.disabled(row, item)
                : item.disabled
            }
            {...props.cellData}
            isRoundOffTwoDecimals={
              item.formatter === "roundOfftoTwoDecimals" ? true : false
            }
            isWhole={item.formatter === "roundOff" ? true : false}
          ></InputCell>
        );
      case "int":
        return (
          <InputCell
            type="number"
            showEmptyOnZero={item.showEmptyOnZero}
            showEmptyOnNull={item.showEmptyOnNull}
            onInputChange={handleChange}
            {...props?.cellData}
            className={classes.TextField}
            isWhole={item.formatter === "roundOff" ? true : false}
          ></InputCell>
        );
      case "str":
        return (
          <InputCell
            type="text"
            onInputChange={handleChange}
            {...props?.cellData}
            className={classes.TextField}
            formatter={item.formatter}
          ></InputCell>
        );
      case "float":
        return (
          <InputCell
            type="number"
            showEmptyOnZero={item.showEmptyOnZero}
            onInputChange={handleChange}
            {...props?.cellData}
            className={classes.TextField}
            isRoundOffTwoDecimals={
              item.formatter === "roundOfftoTwoDecimals" ? true : false
            }
            isWhole={item.formatter === "roundOff" ? true : false}
          ></InputCell>
        );
      case "dollar":
        return (
          <InputCell
            type="number"
            inputType={"dollar"}
            onInputChange={handleChange}
            {...props?.cellData}
            className={classes.TextField}
            isRoundOffTwoDecimals={
              item.formatter === "roundOfftoTwoDecimals" ? true : false
            }
            isWhole={item.formatter === "roundOff" ? true : false}
          ></InputCell>
        );
      case "delete_icon":
        return (
          <div>
            <DeleteCell
              field={"uniqueID"}
              onDeleteClick={() => callDeleteApi(props.cellData)}
              {...props?.cellData}
            ></DeleteCell>
          </div>
        );
      case "edit_icon":
        return (
          <EditCell onEditClick={onEditClick} {...props?.cellData}></EditCell>
        );
      case "review_icon":
        return (
          <ReviewCell
            onReviewClick={onEditClick}
            {...props?.cellData}
          ></ReviewCell>
        );
      case "chart_icon":
        return (
          <div>
            <ChartCell
              title={item.label}
              onChartClick={(ins) => onChartClick(ins)}
              {...props?.cellData}
            />
          </div>
        );
      case "download_icon":
        return (
          <DownloadCell
            onDownloadClick={onDownloadClick}
            {...props?.cellData}
          ></DownloadCell>
        );
      default:
        return <div>{value || ""}</div>;
    }
  };

  return <>{renderForm(props.column)}</>;
};

export default CellRenderer;
