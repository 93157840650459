import React, { useEffect, lazy, useState } from "react";
import { connect } from "react-redux";
import Layout from "../layout";
import { withRouter } from "react-router-dom";
import { APP_PLATFORM } from "config/constants";
import layoutActions from "actions/layoutActions";
import * as Icons from "@mui/icons-material/";
import "../layout/layout.css";
import ProductUnitDefinition from "pages/product-unit-definition";
import CreateNewUnitDefinition from "pages/product-unit-definition/components/createnew";
import GroupDefintions from "pages/product-grouping/components/group-definition-components/groupdefinitions";
import CreateDefintion from "pages/product-grouping/components/group-definition-components/creategrpdefinition";
import EditDefinition from "pages/product-grouping/components/group-definition-components/editdefinition";
import CreateProductGroup from "pages/product-grouping/components/product-group-components/createGroup";
import GroupDefMapper from "pages/product-grouping/components/product-group-components/groupdefinitionMapper";
import GroupDefEdit from "pages/product-grouping/components/group-definition-components/definitionmapedit";
import EditUnitDefinition from "pages/product-unit-definition/components/editDefinition";
import EditGrpProducts from "pages/product-grouping/components/product-group-components/editGrpProducts";
import ModifyGroup from "pages/product-grouping/components/product-group-components/modifyGroup";
import CreateStoreGroup from "pages/store-grouping/components/createGroup";
import ModifyStoreGroup from "pages/store-grouping/components/modifyGroup";
import EditGrpStores from "pages/store-grouping/components/editGrpStores";
import CreateStoreGrade from "pages/store-grading/components/createGrade";
import DCStatusScreen from "pages/dc-status";
import GenericAppConfig from "../../pages/tenant-config/generic-application-config/index";
import Events from "pages/notifications/components/event-configuration";
import UserMangement from "pages/tenant-config/access-user-management";
import UserRoleManagement from "pages/tenant-config/access-user-management/components/assignRole/assign-role";
import Notification from "pages/notifications";
import ReleaseNotes from "pages/release-notes";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import { getSpecificScreenName } from "actions/tenantConfigActions";

const Filters = lazy(() => import("pages/filters"));
const ProductMapping = lazy(() => import("pages/product-mapping"));
const Inventory = lazy(() => import("pages/inventory"));
const Configurator = lazy(() => import("pages/configurator"));

const Product_Grouping_Screen = lazy(() => import("pages/product-grouping"));
const Store_Grouping_Screen = lazy(() => import("pages/store-grouping"));
const ProductFilter = lazy(() => import("pages/productStatus"));

const storeFilter = lazy(() => import("pages/storeStatus"));
const StoreGrading = lazy(() => import("pages/store-grading"));
const storeMapping = lazy(() => import("pages/storeMapping"));
const dcMapping = lazy(() => import("pages/dcmapping"));

const vendorStatusScreen = lazy(() => import("pages/vendor-status"));
const vendorProductScreen = lazy(() => import("pages/vendor-product"));

export const uamSideBarOptions = [
  {
    link: "/user-management",
    title: "User Management",
    icon: React.createElement(Icons.ListAlt),
    order: 1,
    disabled: false,
  },
  {
    link: "/tenantconfig/applicationconfig",
    title: "Tenant Management",
    icon: React.createElement(Icons.MapRounded),
    order: 2,
    disabled: false,
  },
];

export const moduleConfigurationSideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + "/configurator/home",
    title: "IA Smart Configurator",
    icon: React.createElement(Icons.ListAlt),
    order: 1,
    disabled: false,
  },
  {
    link:
      "/" +
      window.location.pathname.split("/")[1] +
      "/configurator/" +
      window.location.pathname.split("/")[2] +
      "/module-config",
    title: "Module Config",
    icon: React.createElement(Icons.ListAlt),
    order: 2,
    disabled: false,
  },
];

export const notificationSideBarOptions = [
  {
    link: "/events",
    title: "Notifications",
    icon: React.createElement(Icons.NotificationsActive),
    order: 1,
  },
];
const CoreLayout = (props) => {
  const [coreRoutes, setCoreRoutes] = useState([]);
  const [options, setOptions] = useState({
    "workflow input center": [],
    "application access management": [],
    "module configurator": [],
  });

  useEffect(() => {
    //update the title of the application
    document.title = APP_PLATFORM.APP_NAME;

    const fetchCoreScreenConfiguration = async () => {
      /**
       * Fetch and filter side bar options & routes based on the client config
       * Base component for any component can also be changed here
       * If the config is not defined for any particular module, in that case default side bar options, routes & base components will be applicable
       */
      let config = await props.getSpecificScreenName(3, {
        attribute_name: "core_screen_configuration",
      });
    };
    fetchCoreScreenConfiguration();
  }, []);

  /**
   * @func
   * @desc
   * Setting new routes and menus if the store coreScreenNames changes
   */
  useEffect(() => {
    const routes = [
      { path: "/release-notes", component: ReleaseNotes },
      {
        path: `/product-unit-definition`,
        component: ProductUnitDefinition,
        title: `${dynamicLabelsBasedOnTenant(
          "product_unit_definition",
          "core"
        )}`,
        screenName: "Product Unit Definition",
      },
      {
        path: `/product-unit-definition/create/:style_id`,
        component: CreateNewUnitDefinition,
        title: `${dynamicLabelsBasedOnTenant(
          "product_unit_definition",
          "core"
        )}`,
        screenName: "Product Unit Definition",
      },
      {
        path: `/product-unit-definition/edit/:style_id/:defn_id`,
        component: EditUnitDefinition,
        title: `${dynamicLabelsBasedOnTenant(
          "product_unit_definition",
          "core"
        )}`,
        screenName: "Product Unit Definition",
      },
      {
        path: `/filters`,
        component: Filters,
        title: "Filters",
      },
      {
        path: `/product-grouping`,
        component: Product_Grouping_Screen,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/group-definition-mapping/:group_id/edit-definitions/:def_id`,
        component: GroupDefEdit,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/group-definition-mapping/:group_id/create-definition`,
        component: CreateDefintion,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/group-definition-mapping/:group_id`,
        component: GroupDefMapper,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/create-group`,
        component: CreateProductGroup,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/create-group/group-definitions`,
        component: GroupDefintions,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/create-group/group-definitions/create-definition`,
        component: CreateDefintion,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/view/:group_id`,
        component: EditGrpProducts,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/modify/:group_id/group-definitions/create-definition`,
        component: CreateDefintion,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/modify/:group_id/group-definitions/edit-definitions/:id`,
        component: EditDefinition,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/modify/:group_id/group-definitions`,
        component: GroupDefintions,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/modify/:group_id`,
        component: ModifyGroup,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/group-definitions`,
        component: GroupDefintions,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/tenant/usermanagement`,
        component: GroupDefintions,
      },
      {
        path: `/product-grouping/group-definitions/create-definition`,
        component: CreateDefintion,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/create-group/group-definitions/edit-definitions/:id`,
        component: EditDefinition,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-grouping/group-definitions/edit-definitions/:id`,
        component: EditDefinition,
        title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
        screenName: "Product Group",
      },
      {
        path: `/product-status`,
        component: ProductFilter,
        title: `${dynamicLabelsBasedOnTenant("product_status", "core")}`,
        screenName: "Product Status",
      },
      {
        path: `/product-mapping`,
        component: ProductMapping,
        title: `${dynamicLabelsBasedOnTenant("product_mapping", "core")}`,
        screenName: "Product Mapping",
      },
      {
        path: `/store-status`,
        component: storeFilter,
        title: "Store Status",
        screenName: "Store Status",
      },
      {
        path: `/store-mapping`,
        component: storeMapping,
        title: "Store Mapping",
        screenName: "Store Mapping",
      },
      {
        path: `/store-grading`,
        component: StoreGrading,
        title: `Store Grading`,
        screenName: "Store Grading",
      },
      {
        path: `/store-grading/create-grading`,
        component: CreateStoreGrade,
        title: `Create new grading`,
        screenName: "Store Grading",
      },
      {
        path: `/events`,
        component: Events,
        title: "Notifications",
      },
      {
        path: `/notifications`,
        component: Notification,
        title: "Notifications",
      },
      {
        path: `/dc-mapping`,
        component: dcMapping,
        title: "DC Mapping",
        screenName: "dc mapping",
      },
      {
        path: `/store-grouping`,
        component: Store_Grouping_Screen,
        title: "Store Grouping",
        screenName: "Store Group",
      },
      {
        path: `/store-grouping/view/:group_id`,
        component: EditGrpStores,
        title: "Store Grouping",
        screenName: "Store Group",
      },
      {
        path: `/store-grouping/modify/:group_id`,
        component: ModifyStoreGroup,
        title: "Store Grouping",
        screenName: "Store Group",
      },
      {
        path: `/store-grouping/create-group`,
        component: CreateStoreGroup,
        title: "Store Grouping",
        screenName: "Store Group",
      },
      {
        path: `/tenantconfig/applicationconfig`,
        component: GenericAppConfig,
      },
      {
        path: `/dc-status`,
        component: DCStatusScreen,
        title: "DC Status",
        screenName: "DC status",
      },
      {
        path: `/user-management`,
        component: UserMangement,
        title: "User Management",
      },
      {
        path: `/user-role-management`,
        component: UserRoleManagement,
        title: "User Management",
      },
      {
        path: `/inventory`,
        component: Inventory,
        title: "Inventory",
      },
      {
        path: `/vendor-status`,
        component: vendorStatusScreen,
        title: "Vendor Status",
        screenName: "VendorConfigurations",
      },
      {
        path: `/vendor-product`,
        component: vendorProductScreen,
        title: `${dynamicLabelsBasedOnTenant("vendor_product", "core")}`,
        screenName: "VendorConfigurations",
      },
    ];
    const masterSideBarOptions = [
      {
        isParent: true,
        title: `Product`,
        icon: React.createElement(Icons.QrCode2),
        childList: [
          {
            link: "/product-mapping",
            title: `${dynamicLabelsBasedOnTenant("product_mapping", "core")}`,
            icon: React.createElement(Icons.AccountTree),
            order: 1,
            disabled: false,
          },
          {
            link: "/product-grouping",
            title: `${dynamicLabelsBasedOnTenant("product_grouping", "core")}`,
            icon: React.createElement(Icons.Dialpad),
            order: 2,
            disabled: false,
          },
          {
            link: "/product-unit-definition",
            title: `${dynamicLabelsBasedOnTenant(
              "product_unit_definition",
              "core"
            )}`,
            icon: React.createElement(Icons.ListAlt),
            order: 3,
            disabled: false,
          },
          {
            link: "/product-status",
            title: `${dynamicLabelsBasedOnTenant("product_status", "core")}`,
            icon: React.createElement(Icons.Flag),
            order: 4,
          },
        ],
      },
      {
        isParent: true,
        title: `Store`,
        icon: React.createElement(Icons.LocalGroceryStore),
        childList: [
          {
            link: "/store-status",
            title: "Store Status",
            icon: React.createElement(Icons.Store),
            order: 5,
          },
          {
            link: "/store-grouping",
            title: "Store Grouping",
            icon: React.createElement(Icons.Dialpad),
            order: 6,
          },
          {
            link: "/store-mapping",
            title: "Store Mapping",
            icon: React.createElement(Icons.AccountTree),
            order: 7,
            disabled: false,
          },
          {
            link: "/store-grading",
            title: "Store Grading",
            icon: React.createElement(Icons.AccountTree),
            order: 8,
            disabled: false,
          },
        ],
      },
      {
        isParent: true,
        title: `DC`,
        icon: React.createElement(Icons.LocalConvenienceStore),
        childList: [
          {
            link: "/dc-status",
            title: "DC Status",
            icon: React.createElement(Icons.Storefront),
            order: 9,
            disabled: false,
          },
          {
            link: "/dc-mapping",
            title: "DC Mapping",
            icon: React.createElement(Icons.MapRounded),
            order: 10,
            disabled: false,
          },
        ],
      },
      {
        isParent: true,
        title: `Vendor`,
        icon: React.createElement(Icons.GroupTwoTone),
        childList: [
          {
            link: "/vendor-status",
            title: "Vendor Status",
            icon: React.createElement(Icons.LocalShipping),
            order: 11,
            disabled: false,
          },
          {
            link: "/vendor-product",
            title: `${dynamicLabelsBasedOnTenant("vendor_product", "core")}`,
            icon: React.createElement(Icons.CategoryRounded),
            order: 12,
            disabled: false,
          },
        ],
      },
      {
        link: "/inventory",
        title: "Inventory",
        icon: React.createElement(Icons.LibraryBooks),
        order: 13,
        disabled: false,
      },
    ];

    const menus = {
      "workflow input center": masterSideBarOptions,
      "application access management": uamSideBarOptions,
      notification: notificationSideBarOptions,
    };
    setOptions(menus);
    setCoreRoutes(routes);
  }, [props.coreScreenNames]);

  return (
    <Layout
      routes={coreRoutes}
      sideBarOptions={options[sessionStorage.getItem("currentApp")]}
      app={sessionStorage.getItem("currentApp")}
    />
  );
};

const mapStateToProps = (state) => ({
  routes: state.layoutReducer.routes,
  coreScreenNames: state.tenantConfigReducer.coreScreenNames,
});

const mapActionsToProps = {
  setLayout: layoutActions.setLayout,
  getSpecificScreenName,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(CoreLayout));
