/* eslint-disable jsx-a11y/alt-text */
import React from "react";

import globalStyles from "Styles/globalStyles";
import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";

const ConfigurationComponent = () => {
  const globalClasses = globalStyles();

  const homeIcon = [
    {
      label: "Configuration",
      id: 1,
    },
  ];

  return (
    <>
      <HeaderBreadCrumbs options={homeIcon}></HeaderBreadCrumbs>
      <div className={`${globalClasses.filterWrapper} page-content`}>
        <object
          style={{ width: "100%", height: "100%" }}
          //data="https://bkp.products.impactsmartsuite.com/tracking"
          data={`https://bkp.products.impactsmartsuite.com${window.location.pathname}`}
        ></object>
      </div>
    </>
  );
};

export default ConfigurationComponent;
