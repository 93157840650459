import React from "react";

import { Reviews } from "@mui/icons-material";
import { useStyles } from "modules/react-demo/pages/styles";

const ReviewCell = (props) => {
  const classes = useStyles();

  return (
    <div
      onClick={() => {
        props.onReviewClick(props);
      }}
      title="Review"
    >
      <Reviews className={classes.iconBlue} />
    </div>
  );
};

export default ReviewCell;
