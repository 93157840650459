import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Typography,
} from "@mui/material";
import { MANUAL_GROUP_TYPE_FILTERS } from "../../../../config/constants/index";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import {
  getAllFilters,
  getFiltersValues,
  setSelectedFilters,
} from "../../../../actions/filterAction";
import {
  fetchProdGrpFilteredProducts,
  setProdGroupFilteredProds,
  fetchDefinitions,
  ToggleLoader,
  setProdGrpFilteredCols,
  setManualGrpFilterType,
  setManualGrpDefns,
  resetFilterProds,
  addSelectedGroups,
  setSelectedProducts,
  setStyleTableData,
  fetchStyleLevelData,
  setDefinitions,
  getDefinitions,
} from "pages/product-grouping/product-grouping-service";
import ProductFilterGroup from "../../../../commonComponents/filters/filterGroup";
import ReactSelect from "../../../../Utils/select";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  getColumns,
  setTableState,
  getColumnsAg,
} from "../../../../actions/tableColumnActions";
import globalStyles from "Styles/globalStyles";
import { Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import CustomAccordion from "commonComponents/Custom-Accordian";
import LoadingOverlay from "Utils/Loader/loader";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

const useStyles = makeStyles((theme) => ({
  filterLabel: {
    marginRight: 15,
  },
  defnIcon: {
    marginLeft: 20,
    padding: 8,
    backgroundColor: "#68727E",
    "&:hover": {
      backgroundColor: "#3D5772",
    },
  },
  listIcon: {
    backgroundColor: "white",
    fontSize: 22,
    color: "#68727E",
    "&:hover": {
      color: "#3D5772",
    },
  },
}));

const initialState = {
  filteredProducts: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FILTERED_PRODUCTS":
      return {
        ...state,
        filteredProducts: action.payload,
      };
    default:
      return state;
  }
};
const ManualGroup = forwardRef((props, ref) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const screenName = "product group";
  const [state, dispatch] = useReducer(reducer, initialState);
  const [filterDependency, setDependency] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterDependencyChips, setFilterDependencyChips] = useState([]);
  const [showFilterLoader, setShowFilterLoader] = useState(false);
  const [showStyleLevelData, setShowStyleLevelData] = useState(true);

  const onFilterChange = (event) => {
    props.setManualGrpFilterType({
      type: event.target.value,
      isDisabled: false,
    });
    setDependency([]);
    setFilterDependencyChips([]);
    props.setManualGrpDefns([]);
    onFilter();
  };

  useEffect(() => {
    const fetchStyleInfo = async () => {
      let showStyleLevelDataResp = await props.getTenantConfigApplicationLevel(
        3,
        {
          attribute_name: "core_show_style_level_info",
        }
      );

      if (showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"]) {
        setShowStyleLevelData(
          showStyleLevelDataResp?.data?.data?.[0]?.["attribute_value"].value
        );
      }
    };
    fetchStyleInfo();
  }, []);

  useEffect(() => {
    return () => {
      props.resetFilterProds();
      let obj = {};
      obj["product_hierarchy"] = [];
      props.setSelectedFilters(obj);
    };
  }, []);

  useEffect(() => {
    props.ToggleLoader(true);
    props.resetFilterProds(true);
    const fetchHierarchyData = async () => {
      props.ToggleLoader(true);
      let response = await props.getAllFilters(screenName);
      const filterElements = response.data.data.map(async (key) => {
        let body = {
          attribute_name: key.column_name,
          filter_type: key.type,
          filters: [],
        };
        const options = await getFiltersValues("product", body)();
        key.filter_keyword = key.column_name;
        key.initialData = options.data.data.attribute.map((item) => {
          return {
            value: item.attribute,
            label: item.attribute,
          };
        });
        return key;
      });
      await Promise.all(filterElements);
      dispatch({
        type: "FILTERED_PRODUCTS",
        payload: response.data.data,
      });
      const cols = await props.getColumnsAg("table_name=product_group_filter");
      props.setProdGrpFilteredCols(cols);
      props.ToggleLoader(false);
    };
    const fetchDefinitionData = async () => {
      const body = {
        search: [],
        sort: [],
        range: [],
        filters: [],
        include_mapped_definition: false,
      };
      const res = await props.getDefinitions(body, 100);
      props.setDefinitions({
        definitions: res.data.data,
        count: res.data.total,
      });
      props.ToggleLoader(false);
    };
    if (props.manualFilterType === "product_hierarchy") {
      fetchHierarchyData();
    } else {
      let obj = {};
      obj["product_hierarchy"] = [];
      props.setSelectedFilters(obj);
      fetchDefinitionData();
    }
  }, [props.manualFilterType]);

  const updateData = async (dependency) => {
    setShowFilterLoader(true);
    let selectionDependency =
      dependency.length > 0
        ? dependency.map((item) => {
            return {
              attribute_name: item.filter_id,
              operator: "in",
              values: Array.isArray(item.values)
                ? item.values.map((opt) => opt.value)
                : item.values,
              filter_type: item.filter_type,
            };
          })
        : [];
    try {
      setFilterDependencyChips(dependency);
      let initialFilterElements = [...state.filteredProducts];
      const filterElements = initialFilterElements.map(async (key) => {
        if (key.type === "cascaded") {
          let body = {
            attribute_name: key.column_name,
            filter_type: key.type,
            filters: selectionDependency,
          };
          const options = await getFiltersValues("product", body)();
          key.initialData = options.data.data.attribute.map((item) => {
            return {
              value: item.attribute,
              label: item.attribute,
            };
          });
        }
        return key;
      });
      await Promise.all(filterElements);
      setDependency(selectionDependency);
      dispatch({
        type: "FILTERED_PRODUCTS",
        payload: initialFilterElements,
      });
      setShowFilterLoader(false);
    } catch (error) {
      setShowFilterLoader(false);
    }
  };
  const onFilter = async (data) => {
    ref.productLvlRef?.current?.api?.refreshServerSideStore({ purge: true });
    ref.productGroupLvlRef?.current?.api?.refreshServerSideStore({
      purge: true,
    });
    if (showStyleLevelData) {
      ref.styleLvlRef?.current?.api?.refreshServerSideStore({ purge: true });
    }
    setOpenModal(false);
    props.ToggleLoader(false);
  };

  const onChange = (options) => {
    props.setManualGrpDefns(options);
  };
  const onDefinitionFilter = async () => {
    props.ToggleLoader(true);
    setDependency([]);
    ref.productLvlRef?.current?.api?.refreshServerSideStore({ purge: false });
    if (showStyleLevelData) {
      ref.styleLvlRef?.current?.api?.refreshServerSideStore({ purge: false });
    }
    props.ToggleLoader(false);
  };

  const onReset = async () => {
    onChange([]);
    let obj = {};
    obj["product_hierarchy"] = [];
    setDependency([]);
    props.setSelectedFilters(obj);
    props.setProdGroupFilteredProds({ data: [], count: 0 });
    ref.productLvlRef?.current?.api?.refreshServerSideStore({ purge: true });
    ref.productGroupLvlRef?.current?.api?.refreshServerSideStore({
      purge: true,
    });
    if (showStyleLevelData) {
      ref.styleLvlRef?.current?.api?.refreshServerSideStore({ purge: true });
    }
  };
  return (
    <>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay loader={showFilterLoader} minHeight={200}>
            <ProductFilterGroup
              doNotUpdateDefaultValue={true}
              filters={state.filteredProducts}
              screen="product_hierarchy"
              update={updateData}
              onFilter={onFilter}
              onReset={onReset}
            />
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>
      <div
        className={`${globalClasses.verticalAlignCenter} ${globalClasses.flexRow}`}
      >
        <FormLabel classes={{ root: classes.filterLabel }} component="legend">
          {`Filter ${dynamicLabelsBasedOnTenant("product", "core")}s by`}
        </FormLabel>
        <FormControl component="fieldset">
          <RadioGroup
            id="productGrpingManualFilterRadioGrp"
            value={props.manualFilterType}
            aria-label="gender"
            name="customized-radios"
            onChange={onFilterChange}
            row
          >
            {MANUAL_GROUP_TYPE_FILTERS.map((filter) => {
              return (
                <>
                  <FormControlLabel
                    value={filter.value}
                    id={`productGrpingManualFilterLabel${filter.value}`}
                    control={
                      <Radio
                        id={`productGrpingManualFilterRadio${filter.value}`}
                        color="primary"
                        disabled={
                          filter.value === "grouping_definitions" &&
                          props.isDefnDisabled &&
                          props.isEdit
                        }
                      />
                    }
                    label={filter.label.replace(
                      "Product",
                      dynamicLabelsBasedOnTenant("product", "core")
                    )}
                  />
                </>
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
      {props.manualFilterType === "product_hierarchy" ? (
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
      ) : (
        <GroupingDefnFilter
          options={props.definitions}
          onChange={onChange}
          value={props.manualDefinitionFilter}
          onFilter={onDefinitionFilter}
          onReset={onReset}
          isEdit={props.isEdit}
        />
      )}
      {filterDependencyChips.length > 0 && (
        <FilterChips filterConfig={filterDependencyChips}></FilterChips>
      )}
    </>
  );
});

const GroupingDefnFilter = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const history = useHistory();

  const getOptions = () => {
    return props.options.map((option) => {
      return {
        ...option,
        label: option.name,
        value: option.pgd_code,
      };
    });
  };

  return (
    <div className={globalClasses.marginVertical2rem}>
      <Grid direction="row">
        <Grid item xs={12}>
          <Typography
            variant="h4"
            component="h4"
            className={globalClasses.subLabel}
          >
            Select Definitions
          </Typography>
        </Grid>
      </Grid>
      <Grid
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        container
        className={globalClasses.marginVertical}
      >
        <Grid item xs={3}>
          <ReactSelect
            id="productGrpingGrpDefnDropDwn"
            isMulti={false}
            isClearable={false}
            isSearchable={true}
            options={getOptions()}
            value={props.value}
            onChange={props.onChange}
            menuPortalTarget={document.body}
          />
        </Grid>
        <Grid item xs={2}>
          {" "}
          <IconButton
            id="productGrpingManualDfnsBtn"
            onClick={() =>
              history.push({
                pathname: props.isEdit
                  ? `/product-grouping/modify/${
                      history.location.pathname.split("/")[3]
                    }/group-definitions`
                  : "/product-grouping/create-group/group-definitions",
              })
            }
            classes={{ root: classes.defnIcon }}
            size="large"
          >
            <ListAltOutlinedIcon classes={{ root: classes.listIcon }} />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          {" "}
          <div
            className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.onFilter()}
              id="productGrpingDfnsFilterBtn"
            >
              Filter
            </Button>
            <Button
              variant="outlined"
              onClick={props.onReset}
              id="productGrpingDfnsResetBtn"
            >
              Reset
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isDefnDisabled: state.productGroupReducer.isDefnBasedDisabled,
    definitions: state.productGroupReducer.productGrpDefinitions,
    manualDefinitionFilter: state.productGroupReducer.manualGroupDfnFilters,
    manualFilterType: state.productGroupReducer.selectedManualFilterType,
    tableState: state.tableReducer.tableState,
  };
};
const mapActionsToProps = {
  fetchProdGrpFilteredProducts,
  setProdGroupFilteredProds,
  fetchDefinitions,
  getAllFilters,
  getFiltersValues,
  ToggleLoader,
  getColumns,
  setProdGrpFilteredCols,
  setManualGrpFilterType,
  setManualGrpDefns,
  setSelectedFilters,
  resetFilterProds,
  addSelectedGroups,
  setSelectedProducts,
  fetchStyleLevelData,
  setStyleTableData,
  setTableState,
  getTenantConfigApplicationLevel,
  getColumnsAg,
  getDefinitions,
  setDefinitions,
};
export default connect(mapStateToProps, mapActionsToProps, null, {
  forwardRef: true,
})(ManualGroup);
