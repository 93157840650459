import React from "react";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import DialpadIcon from "@mui/icons-material/Dialpad";
import CategoryIcon from "@mui/icons-material/Category";
import { BsPlusLg, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { Inventory2Outlined } from "@mui/icons-material";
import GpsFixedTwoToneIcon from "@mui/icons-material/GpsFixedTwoTone";
import CalendarMonthTwoToneIcon from "@mui/icons-material/CalendarMonthTwoTone";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import LightbulbCircleOutlinedIcon from "@mui/icons-material/LightbulbCircleOutlined";
import {
  WORKBENCH,
  PRICE_RULES,
  PLAN_GROUP_CONFIGURATIONS,
  PRICE_LIST_CONFIGURATIONS,
  MARKET_CONFIGURATIONS,
  ALERT_CONFIGURATIONS,
  EXCEPTIONS_CONFIGURATIONS,
  CLEARANCE_REVIEW,
  DECISION_DASHBOARD,
  ITEM_GROUP_CONFIG,
  STORE_CONFIG,
  MARKETING_CALENDAR,
  BASE_PRICE_STRATEGY,
  PROMOTION_STRATEGY,
  RULES,
} from "../constants-pricesmart/routesContants";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import GridViewIcon from "@mui/icons-material/GridView";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import WorkBench from "../pages-pricesmart/react-demo/workbench";
import PriceRules from "../pages-pricesmart/react-demo/price-rules";
import PlanGroupConfigurations from "../pages-pricesmart/react-demo/plan-group-configurations";
import PriceListConfigurations from "../pages-pricesmart/react-demo/price-list-configurations";
import MarketConfigurations from "../pages-pricesmart/react-demo/market-configurations";
import AlertConfigurations from "../pages-pricesmart/react-demo/alert-configurations";
import ExceptionsConfigurations from "../pages-pricesmart/react-demo/exceptions-configurations";
import DecisionDashboard from "../pages-pricesmart/react-demo/decision-dashboard";
import ItemGroupConfig from "../pages-pricesmart/react-demo/item-group-config";
import StoreConfig from "../pages-pricesmart/react-demo/store-config";
import ClearanceReview from "../pages-pricesmart/react-demo/clearance-review";
import MarketingCalendar from "../pages-pricesmart/react-demo/marketing-calendar";
import BasePriceStrategies from "../pages-pricesmart/react-demo/base-price-strategies";
import PromotionStrategies from "../pages-pricesmart/react-demo/promotion-strategies";
import Rules from "../pages-pricesmart/react-demo/rules";

export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + WORKBENCH,
    title: "Workbench",
    icon: React.createElement(GridViewIcon),
    order: 1,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PRICE_RULES,
    title: "Price Rules",
    icon: React.createElement(CategoryIcon),
    order: 2,
  },
  {
    link:
      "/" + window.location.pathname.split("/")[1] + PLAN_GROUP_CONFIGURATIONS,
    title: "Plan Group Configurations",
    icon: React.createElement(Inventory2Outlined),
    order: 3,
  },
  {
    link:
      "/" + window.location.pathname.split("/")[1] + PRICE_LIST_CONFIGURATIONS,
    title: "Price List Configurations",
    icon: React.createElement(MonetizationOnIcon),
    order: 4,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + MARKET_CONFIGURATIONS,
    title: "Market Configurations",
    icon: React.createElement(BubbleChartIcon),
    order: 5,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ALERT_CONFIGURATIONS,
    title: "Alert Configurations",
    icon: React.createElement(BsPlusLg),
    order: 6,
  },
  {
    link:
      "/" + window.location.pathname.split("/")[1] + EXCEPTIONS_CONFIGURATIONS,
    title: "Exceptions Configurations",
    icon: React.createElement(DialpadIcon),
    order: 7,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + DECISION_DASHBOARD,
    title: "Decision Dashboard",
    icon: React.createElement(GridViewIcon),
    order: 8,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + ITEM_GROUP_CONFIG,
    title: "Item Group Config",
    icon: React.createElement(DialpadIcon),
    order: 9,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + STORE_CONFIG,
    title: "Store Config",
    icon: React.createElement(BubbleChartIcon),
    order: 10,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + CLEARANCE_REVIEW,
    title: "Clearance Review",
    icon: React.createElement(CategoryIcon),
    order: 11,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + MARKETING_CALENDAR,
    title: "Marketing Calendar",
    icon: React.createElement(CalendarMonthTwoToneIcon),
    order: 12,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + BASE_PRICE_STRATEGY,
    title: "Base Price Strategies",
    icon: React.createElement(SellOutlinedIcon),
    order: 13,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + PROMOTION_STRATEGY,
    title: "Promotion Strategies",
    icon: React.createElement(LightbulbCircleOutlinedIcon),
    order: 14,
  },
  {
    link: "/" + window.location.pathname.split("/")[1] + RULES,
    title: "Rules",
    icon: React.createElement(GpsFixedTwoToneIcon),
    order: 15,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + WORKBENCH,
      component: WorkBench,
      title: "Workbench",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PRICE_RULES,
      component: PriceRules,
      title: "Price Rules",
    },
    {
      path:
        "/" +
        window.location.pathname.split("/")[1] +
        PLAN_GROUP_CONFIGURATIONS,
      component: PlanGroupConfigurations,
      title: "Plan Group Configurations",
    },
    {
      path:
        "/" +
        window.location.pathname.split("/")[1] +
        PRICE_LIST_CONFIGURATIONS,
      component: PriceListConfigurations,
      title: "Price List Configurations",
    },
    {
      path:
        "/" + window.location.pathname.split("/")[1] + MARKET_CONFIGURATIONS,
      component: MarketConfigurations,
      title: "Market Configurations",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ALERT_CONFIGURATIONS,
      component: AlertConfigurations,
      title: "Alert Configurations",
    },
    {
      path:
        "/" +
        window.location.pathname.split("/")[1] +
        EXCEPTIONS_CONFIGURATIONS,
      component: ExceptionsConfigurations,
      title: "Exceptions Configurations",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + DECISION_DASHBOARD,
      component: DecisionDashboard,
      title: "Decision Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + ITEM_GROUP_CONFIG,
      component: ItemGroupConfig,
      title: "Item Group Config",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + STORE_CONFIG,
      component: StoreConfig,
      title: "Store Config",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + CLEARANCE_REVIEW,
      component: ClearanceReview,
      title: "Clearance Review",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + MARKETING_CALENDAR,
      component: MarketingCalendar,
      title: "Marketing-Calendar",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + BASE_PRICE_STRATEGY,
      component: BasePriceStrategies,
      title: "Base Price Strategies",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + PROMOTION_STRATEGY,
      component: PromotionStrategies,
      title: "Promotion Strategies",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + RULES,
      component: Rules,
      title: "Rules",
    },
  ];

  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app="pricesmart" />
  );
};

export default Routes;
