import React, { useState, useEffect, useRef } from "react";

import { isEmpty, cloneDeep } from "lodash";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";

import AgGridComponent from "Utils/agGrid";
import globalStyles from "Styles/globalStyles";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";

import { ERROR_MESSAGE } from "../../../constants-inventorysmart/stringConstants";

const useStyles = makeStyles(() => ({
  sizeSplitTableWidth: {
    margin: "1rem auto",
    width: "60%",
  },
}));

const StoreStockDrillDownViewTableComponent = (props) => {
  const [storeStockDrillDownColumn, setStoreStockDrillDownColumn] = useState(
    []
  );
  const [openStoreStockPopup, setOpenStoreStockPopup] = useState(false);
  const [
    storeStockDrillDownColumnDetails,
    setStoreStockDrillDownColumnDetails,
  ] = useState([]);
  const [storeStockDrillDownSizeData, setStoreStockDrillDownSizeData] =
    useState([]);
  const [sizeDetailsLoader, setSizeDetailsLoader] = useState(false);
  const [metric, setMetric] = useState(null);
  const [storeNum, setStoreNum] = useState(null);
  const [styleColorId, setStyleColorId] = useState(null);

  const existingArticleSizeDetails = useRef([]);
  const storeStockDrillDownTableInstance = useRef(null);
  const filterConfig = useRef({});

  const globalClasses = globalStyles();
  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(props.renderTable)) {
      filterConfig.current = props.renderTable.filters;
      storeStockDrillDownTableInstance.current?.api?.refreshServerSideStore({
        purge: true,
      });
    }
  }, [props.renderTable]);

  const manualCallBack = async (manualbody, pageIndex, params) => {
    props.setStockDrillDownTableLoader(true);
    let body = {
      meta: {
        ...manualbody,
        limit: { limit: 10, page: pageIndex + 1 },
      },
      filters: filterConfig.current,
    };

    try {
      let drillDownTableDetails = await props.getStockDrillDownTableData(body);
      let copyOfStoreStockTableData = cloneDeep(
        drillDownTableDetails.data?.data?.columns
      );
      let storeStockActions = getActionColumns(copyOfStoreStockTableData);
      let col = agGridColumnFormatter(
        copyOfStoreStockTableData,
        null,
        storeStockActions
      );
      setStoreStockDrillDownColumn(col);
      props.setStockDrillDownTableLoader(false);
      return {
        data: drillDownTableDetails.data?.data?.table_data,
        totalCount: drillDownTableDetails.data?.total,
      };
    } catch (err) {
      props.setStockDrillDownTableLoader(false);
      props.displaySnackMessages(ERROR_MESSAGE, "error");
    }
  };

  const getActionColumns = (cols) => {
    /* 
      This function is used to create a dynamic list of column names whose type is link.
      Dynamic list is needed as the num of DC's vary for a client and the column name for DC's differs from client to client.
      Instead of having column names hardcoded with their action mentioned, which is to be passed as third param to aggrid col formatter,
      we fetch it dynamically based on col type and convert into the required format
     */
    let linkTypeCols = [];
    cols.forEach((item) => {
      if (item?.sub_headers.length) {
        item?.sub_headers.filter((data) => {
          if (data.type === "link") {
            linkTypeCols.push(data);
          }
        });
      } else {
        if (item.type === "link") linkTypeCols.push(item);
      }
    });
    let linkTypeColNames = linkTypeCols.map((obj) => obj.column_name);
    let actionObject = linkTypeColNames.map((colNames) => {
      return {
        [colNames]: toggleStoreStockPopup,
      };
    });
    return Object.assign({}, ...actionObject);
  };

  const loadTableInstance = (params) => {
    storeStockDrillDownTableInstance.current = params;
  };

  const toggleStoreStockPopup = async (data, columnName) => {
    // Modifying the heading to display on popup accordingly
    let formattedColumnName = "";
    if (columnName === "total_oo_oh_it") {
      formattedColumnName = "total";
    } else if (columnName === "total_oh_dc") {
      formattedColumnName = "total_dc";
    } else formattedColumnName = columnName.split("_").join(" ");
    setMetric(formattedColumnName.toUpperCase());
    setStoreNum(data.store_code);
    setStyleColorId(data.article);
    setOpenStoreStockPopup(true);

    let sizeDetailsColKeys;
    // Make an api call initially only for those rows on which a user has not performed on click operation to view size details of an article.
    // Push the response of the size details api to existingArticleSizeDetails ref variable
    if (
      !existingArticleSizeDetails.current.length ||
      existingArticleSizeDetails.current.every((item) => data.key !== item.key)
    ) {
      try {
        setSizeDetailsLoader(true);
        let reqBody = {
          filters: filterConfig.current,
          article: data.article,
          store_code: data.store_code,
        };
        let sizeDetailsResponse = await props.getStockDrillDownSizeDetails(
          reqBody
        );
        sizeDetailsColKeys =
          sizeDetailsResponse.data.data[`size_${columnName}`];
        setSizeDetailsLoader(false);
        let newArticleSizeDetails = [
          ...existingArticleSizeDetails.current,
          {
            key: data.key,
            details: sizeDetailsResponse.data.data,
          },
        ];
        existingArticleSizeDetails.current = newArticleSizeDetails;
      } catch (err) {
        setSizeDetailsLoader(false);
        props.displaySnackMessages(ERROR_MESSAGE, "error");
      }
    } else {
      // If the user performs onClick on the same row whose size details data exists in existingArticleSizeDetails ref variable,
      // fetch the record and map it accordingly into pop-up's table data
      let matchingRecord = existingArticleSizeDetails.current.filter(
        (item) => data.key === item.key
      )[0];
      sizeDetailsColKeys = matchingRecord.details[`size_${columnName}`];
    }
    let sizeColumns = Object.keys(sizeDetailsColKeys).map((item, i) => {
      return {
        sub_headers: [],
        tc_code: 190,
        column_name: item,
        type: "str",
        label: item,
        is_frozen: false,
        is_hidden: false,
        is_editable: false,
        is_aggregated: false,
        order_of_display: i + 1,
        dimension: "Store",
        is_required: true,
        aggregate: null,
        formatter: null,
        is_row_span: false,
        footer: null,
        is_searchable: false,
        is_sortable: false,
        extra: null,
      };
    });
    let agGridFormattedColumns = agGridColumnFormatter(sizeColumns);
    setStoreStockDrillDownColumnDetails(agGridFormattedColumns);
    setStoreStockDrillDownSizeData([sizeDetailsColKeys]);
  };

  const renderPopupToShowSummary = () => {
    return (
      <Dialog
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              {metric}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {dynamicLabelsBasedOnTenant("article_number")}: {styleColorId}
            </Typography>
            <Typography variant="h5" gutterBottom>
              Store Number: {storeNum}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenStoreStockPopup(false)}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Loader loader={sizeDetailsLoader}>
            <div
              className={
                storeStockDrillDownColumnDetails.length < 3
                  ? classes.sizeSplitTableWidth
                  : ""
              }
            >
              <AgGridComponent
                downloadAsExcel={props.enableDownload}
                rowdata={storeStockDrillDownSizeData}
                columns={storeStockDrillDownColumnDetails}
                suppressFieldDotNotation
                sideBar={false}
              />
            </div>
          </Loader>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className={globalClasses.marginVertical1rem}>
      <AgGridComponent
        columns={storeStockDrillDownColumn}
        manualCallBack={(body, pageIndex, params) =>
          manualCallBack(body, pageIndex, params)
        }
        cacheBlockSize={10}
        rowModelType="serverSide"
        serverSideStoreType="partial"
        loadTableInstance={loadTableInstance}
        uniqueRowId={"key"}
      />
      {openStoreStockPopup && renderPopupToShowSummary()}
    </div>
  );
};

export default StoreStockDrillDownViewTableComponent;
