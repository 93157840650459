import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import StorageRoundedIcon from "@mui/icons-material/StorageRounded";
import CloseIcon from "@mui/icons-material/Close";
import { cloneDeep, isEmpty } from "lodash";
import FilterGroup from "commonComponents/filters/filterGroup";
import TransferUser from "./transfer-user";
import { useStyles as sharedStyles } from "../styles-tenant-user-mgmt";
import { ASSIGN_ROLE_USER_DETAILS_FILTERS } from "../constants";

const useStyles = makeStyles((theme) => ({
  footerDialog: {
    padding: theme.typography.pxToRem(15),
    justifyContent: "center",
  },
  storageButtonPos: {
    marginBottom: "-2.5rem",
    cursor: "pointer",
    display: "flex",
    justifyContent: "end",
    maxWidth: "calc(21% - 5px)",
  },
}));

const UserDetailsFilterForm = (props) => {
  const [showTransferUser, setShowTransferUser] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [dbUserList, setDbUserList] = useState([]);
  const [transferTableData, setTransferTableData] = useState([]);
  const [filterConfig, setFilterConfig] = useState([]);
  const [initialDependencyValue, setInitialDependencyValue] = useState([]);

  const classes = useStyles();
  const sharedClasses = sharedStyles();

  useEffect(() => {
    const fetchUserList = async () => {
      props.setLoadingOfFormElements(true);
      try {
        const filterElements = ASSIGN_ROLE_USER_DETAILS_FILTERS.map(
          async (key) => {
            if (key.column_name !== "user_email") {
              // calling the api only for role and application filter config
              let body = {
                attribute_name: key.column_name,
                filter_type: key.type,
                filters: [],
              };
              const filterConfigOptions = await props.getUserDetailsFormFilter(
                body
              );
              return {
                ...key,
                filter_keyword: key.column_name,
                is_mandatory: true,
                initialData: filterConfigOptions.data.data
                  .map((item) => {
                    if (item.attribute !== null) {
                      return {
                        value: item.attribute,
                        label: item.attribute,
                        id: item.attribute,
                      };
                    }
                  })
                  .filter((obj) => obj),
              };
            } else {
              const postBody = {
                meta: {},
              };
              //let response = await props.getUserDetails(postBody);
              let response = {
                total: 1,
                page: 1,
                count: 1,
                status: true,
                data: [
                  {
                    user_name: "Demo User",
                    user_code: 252,
                    email: "demo@impactanalytics.co",
                  },
                ],
                message: "Successful",
                previous: null,
                next: null,
              };
              // setting the response to reducer and state to make use for transfer user table
              props.setUserTableDetails(response.data.data);
              setTransferTableData(response.data.data);
              return {
                ...key,
                filter_keyword: key.column_name,
                is_mandatory: true,
                initialData: response.data.data.map((item) => {
                  return {
                    value: item.user_code,
                    label: item.email,
                    id: item.user_code,
                  };
                }),
              };
            }
          }
        );
        let data = await Promise.all(filterElements);
        setFilterConfig(data);
        props.setLoadingOfFormElements(false);
      } catch (err) {
        props.setLoadingOfFormElements(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };
    fetchUserList();
  }, []);

  // prepopulating user email ids on edit for single and multiselect
  useEffect(() => {
    if (!isEmpty(props.formDataOnEdit) && !isEmpty(props.userList)) {
      if (Array.isArray(props.formDataOnEdit)) {
        let applicationNames = props.formDataOnEdit.map(
          (item) => item.application_name
        );
        let roleNames = props.formDataOnEdit.map((item) => item.role_name);

        // check if all the values of an array are same
        let allApplicationsEqual = applicationNames.every(
          (value) => value === applicationNames[0]
        );
        let allRolesEqual = roleNames.every((value) => value === roleNames[0]);

        let equalValueDepencies = [];
        if (allApplicationsEqual) {
          let applicationDependencies = {
            filter_id: "application_name",
            filter_type: "cascaded",
            values: userApplicationRoleOptions(applicationNames[0]),
          };
          equalValueDepencies.push(applicationDependencies);
        }
        if (allRolesEqual) {
          let roleDependencies = {
            filter_id: "role_name",
            filter_type: "cascaded",
            values: userApplicationRoleOptions(roleNames[0]),
          };
          equalValueDepencies.push(roleDependencies);
        }
        let selectedMultipleUsersForEdit = props.userList.filter((opt) =>
          props.formDataOnEdit.some(
            (editRecord) => opt.user_code === editRecord.user_code
          )
        );
        setEmailDepenedencyValueBasedOnSelections(
          selectedMultipleUsersForEdit,
          equalValueDepencies
        );
      } else {
        let roleApplicationDependency = [
          {
            filter_id: "application_name",
            filter_type: "cascaded",
            values: userApplicationRoleOptions(
              props.formDataOnEdit.application_name
            ),
          },
          {
            filter_id: "role_name",
            filter_type: "cascaded",
            values: userApplicationRoleOptions(props.formDataOnEdit.role_name),
          },
        ];
        let selectedUserForEdit = props.userList.filter(
          (opt) => opt.user_code === props.formDataOnEdit.user_code
        );
        setEmailDepenedencyValueBasedOnSelections(
          selectedUserForEdit,
          roleApplicationDependency
        );
      }
    }
  }, [props.formDataOnEdit, props.userList]);

  // to set the filter drop down values for user email when values are selected from the table
  const emailDefaultValuesFunc = () => {
    setEmailDepenedencyValueBasedOnSelections(usersSelected);
  };

  const setEmailDepenedencyValueBasedOnSelections = (
    arrayToMap,
    dependencies
  ) => {
    let userEmailDependency = [
      {
        filter_id: "user_email",
        filter_type: "cascaded",
        values: userEmailOptions(arrayToMap),
        dimension: "user",
        display_order: 1,
      },
    ];
    let editFilterDependency = dependencies
      ? [...dependencies, ...userEmailDependency]
      : userEmailDependency;
    if (!isEmpty(initialDependencyValue)) {
      let initialDependencyClone = cloneDeep(initialDependencyValue);
      let userEmailPresentInFilterDependency = initialDependencyClone.some(
        (obj) => obj.filter_id === "user_email"
      );
      if (userEmailPresentInFilterDependency) {
        initialDependencyClone.forEach((obj) => {
          if (obj.filter_id === "user_email") {
            // updating with values fetched from table to avoid duplications in setInitialDependencyValue
            obj.values = userEmailDependency[0].values;
          }
        });
        setInitialDependencyValue(initialDependencyClone);
      } else {
        let depencyVal = [...userEmailDependency, ...initialDependencyValue];
        setInitialDependencyValue(depencyVal);
      }
    } else {
      setInitialDependencyValue(editFilterDependency);
    }
    callFilterAPIOnEdit([...dependencies, ...userEmailDependency]);
  };

  const callFilterAPIOnEdit = async (dependency) => {
    // to populate filters dropdown with values based on application and role name selected on edit
    props.setLoadingOfFormElements(true);
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              values: Array.isArray(opt.values)
                ? opt.values.map((option) => option.value)
                : opt.values,
              filter_type: opt.filter_type,
            };
          })
        : [];
    try {
      const filterElements = ASSIGN_ROLE_USER_DETAILS_FILTERS.map(
        async (key) => {
          if (key.column_name !== "user_email") {
            // calling the api only for role and application filter config
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency.filter(
                (obj) => obj.attribute_name !== "user_email"
              ),
            };
            const filterConfigOptions = await props.getUserDetailsFormFilter(
              body
            );
            return {
              ...key,
              filter_keyword: key.column_name,
              initialData: filterConfigOptions.data.data
                .map((item) => {
                  if (item.attribute !== null) {
                    return {
                      value: item.attribute,
                      label: item.attribute,
                      id: item.attribute,
                    };
                  }
                })
                .filter((obj) => obj),
            };
          } else {
            if (isEmpty(props.formDataOnEdit))
              key.initialData = userEmailOptions(props.userList);
            else {
              key.initialData = displaySelectedUsersInDropDownOnEdit();
            }
            return key;
          }
        }
      );
      let data = await Promise.all(filterElements);
      setFilterConfig(data);
      props.saveFormFilterValues(selectionValueDependency);
      props.setLoadingOfFormElements(false);
    } catch (err) {
      props.setLoadingOfFormElements(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const userEmailOptions = (lists) => {
    return lists.map((opt) => {
      return {
        value: opt.user_code,
        label: opt.email,
        id: opt.user_code,
      };
    });
  };

  const userApplicationRoleOptions = (data) => {
    return [
      {
        value: data,
        label: data,
        id: data,
      },
    ];
  };

  const transferUsersModal = () => {
    setShowTransferUser(true);
  };

  const closeModal = () => {
    setShowTransferUser(false);
  };

  const saveSelectedUsers = () => {
    setShowTransferUser(false);
    emailDefaultValuesFunc();
  };

  const storeSelectedList = (data) => {
    setUsersSelected(data);
  };

  const storeDbList = (data) => {
    setDbUserList(data);
  };

  const updateUserDetailFilterValuesOnChange = async (dependency, filter) => {
    setInitialDependencyValue(dependency);
    let selectionValueDependency =
      dependency.length > 0
        ? dependency.map((opt) => {
            return {
              attribute_name: opt.filter_id,
              operator: "in",
              values: Array.isArray(opt.values)
                ? opt.values.map((option) => option.value)
                : opt.values,
              filter_type: opt.filter_type,
            };
          })
        : [];
    // function to set transfer table values based on user email selected from the drop down
    updateTransferUserTableSelections(selectionValueDependency);
    try {
      // no api call to be made when selecting user_email
      if (
        !filter ||
        (filter.filter_type === "cascaded" && filter.filter_id !== "user_email")
      ) {
        props.setLoadingOfFormElements(true);
        let initialFilterElements = [...filterConfig];
        const filterElements = initialFilterElements.map(async (key) => {
          if (key.column_name !== "user_email") {
            let body = {
              attribute_name: key.column_name,
              filter_type: key.type,
              filters: selectionValueDependency.filter(
                (obj) => obj.attribute_name !== "user_email"
              ),
            };
            const options = await props.getUserDetailsFormFilter(body);
            key.initialData = options.data.data.map((opt) => {
              return {
                value: opt.attribute,
                label: opt.attribute,
                id: opt.attribute,
              };
            });
          } else {
            // to populate the dropdown values, to keep the user list intact
            if (isEmpty(props.formDataOnEdit))
              key.initialData = userEmailOptions(props.userList);
            else {
              key.initialData = displaySelectedUsersInDropDownOnEdit();
            }
          }
          return key;
        });
        await Promise.all(filterElements);
        setFilterConfig(initialFilterElements);
        props.setLoadingOfFormElements(false);
      }
      // passing values to parent for submission
      props.saveFormFilterValues(selectionValueDependency);
    } catch (err) {
      displaySnackMessages("Something went wrong on filtering", "error");
      props.setLoadingOfFormElements(false);
    }
  };

  const displaySelectedUsersInDropDownOnEdit = () => {
    let selectedUsersListOnEdit = [];
    if (Array.isArray(props.formDataOnEdit)) {
      selectedUsersListOnEdit = props.userList.filter((opt) =>
        props.formDataOnEdit.some(
          (editRecord) => opt.user_code === editRecord.user_code
        )
      );
    } else {
      selectedUsersListOnEdit = props.userList.filter(
        (opt) => opt.user_code === props.formDataOnEdit.user_code
      );
    }
    return userEmailOptions(selectedUsersListOnEdit);
  };

  const updateTransferUserTableSelections = (selectionValueDependency) => {
    let emailIdsListForTransferTable = selectionValueDependency.filter(
      (obj) => obj.attribute_name === "user_email"
    );
    if (emailIdsListForTransferTable.length > 0) {
      let dropDownUserList = transferTableData.filter((item) =>
        emailIdsListForTransferTable[0].values.some((x) => item.user_code === x)
      );
      setUsersSelected(dropDownUserList);
      let dropDownSelectedDbList = transferTableData.filter(
        (item) =>
          !emailIdsListForTransferTable[0].values.some(
            (x) => item.user_code === x
          )
      );
      setDbUserList(dropDownSelectedDbList);
    } else {
      setUsersSelected([]);
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const renderTransferUserModal = () => {
    return (
      <Dialog
        className={sharedClasses.root}
        maxWidth={"sm"}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="customized-dialog-title">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" gutterBottom>
              Transfer Users
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          classes={{
            root: sharedClasses.content,
          }}
        >
          <div className={classes.contentBody}>
            <TransferUser
              dropDownSelectedListOfUsers={usersSelected}
              userSelectedTableList={(data) => storeSelectedList(data)}
              dbTableList={(data) => storeDbList(data)}
              dbUserList={dbUserList}
              listOfUsers={transferTableData}
            />
          </div>
        </DialogContent>
        <DialogActions
          classes={{
            root: classes.footerDialog,
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveSelectedUsers()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div>
      <div>
        {props.hideIconButtonsOnEdit ? (
          ""
        ) : (
          <div className={classes.storageButtonPos}>
            <IconButton onClick={() => transferUsersModal()} size="large">
              <StorageRoundedIcon />
            </IconButton>
          </div>
        )}
        <FilterGroup
          style={{ border: "none" }}
          filters={filterConfig}
          customFilter={true}
          inititalSelection={initialDependencyValue}
          update={updateUserDetailFilterValuesOnChange}
        />
      </div>
      {showTransferUser && renderTransferUserModal()}
    </div>
  );
};

export default UserDetailsFilterForm;
