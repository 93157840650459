import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmBox from "./confirmPopup";
import SuccessBox from "./successPopup";
import Table from "../index";
import EditableCell from "./textfieldRenderer";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "8px",
    "& .MuiDialog-paper": {
      maxWidth: "1068px",
      maxHeight: "70vh",
    },
  },

  contentBody: {
    padding: theme.typography.pxToRem(25),
  },
  confirmBox: {
    "& .MuiDialog-paper": {
      borderRadius: "10px 10px 6px 6px",
    },
  },
  label: {
    color: theme.palette.common.black,
  },
}));

const ColPreference = (props) => {
  const classes = useStyles();
  const [confirmBox, showConfirmBox] = useState(false);
  const [showErrorBox, setShowErrorBox] = useState(false);
  const [error, setError] = useState("");
  const [rowdata, setData] = useState(
    props.rowdata.map((item, index) => {
      item.order_of_display = item.order_of_display
        ? item.order_of_display
        : index + 1;
      return item;
    })
  );
  const [successBox, showSuccessBox] = useState(false);
  const [flagEdit, setFlagEdit] = useState(false);

  let columns = [
    {
      Header: "Column Name",
      accessor: "Header",
      disableSortBy: true,
      order_of_display: 1,
    },
    {
      Header: "Hide Column",
      accessor: "is_hidden",
      disableSortBy: true,
      Cell: ({
        value: initialValue,
        row: { index },
        column,
        updateMyData: updateData,
      }) => {
        return (
          <Checkbox
            checked={initialValue}
            color="primary"
            size="small"
            onChange={(e) => {
              updateData(
                index,
                column.id,
                e.target.checked,
                initialValue,
                column
              );
            }}
          />
        );
      },
      type: 3,
    },
    {
      Header: "Editable",
      accessor: "is_editable",
      disableSortBy: true,
      Cell: ({
        value: initialValue,
        row: { index },
        column,
        updateMyData: updateData,
        ...wholedata
      }) => {
        return (
          <Checkbox
            color="primary"
            size="small"
            checked={initialValue}
            disabled={wholedata.data[index].system_field ? true : false}
            onChange={(e) => {
              updateData(
                index,
                column.id,
                e.target.checked,
                initialValue,
                column
              );
            }}
          />
        );
      },
      type: 3,
    },
    {
      Header: "Freeze Column",
      accessor: "is_frozen",
      disableSortBy: true,
      Cell: ({
        value: initialValue,
        row: { index },
        column,
        updateMyData: updateData, // This is a custom function that we supplied to our table instance
      }) => {
        return (
          <Checkbox
            color="primary"
            size="small"
            checked={initialValue}
            onChange={(e) => {
              updateData(
                index,
                column.id,
                e.target.checked,
                initialValue,
                column
              );
            }}
          />
        );
      },
      type: 3,
    },
    {
      Header: "Display Order",
      accessor: "order_of_display",
      type: 3,
      Cell: EditableCell,
    },
  ];

  const callWaringMsg = () => {
    setShowErrorBox(true);
    setTimeout(() => {
      setShowErrorBox(false);
    }, 5000);
  };

  const updateMyData = async (
    rowIndex,
    columnId,
    value,
    initialValue,
    column
  ) => {
    let revertChanges = false;

    if (columnId === "order_of_display") {
      // 1 2 3 4 ...> 2 to 4
      let max = rowdata.length;
      if (value > max) {
        setError(
          "Column order number cannot be more than the total columns count."
        );
        callWaringMsg();
        return;
      }
      let min = null;
      let oldValue = initialValue;
      let freezedColumnLength = rowdata.filter((item) => item.is_frozen).length;
      let isColumnFreeze = rowdata[rowIndex].is_frozen;
      if (isColumnFreeze && value > freezedColumnLength) {
        setError("Freezed Columns always displayed before regular columns.");
        callWaringMsg();
      }
      if (!isColumnFreeze && value < freezedColumnLength) {
        setError("Freezed Columns always displayed before regular columns.");
        callWaringMsg();
      }

      if (value > oldValue) {
        min = oldValue;
        max = value;
        await setData((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              let finalValue = value;
              if (isColumnFreeze) {
                finalValue =
                  freezedColumnLength < value ? freezedColumnLength : value;
              }
              revertChanges = finalValue === oldValue ? true : false;
              return {
                ...row,
                [columnId]: finalValue,
              };
            } else if (
              row[columnId] > min &&
              row[columnId] <= max &&
              row.is_frozen === isColumnFreeze
            ) {
              return {
                ...row,
                [columnId]: row[columnId] - 1,
              };
            }
            return row;
          })
        );
      } else if (value < oldValue) {
        min = value;
        max = oldValue;
        let finalData = rowdata.map((row, index) => {
          if (index === rowIndex) {
            let finalValue = value;
            if (!isColumnFreeze) {
              finalValue =
                freezedColumnLength > value ? freezedColumnLength + 1 : value;
            }
            row.togglestate = row.togglestate ? row.togglestate + 1 : 0;
            revertChanges = finalValue === oldValue ? true : false;
            return {
              ...row,
              [columnId]: finalValue,
            };
          } else if (
            row[columnId] >= min &&
            row[columnId] < max &&
            row.is_frozen === isColumnFreeze
          ) {
            return {
              ...row,
              [columnId]: row[columnId] + 1,
            };
          }
          return row;
        });
        await setData(finalData);
      }
    } else if (columnId === "is_frozen") {
      let data = rowdata.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      });
      data.sort((x, y) => {
        let check = x.is_frozen ? -1 : 1;
        return x.is_frozen === y.is_frozen ? 0 : check;
      });
      data = data.map((item, index) => {
        item.order_of_display = index + 1;
        return item;
      });
      // await setData([]);
      setData(data);
    } else {
      setData((old) =>
        old.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...old[rowIndex],
              [columnId]: value,
            };
          }
          return row;
        })
      );
    }
    if (!flagEdit) {
      setFlagEdit(true);
    }
    return revertChanges;
  };

  const onCancel = () => {
    if (flagEdit) {
      showConfirmBox(true);
    } else {
      props.handleModalClose();
    }
  };

  const onApply = () => {
    let finaldata = [...rowdata];
    let sortedData = finaldata.sort((a, b) => {
      if (a.order_of_display < b.order_of_display) return -1;
      return a.order_of_display > b.order_of_display ? 1 : 0;
    });
    let orderedColumn = sortedData.map((item) => item.accessor);
    let hiddenColumn = sortedData
      .filter((item) => item.is_hidden)
      .map((item) => item.accessor);
    let freezedColumns = sortedData
      .filter((item) => item.is_frozen)
      .map((item) => item.accessor);
    props.apply(orderedColumn, hiddenColumn, freezedColumns, sortedData);
  };

  return (
    <Dialog
      onClose={() => onCancel()}
      maxWidth={"sm"}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth={true}
      className={classes.root}
      disableEscapeKeyDown={true}
    >
      {confirmBox && (
        <ConfirmBox
          onClose={() => showConfirmBox(false)}
          onConfirm={() => {
            showConfirmBox(false);
            props.handleModalClose();
          }}
        />
      )}
      {successBox && (
        <SuccessBox
          onClose={() => showSuccessBox(false)}
          onConfirm={() => {
            showSuccessBox(false);
            props.handleModalClose();
          }}
        />
      )}

      <DialogTitle id="customized-dialog-title">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Set Column Preference
          <IconButton
            aria-label="close"
            onClick={() => onCancel()}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {showErrorBox && (
          <div>
            <span style={{ color: "red" }}>{error}</span>
          </div>
        )}
        <Table
          columns={columns}
          rowdata={rowdata}
          tableId={"tableProperties"}
          rowHeight={30}
          style={{ maxHeight: 450 }}
          updateMyData={updateMyData}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button variant="contained" onClick={onApply} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColPreference;
