import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button, Typography, Container } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { isEmpty } from "lodash";
import { withTransaction } from "@elastic/apm-rum-react";
import { getColumns } from "actions/tableColumnActions";
import { getColumnsAg } from "actions/tableColumnActions";
import { addSnack } from "actions/snackbarActions";
import {
  saveNewUser,
  deleteUser,
  getUserDetails,
  setUserTableDetails,
  setUserMgmtLoader,
} from "../services/TenantManagement/User-Management/user-management-service";
import AgGridComponent from "Utils/agGrid";
import { common } from "modules/react-demo/constants/stringContants";
import Loader from "Utils/Loader/loader";
import globalStyles from "Styles/globalStyles";
import Adduser from "./add-user";
import { agGridRowFormatter } from "Utils/agGrid/row-formatter";
import { getInputNoun } from "Utils/formatter";
import ConfirmPrompt from "commonComponents/confirmPrompt";

const Users = (props) => {
  const [userTableColumns, setUserTableColumns] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [selectedRowsIDs, setSelectedRowsIDs] = useState([]);
  const [selected, setSelected] = useState(false);
  const [enableStatus, setEnableStatus] = useState(false);
  const [renderAgGrid, setRenderAgGrid] = useState(true); // using instead of aggrid refreshServerSideStore api
  const globalClasses = globalStyles();

  const postBody = {
    meta: {
      search: [],
      limit: {
        limit: 10,
        page: 1,
      },
    },
  };

  useEffect(() => {
    props.setUserMgmtLoader(true);
    const fetchColumnData = async () => {
      let cols = await getColumnsAg("table_name=user_mgmt")();
      setUserTableColumns(cols);
    };
    fetchColumnData();
  }, []);

  // To maintain button active state even when navigating to different pages
  useEffect(() => {
    if (selectedRowsIDs.length) setEnableStatus(true);
    else setEnableStatus(false);
  }, [selectedRowsIDs]);

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  const renderUserTableData = async (msg, type) => {
    displaySnackMessages(msg, type);
    setSelected(false);
    setShowAddUser(false);
    setSelectedRowsIDs([]);
    setRenderAgGrid(true);
    //let response = await props.getUserDetails(postBody);
    let response = {
      total: 1,
      page: 1,
      count: 1,
      status: true,
      data: [
        {
          user_name: "Demo User",
          user_code: 252,
          email: "demo@impactanalytics.co",
        },
      ],
      message: "Successful",
      previous: null,
      next: null,
    };
    props.setUserTableDetails(response.data.data);
  };

  const saveNewUsersAdded = async (data) => {
    setRenderAgGrid(false);
    props.setUserMgmtLoader(true);
    let body = {
      users: data,
    };
    try {
      await props.saveNewUser(body);
      const userString = getInputNoun("User", body.users.length);
      renderUserTableData(
        `${userString}${" "}${"added successfully"}`,
        "success"
      );
    } catch (err) {
      props.setUserMgmtLoader(false);
      if (!isEmpty(err.response.data?.message)) {
        displaySnackMessages(err.response.data?.message, "error");
      } else {
        displaySnackMessages("Something went wrong", "error");
      }
    }
  };

  const deleteSelectedUsers = () => {
    if (enableStatus) setSelected(true);
    else setSelected(false);
  };

  const handleConfirmBox = async () => {
    setRenderAgGrid(false);
    props.setUserMgmtLoader(true);
    let deleteUsersList = {
      users: selectedRowsIDs,
    };
    try {
      await props.deleteUser(deleteUsersList);
      const userString = getInputNoun("User", deleteUsersList.users.length);
      renderUserTableData(
        `${userString}${" "}${"deleted successfully"}`,
        "success"
      );
    } catch (err) {
      props.setUserMgmtLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const manualCallBack = async (manualbody, pageIndex, params) => {
    props.setUserMgmtLoader(true);
    let body = {
      meta: {
        ...manualbody,
        limit: { limit: 10, page: pageIndex + 1 },
      },
    };
    try {
      //let response = await props.getUserDetails(body);
      let response = {
        total: 1,
        page: 1,
        count: 1,
        status: true,
        data: [
          {
            user_name: "Demo User",
            user_code: 252,
            email: "demo@impactanalytics.co",
          },
        ],
        message: "Successful",
        previous: null,
        next: null,
      };
      //agGridRowFormatter should be removed once it's handled from BE
      //to handle it from BE pass params.api.checkConfiguration in request body to the above api call
      let formatedData = agGridRowFormatter(
        response.data.data,
        params.api.checkConfiguration,
        "user_code"
      );
      props.setUserTableDetails(formatedData);
      // passing total count along with data here as total count gets set to more
      // null is passed initially
      return {
        data: formatedData,
        totalCount: response.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      props.setUserMgmtLoader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const onSelectionChanged = (event) => {
    // fetch all selected rows
    let selections = event.api.getSelectedRows();
    setSelectedRowsIDs(selections);
  };

  return (
    <Loader loader={props.loaderUserMgmt}>
      <Container maxWidth={false}>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
        >
          <Typography
            variant="h3"
            component="h3"
            className={`${globalClasses.pageHeader}`}
          >
            Manage Added User On Platform
          </Typography>
          <div className={`${globalClasses.flexRow} ${globalClasses.gap}`}>
            <Button
              variant="contained"
              color="primary"
              disabled={enableStatus ? false : true}
              onClick={() => deleteSelectedUsers()}
              startIcon={<DeleteIcon />}
            >
              Delete Selected Users
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowAddUser(true)}
              startIcon={<AddIcon />}
            >
              Add User
            </Button>
          </div>
        </div>
        {showAddUser && (
          <Adduser
            handleClose={() => setShowAddUser(false)}
            onApply={(data) => saveNewUsersAdded(data)}
            addSnack={props.addSnack}
            getColumns={props.getColumns}
          ></Adduser>
        )}
        {renderAgGrid && (
          <AgGridComponent
            columns={userTableColumns}
            selectAllHeaderComponent={true}
            onSelectionChanged={onSelectionChanged}
            onRowSelected
            manualCallBack={(body, pageIndex, params) =>
              manualCallBack(body, pageIndex, params)
            }
            rowModelType="serverSide"
            serverSideStoreType="partial"
            cacheBlockSize={10}
            sizeColumnsToFitFlag={true}
            uniqueRowId={"user_code"}
            rowSelection={"multiple"}
          />
        )}
        <ConfirmPrompt
          showModal={selected}
          title="Delete Users"
          message="Are you sure you want to delete selected users?"
          ariaLabeledBy="delete-users-dialog"
          primaryBtnText={common.__ConfirmBtnText}
          secondaryBtnText={common.__RejectBtnText}
          showCloseIcon={true}
          setConfirm={setSelected}
          confirmCallback={(val) => {
            if (val) {
              handleConfirmBox();
            }
          }}
        />
      </Container>
    </Loader>
  );
};

Users.defaultProps = {
  listOfUserData: [],
  loaderUserMgmt: false,
};

Users.propTypes = {
  listOfUserData: PropTypes.array,
  loaderUserMgmt: PropTypes.bool,
  addSnack: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  saveNewUser: PropTypes.func.isRequired,
  getColumns: PropTypes.func.isRequired,
  getColumnsAg: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => {
  const { tenantUserRoleMgmtReducer } = store;
  return {
    listOfUserData:
      tenantUserRoleMgmtReducer.userManagementReducer.listOfUserData,
    loaderUserMgmt:
      tenantUserRoleMgmtReducer.userManagementReducer.loaderUserMgmt,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    getUserDetails: (body) => dispatch(getUserDetails(body)),
    setUserTableDetails: (body) => dispatch(setUserTableDetails(body)),
    setUserMgmtLoader: (body) => dispatch(setUserMgmtLoader(body)),
    deleteUser: (body) => dispatch(deleteUser(body)),
    saveNewUser: (body) => dispatch(saveNewUser(body)),
    getColumns: (body) => dispatch(getColumns(body)),
    getColumnsAg: (body) => dispatch(getColumnsAg(body)),
  };
};

export default withTransaction(
  "Users",
  "component"
)(connect(mapStateToProps, mapDispatchToProps)(Users));
