import {
  CHANGE_FILTER_DATA,
  SET_SELECTED_FILTERS,
  SET_SELECTED_FILTER_CONFIGURATION,
  SET_SAVED_FILTER_SELECTION,
} from "../actions/types";

export const initialState = {
  filterInitialData: [],
  selectedFilters: {},
  filterDashboardConfiguration: {},
  savedFilterSelection: {
    total: null,
    page: null,
    count: null,
    status: true,
    data: [
      {
        filter_type: "cascaded",
        attribute_name: "l0_name",
        operator: "in",
        values: ["Accessories", "Bags", "Other", "Travel"],
        dimension: "product",
      },
      {
        filter_type: "cascaded",
        attribute_name: "l1_name",
        operator: "in",
        values: [
          "Baby Bags",
          "Handbags",
          "Jewelry",
          "Other",
          "Travel Accessories",
        ],
        dimension: "product",
      },
    ],
    message: "",
    previous: null,
    next: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_FILTER_DATA:
      return {
        ...state,
        filterInitialData: action.payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: { ...state.selectedFilters, ...action.payload },
      };
    case SET_SELECTED_FILTER_CONFIGURATION:
      return {
        ...state,
        filterDashboardConfiguration: {
          ...state.filterDashboardConfiguration,
          ...action.payload,
        },
      };
    case SET_SAVED_FILTER_SELECTION:
      return {
        ...state,
        savedFilterSelection: action.payload,
      };
    default:
      return state;
  }
}
