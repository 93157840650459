import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import Form from "Utils/form";
import AgGridComponent from "Utils/agGrid";
import globalStyles from "Styles/globalStyles";
import { addSnack } from "actions/snackbarActions";
import { getColumnsAg } from "actions/tableColumnActions";

import {
  setLostSalesTableData,
  getLostSalesTableData,
  setLostSalesDataLoader,
} from "../../../services-inventorysmart/Allocation-Reports/lost-sales-service";
import {
  ERROR_MESSAGE,
  LOST_SALES_FISCAL_WEEK,
} from "../../../constants-inventorysmart/stringConstants";
import { isEmpty } from "lodash";

const LostSalesTableViewComponent = (props) => {
  const [lostSalesTableConfig, setLostSalesTableConfig] = useState([]);
  const [lostSalesTableData, setLostSalesData] = useState([]);
  const [lostSalesFiscalWeek, setLostSalesFiscalWeek] = useState({
    fiscal_week: "",
  });
  const [fiscalWeekLostSaleDetails, setFiscalWeekLostSaleDetails] = useState({
    units: "",
    revenue: "",
  });
  const filterConfig = useRef({});

  const useStyles = makeStyles(() => ({
    fiscalWeekContainer: {
      width: "20%",
      marginBottom: "1rem",
    },
  }));

  const globalClasses = globalStyles();
  const classes = useStyles();

  const fetchLostSalesTableData = async () => {
    props.setLostSalesDataLoader(true);
    let selectedFiscalWeek = lostSalesFiscalWeek?.fiscal_week?.split("-");
    let body = {
      filters: filterConfig.current,
      week: selectedFiscalWeek[0],
      year: selectedFiscalWeek[1],
    };
    try {
      let response = await props.getLostSalesTableData(body);
      props.setLostSalesTableData(response.data?.data);
      props.setLostSalesDataLoader(false);

      setLostSalesData(response.data?.data);
    } catch (e) {
      props.setLostSalesDataLoader(false);
      displaySnackMessages(ERROR_MESSAGE, "error");
    } finally {
      if (props.hideGraphComponent) {
        props.toggleModal(false);
      }
    }
  };

  useEffect(() => {
    (async () => {
      props.setLostSalesDataLoader(true);
      try {
        let lostSalesColDef = await getColumnsAg(
          "table_name=inventory_loss_sales_list"
        )();

        setLostSalesTableConfig(lostSalesColDef);
      } catch (e) {
        props.setLostSalesDataLoader(false);
        displaySnackMessages(ERROR_MESSAGE, "error");
      }
    })();
  }, []);

  useEffect(() => {
    if (lostSalesFiscalWeek?.fiscal_week) {
      fetchLostSalesTableData();
    }
  }, [lostSalesFiscalWeek]);

  useEffect(() => {
    if (!isEmpty(props.selectedFilters)) {
      filterConfig.current = props.selectedFilters;
      if (props.hideGraphComponent) {
        const fiscalYear = props.selectedDates?.fiscalInfoStartDate?.fiscal_year_week
          ?.toString()
          .slice(0, 4);
        const fiscalWeek = props.selectedDates?.fiscalInfoStartDate?.fiscal_year_week
          ?.toString()
          .slice(4);
        setLostSalesFiscalWeek({ fiscal_week: `${fiscalWeek}-${fiscalYear}` });
      }
    }
  }, [props.selectedFilters]);

  useEffect(() => {
    if (props.weeksAvailable?.length) {
      LOST_SALES_FISCAL_WEEK[0].options = props.weeksAvailable.map((item) => {
        let fiscalWeekYearValue = `${item.fiscal_week}-${item.fiscal_year}`;
        return {
          label: fiscalWeekYearValue,
          value: fiscalWeekYearValue,
          id: fiscalWeekYearValue,
        };
      });
      setFiscalWeekLostSaleDetails({
        ...fiscalWeekLostSaleDetails,
        units: props.weeksAvailable[0].lost_units,
        revenue: props.weeksAvailable[0].lost_sales,
      });
      let defaultSelectedYearVal = `${props.weeksAvailable[0].fiscal_week}-${props.weeksAvailable[0].fiscal_year}`;
      setLostSalesFiscalWeek({ fiscal_week: defaultSelectedYearVal });
    }
  }, [props.weeksAvailable]);

  const handleChangeFiscalWeekValue = (updatedFormData) => {
    setLostSalesFiscalWeek(updatedFormData);
    // To populate the lost sales summary details
    // fetching the lost sales details based on selected fiscal week and year
    if (props.weeksAvailable.length) {
      let selectedFiscalYear = updatedFormData.fiscal_week.split("-");
      let filteredFiscalYearDetails = props.weeksAvailable.filter((item) => {
        if (
          item.fiscal_week === parseInt(selectedFiscalYear[0]) &&
          item.fiscal_year === parseInt(selectedFiscalYear[1])
        )
          return item;
      });
      filteredFiscalYearDetails.length &&
        setFiscalWeekLostSaleDetails({
          ...fiscalWeekLostSaleDetails,
          units: filteredFiscalYearDetails[0].lost_units,
          revenue: filteredFiscalYearDetails[0].lost_sales,
        });
    }
  };

  const displaySnackMessages = (message, variance) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
      },
    });
  };

  return (
    <div>
      <div>
        <Typography variant="h5" className={globalClasses.paddingVertical}>
          Lost Sales - Detailed View
        </Typography>
        {!props.hideGraphComponent && (
          <Typography variant="h5" className={globalClasses.paddingVertical}>
            Lost Sales Unit: {fiscalWeekLostSaleDetails.units}, Lost Sales
            Revenue: {fiscalWeekLostSaleDetails.revenue}
          </Typography>
        )}
      </div>

      {!props.hideGraphComponent && (
        <div className={classes.fiscalWeekContainer}>
          <Form
            layout={"vertical"}
            maxFieldsInRow={1}
            handleChange={handleChangeFiscalWeekValue}
            fields={LOST_SALES_FISCAL_WEEK}
            updateDefaultValue={false}
            defaultValues={lostSalesFiscalWeek}
            labelWidthSpan={2}
            fieldTypeWidthSpan={2}
          ></Form>
        </div>
      )}
      <AgGridComponent
        columns={lostSalesTableConfig}
        rowdata={lostSalesTableData}
        downloadAsExcel={props.enableDownload}
        uniqueRowId={"key"}
      />
    </div>
  );
};

const mapStateToProps = (store) => {
  const { inventorysmartReducer } = store;
  return {
    selectedFilters:
      inventorysmartReducer.inventorySmartLostSalesService.selectedFilters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (snack) => dispatch(addSnack(snack)),
    getLostSalesTableData: (body) => dispatch(getLostSalesTableData(body)),
    setLostSalesTableData: (body) => dispatch(setLostSalesTableData(body)),
    setLostSalesDataLoader: (body) => dispatch(setLostSalesDataLoader(body)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostSalesTableViewComponent);
