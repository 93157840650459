import HeaderBreadCrumbs from "Utils/HeaderBreadCrumbs";
import { useHistory } from "react-router";
import { DASHBOARD } from "../../constants-inventorysmart/routesConstants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getFilterConfiguration,
  setInventorysmartFilterLoader,
  setSelectedFilters,
  setIsFiltersValid,
} from "modules/inventorysmart/services-inventorysmart/Decision-Dashboard/decision-dashboard-services";
import {
  getStoreTableData,
  resetConstraintsStoreState,
  setConstraintsArticles,
  setConstraintsLoader,
  setConstraintsStoreCodes,
  setInventorysmartConstraintsFilterDependency,
} from "modules/inventorysmart/services-inventorysmart/Constraints/constraints-services";
import {
  fetchFilterConfig,
  fetchFilterOptions,
  filtersPayload,
} from "../inventorysmart-utility";
import globalStyles from "Styles/globalStyles";
import ConstraintTables from "./components/constraints-tables";
import { addSnack } from "actions/snackbarActions";
import LoadingOverlay from "Utils/Loader/loader";
import CustomAccordion from "commonComponents/Custom-Accordian";
import { Button, Grid } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterChips from "commonComponents/filters/filterChips";
import FilterModal from "commonComponents/filterModal/FilterModal";
import { cloneDeep } from "lodash";
import Filters from "commonComponents/filters/filterGroup";
import {
  getModuleLevelAccess,
  setInventorySmartModulesPermissions,
  setInventorySmartPermissionLoader,
} from "modules/inventorysmart/services-inventorysmart/common/inventory-smart-common-services";
import {
  APP_NAME,
  ROLES_ACCESS_MODULES_MAPPING,
  FULL_ACCESS_PERMISSIONS_LIST,
} from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const InventoryDashboard = (props) => {
  const history = useHistory();
  const globalClasses = globalStyles();

  const [filters, setFilters] = useState([]);
  const [filterElements, setFilterElements] = useState([]);
  const [filterDependency, setFilterDependency] = useState([]);
  const [filterDependencyChips, setFilterDependencyChips] = useState([]);
  const [filterPayload, setFilterPayload] = useState([]);
  const [isFiltersValid, updateIsFiltersValid] = useState(false);
  const [isRedirectedFromDifferentPage, setIsRedirectedFromDifferentPage] =
    useState(false);
  const [openModal, setOpenModal] = useState(false);

  const type = new URLSearchParams(window.location.search).get("type");

  const savedFiltersDependency =
    JSON.parse(localStorage.getItem("selectedFiltersDependency")) || [];
  const articles = JSON.parse(localStorage.getItem("selectedArticles")) || [];
  const storeCodes = JSON.parse(localStorage.getItem("storeCodes")) || [];

  const onFilterDashboard = async (elements, dependency) => {
    const payload = filtersPayload(
      elements || filterElements,
      dependency || filterDependency,
      true
    );
    payload.reqBody = payload.reqBody.filter(
      (item) =>
        ["sale_type", "store_capacity"].indexOf(item.attribute_name) === -1
    );

    setFilterPayload(payload.reqBody);
    updateIsFiltersValid(payload.isValid);
    setFilterDependencyChips(dependency || filterDependency);
    setOpenModal(false);
  };

  const onResetDashboard = async () => {
    props.setInventorysmartConstraintsFilterDependency([]);
    setFilterDependency([]);
    onFilterDashboard(filterElements, []);
    setFilterDependencyChips([]);
    setOpenModal(false);
  };

  useEffect(() => {
    const selectedArticles =
      articles?.length > 0 ? articles : props.selectedConstraintArticles;
    const selectedFiltersDependency =
      savedFiltersDependency?.length > 0
        ? savedFiltersDependency
        : props.inventorysmartConstraintsFilterDependency;

    const redirectedFromDifferentPage = type && selectedArticles?.length > 0;

    props.setInventorysmartConstraintsFilterDependency(
      selectedFiltersDependency
    );
    props.setConstraintsArticles(articles);
    props.setConstraintsStoreCodes(storeCodes);
    setIsRedirectedFromDifferentPage(redirectedFromDifferentPage);
    localStorage.removeItem("selectedFiltersDependency");
    localStorage.removeItem("selectedArticles");
    localStorage.removeItem("storeCodes");

    const fetchData = async () => {
      props.setConstraintsLoader(true);
      try {
        //fetch the filter levels
        let response = await fetchFilterConfig("inventorysmart_constraints");
        setFilters(response);
        props.setConstraintsLoader(false);
      } catch (error) {
        props.setConstraintsLoader(false);
      }
    };

    fetchData();

    return () => {
      props.resetConstraintsStoreState([]);
    };
  }, []);

  useEffect(() => {
    if (props.inventorysmartScreenConfig) {
      const fetchModulesAccess = async () => {
        try {
          props.setInventorySmartPermissionLoader(true);

          const moduleName = props?.module;
          const subModules = ROLES_ACCESS_MODULES_MAPPING[props?.module];

          let rolesBasedModulesPermission = {};

          if (props.inventorysmartScreenConfig.roleBasedAccess) {
            await Promise.all(
              subModules.map(async (module) => {
                const accessDataResponse = await props?.getModuleLevelAccess({
                  app: APP_NAME,
                  module,
                });

                rolesBasedModulesPermission[module] = Object.keys(
                  accessDataResponse.data.data
                );
              })
            );
          } else {
            subModules.map(async (subModule) => {
              rolesBasedModulesPermission[subModule] =
                FULL_ACCESS_PERMISSIONS_LIST;
            });
          }

          props?.setInventorySmartModulesPermissions({
            [moduleName]: rolesBasedModulesPermission,
          });
        } catch (error) {
          console.log(error, "e");
        } finally {
          props.setInventorySmartPermissionLoader(false);
        }
      };

      fetchModulesAccess();
    }
  }, [props.inventorysmartScreenConfig]);

  useEffect(() => {
    if (!filters || filters?.length === 0) {
      return;
    }
    getFiltersOptions();
  }, [filters]);

  const updateFilters = async (selected, current) => {
    if (["sale_type", "store_capacity"].indexOf(current?.filter_id) === -1) {
      await getFiltersOptions(selected, current);
      setFilterDependency(selected);
    }
    setFilterDependencyChips(selected);
  };
  const getFiltersOptions = async (selected, current) => {
    try {
      props.setConstraintsLoader(true);

      const selectedFilters = isRedirectedFromDifferentPage
        ? cloneDeep(props.inventorysmartConstraintsFilterDependency)
        : selected;

      const response = await fetchFilterOptions(
        filters || [],
        selectedFilters,
        current,
        true,
        props.inventorysmartScreenConfig.roleBasedAccess,
        props.screenName
      );

      if (isRedirectedFromDifferentPage) {
        onFilterDashboard(response, selectedFilters);
      }

      setFilterElements(response);
    } catch (error) {
      props.addSnack({
        message: "Error while fetching options",
        options: {
          variant: "error",
        },
      });
    } finally {
      props.setConstraintsLoader(false);
    }
  };

  return (
    <>
      <HeaderBreadCrumbs
        options={[
          {
            label: "Constraints",
            id: 1,
            action: () => {
              history.push(DASHBOARD);
            },
          },
        ]}
      ></HeaderBreadCrumbs>
      <div className={globalClasses.filterWrapper}>
        <Grid
          container
          direction="row-reverse"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid>
            <Button
              variant="contained"
              color="primary"
              startIcon={<FilterAltOutlinedIcon />}
              onClick={() => setOpenModal(true)}
            >
              Select Filters
            </Button>
          </Grid>
        </Grid>
        {filterDependencyChips?.length > 0 && (
          <FilterChips filterConfig={filterDependencyChips}></FilterChips>
        )}
      </div>
      <FilterModal
        open={openModal}
        isModalFixedTop={true}
        closeOnOverlayClick={() => setOpenModal(false)}
      >
        <CustomAccordion label="Filter" defaultExpanded={true}>
          <LoadingOverlay
            loader={
              props.constraintsLoader || props.inventorySmartPermissionLoader
            }
          >
            <Filters
              filters={filterElements}
              update={updateFilters}
              onFilter={onFilterDashboard}
              onReset={onResetDashboard}
              filterElevation={3}
              showBorderedWrapper={false}
              disabledDropdown={isRedirectedFromDifferentPage}
              screen="inventory-smart-constraints"
              inititalSelection={
                props.inventorysmartConstraintsFilterDependency
              }
              doNotUpdateDefaultValue
            />
          </LoadingOverlay>
        </CustomAccordion>
      </FilterModal>
      {isFiltersValid && (
        <ConstraintTables
          filterDependency={filterPayload}
          isRedirectedFromDifferentPage={isRedirectedFromDifferentPage}
          module={props.module}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    constraintsLoader:
      store.inventorysmartReducer.inventorySmartConstraints.constraintsLoader,
    selectedConstraintArticles:
      store.inventorysmartReducer.inventorySmartConstraints
        .selectedConstraintArticles,
    inventorysmartConstraintsFilterDependency:
      store.inventorysmartReducer.inventorySmartConstraints
        .inventorysmartConstraintsFilterDependency,
    inventorysmartScreenConfig:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorysmartScreenConfig,
    inventorySmartPermissionLoader:
      store.inventorysmartReducer.inventorySmartCommonService
        .inventorySmartPermissionLoader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (payload) => dispatch(addSnack(payload)),
  getStoreTableData: (payload) => dispatch(getStoreTableData(payload)),
  getFilterConfiguration: (payload) =>
    dispatch(getFilterConfiguration(payload)),
  setSelectedFilters: (payload) => dispatch(setSelectedFilters(payload)),
  setConstraintsLoader: (payload) => dispatch(setConstraintsLoader(payload)),
  setIsFiltersValid: (payload) => dispatch(setIsFiltersValid(payload)),
  setInventorysmartFilterLoader: (payload) =>
    dispatch(setInventorysmartFilterLoader(payload)),
  setInventorysmartConstraintsFilterDependency: (payload) =>
    dispatch(setInventorysmartConstraintsFilterDependency(payload)),
  setConstraintsArticles: (payload) =>
    dispatch(setConstraintsArticles(payload)),
  setConstraintsStoreCodes: (payload) =>
    dispatch(setConstraintsStoreCodes(payload)),
  resetConstraintsStoreState: (payload) =>
    dispatch(resetConstraintsStoreState(payload)),
  setInventorySmartPermissionLoader: (payload) =>
    dispatch(setInventorySmartPermissionLoader(payload)),
  setInventorySmartModulesPermissions: (payload) =>
    dispatch(setInventorySmartModulesPermissions(payload)),
  getModuleLevelAccess: (payload) => dispatch(getModuleLevelAccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDashboard);
