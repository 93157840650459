import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";

import {
  GET_LOST_SALES_GRAPHDATA,
  GET_LOST_SALES_TABLE_DETAILS,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";

export const allocationLostSalesService = createSlice({
  name: "allocationLostSalesService",
  initialState: {
    lostSalesScreenLoader: false,
    lostSalesDataLoader: false,
    lostSalesFiscalWeekGraph: [],
    lostSalesFilterElements: [],
    lostSalesFilterDependency: [],
    lostSalesTableDate: [],
    isFiltersValid: false,
    selectedFilters: null,
  },
  reducers: {
    setLostSalesScreenLoader: (state, action) => {
      state.lostSalesScreenLoader = action.payload;
    },
    setLostSalesDataLoader: (state, action) => {
      state.lostSalesDataLoader = action.payload;
    },
    setLostSalesFiscalGraphData: (state, action) => {
      state.lostSalesFiscalWeekGraph = action.payload;
    },
    setLostSalesTableData: (state, action) => {
      state.lostSalesTableDate = action.payload;
    },
    setLostSalesFilterElements: (state, action) => {
      state.lostSalesFilterElements = action.payload;
    },
    setLostSalesFilterDependency: (state, action) => {
      state.lostSalesFilterDependency = action.payload;
    },
    setSelectedFilters: (state, action) => {
      state.selectedFilters = action.payload;
    },
    setIsFiltersValid: (state, action) => {
      state.isFiltersValid = action.payload;
    },
    clearLostSalesStates: (state) => {
      state.lostSalesScreenLoader = false;
      state.lostSalesDataLoader = false;
      state.lostSalesFiscalWeekGraph = [];
      state.lostSalesFilterElements = [];
      state.lostSalesFilterDependency = [];
      state.isFiltersValid = false;
      state.selectedFilters = null;
    },
  },
});

export const {
  setLostSalesScreenLoader,
  setLostSalesDataLoader,
  setLostSalesFiscalGraphData,
  setLostSalesTableData,
  setLostSalesFilterElements,
  setLostSalesFilterDependency,
  setSelectedFilters,
  setIsFiltersValid,
  clearLostSalesStates,
} = allocationLostSalesService.actions;

export const getLostSalesFiscalWeekGraph = (postbody) => () => {
  return axiosInstance({
    url: GET_LOST_SALES_GRAPHDATA,
    method: "POST",
    data: postbody,
  });
};

export const getLostSalesTableData = (postbody) => () => {
  return axiosInstance({
    url: GET_LOST_SALES_TABLE_DETAILS,
    method: "POST",
    data: postbody,
  });
};

export default allocationLostSalesService.reducer;
