import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";

import { GET_ALLOCATION_DEEP_DIVE_TABLE_DETAILS } from "modules/inventorysmart/constants-inventorysmart/apiConstants";

export const allocationDeepDiveService = createSlice({
  name: "allocationDeepDiveService",
  initialState: {
    allocationDeepDiveLoader: false,
    allocationDeepDiveTableData: [],
    allocationDeepDiveFilterConfiguration: [],
  },
  reducers: {
    setAllocationDeepDiveScreenLoader: (state, action) => {
      state.allocationDeepDiveLoader = action.payload;
    },
    setAllocationDeepDiveTableData: (state, action) => {
      state.allocationDeepDiveTableData = action.payload;
    },
    setAllocationDeepDiveFilterConfiguration: (state, action) => {
      state.allocationDeepDiveFilterConfiguration = action.payload;
    },
    clearAllocationDeepDiveStates: (state) => {
      state.allocationDeepDiveLoader = false;
      state.allocationDeepDiveTableData = [];
      state.allocationDeepDiveFilterConfiguration = [];
    },
  },
});

export const {
  setAllocationDeepDiveScreenLoader,
  setAllocationDeepDiveTableData,
  setAllocationDeepDiveFilterConfiguration,
  clearAllocationDeepDiveStates,
} = allocationDeepDiveService.actions;

export const getAllocationDeepDiveTableData = (postbody) => () => {
  return axiosInstance({
    url: GET_ALLOCATION_DEEP_DIVE_TABLE_DETAILS,
    method: "POST",
    data: postbody,
  });
};

export default allocationDeepDiveService.reducer;
