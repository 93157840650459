import { cloneDeep } from "lodash";
import { ALERTS_ACTION_MAP } from "modules/inventorysmart/constants-inventorysmart/stringConstants";
import { setInventoryDashboardAlertCount } from "modules/inventorysmart/services-inventorysmart/KPI-Matrix/kpi-services";
import { reviewAlerts } from "modules/inventorysmart/services-inventorysmart/StoreInventoryAlerts/alerts-actions-service";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import AgGridComponent from "Utils/agGrid";
import agGridColumnFormatter from "Utils/agGrid/column-formatter";
import Loader from "Utils/Loader/loader";
import {
  fetchProductCodes,
  modifyInvetoryAlertsCount,
} from "../inventorysmart-utility";

import AlertsAction from "./components/AlertsAction";

const StoreInventoryAlerts = (props) => {
  const agGridInstance = useRef(null);
  const inventoryAlertsCount = useRef([]);

  const [showActionLoader, setShowActionLoader] = useState(false);
  const [showAlertAction, setShowAlertsAction] = useState(false);
  const [
    storeInventoryAlertsTableColumns,
    setStoreInventoryAlertsTableColumns,
  ] = useState([]);
  const [selectedAlertsTableData, setSelectedAlertsTableData] = useState(null);

  useEffect(() => {
    const fetchColumnData = async () => {
      let formattedColumns = agGridColumnFormatter(props.columnConfig);
      setStoreInventoryAlertsTableColumns(formattedColumns);
    };
    fetchColumnData();
  }, [props.columnConfig]);

  useEffect(() => {
    if (props.inventoryDashboardAlertCount?.length > 0) {
      inventoryAlertsCount.current = cloneDeep(
        props.inventoryDashboardAlertCount
      );
    }
  }, [props.inventoryDashboardAlertCount]);

  const handleAlertsAction = (data) => {
    setSelectedAlertsTableData(data);
    setShowAlertsAction(true);

    console.log("Data", data);

    if (
      data.level === 1 &&
      data.table_name &&
      data.is_tracking &&
      data.is_not_resolved > 0
    ) {
      let articles = [];
      let product_codes = [];

      if (data?.type[data.level] === ALERTS_ACTION_MAP.POP_UP_LINK) {
        articles = [...data?.[`${data?.type[data.level]}_data`].articles];
        product_codes = articles;
      } else if (data?.type[data.level] === ALERTS_ACTION_MAP.DYNAMIC_POP_UP) {
        articles = [...data?.[`${data?.type[data.level]}_data`]?.data].filter(
          (article) => article.is_resolved === 0
        );
        product_codes = fetchProductCodes(articles);
      } else {
        articles = [...data?.[`${data?.type[data.level]}_data`]].filter(
          (article) => article.is_resolved === 0
        );
        product_codes = fetchProductCodes(articles);
      }

      const payload = {
        table_name: data.table_name,
        product_codes,
      };

      data.is_resolved = data.is_resolved + product_codes?.length;
      agGridInstance.current?.api?.redrawRows();

      const alertsCount = modifyInvetoryAlertsCount(
        inventoryAlertsCount.current,
        product_codes
      );

      props.setInventoryDashboardAlertCount(alertsCount);
      props.reviewAlerts(payload);
    }
  };

  const onReviewClick = (data) => {
    if (data?.level) {
      resetPopupsAndTables();
      setTimeout(() => {
        handleAlertsAction(data);
      }, 0);
    }
  };

  const resetPopupsAndTables = () => {
    setShowAlertsAction(false);
  };

  const loadAlertsTableInstance = (params) => {
    agGridInstance.current = params;
  };

  return (
    <>
      <Loader
        loader={
          props.storeInventoryAlertsTableConfigLoader ||
          props.storeInventoryAlertsTableDataLoader ||
          showActionLoader
        }
        minHeight={"120px"}
      >
        <AgGridComponent
          columns={storeInventoryAlertsTableColumns}
          rowdata={props.data}
          onReviewClick={(tableInfo) => onReviewClick(tableInfo.data)}
          uniqueRowId={"index"}
          sizeColumnsToFitFlag
          onGridChanged
          loadTableInstance={loadAlertsTableInstance}
          pagination={false}
        />
      </Loader>
      {showAlertAction && (
        <AlertsAction
          ref={inventoryAlertsCount}
          data={selectedAlertsTableData}
          setShowActionLoader={setShowActionLoader}
          inventoryAlertsCount={inventoryAlertsCount}
          canEdit={props.canEdit}
          canDelete={props.canDelete}
          canCreate={props.canCreate}
        />
      )}
    </>
  );
};

const mapStateToProps = (store) => {
  return {
    storeInventoryAlertsTableConfigLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryAlertsService
        .storeInventoryAlertsTableConfigLoader,
    storeInventoryAlertsTableDataLoader:
      store.inventorysmartReducer.inventorySmartStoreInventoryAlertsService
        .storeInventoryAlertsTableDataLoader,
    inventoryDashboardAlertCount:
      store.inventorysmartReducer.inventorySmartKPIService
        .inventoryDashboardAlertCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setInventoryDashboardAlertCount: (payload) =>
    dispatch(setInventoryDashboardAlertCount(payload)),
  reviewAlerts: (payload) => dispatch(reviewAlerts(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreInventoryAlerts);
