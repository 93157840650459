import React, { useEffect, useState, useRef } from "react";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import makeStyles from "@mui/styles/makeStyles";
import { getEmptyImage } from "react-dnd-html5-backend";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import CheckIcon from "@mui/icons-material/Check";
import RangeFilter from "./range-column-filter";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import "../index.scss";

const useClasses = makeStyles(() => ({
  dragIconSpan: {
    padding: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  dragIcon: {
    opacity: 0.6,
  },
  avatar_count: {
    padding: " 0 6px",
    marginLeft: "4px",
    borderRadius: "1em",
    color: "black",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
}));
const DND_ITEM_TYPE = "col";

export default function ColumnLayout({
  column,
  index,
  moveCol,
  columns,
  toggleColumnExpandedProps,
}) {
  const classes = useClasses();
  const [anchorEl, setAnchorEl] = useState(null);
  const Ref = useRef(null);
  const ascendingOrderLabel = {
    str: "Sort A to Z",
    int: "Sort by asc",
    float: "Sort by asc",
    percentage: "Sort by asc",
    dollar: "Sort by asc",
    link: "Sort by asc",
    DateTimeField: " Least Recent",
  };
  const descendingOrderLabel = {
    string: "Sort Z to A",
    int: "Sort by desc",
    float: "Sort by desc",
    percentage: "Sort by desc",
    dollar: "Sort by desc",
    link: "Sort by desc",
    DateTimeField: "Most Recent ",
  };
  const [{ isOver }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    canDrop(item) {
      return item.index !== index;
    },
    drop(item) {
      if (!Ref.current) {
        return;
      }
      if (column.is_frozen !== columns[item.index].is_frozen) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCol(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index: index, heading: column.Header },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (!column.isFixed) {
    drag(drop(Ref));
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const isOverStyle = isOver
    ? `table-header col-hovering-header`
    : `table-header `;
  const isDraggingStyle = isDragging
    ? `table-header ${classes.header} col-during-dragging`
    : isOverStyle;

  const onSortClick = (status) => {
    switch (status) {
      case "asc":
        column.toggleSortBy(false);
        handleClose();
        break;
      case "dsc":
        column.toggleSortBy(true);
        handleClose();
        break;
      case "reset":
        column.clearSortBy();
        handleClose();
        break;
      default:
        break;
    }
  };
  let colStyle =
    column.id === "selection"
      ? {}
      : {
          display: "flex",
          alignItems: "center",
          justifyContent:
            column.columns && column.columns.length > 1
              ? "center"
              : "space-between",
        };

  const getExpandedColumn = () => {
    let iconText = column.totalVisibleHeaderCount > 1 ? "-" : "+";
    return column.enableColumnExpand ? (
      <span
        onClick={() => {
          toggleColumnExpandedProps({
            column,
          });
        }}
        className={classes.avatar_count}
      >
        {iconText}
      </span>
    ) : null;
  };
  return (
    <Tooltip title={column.showTooltip ? column.Header : ""} arrow>
      <th
        className={
          column.isFixed ? `table-header table-header-fixed` : isDraggingStyle
        }
        {...column.getHeaderProps({
          style: {
            width: "calc(100% + 0.7rem)",
            display: "block",
            boxShadow: "none",
          },
        })}
        ref={Ref}
      >
        <div style={colStyle}>
          <div className="header-text">
            {column.render("Header")}
            {getExpandedColumn()}
          </div>
          {Boolean(column.Header.length) &&
            (!column.columns ||
              (column.columns && column.columns.length < 2)) && (
              <div className="header-icons">
                {!column.disableSortBy && (
                  <>
                    <ImportExportOutlinedIcon
                      className="sorting-icon"
                      onClick={handleClick}
                    />
                    <Menu
                      id="sorting-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        className="sort-menu-option"
                        onClick={() => onSortClick("asc")}
                      >
                        <span className="sorted-indicator">
                          {column.isSorted && !column.isSortedDesc && (
                            <CheckIcon />
                          )}
                        </span>
                        <span>
                          {" "}
                          {ascendingOrderLabel[column.type]
                            ? ascendingOrderLabel[column.type]
                            : "Sort A to Z"}{" "}
                        </span>
                      </MenuItem>
                      <MenuItem
                        className="sort-menu-option"
                        onClick={() => onSortClick("dsc")}
                      >
                        <span className="sorted-indicator">
                          {column.isSorted && column.isSortedDesc && (
                            <CheckIcon />
                          )}
                        </span>
                        <span>
                          {" "}
                          {descendingOrderLabel[column.type]
                            ? descendingOrderLabel[column.type]
                            : "Sort Z to A"}{" "}
                        </span>
                      </MenuItem>
                      <MenuItem
                        className="sort-menu-option"
                        onClick={() => onSortClick("reset")}
                      >
                        <span className="sorted-indicator"></span>
                        <span>Reset</span>
                      </MenuItem>
                    </Menu>
                  </>
                )}
                {column.showRangeFilter ? (
                  <RangeFilter column={column} />
                ) : null}

                {!column.isFixed && (
                  <span className={classes.dragIconSpan}>
                    <DragIndicatorIcon className={classes.dragIcon} />
                  </span>
                )}
              </div>
            )}
        </div>
      </th>
    </Tooltip>
  );
}
