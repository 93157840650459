import React, { Suspense, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import SideBar from "../sidebar/SideBar";
import Header from "../header";
import { withRouter, Switch, Route } from "react-router-dom";
import { APP_PLATFORM } from "config/constants";
import NotFound from "../notFound/NotFound";
import LoadingOverlay from "Utils/Loader/loader";
import layoutActions from "actions/layoutActions";
import {
  CalendarToday,
  FactCheck,
  PieChart,
  Factory,
} from "@mui/icons-material";
import {
  sideBarActions,
  sideBarData,
  sideBarDataDispatch,
  setUserPlatformScreen,
} from "actions/sideBarActions";
import {
  getUserModulePreference,
  getUserPreference,
} from "../../actions/authActions";
import "./layout.css";
import ProtectedRoute from "./ProtectedRoute";
import Skeleton from "@mui/material/Skeleton";
import {
  getAccessHierarchy,
  hasSuperUserAccess,
} from "Utils/filter-accessible-data";
import {
  setIsSuperUser,
  setPlanningLevelHierarchy,
  setUserAccessList,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
} from "pages/tenant-config/access-user-management/services/TenantManagement/User-Role-Management/user-role-management-service";
import {
  getPlanningHierarchy,
  setSavedFilterData,
  getSavedFilterSelection,
} from "actions/filterAction";
import CommentBar from "commonComponents/commentbar/CommentBar";
import { setActiveScreenName } from "modules/react-demo/services/common-assort-service";
import { useHistory } from "react-router";
import { sideBarDataSet } from "config/sidebar";
import {
  getTenantTimeConfig,
  getFilterExclusionValues,
} from "actions/tenantConfigActions";
import { appName } from "config/constants";
import DemoPage from "modules/react-demo/pages/react-demo/demo-page";

const Layout = (props) => {
  const history = useHistory();
  const { path } = props.match;
  const dispatch = useDispatch();
  const [screens, setScreens] = useState();
  const [routes, setRoutes] = useState();

  /**
   * @func
   * @desc Check if the route matches the workflow input center and set session to workflow
   * @returns return the current app as 'workflow input center'
   */
  const getAppName = () => {
    let isWorkFlowCenter = false;
    props.routes.forEach((item) => {
      if (item.path === window.location.pathname) {
        isWorkFlowCenter = true;
        sessionStorage.setItem("currentApp", appName.WORKFLOW_INPUT_CENTER);
        return;
      }
    });
    return isWorkFlowCenter ? appName.WORKFLOW_INPUT_CENTER : "";
  };

  /**
   * @func
   * @desc
   * Update component state routes and screens based on store data and props,
   * The dependent store and props to watch are routes, screens, userScreenData.
   * This sideBarDataDispatch sets the userScreenData.
   * It updates from the state again with the latest routes if userScreenData changes.
   */

  useEffect(() => {
    const setSideBarOption = async () => {
      try {
        let screenArray;
        let updatedRoute = [...props.routes];
        let appName = props.app || getAppName();
        let updatedScreens = [];
        if (props.activeAppName !== appName && props.activeAppName) {
          updatedScreens = sideBarDataSet[props.activeAppName];
          appName = props.activeAppName;
        } else {
          updatedScreens = props.sideBarOptions
            ? props.sideBarOptions
            : sideBarDataSet[appName];
        }
        if (props.userScreenData[appName]?.screens) {
          screenArray = props.userScreenData[appName]?.screens;
        } else {
          const screenData = await sideBarData(appName);
          /**
           * Structure of the api has changed to make screen accessible based on roles and screns assigned to a user
           * Default Value is "All", in case a user hasn't been given an access to a particular app, it comes as blank object and Home page fails to load
           */
          screenData?.data?.data?.data?.length > 0
            ? screenData?.data?.data?.data?.forEach((e) => {
                screenArray = e.users.screen.map((item) => item.name);
              })
            : (screenArray = ["All"]);
          /** Changing userScreenData structure as comparison for active and accessible apps are changing in Homepage */
          dispatch(
            sideBarDataDispatch({
              [appName]: { screens: screenArray, appName },
            })
          );
        }
        if (!screenArray.includes("All")) {
          const accessibleRouteOptions = props.routes?.filter((e) =>
            screenArray.includes(e.screenName)
          );
          const accessibleSideBarOptions = props.sideBarOptions?.filter((e) =>
            screenArray.includes(e.screenName)
          );
          updatedRoute = accessibleRouteOptions;
          updatedScreens = accessibleSideBarOptions;
        }
        setScreens(updatedScreens);
        setRoutes(updatedRoute);
      } catch (error) {
        console.log(error);
      }
    };
    setSideBarOption();
  }, [props.routes]);

  useEffect(() => {
    const setConfiguration = async () => {
      try {
        let planningHierarchyResponse = await props.getPlanningHierarchy(2, {
          attribute_name: "planning_level_hierarchy",
        });
        props.setPlanningLevelHierarchy(
          planningHierarchyResponse?.data?.data[0]?.attribute_value?.value
        );
        const accessData = await getAccessHierarchy();
        props.setUserAccessList(accessData);
        props.setIsSuperUser(hasSuperUserAccess(accessData));
        // const tenantTimeconfig = await getTenantTimeConfig();
        const tenantTimeconfig = [
          {
            name: "tenant_time_config",
            attribute_value: {
              value: {
                time_zone: "US/eastern",
                time_format: "MM-DD-YYYY",
              },
            },
          },
        ];
        props.setTenantTimeconfig(tenantTimeconfig);
        // added excluded filter values as dependency in redux
        const excludedValues = await getFilterExclusionValues();
        if (excludedValues[0]?.attribute_value?.value) {
          let filterData = excludedValues[0]?.attribute_value?.value;
          let finalDependency = [];
          Object.keys(filterData).forEach((key) => {
            let filtersData = Object.keys(filterData[key]).map((item) => {
              return {
                attribute_name: item,
                operator: "not in",
                values: filterData[key][item],
                filter_type: "cascaded",
                dimension: key,
              };
            });
            finalDependency = [...finalDependency, ...filtersData];
          });
          props.setfilterAttributeExclusionValues(finalDependency);
        }
        //const savedFilterSelection = await getSavedFilterSelection();
        const savedFilterSelection = {};
        props.setSavedFilterData(savedFilterSelection);
      } catch (error) {
        console.log(error);
      }
    };
    setConfiguration();
  }, []);

  return (
    <LoadingOverlay loader={props.overlayLoaderState || props.loading} spinner>
      {routes && (
        <div className="wrapper">
          <SideBar
            options={screens}
            authReducer={props.authReducer}
            props={props}
            setActivePlanStep={props.setActivePlanStep}
          />
          <div className="layout">
            <Header title={APP_PLATFORM.APP_NAME} />
            <div className="main-content" id="content">
              <div id="sub-content">
                <Suspense
                  fallback={
                    <div>
                      <Skeleton> </Skeleton>
                    </div>
                  }
                >
                  <Switch>
                    {routes.map((route) => (
                      <Route
                        exact
                        path={`${route.path}`}
                        // component={route.component}
                        screenName={route.screenName}
                        module={route.module}
                      >
                        {route.component}
                      </Route>
                    ))}
                    <Route exact path={`${path}/*`} component={NotFound} />
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>
          <CommentBar />
        </div>
      )}
    </LoadingOverlay>
  );
};

const mapStateToProps = (state) => ({
  overlayLoaderState: state.loaderReducer.overlayLoaderState,
  userScreenData: state.sideBarReducer.userScreenData,
  activeAppName: state.sideBarReducer.activeAppName,
  authReducer: state.authReducer,
});

const mapActionsToProps = {
  setUserScreen: sideBarActions,
  setLayout: layoutActions.setLayout,
  setIsSuperUser,
  getPlanningHierarchy,
  setPlanningLevelHierarchy,
  setUserAccessList,
  setUserPlatformScreen,
  setActiveScreenName,
  setTenantTimeconfig,
  setfilterAttributeExclusionValues,
  setSavedFilterData,
};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Layout));
