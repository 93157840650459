import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Loader from "../../Utils/Loader/loader";
import "./dcStatus.css";
import CreateDCModal from "./components/create-dc";
import ConfirmBox from "../../Utils/reactTable/components/confirmPopup";
import { configureDCFCColumnDef } from "../dc-status/common-functions";
import AddIcon from "@mui/icons-material/Add";
import { getColumnsAg } from "../../actions/tableColumnActions";
import { Container, Button, Typography } from "@mui/material";
import { setProductStatusData } from "../../actions/productStoreStatusActions";
import { fetchDCstatus, createNewDC, setStatusData } from "./dc-status-service";
import { END_DATE } from "../../config/constants";
import { Prompt } from "react-router";
import { isUndefined, isEmpty } from "lodash";
import { dateValidationMessage } from "Utils/functions/helpers/validation-helpers";
import { getTenantConfigApplicationLevel } from "actions/tenantConfigActions";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import AgGridComponent from "Utils/agGrid";
import { addSnack } from "actions/snackbarActions";
import globalStyles from "Styles/globalStyles";
import ConfirmPrompt from "commonComponents/confirmPrompt";
import Switch from "Utils/switch";
import makeStyles from "@mui/styles/makeStyles";
import { setFilterConfiguration } from "actions/filterAction";

import { isActionAllowedOnSubModule } from "modules/inventorysmart/pages-inventorysmart/inventorysmart-utility";
import { INVENTORY_SUBMODULES_NAMES } from "modules/inventorysmart/constants-inventorysmart/stringConstants";

const useStyles = makeStyles((theme) => ({
  typeDisplay: {
    flex: 1,
    display: "flex",
    alignItems: "center",
  },
}));

function DCStatusScreen(props) {
  const [dccolumns, setdcColumns] = useState([]);
  const [fccolumns, setfcColumns] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [showloader, setloader] = useState(true);
  const [dcView, setdcView] = useState("dc");
  const globalClasses = globalStyles();
  const [createDC, showCreateDC] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [confirmBox, showConfirmBox] = useState(false);
  const [setAllData, updateSetAllData] = useState({});
  const [flag_edit, setFlag_edit] = useState(false);
  const [showCreateDCBtn, setShowCreateDCBtn] = useState(false);
  const [showFCLevelView, setShowFCLevelView] = useState(false);
  const [selectedDcRowsIDs, setSelectedDcRowsIDs] = useState([]);
  const [selectedFcRowsIDs, setSelectedFcRowsIDs] = useState([]);
  const tableInstance = useRef({});
  const onFilterDependency = useRef([]);
  const classes = useStyles();

  const setNewTableInstance = (params) => {
    tableInstance.current = params;
  };

  const setDcFilterConfiguration = async () => {
    if (isEmpty(props.dcFilterDashboardConfiguration)) {
      const dcFilterData = props?.roleBasedAccess
        ? await fetchFilterFieldValues(
            "dc status",
            [],
            true,
            props.screenName,
            1
          )
        : await fetchFilterFieldValues("dc status");
      setFilterData(dcFilterData);
      let filterConfigData = [
        {
          filterDashboardData: dcFilterData,
          expectedFilterDimensions: ["store"],
          screenDimension: "store",
          isCrossDimensionFilter: false,
        },
      ];
      if (props?.roleBasedAccess) {
        filterConfigData[0] = {
          ...filterConfigData[0],
          is_urm_filter: true,
          screen_name: props.screenName,
          application_code: 1,
        };
      }
      const filterConfig = formattedFilterConfiguration(
        "dcStatusDcFilterConfiguration",
        filterConfigData,
        "Dc Status DC"
      );
      props.setFilterConfiguration(filterConfig);
    } else {
      onFilterDependency.current =
        props.dcFilterDashboardConfiguration.appliedFilterData.dependencyData;
    }
  };

  const setFcFilterConfiguration = async () => {
    if (isEmpty(props.fcFilterDashboardConfiguration)) {
      const fcFilterData = await fetchFilterFieldValues("fc status");
      const filterConfigData = [
        {
          filterDashboardData: fcFilterData,
          expectedFilterDimensions: ["store"],
          screenDimension: "store",
          isCrossDimensionFilter: false,
        },
      ];
      const filterConfig = formattedFilterConfiguration(
        "dcStatusFcFilterConfiguration",
        filterConfigData,
        "Dc Status FC"
      );
      props.setFilterConfiguration(filterConfig);
    } else {
      onFilterDependency.current =
        props.fcFilterDashboardConfiguration.appliedFilterData.dependencyData;
    }
  };

  useEffect(() => {
    const getFilterData = async () => {
      try {
        if (dcView === "dc") {
          await setDcFilterConfiguration();
        } else if (dcView === "fc") {
          await setFcFilterConfiguration();
        }
      } catch (error) {
        setloader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getFilterData();
  }, [dcView]);

  useEffect(() => {
    const getInitialData = async () => {
      try {
        let dcColumns = await getColumnsAg("table_name=dc_status")();
        dcColumns = configureDCFCColumnDef(dcColumns);

        let fcColumns = await getColumnsAg("table_name=fc_status")();
        fcColumns = configureDCFCColumnDef(fcColumns);

        setdcColumns(dcColumns);
        setfcColumns(fcColumns);
        setloader(false);

        let showCreateDCBtnResp = await props.getTenantConfigApplicationLevel(
            3,
            {
              attribute_name: "core_show_create_product_store_dc",
            }
          ),
          showFCLevelViewResp = await props.getTenantConfigApplicationLevel(3, {
            attribute_name: "core_show_fc_level_view",
          });

        if (showCreateDCBtnResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowCreateDCBtn(
            showCreateDCBtnResp?.data?.data?.[0]?.["attribute_value"]?.value
          );
        }

        if (showFCLevelViewResp?.data?.data?.[0]?.["attribute_value"]) {
          setShowFCLevelView(
            showFCLevelViewResp?.data?.data?.[0]?.["attribute_value"]?.value
          );
        }
      } catch (error) {
        setloader(false);
        displaySnackMessages("Something went wrong", "error");
      }
    };

    getInitialData();
  }, []);

  //This is unused because DC-FC toggle switch code is commented
  //If that is needed, this part of code will be needed
  //As this is dependant on line 594, Not removing this function
  const dcSwitch = async () => {
    if (dcView === "dc") {
      setdcView("fc");
    } else {
      setdcView("dc");
    }
  };

  const onConfirm = async () => {
    setloader(true);
    try {
      setShowModal(false);
      await setStatusData(dcView, setAllData)();
      setFlag_edit(false);
      tableInstance.current.api.deselectAll(true);
      tableInstance.current.api?.refreshServerSideStore({ purge: false });
      updateSetAllData([]);
      setloader(false);
      displaySnackMessages(formattedRespMsg(dcView), "success");
    } catch (err) {
      setloader(false);
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const dcTableManualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    try {
      let body = {
        filters: onFilterDependency.current,
        meta: {
          ...manualbody,
          limit: { limit: 10, page: pageIndex + 1 },
        },
        headers: [],
      };
      const response = await fetchDCstatus(body, "dc")();
      setloader(false);
      return {
        data: response?.data.data,
        totalCount: response?.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      setloader(false);
    }
  };

  const fcTableManualCallBack = async (manualbody, pageIndex, params) => {
    setloader(true);
    try {
      let body = {
        filters: onFilterDependency.current,
        meta: {
          ...manualbody,
          limit: { limit: 10, page: pageIndex + 1 },
        },
        headers: [],
      };
      const response = await fetchDCstatus(body, "fc")();
      setloader(false);
      return {
        data: response?.data.data,
        totalCount: response?.data.total,
      }; // returning for server side pagination on ag grid
    } catch (err) {
      setloader(false);
    }
  };

  const onFilter = async (unsavedChangeCheck = false) => {
    if (setAllData.length !== 0 && unsavedChangeCheck) {
      showConfirmBox(true);
      throw Error("Unsaved changes");
    }
    try {
      let body = {
        meta: {
          range: [],
          sort: [],
          search: [],
          limit: { limit: 10, page: 0 },
        },
        headers: [],
      };
      tableInstance.current.api?.refreshServerSideStore({ purge: true });
      dcView === "dc"
        ? dcTableManualCallBack(body.meta, 0)
        : fcTableManualCallBack(body.meta, 0);
      tableInstance.current.api?.deselectAll(true);
    } catch (error) {
      displaySnackMessages("Something went wrong", "error");
    }
  };

  const dateValidation = (startDate, endDate) => {
    const startdate = new Date(startDate);
    const enddate = new Date(endDate);
    const errMessage = dateValidationMessage(startdate, enddate);
    if (errMessage) {
      setloader(false);
      displaySnackMessages(errMessage, "error");
      throw Error("Date is not correct");
    }
  };

  const createNewProducFunc = async (dataObj, newSetAllData) => {
    setloader(true);
    try {
      let body = [];
      Object.keys(dataObj).forEach((key) => {
        if (
          key !== "store_code" &&
          dataObj[key] &&
          key !== "master_store_code"
        ) {
          let obj = {
            attribute_name: key,
            attribute_value: dataObj[key],
          };
          body.push(obj);
        }
      });
      let reqBody = {
        attributes: body,
        code: dataObj.store_code,
      };
      let setAllBody = {
        attributes: [
          {
            attribute_name: "status",
            attribute_value: newSetAllData.status,
            start_date: moment(newSetAllData.start_time).format("YYYY-MM-DD"),
            end_date: moment(newSetAllData.end_time).format("YYYY-MM-DD"),
          },
        ],
        codes: [],
      };
      dateValidation(
        setAllBody.attributes[0].start_date,
        setAllBody.attributes[0].end_date
      );
      if (dataObj.store_code) {
        const new_code = await createNewDC(dcView, reqBody)();

        setAllBody.codes = [new_code.data.data.code.toString()];
        if (Object.keys(newSetAllData).length > 0)
          await setStatusData(dcView, setAllBody)();
        displaySnackMessages(formattedRespMsg(dcView), "success");
        onFilter();
        setloader(false);
        return true;
      } else {
        displaySnackMessages(
          "Please enter " + dcView.toUpperCase() + " code",
          "error"
        );
      }
      setloader(false);
    } catch (err) {
      setloader(false);
      displaySnackMessages(
        "Failed to create a " +
          dcView.toUpperCase() +
          ". Please try again with different " +
          dcView.toUpperCase() +
          " name / store code",
        "error"
      );
    }
  };

  const formatData = (data, selectedIds) => {
    let setAllOutput = [];

    const attributesObj = {
      attribute_name: "status",
      attribute_value: data["status"],
      start_date: data["start_time"],
      end_date:
        !isUndefined(data["end_time"]) && data["end_time"] !== "Invalid date"
          ? data["end_time"]
          : END_DATE,
    };
    dateValidation(attributesObj.start_date, attributesObj.end_date);
    setAllOutput.push(attributesObj);

    return {
      attributes: setAllOutput,
      codes: selectedIds,
    };
  };

  // updating ag-grid data
  const onSetAllApply = (data, agGrid) => {
    const dataSet =
      dcView === "dc"
        ? formatData(data, selectedDcRowsIDs)
        : formatData(data, selectedFcRowsIDs);
    updateSetAllData(dataSet);
    let rowNodes = agGrid.api.getSelectedNodes();
    rowNodes.forEach((item) => {
      const rowNode = agGrid.api.getRowNode(item.id);
      const attributes = dataSet.attributes[0];
      const updatedKeys = {
        status: attributes["attribute_value"],
        start_time: attributes["start_date"],
        end_time:
          attributes["end_date"] === END_DATE ? "-" : attributes["end_date"],
      };
      rowNode.setData({ ...rowNode.data, ...updatedKeys });
    });
    setFlag_edit(true);
    agGrid.api.flashCells({ rowNodes });
  };

  const saveRequest = () => {
    if (Object.keys(setAllData).length) {
      setShowModal(true);
    } else {
      displaySnackMessages("There is no change to save", "warning");
    }
  };

  const onCancel = async () => {
    if (Object.keys(setAllData).length) {
      showConfirmBox(true);
    } else {
      tableInstance.current?.api.deselectAll(true);
      displaySnackMessages("There is no change to save.", "warning");
    }
  };

  const rowselected = () => {
    if (
      (dcView === "dc" && selectedDcRowsIDs.length > 0) ||
      (dcView === "fc" && selectedFcRowsIDs.length > 0)
    ) {
      tableInstance.current.trigerSetAll(true);
    } else {
      displaySnackMessages(
        "Please select atleast one " + dcView.toUpperCase(),
        "error"
      );
    }
  };

  let formData = filterData.map((item) => {
    if (
      item.column_name === "special_classification" ||
      item.column_name === "status"
    ) {
      return {
        label: item.label,
        field_type: "list",
        filter_type: item.type,
        options: item.initialData,
        required: false,
        accessor: item.column_name,
        column_name: item.column_name,
      };
    } else {
      return {
        label: item.label,
        field_type: "list",
        filter_type: item.type,
        options: item.initialData,
        required: true,
        accessor: item.column_name,
        column_name: item.column_name,
      };
    }
  });

  let Additionalfields = [
    {
      label: "Store ID",
      field_type: "TextField",
      required: true,
      accessor: "store_code",
      column_name: "store_code",
    },
    {
      label: "Store Name",
      field_type: "TextField",
      required: true,
      accessor: "store_name",
      column_name: "store_name",
    },
    {
      label: "DC Name",
      field_type: "TextField",
      required: true,
      accessor: "name",
      column_name: "name",
    },
  ];
  let Additionalfcfields = [
    {
      label: "Store ID",
      field_type: "TextField",
      required: true,
      accessor: "store_code",
      column_name: "store_code",
    },
    {
      label: "Store Name",
      field_type: "TextField",
      required: true,
      accessor: "store_name",
      column_name: "store_name",
    },
    {
      label: "FC Name",
      field_type: "TextField",
      required: true,
      accessor: "name",
      column_name: "name",
    },
  ];

  if (dcView === "dc") {
    formData = [...formData, ...Additionalfields];
  } else {
    formData = [...formData, ...Additionalfcfields];
  }

  const formattedRespMsg = (viewText) => {
    return viewText.toUpperCase() + " Data Updated Successfully";
  };

  const onDcSelectionChanged = (event) => {
    let selections = event.api.getSelectedRows().map((item) => {
      return item.dc_code;
    });
    setSelectedDcRowsIDs(selections);
  };

  const onFcSelectionChanged = (event) => {
    let selections = event.api.getSelectedRows().map((item) => {
      return item.fc_code;
    });
    setSelectedFcRowsIDs(selections);
  };

  const displaySnackMessages = (message, variance, onClose) => {
    props.addSnack({
      message: message,
      options: {
        variant: variance,
        ...(onClose && { onClose: onClose }),
      },
    });
  };

  const handleConfirmBox = () => {
    tableInstance.current.api?.refreshServerSideStore({ purge: false });
    tableInstance.current.api?.deselectAll(true);
    updateSetAllData([]);
    setFlag_edit(false);
    showConfirmBox(false);
  };

  const canTakeActionOnModules = (subModuleName, action) => {
    return isActionAllowedOnSubModule(
      props?.inventorysmartModulesPermission,
      props?.module,
      subModuleName,
      action
    );
  };

  const onFilterDashboardClick = (dependencyData) => {
    onFilterDependency.current = dependencyData;
    onFilter();
  };

  return (
    <>
      {dcView === "dc" && (
        <CoreComponentScreen
          pageLabel={"DC Status"}
          showPageRoute={true}
          showPageHeader={true}
          // Filter dashboard props
          showFilterDashboard={true}
          filterConfigKey={"dcStatusDcFilterConfiguration"}
          onApplyFilter={onFilterDashboardClick}
        />
      )}

      {dcView === "fc" && (
        <CoreComponentScreen
          pageLabel={"DC Status"}
          showPageRoute={true}
          showPageHeader={true}
          // Filter dashboard props
          showFilterDashboard={true}
          filterConfigKey={"dcStatusFcFilterConfiguration"}
          onApplyFilter={onFilterDashboardClick}
        />
      )}
      <Loader loader={showloader}>
        <Prompt when={flag_edit} message={""} />
        {confirmBox && (
          <ConfirmBox
            onClose={() => showConfirmBox(false)}
            onConfirm={() => handleConfirmBox()}
          />
        )}
        <ConfirmPrompt
          showModal={showModal}
          title="Confirm Changes"
          message="Are you sure to save all your changes?"
          ariaLabeledBy="confirm-changes-dialog"
          primaryBtnText="Update"
          secondaryBtnText="Close"
          showCloseIcon={true}
          setConfirm={setShowModal}
          confirmCallback={(val) => {
            if (val) {
              onConfirm();
            }
          }}
        />

        {createDC && (
          <CreateDCModal
            fields={formData}
            title={dcView === "dc" ? "Create New DC" : "Create New FC"}
            onCreateNewDC={createNewProducFunc}
            toggleError={(errMsg) => {
              displaySnackMessages(errMsg, "error");
            }}
            handleModalClose={() => showCreateDC(false)}
          ></CreateDCModal>
        )}
        <Container maxWidth={false}>
          <div
            className={`${globalClasses.flexRow} ${globalClasses.layoutAlignBetweenCenter} ${globalClasses.marginBottom}`}
          >
            <Typography variant="h6" gutterBottom>
              {`${showFCLevelView ? "DC / FC" : "DC"}`}
            </Typography>
            {/* {`${
              !showFCLevelView ? (
                <div className={classes.typeDisplay}>
                  DC
                  <>
                    <Switch className="switch" onChange={dcSwitch}></Switch>
                    FC
                  </>
                  )
                </div>
              ) : (
                <div />
              )
            }`} */}
            <div
              className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.verticalAlignCenter}`}
            >
              {showCreateDCBtn && (
                <Button
                  variant="contained"
                  color="primary"
                  id="createDCBtn"
                  onClick={() => showCreateDC(true)}
                  // disabled={!props.isSuperUser}
                >
                  <AddIcon fontSize="small"></AddIcon>{" "}
                </Button>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={rowselected}
                // disabled={!props.isSuperUser}
              >
                Set All
              </Button>
            </div>
          </div>
          {dccolumns.length > 0 && dcView === "dc" && (
            <AgGridComponent
              columns={dccolumns}
              selectAllHeaderComponent={true}
              onSelectionChanged={onDcSelectionChanged}
              sizeColumnsToFitFlag
              onGridChanged
              manualCallBack={(body, pageIndex, params) =>
                dcTableManualCallBack(body, pageIndex, params)
              }
              rowModelType="serverSide"
              serverSideStoreType="partial"
              cacheBlockSize={10}
              uniqueRowId={"dc_code"}
              hideChildSelection={true}
              loadTableInstance={setNewTableInstance}
              showSetAll={false}
              purgeClosedRowNodes={true}
              suppressAggFuncInHeader={true}
              rowSelection={"multiple"}
              hideSelectAllRecords={true}
              suppressClickEdit={true}
              onSetAllApply={onSetAllApply}
            />
          )}
          {fccolumns.length > 0 && dcView === "fc" && (
            <AgGridComponent
              columns={fccolumns}
              selectAllHeaderComponent={true}
              onSelectionChanged={onFcSelectionChanged}
              sizeColumnsToFitFlag
              onGridChanged
              manualCallBack={(body, pageIndex, params) =>
                fcTableManualCallBack(body, pageIndex, params)
              }
              rowModelType="serverSide"
              serverSideStoreType="partial"
              cacheBlockSize={10}
              uniqueRowId={"dc_code"}
              hideChildSelection={true}
              loadTableInstance={setNewTableInstance}
              showSetAll={false}
              purgeClosedRowNodes={true}
              suppressAggFuncInHeader={true}
              rowSelection={"multiple"}
              hideSelectAllRecords={true}
              suppressClickEdit={true}
            />
          )}
        </Container>
        <div
          className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.centerAlign} ${globalClasses.marginTop}`}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              saveRequest();
            }}
            disabled={
              !canTakeActionOnModules(
                INVENTORY_SUBMODULES_NAMES.INVENTORY_DC_STATUS,
                "edit"
              )
            }
          >
            Save
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </div>
      </Loader>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    dcStatus: state.dcStatusReducer.dcStatus,
    dcStatusCount: state.storeGroupReducer.dcStatusCount,
    isSuperUser:
      state.tenantUserRoleMgmtReducer.userRoleManagementReducer.isSuperUser,
    inventorysmartModulesPermission:
      state.inventorysmartReducer.inventorySmartCommonService
        ?.inventorysmartModulesPermission,
    dcFilterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "dcStatusDcFilterConfiguration"
      ],
    fcFilterDashboardConfiguration:
      state.filterReducer.filterDashboardConfiguration[
        "dcStatusFcFilterConfiguration"
      ],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSnack: (body) => dispatch(addSnack(body)),
    setProductStatusData: (data) => dispatch(setProductStatusData(data)),
    getTenantConfigApplicationLevel: (dynamicRoute, queryParam) =>
      dispatch(getTenantConfigApplicationLevel(dynamicRoute, queryParam)),
    setFilterConfiguration: (filterConfiguration) =>
      dispatch(setFilterConfiguration(filterConfiguration)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DCStatusScreen);
