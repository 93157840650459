import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import ProductToStore from "./components/product-to-store";
import HeaderBreadCrumbs from "../../Utils/HeaderBreadCrumbs";
import Modify from "./components/modify-product-mapping";
import ProductDcFc from "./components/product-dc-fc";
import ProductToPo from "./components/product-to-po";
import ProductToAsn from "./components/product-to-asn";
import { cloneDeep, isEmpty } from "lodash";
import "./index.scss";
import globalStyles from "Styles/globalStyles";
import { fetchProductIdsInGrp } from "./services-product-mapping/productMappingService";
import { resetProductMappingStates } from "./services-product-mapping/productMappingService";
import { connect } from "react-redux";
import { dynamicLabelsBasedOnTenant } from "Utils/DynamicLabels";
import CoreComponentScreen from "commonComponents/coreComponentScreen";
import {
  fetchFilterFieldValues,
  formattedFilterConfiguration,
} from "commonComponents/coreComponentScreen/utils";
import { setFilterConfiguration } from "actions/filterAction";

/**
 *
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    padding: "2%",
  },
}));

function ProductMapping(props) {
  const [value, setValue] = React.useState(0);
  const [modifyMapping, setModifyMapping] = useState(false);
  const [defaultDataSet, updateDefaultDataSet] = useState([]);
  const [cols, updateCols] = useState([]);
  const [productDimension, setProductDimension] = useState("product");
  const [selectedProducts, updateselectedProducts] = useState([]);
  const [filtersSelection, setFiltersSelection] = useState([]);
  const [fetchProductIdsInGrpLoader, showfetchProductIdsInGrpLoader] =
    useState(false);
  const [reset, setReset] = useState(false);
  const filterDependencyRef = useRef([]);
  const type = new URLSearchParams(window.location.search).get("type");
  const isRedirectedFromDifferentPage =
    type && props?.productMappingFilterDependency?.length > 0;

  const onFilter = async () => {
    try {
      if (isRedirectedFromDifferentPage) {
        let dependencyList = dependencyStructure(
          props.productMappingFilterDependency
        );
        setFiltersSelection(cloneDeep(dependencyList));
      } else {
        let dependencyList = filterDependencyRef.current;
        setFiltersSelection(cloneDeep(dependencyList));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const loadFilters = async () => {
    if (isEmpty(props.productMappingFilterDashboardConfiguration)) {
      const response = props?.roleBasedAccess
        ? await fetchFilterFieldValues(
            "product mapping",
            [],
            true,
            props.screenName,
            1
          )
        : await fetchFilterFieldValues("product mapping");
      let filterConfigData = [
        {
          filterDashboardData: response,
          expectedFilterDimensions: ["product"],
          screenDimension: "product",
          isCrossDimensionFilter: false,
        },
      ];

      if (props?.roleBasedAccess) {
        filterConfigData[0] = {
          ...filterConfigData[0],
          is_urm_filter: true,
          screen_name: props.screenName,
          application_code: 1,
        };
      }

      const filterConfig = formattedFilterConfiguration(
        "productMappingFilterConfiguration",
        filterConfigData,
        "Product Mapping"
      );
      props.setFilterConfiguration(filterConfig);
    } else {
      filterDependencyRef.current =
        props.productMappingFilterDashboardConfiguration.appliedFilterData.dependencyData;
    }
  };

  useEffect(() => {
    loadFilters();
    return () => props.resetProductMappingStates();
  }, []);

  const handleChange = (_event, newValue) => {
    setReset(!reset);
    setValue(newValue);
  };

  const toggleModifyMapping = async (data) => {
    updateDefaultDataSet(data.defaultDataSet);
    updateCols(data.cols);
    updateselectedProducts(data.selectedProducts);
    if (productDimension === "product_group") {
      showfetchProductIdsInGrpLoader(true);
      const resp = await props.fetchProductIdsInGrp({
        group_ids: data.selectedProducts.map((codes) => codes.pg_code),
      });
      showfetchProductIdsInGrpLoader(false);
      setModifyMapping(true);
      updateselectedProducts(resp.data.data);
    } else {
      updateselectedProducts(data.selectedProducts);
      setModifyMapping(true);
    }
  };

  useEffect(() => {
    if (props.productMappingFilterDependency.length > 0) {
      if (isRedirectedFromDifferentPage) {
        onFilter();
      }
    }
  }, [
    props.productMappingFilterDependency,
    value,
    isRedirectedFromDifferentPage,
  ]);

  const dependencyStructure = (dependencyList) => {
    return dependencyList.map((item) => {
      return {
        attribute_name: item.filter_id,
        operator: "in",
        values: Array.isArray(item.values)
          ? item.values.map((opt) => opt.value)
          : item.values,
        filter_type: item.filter_type,
      };
    });
  };

  const onFilterDashboardClick = (dependencyData) => {
    filterDependencyRef.current = dependencyData;
    onFilter();
  };

  return (
    <>
      {!props.hideBreadCrumbs && (
        <HeaderBreadCrumbs
          options={
            modifyMapping
              ? [
                  {
                    label: dynamicLabelsBasedOnTenant(
                      "product_mapping",
                      "core"
                    ),
                    id: 1,
                    action: () => {
                      setModifyMapping(false);
                    },
                  },
                  {
                    label: "Modify Mapping",
                    id: 2,
                    action: () => {},
                  },
                ]
              : [
                  {
                    label: dynamicLabelsBasedOnTenant(
                      "product_mapping",
                      "core"
                    ),
                    id: 1,
                    action: () => {
                      setModifyMapping(false);
                    },
                  },
                ]
          }
        ></HeaderBreadCrumbs>
      )}
      <Container maxWidth={false}>
        {!modifyMapping && (
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab
                label={`${dynamicLabelsBasedOnTenant("product", "core")}-Store`}
                {...a11yProps(0)}
              />
              <Tab
                label={`${dynamicLabelsBasedOnTenant("product", "core")}-DC/FC`}
                {...a11yProps(1)}
              />
              {/* <Tab label="Product-PO" {...a11yProps(0)} />
            <Tab label="Product-ASN" {...a11yProps(1)} /> */}
            </Tabs>
            <Paper elevation={0}>
              <CoreComponentScreen
                showFilterDashboard={true}
                filterConfigKey={"productMappingFilterConfiguration"}
                onApplyFilter={onFilterDashboardClick}
              />
              <TabPanel value={value} index={0}>
                <ProductToStore
                  ref={filterDependencyRef}
                  toggleModifyMapping={toggleModifyMapping}
                  changeDimension={(event) => {
                    setProductDimension(event);
                  }}
                  filtersSelection={filtersSelection}
                  fetchProductIdsInGrpLoader={fetchProductIdsInGrpLoader}
                  selectedProductMappingArticles={
                    props.selectedProductMappingArticles
                  }
                  isRedirectedFromDifferentPage={isRedirectedFromDifferentPage}
                  module={props.module} // for roleBasedAccess
                  screenName={props.screenName}
                  roleBasedAccess={props.roleBasedAccess}
                ></ProductToStore>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <ProductDcFc
                  filtersSelection={filtersSelection}
                  ref={filterDependencyRef}
                  selectedProductMappingArticles={
                    props.selectedProductMappingArticles
                  }
                  isRedirectedFromDifferentPage={isRedirectedFromDifferentPage}
                  module={props.module}
                  screenName={props.screenName}
                  roleBasedAccess={props.roleBasedAccess}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <ProductToPo filtersSelection={filtersSelection} />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <ProductToAsn filtersSelection={filtersSelection} />
              </TabPanel>
            </Paper>
          </div>
        )}

        {modifyMapping && (
          <Modify
            selectedDimension={"product"}
            defaultDataSet={defaultDataSet}
            cols={cols}
            selectedProducts={selectedProducts}
            goBack={() => setModifyMapping(false)}
            fetchIdsLoader={fetchProductIdsInGrpLoader}
          ></Modify>
        )}
      </Container>
    </>
  );
}

const mapStateToProps = (store) => {
  return {
    productMappingFilterDependency:
      store.productMappingReducerService.productMappingFilterDependency,
    selectedProductMappingArticles:
      store.productMappingReducerService.selectedProductMappingArticles,
    productMappingFilterDashboardConfiguration:
      store.filterReducer.filterDashboardConfiguration[
        "productMappingFilterConfiguration"
      ],
  };
};

const mapActionsToProps = {
  fetchProductIdsInGrp,
  resetProductMappingStates,
  setFilterConfiguration,
};

export default connect(mapStateToProps, mapActionsToProps)(ProductMapping);
