import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "commonComponents/layout/layout.css";
import Layout from "commonComponents/layout";
import {
  DASHBOARD,
  DIRECTORYVIEW,
} from "../constants-marksmart/routesConstants";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Dashboard from "../pages-marksmart/dashboard";
import DirectoryView from "../pages-marksmart/dashboard/directory-view";
export const sideBarOptions = [
  {
    link: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
    title: "Dashboard",
    icon: React.createElement(CalendarTodayIcon),
    order: 1,
  },
];

const Routes = () => {
  const routes = [
    {
      path: "/" + window.location.pathname.split("/")[1] + DASHBOARD,
      component: Dashboard,
      title: "Dashboard",
    },
    {
      path: "/" + window.location.pathname.split("/")[1] + DIRECTORYVIEW,
      component: DirectoryView,
      title: "Directory View",
    },
  ];
  return (
    <Layout routes={routes} sideBarOptions={sideBarOptions} app={"marksmart"} />
  );
};
const mapStateToProps = () => ({});

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(withRouter(Routes));
