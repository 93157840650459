import React, { useRef, useState } from "react";
import FilterGroup from "commonComponents/filters/filterGroup";
import CustomAccordion from "commonComponents/Custom-Accordian";
import Loader from "Utils/Loader/loader";
import { useEffect } from "react";
import { STATIC_DATE_INPUT } from "../grading-constants/stringConstants";
import { cloneDeep } from "lodash";
import { addGradingMatrices } from "../grading-services";
import { addSnack } from "actions/snackbarActions";
import { Typography, Button } from "@mui/material";
import globalStyles from "Styles/globalStyles";
import {
  fetchFilterFieldValues,
  fetchTableData,
} from "commonComponents/coreComponentScreen/utils";
import { GET_PERFORMANCE_MATRICES } from "../grading-constants/api-constants";
import AgGridComponent from "Utils/agGrid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import CustomFilter from "modules/inventorysmart/pages-inventorysmart/Allocation-Reporting/filters/CustomFilter";

const PerformanceMetrices = () => {
  const globalClasses = globalStyles();
  const [showFilterLoader, setShowFilterLoader] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [staticFilterData, setStaticFilterData] = useState([]);
  const [showloader, setLoader] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedDates, setSelectedDates] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [timePeriod, setTimePeriod] = useState([]);
  const [columns, setColumns] = useState([]);
  const dispatch = useDispatch();
  const { gradeID } = useSelector(
    (store) => store?.createGradeReducer
  );
  const headerObj = [
    {
      sub_headers: [],
      column_name: "feature",
      type: "int",
      label: "Performance Metrics",
      order_of_display: 1,
      dimension: "store",
      field: "feature",
      accessor: "feature",
      id: "feature",
      headerName: "Performance Metrics",
      tooltipField: "feature",
    },
    {
      sub_headers: [],
      column_name: "significance_score",
      type: "str",
      label: "Significant Score",
      order_of_display: 1,
      dimension: "store",
      field: "significance_score",
      accessor: "significance_score",
      id: "significance_score",
      headerName: "Significant Score",
      tooltipField: "significance_score",
    },
  ];

  useEffect(() => {
    setShowFilterLoader(true);
    getInitialData();
    setTableData();
  }, []);

  /**
   * @func
   * @desc Fetch and update table configurations
   */
  const setTableData = () => {
    const fetchData = async () => {
      setLoader(true);
      // To be Updated once Header Config API's are ready.
      //   const data = await fetchFilterFieldValues(screenName);
      //   props.setViewGrpFilterValues(data);
      //   if (props.groupCols.length == 0) {
      //     let cols = await props.getColumnsAg("table_name=performance_matrices");
      //     cols = agGridColumnFormatter(cols);
      //     props.setGroupsCols(cols);
      //   }
      // setGroupClomuns(headerObj);
      setColumns(headerObj);
      setLoader(false);
    };
    fetchData();
  };

  /**
   * @func
   * @desc Fetch and Update filter configurations and data
   */
  const getInitialData = async () => {
    try {
      const filterConfig = await fetchFilterFieldValues("grade_metrics");
      setFilterData(filterConfig);
      setStaticFilterData(STATIC_DATE_INPUT);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
      setShowFilterLoader(false);
    }
  };

  /**
   * @func
   * @desc Handle on Filter operations and update table data accordingly
   */
  const onFilter = async () => {
    const startDate = selectedDates?.startDate
      ? moment(selectedDates?.startDate).format("YYYY-MM-DD")
      : false;
    const endDate = selectedDates?.endDate
      ? moment(selectedDates?.endDate).format("YYYY-MM-DD")
      : false;
    if (!startDate || !endDate) {
      dispatch(
        addSnack({
          message: "Please enter date range.",
          options: {
            variant: "error",
          },
        })
      );
      return;
    } else {
      setLoader(true);
      setShowFilterLoader(true);
      try {
        const selectedFiltersCopy = cloneDeep(selectedFilters);
        const filters = selectedFiltersCopy.map((filter) => {
          const values = filter.values.map((item) => item.value);
          filter.attribute_name = [filter.filter_id][0];
          filter.operator = "in";
          delete filter.filter_id;
          return { ...filter, values };
        });
        const body = {
          filters: filters,
          performance_features: ["total_quantity", "APS", "AUR", "store_area"],
          time_period: [startDate, endDate],
        };
        setTimePeriod([startDate, endDate]);
        const tableData = await fetchTableData(body, GET_PERFORMANCE_MATRICES);
        setRowData(tableData.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoader(false);
        setShowFilterLoader(false);
      }
    }
  };

  /**
   * @func
   * @desc Reset filter changes
   */
  const onReset = () => {
    setSelectedFilters([]);
    setSelectedDates(null);
  };

  /**
   * @func
   * @desc Handle selection and deselections changes from Table
   * @param {Object} instance 
   */
  const onSelectionChanged = (instance) => {
    let newSelectedRows = [];
    instance.api.forEachNode((node) => {
      node.selected &&
        newSelectedRows.push({ ...node.data, is_selected: true });
    });
    setSelectedRows(newSelectedRows);
  };

  const gotoPreviousStep = () => {};

  /**
   * @func
   * @desc Validate and add selected matrices before moving to next step.
   */
  const gotoNextStep = async () => {
    const metrices = selectedRows.map(row => {
      const returnObj = {}
      returnObj[row["feature"]] = {
          score: row.significance_score,
          weight: row.rank,
          is_selected: true,
      };
      return returnObj;
    })
    const body = {
      grade_id: gradeID,
      time_period: {start_date: timePeriod[0] , end_date: timePeriod[1]},
      metrics: metrices,
      filters: {}
    }
    const addedMatrices = await addGradingMatrices(body);
  };

  return (
    <>
      <CustomAccordion label="Select Filters" defaultExpanded={true}>
        <Loader loader={showFilterLoader}>
          <FilterGroup
            filters={filterData}
            onFilter={onFilter}
            onReset={onReset}
            inititalSelection={selectedFilters}
            screen={"filterGroup"}
            update={setSelectedFilters}
            customFilter={true}
            customComponent={
              <CustomFilter
                staticFilters={staticFilterData}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                setLoader={setShowFilterLoader}
                isGroup={false}
                fiscalCalendarDetails={null}
                setFiscalDates={() => {}}
              />
            }
          />
        </Loader>
      </CustomAccordion>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.marginTop} ${globalClasses.marginBottom}`}
      >
        <Button color="primary" variant="contained" onClick={onFilter}>
          Submit
        </Button>
        <Button color="primary" variant="text" onClick={onReset}>
          Clear Filter
        </Button>
      </div>
      <Typography variant="h5" gutterBottom>
        Select performance metrics
      </Typography>
      <Loader loader={showloader} spinner>
        <AgGridComponent
          columns={columns}
          rowdata={rowData}
          selectAllHeaderComponent={true}
          sizeColumnsToFitFlag
          uniqueRowId={"pg_code"}
          onSelectionChanged={onSelectionChanged}
        />
      </Loader>
      <div
        className={`${globalClasses.flexRow} ${globalClasses.gap} ${globalClasses.marginTop}`}
      >
        <Button color="primary" variant="outlined" onClick={gotoPreviousStep}>
          Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (selectedRows.length) {
              gotoNextStep();
            }
          }}
        >
          Next
        </Button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addSnack: (snack) => dispatch(addSnack(snack)),
});

export default connect(null, mapDispatchToProps)(PerformanceMetrices);
