import React from "react";
import { isEmpty, isObject, isString, isNil } from "lodash";
import { FormControlLabel, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import { capitalize } from "Utils/formatter";
import { useBudgetStyles } from "../pages-plansmart/plansmart-budget-table/budget-table-style";
import moment from "moment";

export const FILTER_FIELDS = [
  {
    label: "Target Margin",
    field_type: "IntegerField",
    value_type: "percentage",
    accessor: "target_margin",
  },
  {
    label: "Target Revenue Growth",
    field_type: "IntegerField",
    value_type: "percentage",
    accessor: "target_revenue_growth",
  },
  {
    label: "Target EOP Variance",
    field_type: "IntegerField",
    value_type: "percentage",
    accessor: "target_sell_through",
  },
];

export const ifEmpty = (val) => {
  if (isObject(val))
    return isEmpty(val) || (val.length === 1 && isEmpty(val[0]));
  if (val.length === 0) isNil(val);
  return isString(val);
};

export const tabData = [
  {
    id: "l0_name",
    type: "Division",
  },
  { id: "l1_name", type: "Department" },
  { id: "l2_name", type: "Category" },
  { id: "l3_name", type: "Sub class" },
];
export const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const displayRowsForBudgetTable = [
  "current",
  "compare",
  "variance",
  "variance_fcst",
  "forcasted",
];

export const viewOption = [
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
];

export const maxLength = (tempData) =>
  tempData.reduce((a, i, ii) => {
    if (ii === 1) {
      return a;
    }
    if (i.length > a.length) {
      return i;
    }
    return a;
  });
export const getGroupedItems = (item, props) => {
  const returnArray = [];
  Object.keys(item).forEach((_array, i) => {
    returnArray.push(item?.[props[i]]);
  });
  return returnArray;
};

export const groupByRowSubrows = ({ Group: array, By: props }) => {
  const groups = {};
  if (array) {
    Object.keys(array).forEach((i) => {
      const arrayRecord = array[i];
      const group = JSON.stringify(getGroupedItems(arrayRecord, props));
      groups[group] = { ...arrayRecord, ...groups[group] };
    });
  }
  return groups;
};

export const TabTitle = (props) => {
  const classes = useBudgetStyles();
  return props.isActive === props.dataTab ? (
    <li
      onClick={props.onClick}
      className={classes.tab_title__active}
      data-tab={props.dataTab}
    >
      {capitalize(props.title)}
    </li>
  ) : (
    <li
      onClick={props.onClick}
      className={classes.tab_title}
      data-tab={props.dataTab}
      style={{ fontSize: "14px" }}
    >
      {capitalize(props.title)}
    </li>
  );
};

export const updateDefaultFilterValue = async ({
  filterElements,
  setFilter,
}) => {
  const obj = {};
  for (const filter of filterElements) {
    const value = filter.default_value;
    if (value) {
      if (filter.field_type === "rangePicker") {
        const date = [
          moment(value[0], "YYYY-MM-DD"),
          moment(value[1], "YYYY-MM-DD"),
        ];
        obj[filter.column_name] = date;
        await setFilter(obj, filter.column_name);
      } else {
        obj[filter.column_name] = value;
        await setFilter(obj, filter.column_name);
      }
    }
  }
  return obj;
};

export const handleMasterPlanAndReportSubmission = ({
  addSnack,
  filterElements,
  filterDependency,
  setFilterPayload,
}) => {
  let isValid = true;
  let formValid = true;

  const reqBody = filterElements.map((filter) => {
    let value = filterDependency[filter.column_name];
    if (filter.field_type === "rangePicker" && value?.length) {
      isValid = !(Boolean(value[0]) ^ Boolean(value[1]));
      value =
        Boolean(value[0]) & Boolean(value[1])
          ? [
              moment(value[0]).format("YYYY-MM-DD"),
              moment(value[1]).format("YYYY-MM-DD"),
            ]
          : [];
    }
    const isValuedArray = Array.isArray(filterDependency[filter.column_name])
      ? filterDependency[filter.column_name].filter(
          (filterValue) => !!filterValue
        ).length > 0
      : true;
    if (
      (filter.required || filter.is_mandatory) &&
      (!filterDependency[filter.column_name] || !isValuedArray)
    ) {
      formValid = false;
      addSnack({
        message: `${filter.label} is required`,
        options: {
          variant: "error",
        },
      });
    }
    return {
      filter_type: "string",
      attribute_name: filter.column_name,
      operator: "in",
      values: value ? [value].flat() : [],
    };
  });

  if (isValid) {
    if (formValid) {
      setFilterPayload(reqBody);
    }
  } else {
    addSnack({
      message: "Enter complete date range",
      options: {
        variant: "error",
      },
    });
  }
};

const StyledMenuItem = withStyles({
  root: {
    display: "block",
    padding: "0.5rem",
  },
})(MenuItem);

const StyledFormControlLabel = withStyles({
  label: {
    marginLeft: "1rem",
  },
})(FormControlLabel);
