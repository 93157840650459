import { createSlice } from "@reduxjs/toolkit";

import axiosInstance from "Utils/axios";

import {
  GET_STORE_STOCK_DRILL_DOWN_LIST,
  GET_STORE_STOCK_DRILL_DOWN_SIZE_DETAILS,
} from "modules/inventorysmart/constants-inventorysmart/apiConstants";

export const storeStockDrillDownService = createSlice({
  name: "storeStockDrillDownService",
  initialState: {
    stockDrillDownScreenLoader: false,
    stockDrillDownTableLoader: false,
    stockDrillDownTableData: [],
    stockDrillDownFilterConfiguration: [],
  },
  reducers: {
    setStockDrillDownScreenLoader: (state, action) => {
      state.stockDrillDownScreenLoader = action.payload;
    },
    setStockDrillDownTableLoader: (state, action) => {
      state.stockDrillDownTableLoader = action.payload;
    },
    setStockDrillDownTableData: (state, action) => {
      state.stockDrillDownTableData = action.payload;
    },
    setStockDrillDownFilterConfiguration: (state, action) => {
      state.stockDrillDownFilterConfiguration = action.payload;
    },
    clearStoreStockDrillDownStates: (state) => {
      state.stockDrillDownScreenLoader = false;
      state.stockDrillDownTableData = [];
      state.stockDrillDownFilterConfiguration = [];
    },
  },
});

export const {
  setStockDrillDownScreenLoader,
  setStockDrillDownTableData,
  setStockDrillDownFilterConfiguration,
  clearStoreStockDrillDownStates,
  setStockDrillDownTableLoader,
} = storeStockDrillDownService.actions;

export const getStockDrillDownTableData = (postbody) => () => {
  return axiosInstance({
    url: GET_STORE_STOCK_DRILL_DOWN_LIST,
    method: "POST",
    data: postbody,
  });
};

export const getStockDrillDownSizeDetails = (postbody) => () => {
  return axiosInstance({
    url: GET_STORE_STOCK_DRILL_DOWN_SIZE_DETAILS,
    method: "POST",
    data: postbody,
  });
};

export default storeStockDrillDownService.reducer;
